import styled from 'styled-components';

export const SelectField = styled.select`
font-weight: 500;
    font-size: 16px;
    color: #fff;
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    background-color: transparent; 
    width: 100%;
    &:focus {
        outline: none;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
`;
export const Error = styled.span`
    font-size: 11px;
    color: red;
`;
