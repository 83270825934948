import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { http } from '../../../components/http';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal } from 'antd';
import { Title } from '../../../components/b2c/index.styles';
import SEO from '../../../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentSucceeded = () => {
    const [data, setNum] = useQueryParam('data', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    useEffect(() => {
        
        navigate('/b2c', {state: { step: 3,data:data ,codecmr:codecmr}})
        // http.post('/card-order/paymentticketssucceeded', { orderId: data }).then(
        //     (res) => {
        //         setState({
        //             ...state,
        //             isLoading: false,
        //         });
        //     }
        // );
    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Paiement succès" url={'/b2c/success'} />
        </Layout>
    );
};

export default PaymentSucceeded;
