import React from 'react';
import { Wrapper, Content, Header, Title } from './styles';
import { IconWrapper, Icon } from '../product-card/payment-card/styles';
const PackCard = ({ title, content }) => {
    return (
        <Wrapper>
            <Header>
                <Icon
                    style={{ position: 'absolute', top: '100px', right: '48%' }}
                    icon="chevron-down"
                />
                <Title>{title}</Title>
            </Header>
            <Content>{content}</Content>
        </Wrapper>
    );
};

export default PackCard;
