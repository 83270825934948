import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 280px;
    height: 280px;
    min-width: 280px;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => (theme === 'dark' ? '#212121' : '#fff')};
    box-shadow: -1px 7px 19px 0px
        ${({ theme }) => (theme === 'dark' ? '#000' : '#e5e5e5')};
    border-radius: 10px;
    padding: 10px;
`;
export const Title = styled.h2`
    font-size: 22px;
    font-family: 'Maven Pro', sans-serif;
    color: #e96d25;
    margin: 0 0 20px 0;
    text-align: center;
`;
export const Content = styled.p`
    margin: 0;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 300;
    font-size: 15px;
    text-align: center;
    min-height: 40px;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '')};
    width: 100%;
`;
export const Button = styled.button`
    border-radius: 5px;
    padding: 0 10px;
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-width: 120px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    box-shadow: #e96d25 0px 0px 54px -14px;
    margin-top: 1rem;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e96d25 0px 0px 20px -14px;
    }
`;

export const IconWrapper = styled.div`
    width: 100px;
    height: 100px;
    max-height: 100px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Picture = styled.img`
    max-width: 100%;
    max-height: 100%;
`;
