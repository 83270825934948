import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { http } from '../../http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Title, SubTitle,InfoWrapper, ButtonWrapper, Button, BackButton } from '../styles';




export const CongratulationForm = ({
    values,
    orderType,
}) => {

    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });

    const dowlnoadReceipt = () =>{
        http.post('/card-order/pdf/'+state.data?.id,null,{ responseType: 'blob'}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reçu-paiment.pdf'); 
            document.body.appendChild(link);
            link.click();
         });
         
    }

    useEffect(() => {
        if (values.gData) {
            console.log(values.gData)
            setState({
                isLoading: true
            })
                http.post('/card-order/checkIfPaymentSuccess/',{ data: values.gData, codecmr: values.codecmr  }).then(
                    (res) => {
                         if(res.data.repauto ==="00"){
                            http.post('/card-order/payment', { data: values.gData, codecmr: values.codecmr }).then(
                                (res) => {
                                    setState({
                                        ...state,
                                        isLoading: false,
                                        data: res.data,
                                    });
                                }
                            );
                         }else{
                            values.message = "Votre commande n'a pas abouti. Merci de réessayer."
                            setState({
                                ...state,
                                isLoading: false,
                            });
                        }
                    }
                    );
        }else{
            console.log(values.orderId)
            http.post('/card-order/sendToCrm/'+values.orderId, null).then();
        }
    }, [values.paymentType]);
  
    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                minHeight: '250px',
                                                marginTop:'10rem',
                                                flexDirection: 'column',
                                            }}
                                        >
                                {!values.message || values.message =='' ? (
                                    <>
                                       {orderType ==='wac' ? (
                                        <>
                                            <Title
                                                style={{
                                                    fontSize: '35px',
                                                    color: '#4e4e4e',
                                                    textAlign:'center'
                                                }}
                                            >
                                                Merci pour votre commande
                                            <br />
                                            </Title>
                                            </>
                                            ):(
                                        <>
                                        <StaticImage style={{
                                            width: '9rem',
                                            height: '100%',
                                        }}
                                            formats={["auto", "webp"]}
                                            placeholder='blurred'
                                            className='center-img'
                                            src="../../../images/logo.webp"
                                        />
                                            <Title
                                                style={{
                                                    fontSize: '35px',
                                                    color: '#4e4e4e',
                                                    textAlign:'center'
                                                }}
                                            >
                                                Vous remercie 
                                                <br />
                                                pour votre commande
                                                <br />
                                            </Title>
                                            </>
                                            )}
                                            <Button  onClick={dowlnoadReceipt} style={{marginRight: '0',display: 'block',marginTop:'0.5rem',height: '35px',padding: '0 40px',fontWeight: '500'}} 
                                                    type="button" >
                                                Télécharger votre reçu
                                            </Button>
                                            <Link to="/individual#order">
                                                <BackButton style={{marginRight: '0',marginLeft: 'auto',display: 'block',marginTop:'0.5rem',height: '35px',padding: '0 40px',fontWeight: '500'}} 
                                                    type="button" >
                                                    Retour
                                                </BackButton>
                                            </Link>
                                </>
                                ):(
                                    <div style={{display: 'contents'}}>
                                     <FontAwesomeIcon
                                        icon={"times"}
                                        className='center-img'
                                        style={{
                                            fontSize: '80px',
                                            color: "#FF0000",
                                            width: '50px',
                                        }}
                    />
                                        <Title
                                            style={{
                                                fontSize: '35px',
                                                color: '#4e4e4e',
                                                textAlign:'center'
                                            }}
                                        >
                                            {values.message}
                                        </Title>
                                        <Link to="/individual#order">
                                            <Button style={{marginRight: '0',marginLeft: 'auto',display: 'block',marginTop:'0.5rem',height: '35px',padding: '0 40px',fontWeight: '500'}} 
                                                    type="button" >
                                                Réessayer
                                            </Button>
                                        </Link>

                            </div>
                                )}
                    </div>
                </Col>
            </Row>


        </>
    );
};
