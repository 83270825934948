import React, { Component, useEffect,useState } from 'react';
import {Row, Col,Modal} from 'antd';
import EventBooking from '../../common/event-booking';
import {Title, SubTitle, ButtonWrapper, Button, BackButton} from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import {navigate} from "@reach/router";
import { values } from 'lodash';

const data = [
    {
        "ongoing": true,
        "zones": [
            {
                "zone": 1,
                "name": "Zone 1",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 2,
                "name": "Zone 2",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 3,
                "name": "Zone 3",
                "price": 1,
                "color": "#1f4287"
            }
        ],
        "_id": "5e450eb31bc13b00153930be",
        "name": "TEAM1 VS TEAM2",
        "description": "TEAM1 VS TEAM2 Complexe Sportif Mohammed V - CasablancaSamedi 05 Mars 2022 à 18h15",
        "image": "https://guichet.imgix.net/medias/PnwEhLGKqA1jWq050wIFa5oqlkZmBBrlRvhZ5J7y.jpeg?w=900&h=600&fit=clip&auto=format,compress&q=80",
        "eventdate": "2022-04-27T18:17:00.000Z",
        "startprice": 1,
        "location": "casablanca",
        "codeName": "TEAM1-VS-TEAM2",
        "category": "Football",
        "isVisible": true
    },
    {
        "ongoing": true,
        "zones": [
            {
                "zone": 1,
                "name": "Zone 1",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 2,
                "name": "Zone 2",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 3,
                "name": "Zone 3",
                "price": 1,
                "color": "#1f4287"
            }
        ],
        "_id": "5e450eb31bc13b001cc",
        "name": "WAC VS PAL",
        "description": "WAC VS PAL Complexe Sportif Mohammed V - CasablancaSamedi 05 Mars 2022 à 18h15",
        "eventdate": "2022-04-17T21:00:00.000Z",
        "startprice": 1,
        "location": "casablanca",
        "image": "https://guichet.imgix.net/medias/PnwEhLGKqA1jWq050wIFa5oqlkZmBBrlRvhZ5J7y.jpeg?w=900&h=600&fit=clip&auto=format,compress&q=80",
        "codeName": "WAC-VS-PAL",
        "category": "Football",
        "isVisible": true,
        "redirectLink": "https://casawe.guichet.ma/billetterie/sport/wydad-athletic-club-vs-petro-athletico-luanda"
    },
    {
        "ongoing": true,
        "zones": [
            {
                "zone": 1,
                "name": "Zone 1",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 2,
                "name": "Zone 2",
                "price": 1,
                "color": "#1f4287"
            },
            {
                "zone": 3,
                "name": "Zone 3",
                "price": 1,
                "color": "#1f4287"
            }
        ],
        "_id": "1250eb31bc13b001539312",
        "name": "WAC VS FAR",
        "description": "WAC VS FAR Complexe Sportif Mohammed V - CasablancaSamedi 05 Mars 2022 à 18h15",
        "eventdate": "2022-04-19T21:00:00.000Z",
        "startprice": 1,
        "image": "https://casawe.s3.eu-west-3.amazonaws.com/index.jpg",
        "location": "casablanca",
        "codeName": "WAC-VS-FAR",
        "category": "Football",
        "isVisible": true,
        "redirectLink": "https://casawe.guichet.ma/billetterie/sport/wydad-athletic-club-vs-petro-athletico-luanda"
    }
]

export const WelcomeForm = ({
                                nextStep,
                                getAllEvents,
                                setValue,
                                values,
                            }) => {
    const handleNextClick = () => {
        nextStep();
        setValue('step', 1);
    };
    const [events, setEvent] = useState({
        events:{},
        loading:false,
    });
    const [state, setState] = useState({
        showModal: false,
        isLoading: false,
    });

    const toBuy = (event) => {
        setValue('event',event);
        setValue('eventName',event.name);
        setValue('eventdate',event.eventdate);
        setValue('description',event.description);
        setValue('picEvent',event.image);

        var zones = [];
        event.zones.map((zone) => {
            var obj={}
            obj.value = zone.zone
            obj.label =  zone.name + " - " + zone.price + "Dhs"
            zones.push(obj)
        });
        setValue('zones',zones);
        handleNextClick()
    }

    // function counter(date){
    //     var diff = {}                       
    //     var d1 = new Date(new Date(date).toISOString().slice(0, -1));
    //     var d2 = new Date()
    //     var tmp = d1 - d2;

    //     tmp = Math.floor(tmp/1000);             
    //     diff.sec = tmp % 60;                   
     
    //     tmp = Math.floor((tmp-diff.sec)/60);    
    //     diff.min = tmp % 60;                   
     
    //     tmp = Math.floor((tmp-diff.min)/60);   
    //     diff.hour = tmp % 24;                   
         
    //     tmp = Math.floor((tmp-diff.hour)/24);   
    //     diff.day = tmp;
         
    //     return diff;
    // }

    useEffect(() => {
        var allEvent=[];
        getAllEvents().then((res) => {
            res.data?.forEach((element) => {
                var d1 = new Date();
                var d2 = new Date(element.eventdate);
                if(d1 < d2 ){
                        allEvent.push(element);
                    }
              });
            setEvent({
                ...events,
               events:  allEvent,
               loading: true,
           });
    });

    if(values.messageError && values.messageError !=''){
        setState({
            ...state,
            showModal: true,
            isLoading: false,
        });
    }
 },[]);
    return (
        <>
        
            <Row align="middle" gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{padding: '0px 8px'}}
                >
                    <Title>Billetterie des matchs à Casablanca</Title>
                    <SubTitle>Choisissez un événement </SubTitle>
                    
                </Col>
            </Row>
            {events.loading === true && (
                  <>
            {events.events?.length >0  ? (
                <>
                <Row style={{width: '100%', justifyContent: 'center'}}>

                {events.events?.map((event, key) => {
                    return (
                        <>
                                <Col
                                key={key}
                                style={{margin: '0rem 0.5rem'}}
                                xxl={6}
                                xl={8}
                                lg={10}
                                sm={24}
                                xs={24}
                            >
                                <EventBooking 
                                    event={event}
                                    index={key}
                                    toBuy={toBuy}
                                />
                                </Col>
                        </> 
                    );
                })}
                </Row>
                <Row align="top" justify="center" style={{marginBottom: '20px'}}>
                    <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Link className='back-btn' to="/">Retour</Link>
                    </Col>
                </Row>
                </>
                ):(
                    <div style={{display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'}}>
                        <Title style={{color:'#e2651a'}}>
                            Aucun évènements en vente pour le moment.
                        </Title>
                        <Row align="top" justify="center" style={{marginBottom: '20px'}}>
                            <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12} style={{display: 'flex'}}>
                                <Link className='back-btn' to="/">Retour</Link>
                            </Col>
                        </Row>
                    </div>
                )}
                </>
            )}
                
              <Modal className='dialog'
                width='350px'
                visible={state.showModal}
                centered
                onCancel={() => {
                    setState({ ...state, showModal: false });                       
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '7rem',
                        flexDirection: 'column',
                    }}
                >
                    <FontAwesomeIcon
                        icon={"times"}
                        style={{
                            fontSize: '30px',
                            color: "#FF0000",
                            width: '30px',
                        }}
                    />
                   
                    <p style={{ textAlign: 'center', fontSize: '15px' }}>
                        {values.messageError}
                    </p>
                </div>
                <Button style={{marginRight: '0',marginLeft: 'auto',display: 'block',marginTop:'0.5rem',height: '35px',padding: '0 40px',fontWeight: '500'}} 
                onClick={() => {
                    setState({ ...state, showModal: false });                       
                }} type="button" >
                   Réessayer
                </Button>
            </Modal>
        </>

    );
};
