/* This example requires Tailwind CSS v2.0+ */

import React, { Fragment, useRef, useState } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import {
    MenuIcon,
    PlayIcon,
    CreditCardIcon,
    DeviceMobileIcon,
    ShoppingBagIcon,
    MailIcon,
    OfficeBuildingIcon,
    AcademicCapIcon,
    UserCircleIcon,
    TruckIcon,
    BookmarkIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Logo } from '../styles';
import { OrderLink, Wrapper, OrderButton } from './styles';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import NewspaperIcon from '@heroicons/react/outline/esm/NewspaperIcon';
import { Image } from '../../../image';
import { text } from '@fortawesome/fontawesome-svg-core';

const pros = [
    {
        name: 'TPE',
        href: '/products/tpe',
        description: 'Des solutions TPE simples et innovantes',
        state: {
            pack: 'tpe',
        },
        icon: DeviceMobileIcon,
    },
    {
        name: 'NAPS Pro',
        href: '/pro/entreprise',
        description: 'Digitalisez vos notes de frais et simplifiez leur gestion',
        state: {
            pack: 'entreprise',
        },
        icon: OfficeBuildingIcon,
    },
    {
        name: 'E-commerce',
        href: '/products/e-commerce',
        description: 'Des solutions e-commerce fluides et performantes',
        state: {
            pack: 'e-commerce',
        },
        icon: ShoppingBagIcon,
    },
    {
        name: 'PayByEmail',
        href: '/products/pay-by-email',
        description: 'Votre solution e-commerce sans boutique en ligne',
        state: {
            pack: 'pay-by-email',
        },
        icon: MailIcon,
    },
    {
        name: 'NAPS Campus',
        href: '/pro/campus',
        description: 'Notre solution pour digitaliser l’identité, les paiements et les services destinés à vos étudiants',
        state: {
            pack: 'institution',
        },
        icon: AcademicCapIcon,
    },
];

const acts = [
    {
        name: 'Articles de presse',
        href: '/news/articles',
        state: {},
        icon: NewspaperIcon,
    },
    {
        name: 'Vidéos',
        href: '/news/videos',
        state: {
            pack: 'family',
        },
        icon: PlayIcon,
    },
    {
        name: 'Blog',
        href: '/blog',
        state: {},
        icon: NewspaperIcon,
    }
];

const indis = [
    {
        name: 'Carte NAPS',
        description: ' La carte qui vous apporte toujours PLUS',
        href: '/individual',
        state: {},
        icon: CreditCardIcon,
    },
    /*{
        name: 'Carte Casawe',
        description: '1ère carte urbaine 100% Casablancaise',
        href: '/casawe',
        state: {},
        icon: CreditCardIcon,
    },*/
    {
        name: 'Paiement vignette',
        description: 'Payer et télécharger votre vignette en 2 clics',
        href: '/paiement-vignette-2023',
        state: {},
        icon: TruckIcon,
    },
    // {
    //     name: 'NAPS Family',
    //     description: 'Gérez vos finances familiales depuis votre Application Mobile !',
    //     href: '/individual#family',
    //     state: {
    //         pack: 'family',
    //     },
    //     icon: UsersIcon,
    // },
    // {
    //     name: 'NAPS Ambassadeur',
    //     description: 'Bientôt Disponible',
    //     href: '/products/ambassadeurs',
    //     icon: ShieldCheckIcon,
    // },

];
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NewHeader = ({ isTransparent }) => {
    const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
    const indRef = useRef(null);
    const proRef = useRef(null);
    const artRef = useRef(null);
    const toggleIndMenu = () => {
        indRef?.current?.click(); // eslint-disable-line
    };
    const toggleProMenu = () => {
        proRef?.current?.click(); // eslint-disable-line
    };
    const toggleArtMenu = () => {
        artRef?.current?.click(); // eslint-disable-line
    };

    const data = useStaticQuery(graphql`
        query {
            contentfulLogo {
                largeImage: image {
                    gatsbyImageData(width: 100, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 70)
                }
                smallImage: image {
                    gatsbyImageData(width: 100, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 50)
                }
            }
        }
    `);
    return (
        <Wrapper isTransparent={isTransparent} className="fixed sm:px-5 xs:px-5">
            <div className="container mx-auto lg:px-0 md:px-0 sm:px-5 px-5">
                <div
                    className="flex justify-between items-center border-b-2 border-gray-100 py-4 xl:space-x-10 md:space-x-7 xs:space-x-6">
                    <div
                        className="flex justify-start lg:w-0 "
                        style={{ width: '100%', flexBasis: '119px' }}
                    >
                        <Link to="/">
                            <span className="sr-only">Naps</span>
                            <Image style={{ width: '100%', height: '100%' }}
                                largeImage={data.contentfulLogo.largeImage}
                                smallImage={data.contentfulLogo.smallImage}
                                alt="naps.ma"
                                className="h-8 w-auto sm:h-10"
                            />
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none  focus:ring-inset focus:text-indigo-900">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon
                                onClick={() => setmobileMenuOpen(true)}
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </Popover.Button>
                    </div>
                    <Popover.Group
                        as="nav"
                        className="hidden md:flex xl:space-x-9 md:space-x-7 xs:space-x-6"
                    >
                        <Popover
                            className="relative"
                            onMouseEnter={() => toggleIndMenu()}
                            onMouseLeave={() => toggleIndMenu()}
                        >
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={indRef}

                                        className={classNames(
                                            open
                                                ? 'text-gray-900'
                                                : 'text-gray-500',
                                            'group rounded-md inline-flex items-center lg:text-sm md:text-xs  hover:text-indigo-900 focus:outline-none   hover:text-indigo-900',
                                        )}
                                    >
                                        <span onClick={() => navigate('/individual')}>Particuliers</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open
                                                    ? 'text-gray-600'
                                                    : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            className="absolute z-10 left-full transform -translate-x-1/2 px-2 w-screen max-w-md sm:px-0"
                                            style={{
                                                width: '50rem',
                                                height: '25rem',
                                            }}
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                    {indis.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <h2 className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </h2>
                                                                <h3 className="mt-1 lg:text-sm md:text-xs text-gray-500">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </h3>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Popover
                            className="relative"
                            onMouseEnter={() => toggleProMenu()}
                            onMouseLeave={() => toggleProMenu()}
                        >
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={proRef}
                                        className={classNames(
                                            open
                                                ? 'text-gray-900'
                                                : 'text-gray-500',
                                            'group rounded-md inline-flex items-center lg:text-sm md:text-xs hover:text-indigo-900 focus:outline-none   hover:text-indigo-900',
                                        )}
                                    >
                                        <span onClick={() => navigate('/b2b')}>Professionnels</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open
                                                    ? 'text-gray-600'
                                                    : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-indigo-500',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            className="absolute z-10 left-1/2 transform -translate-x-1/2  px-2 w-screen max-w-md sm:px-0 overflow-auto shadow-lg"
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                    {pros.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <h2 className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </h2>
                                                                <h3 className="mt-1 lg:text-sm md:text-xs text-gray-500">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </h3>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>


                        <Popover
                            className="relative"
                            onMouseEnter={() => toggleArtMenu()}
                            onMouseLeave={() => toggleArtMenu()}
                        >
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={artRef}

                                        className={classNames(
                                            open
                                                ? 'text-gray-900'
                                                : 'text-gray-500',
                                            'group rounded-md inline-flex items-center lg:text-sm md:text-xs  hover:text-indigo-900 focus:outline-none   hover:text-indigo-900',
                                        )}
                                    >
                                        <span onClick={() => navigate('/news/articles')}>Actualités</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open
                                                    ? 'text-gray-600'
                                                    : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            className="absolute z-10 left-full transform -translate-x-1/2 px-2 w-screen max-w-md sm:px-0"
                                            style={{
                                                width: '25rem',
                                                height: '25rem',
                                            }}
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                    {acts.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <h2 className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </h2>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Link
                            to="/franchises"
                            className="lg:text-sm md:text-xs  text-gray-500 hover:text-indigo-900"
                        >
                            Devenir Partenaire
                        </Link>
                        <Link
                            to="/billetterie"
                            className="lg:text-sm md:text-xs  text-gray-500 hover:text-indigo-900"
                        >
                            Billetterie
                        </Link>
                        <Link
                            to="/contact"
                            className="lg:text-sm md:text-xs text-gray-500 hover:text-indigo-900"
                        >
                            Nous Contacter
                        </Link>
                    </Popover.Group>
                    <OrderButton className="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
                        <OrderLink
                            to="/individual/#order"
                            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                            Commander votre carte
                        </OrderLink>
                    </OrderButton>
                </div>
            </div>
            {mobileMenuOpen &&
                <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div class="pt-5 pb-6 px-5">
                            <div class="flex items-center justify-between">
                                <div>
                                    <Link to="/">
                                        <span className="sr-only">Naps</span>
                                        <Image style={{ width: '100%', height: '100%' }}
                                            largeImage={data.contentfulLogo.largeImage}
                                            smallImage={data.contentfulLogo.smallImage}
                                            alt="naps.ma"
                                            className="h-8 w-auto sm:h-10"
                                        />
                                    </Link>
                                </div>
                                <div class="-mr-2">
                                    <button onClick={() => setmobileMenuOpen(false)} type="button"
                                        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span class="sr-only">Close menu</span>
                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="mt-6">
                                <nav class="grid gap-y-8">
                                    <Menu as="div"
                                        className="relative inline-block text-left -m-3 p-3 rounded-md hover:bg-gray-50">
                                        <div style={{ width: '100%' }}>
                                            <Menu.Button style={{ width: '100%' }}
                                                className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                                <UserCircleIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" />
                                                <span class="ml-3 text-base font-medium text-gray-900">
                                                    Particuliers
                                                </span>
                                                <ChevronDownIcon
                                                    className={classNames('text-gray-600', 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {indis.map((item) => (
                                                    <Menu.Item
                                                        className="p-3 flex items-start rounded-lg hover:bg-gray-50 p-2">
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                            style={{ alignItems: 'center' }}
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <p style={{ marginBottom: 0 }}
                                                                    className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <Menu as="div"
                                        className="relative inline-block text-left -m-3 p-3 rounded-md hover:bg-gray-50">
                                        <div style={{ width: '100%' }}>
                                            <Menu.Button style={{ width: '100%' }}
                                                className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                                <UserCircleIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" />
                                                <span class="ml-3 text-base font-medium text-gray-900">
                                                    Professionnels
                                                </span>
                                                <ChevronDownIcon
                                                    className={classNames('text-gray-600', 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {pros.map((item) => (
                                                    <Menu.Item
                                                        className="p-3 flex items-start rounded-lg hover:bg-gray-50 p-2">
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                            style={{ alignItems: 'center' }}
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <p style={{ marginBottom: 0 }}
                                                                    className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </Menu.Item>
                                                ))}

                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <Menu as="div"
                                        className="relative inline-block text-left -m-3 p-3 rounded-md hover:bg-gray-50">
                                        <div style={{ width: '100%' }}>
                                            <Menu.Button style={{ width: '100%' }}
                                                className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                                <UserCircleIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" />
                                                <span class="ml-3 text-base font-medium text-gray-900">
                                                    Actualités
                                                </span>
                                                <ChevronDownIcon
                                                    className={classNames('text-gray-600', 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {acts.map((item) => (
                                                    <Menu.Item
                                                        className="p-3 flex items-start rounded-lg hover:bg-gray-50 p-2">
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                            style={{ alignItems: 'center' }}
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <div className="ml-4">
                                                                <p style={{ marginBottom: 0 }}
                                                                    className="lg:text-sm md:text-xs font-medium text-gray-900">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </Menu.Item>
                                                ))}

                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <Link to="/franchises"
                                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <svg className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                        </svg>
                                        <span class="ml-3 text-base font-medium text-gray-900">
                                            Devenir Partenaire
                                        </span>
                                    </Link>
                                    <Link
                                        to="/billetterie"
                                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" 
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" 
                                            stroke="currentColor" aria-hidden="true">
                                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z" fill="blue"></path> 
                                        </svg>
                                        <span class="ml-3 text-base font-medium text-gray-900">
                                            Billetterie
                                        </span>
                                    </Link>

                                    <Link to="/contact" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">

                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            className="flex-shrink-0 h-6 w-6 text-indigo-600" fill="none"
                                            viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                        </svg>
                                        <span class="ml-3 text-base font-medium text-gray-900">
                                            Nous Contacter
                                        </span>
                                    </Link>
                                </nav>
                            </div>
                        </div>
                        <div class="py-6 px-5 space-y-6">
                            <div>
                                <Link
                                    style={{ padding: '16px 20px' }}
                                    to="/individual/#order"
                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    Commander votre carte
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Wrapper>
    );
};
export default NewHeader;
