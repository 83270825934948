import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import {
    Wrapper,
    Info,
    WrapTitle,
    TitleWrapper,
    Line,
    HelpText,
    BottomText,
    ContactText,
} from './styles';
import './styles.css';
import ContactForm from './form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Zoom, Fade } from 'react-awesome-reveal';
import { detect } from 'detect-browser';
const ContactTemplate = () => {
    const [state, setState] = useState({
        cardStyle: {},
        cardWrapperStyle: {display: 'none'},
        form: {display: 'flex'},
        br: '',
    });

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    return (
        <Wrapper>
            <Zoom>
                <div className="wrap" style={state.cardWrapperStyle}>
                    <div
                        className="pre-form-wrapper"
                        style={state.br === 'ie' ? { marginTop: '160px' } : {}}
                    >
                        <TitleWrapper>
                            <Line />
                            <WrapTitle>NOUS</WrapTitle>
                        </TitleWrapper>
                        <ContactText style={{ color: '#e66d30' }}>
                            Contacter
                        </ContactText>
                        <HelpText>
                            Vous avez besoin <br /> d'un conseil ou d'un
                            accompagnement ?
                        </HelpText>
                        <BottomText>
                            Remplissez le formulaire ci-après, nous serons ravis
                            de <br /> vous contacter !
                        </BottomText>
                    </div>
                </div>
            </Zoom>
            <Row className="contact-modal" style={state.form}>
                <Col className="contact-left" lg={8} md={24} sm={24} xs={24}>
                    <Row
                        style={{
                            width: '100%',
                            height: '100%',
                            alignContent: 'center',
                        }}
                    >
                        <Col
                            xs={24}
                            style={{
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: 'fit-content',
                                margin: '20px',
                            }}
                        >
                            <Fade left>
                                <FontAwesomeIcon
                                    icon="envelope"
                                    style={{
                                        color: '#e66d30',
                                        fontSize: '75px',
                                        width: '40px',
                                    }}
                                />
                                <Info>info@naps.ma</Info>
                            </Fade>
                        </Col>
                        <Col
                            xs={24}
                            style={{
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: 'fit-content',
                                margin: '20px',
                            }}
                        >
                            <Fade left>
                                <FontAwesomeIcon
                                    icon="phone"
                                    style={{
                                        color: '#e66d30',
                                        fontSize: '75px',
                                        width: '40px',
                                        marginBottom: '20px',
                                    }}
                                />
                                <Info>Offres Carte & Application Mobile</Info>
                                <Info>05 22 91 74 75</Info>

                                <Info>Offres TPE & e-Commerce</Info>
                                <Info>05 22 91 74 74</Info>
                            </Fade>
                        </Col>
                    </Row>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}></Col>
                <Col lg={16} md={24} sm={24} xs={24}>
                    <ContactForm />
                </Col>
            </Row>
        </Wrapper>
    );
};
export default ContactTemplate;
