import styled, { css } from 'styled-components';
import EntBack from '../../../../images/entrepriseplatformback.svg';
import EntBackDark from '../../../../images/entrepriseplatformbackdark.svg';
import IeEntBack from '../../../../images/ie-entrepriseplatformback.webp';
import IeEntBackDark from '../../../../images/ie-entrepriseplatformbackdark.webp';
import { ImageWrapper } from '../../../common/SimpleRow/styles';
export const Title = styled.h2`
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#383838')};
    text-align: center;
    max-width: 645px;
    font-family: 'Maven Pro', sans-serif;
`;
export const OfferRow = styled.div`
    ${({ br }) =>
        br === 'ie'
            ? css`
                  background-image: ${({ theme }) =>
                    theme === 'dark'
                        ? `url(${IeEntBackDark})`
                        : `url(${IeEntBack})`};
              `
            : css`
                  background-image: ${({ theme }) =>
                    theme === 'dark'
                        ? `url(${EntBackDark})`
                        : `url(${EntBack})`};
              `};

    height: auto;
    padding: 30px 20px;

    background-repeat: no-repeat;
    background-size: cover;
`;

export const ThemedImageWrapper = styled(ImageWrapper)`
    background-color: ${({ theme }) =>
        theme === 'dark' ? 'rgb(43,42,42)' : ''};
    margin-bottom: 50px;
    max-width: 22rem;
    max-height: 22rem;
    height: 20rem;
    @media(max-width: 574px){
        max-width: 16rem;
        max-height: 16rem;
    }
`;
