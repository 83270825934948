import { Popover } from '@headlessui/react';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const Wrapper = styled(Popover)`
    z-index: 10;
    background-color: ${({ theme, isTransparent }) => !isTransparent ? theme === 'dark' ? '#212121' : '#fff' : 'transparent'};
    width: 100vw;
`;

export const OrderButton = styled.button`
   
   @media (max-width:771px) { 
       display: none;
   }
   
   @media (max-width:1289px) { 
        margin: 0 0 0 10px;
    }
`;
export const OrderLink = styled(Link)`
    padding: 16px 20px;
    @media (max-width:1289px) { 
        font-size: 12px;
        padding: 10px!important;
        margin: 0 0 0 10px;
    }
 `