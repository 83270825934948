import React from 'react';
import casaweGoldCard from '../../../images/slide/casawe-carte-gold.webp'
import SEO from '../../seo';
import { Link } from 'gatsby';
import { Zoom, Fade } from 'react-awesome-reveal';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { InfoWrapper } from './styles';
import {
    Title,
    SubTitle,
    Button,
    ImageWrapper,
    AnimatedImage,
} from '../../landing/styles';

const imageStyle = {
    maxWidth: '100%',
    cursor: 'pointer',
    zIndex: '1',
}

const CasaweSlide = () => {
    return (
        <>
            <div
            className="container mx-auto auto-top-margin row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
        >
            <div
                className="justify-center"
            >
                <InfoWrapper>
                    <Fade left>
                        <Title>
                        <span style={{ display: 'block' }} >Carte CasaWe : </span>une seule carte pour tous vos besoins
                        </Title>
                    </Fade>
                    <Zoom right>
                        <SubTitle style={{color: 'inherit'}} >
                            la smart city c'est à vivre maintenant avec la carte CasaWe ! une carte intelligente pour payer et pour accéder aux événements et services de la ville de Casablanca
                        </SubTitle>
                        <SubTitle style={{backgroundColor: '#285da6',
                                         fontSize: '22px',
                                         width: 'fit-content',
                                         padding: '2px 8px',
                                         borderRadius: '5px',}} >
                            Profitez de la promotion ! 
                        </SubTitle>
                    </Zoom>

                    <div style={{ position: 'relative' }}>
                        <Link style={{ color: '#fff' }} to="#order"  state={{ pack: 'casawe' }}>
                            <Button>
                                Je commande ma carte maintenant{' '}
                            </Button>
                        </Link>
                    </div>
                </InfoWrapper>
            </div>
            <div
                className="justify-center"
            >
                <ImageWrapper style={{ maxWidth: '80%' }}>
                    <Fade right>
                    <StaticImage style={imageStyle}
                        // src='../../../images/slide/casawe-carte-gold.webp'
                        src='../../../images/slide/carte-casawe-promo.webp'
                        alt="casaWe-Card"/>
                    </Fade>
                </ImageWrapper>
            </div>
        </div>
        </>
    );
};
export default CasaweSlide;
