import React, { useContext, useEffect, useState } from 'react';

import './styles.css';

import { graphql, useStaticQuery } from 'gatsby';
import ThemeContext from '../../layout/theme/ThemeContext';
import {
    ArticleRow,
    InfoWrapper,
    ImageWrapper,
} from './styles';
import { Col } from 'antd';
import ArticleCard from '../../common/article-card';
import ArticleImageCard from '../../common/article-image-card';
import { detect } from 'detect-browser';
import { Button, ButtonIcon } from '../../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";


const Articles = () => {
    const [selectedArticle, setSelectedArticle] = useState(undefined)
    const { articles } = useStaticQuery(graphql`
            query {
              articles: allContentfulArticles(sort: {order: ASC, fields: order}) {
                     nodes {
                          content {
                             content
                           }
                          image {
                            file {
                               url
                            }
                         }
                          logo {
                            file {
                               url
                             }
                          }
                      title
                      link
                    }
                  }
             }
        `);

    const i = articles?.nodes.length - 1;
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        if (!selectedArticle) {
            const i = articles?.nodes.length - 1;
            setSelectedArticle(articles?.nodes[i])
        }
    })
    return (
        <div className="news-article">
            <ArticleRow theme={theme} justify="center">
                <Col className="justify-center border-left" lg={10} md={10} sm={25} xs={25}>
                    <InfoWrapper>
                        {articles?.nodes.map((item, index) => {
                            return (
                                <ArticleCard key={index}
                                    title={item.title}
                                    content={item.content.content}
                                    image={item.logo?.file.url}
                                    link={item.link}
                                    onMouseEnter={() => setSelectedArticle(item)}
                                >

                                </ArticleCard>
                            );
                        })}
                    </InfoWrapper>
                </Col>
                <Col className="justify-center border-right" lg={12} md={8} sm={23} xs={23} >

                    <ImageWrapper background={selectedArticle?.image?.file.url}>
                        <ArticleImageCard title={selectedArticle?.title}
                            content={selectedArticle?.content.content}
                            link={selectedArticle?.link}>
                        </ArticleImageCard>
                    </ImageWrapper>
                </Col>
            </ArticleRow>
        </div>
    );
};

export default Articles;
