import styled from 'styled-components';
import { Row } from 'antd';


export const ArticleWrapper = styled(Row)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: solid 2px #00000010;
`;

export const ArticleImage = styled.img`
    max-width: 30%;
    cursor: pointer;
    height:5rem;
    z-index: 1;
`;


export const Title = styled.h3`
    padding: 0;
    margin: 0 0 1.45rem;
    color: #3c3c3b;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 1.1rem;
    margin: 0;
    font-weight: bold;
    @media (max-width:801px) {
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }
`;

export const SubTitle = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    color: #3c3c3b;
    @media (max-width:801px) {
        font-size: 14px;
    }
`;

export const Button = styled.button`
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #e66d30;
    width:50%;
    margin-top: 1rem;
    padding: 0.6rem 1.5rem 0.5rem 1.5rem;
    color: #e66d30;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    font-size: 15px;
    position: relative;
`;