import React, { useRef, useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Formik } from 'formik';
import { Image } from '../../components/image';
import SEO from '../../components/seo';
import * as S from '../../styles/tpe-accepter-paiement-carte-style'
import { Carousel } from 'antd';
import * as Yup from 'yup';
import { http } from '../../components/http'
import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image';
import Footer from '../../components/layout/footer'


const TpeLanding = () => {

    const formRef = useRef()

     const formSchema = Yup.object().shape({
       firstName: Yup.string()
         .required('Obligatoire'),
       lastName: Yup.string()
         .required('Obligatoire'),
       email: Yup.string().email('Email invalide').required('Obligatoire'),
       phone: Yup.string().required('Obligatoire'),
       sector: Yup.string().required('Obligatoire'),
       deliveryDate: Yup.date('Date invalide').required('Obligatoire'),
     });

    const [submitted, setSubmitted] = useState(false)


    const handleSubmitButton = () => {
        if(!formRef.current) {
            return
        }

        if (formRef.current) {
          formRef.current.handleSubmit()
        }

        
    }
    
    const handleSubmit = (values) => {
        if(!formRef.current.isValid) {
            return
        }
        http.post('/tpe', {isClient: false ,type: "tpe", ...values}).then((res) => {
            formRef.current.resetForm()
            setSubmitted(true)
        });
    }

    const data = useStaticQuery(graphql`
        query {
            contentfulLogo {
                largeImage: image {
                    gatsbyImageData(width: 100, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 70)
                }
                smallImage: image {
                    gatsbyImageData(width: 100, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 50)
                }
            }
            slide1: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/images/tpe-landing/carousel/1/"}}) {
                  edges {
                    node {
                      id
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
            }
            slide2: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/images/tpe-landing/carousel/2/"}}) {
                  edges {
                    node {
                      id
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
            }
            slide3: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/images/tpe-landing/carousel/3/"}}) {
                  edges {
                    node {
                      id
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
            }
        }
    `);
    return (
        <div className="flex flex-col sm:justify-center">
            <SEO title="Naps TPE" url="tpe-accepter-paiement-carte" />
            <S.Header className="sticky top-0">
                <div className="container mx-auto flex flex-row justify-between items-center sm:p-0">
            
                            <Image style={{ width: '100%', height: '100%' }}
                                largeImage={data.contentfulLogo.largeImage}
                                smallImage={data.contentfulLogo.smallImage}
                                alt="naps.ma"
                                className="h-8 w-auto sm:h-10"
                            />
            <div className="flex gap-3">
                <S.BadgeInfo>info@naps.ma</S.BadgeInfo>
                <S.BadgeInfo>05 22 91 74 74</S.BadgeInfo>
            </div>
            </div>
            </S.Header>
            <S.Banner>
            <S.BannerLabel>
                <div className="container mx-auto flex flex-row justify-between pt-3 pb-1 flex-wrap">
                    <div className="flex flex-col justify-between items-center">
                    <S.Title>Acceptez les paiements <br /> par carte dès maintenant</S.Title>
                    <S.Subtitle>Commandez et installez votre TPE en 24h</S.Subtitle>
                    </div>
        <S.FormSection>
            <S.FormWrapper className="sm:w-full ">
            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    lastName: '',
                                    firstName: '', 
                                    sector: '',
                                    email: '',
                                    phone: '',
                                    deliveryDate: '',
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={formSchema}
                            >
                                {({
                                    isValid,
                                    setFieldTouched,
                                    errors,
                                    setTouched,
                                    validateForm,
                                    setFieldValue,
                                    values,
                                    isSubmitting,
                                }) => (

                                    <S.Form className="w-full h-full"  autoComplete="on">

                                        <S.FormControl  className="flex flex-row"> 
                                            <S.FormLabel>Nom {errors.lastName && <S.ErrorMessage>({errors.lastName})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="lastName" required />
                                        </S.FormControl>

                                        <S.FormControl className="flex flex-row"> 
                                            <S.FormLabel>Prénom {errors.firstName && <S.ErrorMessage>({errors.firstName})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="firstName" required />
                                        </S.FormControl>

                                        <S.FormControl className="flex flex-row"> 
                                            <S.FormLabel>Secteur d&apos;activité {errors.sector && <S.ErrorMessage>({errors.sector})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="sector" required />
                                        </S.FormControl>

                                        <S.FormControl className="flex flex-row"> 
                                            <S.FormLabel>Email {errors.email && <S.ErrorMessage>({errors.email})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="email" type="email" required />
                                        </S.FormControl>

                                        <S.FormControl className="flex flex-row"> 
                                            <S.FormLabel>Téléphone {errors.phone && <S.ErrorMessage>({errors.phone})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="phone" required />
                                        </S.FormControl>

                                        <S.FormControl className="flex flex-row"> 
                                            <S.FormLabel>Date de livraison souhaitée {errors.deliveryDate && <S.ErrorMessage>({errors.deliveryDate})</S.ErrorMessage>}</S.FormLabel>
                                            <S.FormField name="deliveryDate" required type="date"/>
                                        </S.FormControl>
                                        <button type="submit" style={{display: 'none'}}/>
                                    </S.Form>
                                )}
                        </Formik>
                        </S.FormWrapper>
                        <S.FormButton submitted={submitted} disabled={submitted} type="button" onClick={handleSubmitButton}>{submitted ? 'Votre demande a bien été prise en compte': 'Soumettre ma demande'}</S.FormButton>
                    </S.FormSection>
                </div>
            </S.BannerLabel>
            </S.Banner>
            <div className="flex flex-col justify-center mt-16">
                <S.LabelPrimary>+ 1500</S.LabelPrimary>
                <S.Label>Commençants au Maroc nous font confiance</S.Label>
            </div>
          <Carousel autoplay dots={false} className="my-20">
                <div className="flex flex-row justify-center gap-12 flex-nowrap">
                {data.slide1.edges.map(edge => {
                    return (<div key={edge.node.id} style={{width: '128px', height: '128px'}}>
                          <Img  fluid={edge.node.childImageSharp.fluid} />
                        </div>)
                })}
        </div>
                <div className="flex flex-row justify-center gap-12 flex-nowrap">
                {data.slide2.edges.map(edge => {
                    return (<div key={edge.node.id} style={{width: '128px', height: '128px'}}>
                          <Img  fluid={edge.node.childImageSharp.fluid} />
                        </div>)
                })}
        </div>
                <div className="flex flex-row justify-center gap-12 flex-nowrap">
                {data.slide3.edges.map(edge => {
                    return (<div key={edge.node.id} style={{width: '128px', height: '128px'}}>
                          <Img  fluid={edge.node.childImageSharp.fluid} />
                        </div>)
                })}
        </div>
      </Carousel>
        <S.Label style={{textAlign: 'center'}} className="flex flex-row w100 justify-center tracking-wide mb-20">Une offre clé en main pour un <br /> lancement rapide avec des <br /> coûts maîtrisés</S.Label>
        <div className="flex flex-row justify-center gap-12">
            <S.GreyContainer className="flex flex-col gap-12">
                <S.LabelPrimary>Forfaits <br /> sans commission</S.LabelPrimary>
                <S.Text>Débutez avec un forfait <br /> en mensualité fixe sans commission...</S.Text>
                <a href="./images/tpe-landing/Brochure_TPE_GO.pdf" download><S.FormButton style={{padding: "5px"}}>Télécharger la brochure</S.FormButton></a>
            </S.GreyContainer>
           <StaticImage width="270" src="../../images/tpe-landing/zero-percent.webp" />
        </div>
        <div className="flex flex-row justify-center gap-12">
            <StaticImage width="270" src="../../images/tpe-landing/phone.webp" />
            <S.GreyContainer className="flex flex-col gap-12">
                <S.LabelPrimary>Encaissez aussi <br /> à distance en ligne</S.LabelPrimary>
                <S.Text>Avec les modules E-Commerce <br /> ou Pay-By-Link de Naps...</S.Text>
                <a href="./images/tpe-landing/Brochure_Ecommerce.pdf" download><S.FormButton style={{padding: "5px"}}>Télécharger la brochure</S.FormButton></a>
            </S.GreyContainer>
        </div>
        <div className="flex flex-row justify-center gap-12">
            <S.GreyContainer className="flex flex-col gap-12">
                <S.LabelPrimary>Service client <br /> à votre écoute 24/7 </S.LabelPrimary>
                <S.Text>Support commercial et technique <br /> à votre disposition <br /> à tout moment</S.Text>
                <S.FormButton style={{padding: "5px"}}>Appeler le service client</S.FormButton>
            </S.GreyContainer>
            <StaticImage width="270" src="../../images/tpe-landing/customer-service.webp" />
        </div>
        <S.Label className="flex flex-row justify-center text-center">Commencez à encaissez par <br /> carte dès maintenant</S.Label>
        <S.ButtonCall className="mt-4">Prendre rendez-vous dès maintenant</S.ButtonCall>
        <Footer />
    </div>
    );
};

export default TpeLanding;
