import styled from 'styled-components';
import { Drawer, Menu } from 'antd';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: ${({ theme, isTransparent }) => !isTransparent ? theme === 'dark' ? '#212121' : '#fff' : 'transparent'};
    width: 100vw;
    padding: 9px 0;
    align-items: center;
    box-shadow: ${({ theme, isTransparent }) => !isTransparent ? theme === 'dark' ? 'rgba(0, 0, 0, 0.9) 2px 0px 20px 0px' : 'rgba(0, 0, 0, 0.2) 2px 0px 20px 0px' : ''} ;
    position: fixed;
    /* margin-bottom:70px; */
    z-index: 3;
    overflow: hidden;
    transition: all 0.2s ease-out;
    @media (max-width: 877px) {
        justify-content: space-between;
    }
`;

export const Brand = styled.div`
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    align-items: flex-start;
    width: auto;
    margin-right: 25px;
    /* justify-content: center;
    align-items: center; */
`;

export const Logo = styled(GatsbyImage)`
    /* width: 100px; */
`;

export const SideBarToggler = styled.div`
    display: inline-block;
    padding: 10px;
    position: sticky;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '')};
`;


export const SideMenu = styled(Menu)`
    background: ${({ ctheme }) => (ctheme === 'dark' ? '#212121' : '#fff')};
`;

export const SubMenu = styled(Menu.SubMenu)`
    background: ${({ ctheme }) => (ctheme === 'dark' ? '#212121' : '#fff')};
    font-size: 13px;
`;

export const ThemedLink = styled(Link)`
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
`;
