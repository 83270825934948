import styled from 'styled-components';
import { Field, Form as FormikForm} from 'formik';

const Header = styled.div`
    background-color: #ffffff;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 1rem 0;
    z-index: 1001;
`;

const BadgeInfo = styled.div`
    display: flex;
    background-color: #E66D30;    
    padding: 8px;
    border-radius: 5px;
    line-height: initial !important;
    color: #ffffff;
`;

const Banner = styled.div`
    background-image: url('../../images/tpe-landing/tpe-banner-background.webp');
    background-size: cover;
    width: 101vw;
    margin-left: -0.5vw;
    display: flex;
`
const BannerLabel = styled.div`
    background-image: url('../../images/tpe-landing/tpe-banner.webp');
    background-size: contain;
    background-repeat: no-repeat;
    width: 101vw;
    display: flex;
    flex: 1;
`

const Title = styled.h1`
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
`

const Subtitle = styled.h2`
    color: #ffffff;
    font-size: 24px;
`

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 640px) {
        width: 100%;
    } 
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-bottom: 0;
    background-color: #ffffff;
    height: fit-content;
    border-radius: 5px;
`

const FormControl = styled.div`
    border-bottom: 1px solid black;
    
`

const FormLabel = styled.p`
    padding: 10px;
    margin: 0;
    width: 50%;
`

const FormField = styled(Field)`
    border-left: 1px solid black;
    text-indent: 5px;
    width: 49%;
`

const FormButton = styled.button`
    background-color: #201B50;
    color: #ffffff;
    font-size: 18px;
    margin: 20px 0px;
    border-radius: 5px;
    font-weight: bold;
    padding: 20px;
    opacity: ${({ submitted }) => submitted ? "0.9" :  "1"};
    cursor: ${({ submitted }) => submitted ? "default" :  "pointer"};
`

const ErrorMessage = styled.span`
    color: red;
`

const Label = styled.div`
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
`

const LabelPrimary = styled.div`
    font-size: 2rem;
    color: #E66D30;    
    font-weight: 600;
    text-align: center;
`

const Text = styled.div`
    font-size: 1rem;
    font-weight: 600;
`

const GreyContainer = styled.div`
    background-color: #F8F8F8;
    padding: 20px;
    text-align: center;
` 

const ButtonCall = styled.button`
    display: flex;
    margin: auto;
    margin-bottom: 24px;
    padding: 10px;
    border-radius: 3px;
    font-weight: 600;
    justify-content: center;
    background-color: #E66D30;    
    color: #ffffff;
`
const Form = styled(FormikForm)`
    width: 480px;
    height: 100%;
    @media (max-width: 640px) {
        width: 100%;
    } 

`

export { Header, BadgeInfo, Banner, BannerLabel, Title, Subtitle, FormWrapper, FormControl, FormLabel, FormField, FormButton, FormSection, ErrorMessage, Label, LabelPrimary, Text, GreyContainer, ButtonCall, Form}
