import React,{ useState } from 'react';
import {Row, Col} from 'antd';
import {TextField} from '../../text-field';
import PhoneIcon from '../../../images/phone.inline.svg';
import {Select} from '../../select';
import {Title, SubTitle, ButtonWrapper, Button, BackButton} from '../styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RadioField from '../../common/radio-field';
import { Input, Label } from '../../text-field/test-field.styles';
import { Card } from 'antd';
import imageExUrl1 from '../../../images/CARTE-NAPS-Gold-ID.png';
import imageExUrl2 from '../../../images/CARTE-NAPS-Gold-4-derniers-chiffres-num-carte.png';
import { http } from '../../../components/http';

import {
    onlyCharacters,
    emailKeyPress,
} from '../../helpers/only-characters';
import {navigate} from "@reach/router";
import { values } from 'lodash';

const civilities = [
    {value: 'M.', label: 'M.'},
    {value: 'Mme', label: 'Mme'},
];

const operators = [
    {value: 'IAM', label: 'IAM'},
    {value: 'INWI', label: 'INWI'},
    {value: 'ORANGE', label: 'ORANGE'},
];
export const WelcomeForm = ({
                                back,
                                nextStep,
                                isValid,
                                setTouched,
                                validate,
                                setValue,
                                orderType,
                                openNotification,
                                showIdClient
                            }) => {
    const [phoneValue, setPhoneValue] = useState('');
    const handlePhoneChange = (e) => {
        const phone = e.target.value;
        setPhoneValue(phone);
        setValue('phone', phone); // Mise à jour de la valeur du téléphone dans le formulaire
    };
    const handleNextClick = () => {
        setTouched({
            civility: true,
            firstName: true,
            lastName: true,
            email: true,
            confirmEmail: true,
            phone:true,
            confirmPhone:true,
            idClient: true,
            fourLastCardDigits: true,
        });
        validate().then((errors) => {
            if (Object.keys(errors).length === 0) {
                nextStep();
                handleCodePhone(phoneValue);
                setValue('step', orderType === 'wac' ? 2 : 1);
                //console.log("Numéro de téléphone:", phoneValue);
            } else {
                console.log(errors);
                openNotification();
            }
        });
    };
    const handleCodePhone = async (phone) => {
        try {
            const resphone = await http.get(`/code-phone/check-phone/${phone}`);
            //console.log("Réponse de l'API: ", resphone.data);
        } catch (error) {
            console.error('Erreur lors de l\'appel à l\'API :', error);
        }
    };
    const handleFourLastDigitsChange = (event) => {
            //setValue('fourLastCardDigits', event.target.value);
            if (event.target.length <= 4) {
                setValue('fourLastCardDigits', event.target.value);
            }else {
                setValue('fourLastCardDigits',event.target.value.slice(0, 4));
            }
      };

    const handleIdClientChange = (event) => {
        if (event.target.length <= 12) {
            setValue('idClient', event.target.value);
        }else {
            setValue('idClient',event.target.value.slice(0, 12));
        }
      };

    const handleChangeLastName = (event) => {
        const result = event.target.value.replace(/[^a-z]/gi, '').slice(0, 20);
            setValue(
                'lastName',
                result
                    ? result.toUpperCase()
                    : ''
            );
      };

    const handleChangeFirstName = (event) => {
            const result = event.target.value.replace(/[^a-z]/gi, '').slice(0, 20);
            setValue(
                'firstName',
                result
                    ? result
                        .substring(0, 1)
                        .toUpperCase() +
                        result
                        .substring(1)
                        .toLowerCase()
                    : '');
      };
      
      
    return (
        <>
            <Row align="middle" className='field-left'  gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{padding: '0px 8px'}}
                >
                    <Title>Coordonnées</Title>
                </Col>
            </Row>
            <Row align="middle" className='field-title' gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{
                        margin: '0',
                        padding: '20px 8px 0 8px',
                        marginBottom: '-10px',
                    }}
                >
                    <SubTitle
                        style={{
                            color: '#e66d30',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: '25px',
                                width: '40px',
                                marginRight: '2px',
                            }}
                            icon={['fa', 'user-circle']}
                        />
                        Profile
                    </SubTitle>
                </Col>
            </Row>
        {showIdClient && <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={14}>
                    <TextField
                        name="idClient"
                        placeholder=""
                        label="Identifiant client (inscrit sur votre carte)"
                        type="number"
                        setValue={setValue}
                        maxLength={12}                        
                        onChange={handleIdClientChange}
                    ></TextField>
                </Col>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Card 
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Example: Identifiant client"
                                src={imageExUrl1}
                                 />}
                            >
                                Ex: Identifiant client   
                        </Card>
                        <Card 
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Example: 4 derniers chiffres de la carte"
                                src={imageExUrl2}
                                 />}
                            > 
                                Ex: 4 derniers chiffres de la carte
                        </Card>
                    </div>
                </Col>
            </Row>}
        {showIdClient && <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <TextField
                        name="fourLastCardDigits"
                        placeholder=""
                        label="4 derniers chiffres de la carte"
                        type="number"
                        setValue={setValue}
                        maxLength={4}
                        onChange={handleFourLastDigitsChange}
                    ></TextField>
                </Col>
            </Row>}
            <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <div style={{padding: '12px 8px'}}>
                        <p style={{ fontSize: '16px',
                                        fontWeight: '500',
                                        marginBottom: '10px',
                                        color: 'rgba(0,0,0,0.7)',
                                        fontFamily: 'Maven Pro'}}>
                            Civilité *
                        </p>
                        <RadioField
                            values={civilities}
                            name="civility"
                        ></RadioField>
                    </div>
                </Col>
            </Row>
            <Row align="top"  className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={9} lg={6} xl={6} xxl={5}>
                    <TextField
                        name="firstName"
                        placeholder=""
                        label="Prénom"
                        type="text"
                        setValue={setValue}
                        maxLength={20}
                        onChange={handleChangeFirstName}
                        keyPress={onlyCharacters}
                    ></TextField>
                </Col>
                <Col xs={24} sm={24} md={9} lg={6} xl={6} xxl={5}>
                    <TextField
                        name="lastName"
                        placeholder=""
                        label="Nom"
                        type="text"
                        setValue={setValue}
                        maxLength={20}
                        onChange={handleChangeLastName}
                        keyPress={onlyCharacters}
                    ></TextField>
                </Col>
            </Row>
            <Row align="middle" className='field-title' gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{
                        margin: '0',
                        padding: '20px 8px 0 8px',
                        marginBottom: '-10px',
                    }}
                >
                    <SubTitle
                        style={{
                            color: '#e66d30',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: '25px',
                                width: '40px',
                                marginRight: '2px',
                            }}
                            icon={['fa', 'envelope']}
                        />
                        Email
                    </SubTitle>
                </Col>
            </Row>
            <Row align="top" className='field-left' gutter={[16, 24]}>
            <Col xs={24} sm={24} md={17} lg={11} xl={11} xxl={9}>
                    <TextField
                        label="Votre e-mail"
                        placeholder=""
                        name="email"
                        maxLength={60}
                        keyPress={emailKeyPress}
                    ></TextField>
                </Col>
                <Col xs={24} sm={24} md={17} lg={11} xl={11} xxl={9}>
                    <TextField
                        label="Confirmation de votre e-mail"
                        placeholder=""
                        name="confirmEmail"
                        maxLength={60}
                        keyPress={emailKeyPress}
                    ></TextField>
                </Col>
            </Row>
            <Row align="middle" className='field-title' gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{
                        margin: '0',
                        padding: '20px 8px 0 8px',
                        marginBottom: '-10px',
                    }}
                >
                    <SubTitle
                        style={{
                            color: '#e66d30',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: '25px',
                                width: '40px',
                                marginRight: '2px',
                            }}
                            icon={['fa', 'phone']}
                        />
                        Mobile
                    </SubTitle>
                </Col>
            </Row>
            {orderType!=="wac" && (
                <>
            <Row align="top"  className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={9} lg={6} xl={6} xxl={5}>
                    <Select
                        data={operators}
                        label="Opérateurs"
                        placeholder=""
                        name="operator"
                    ></Select>
                </Col>
            </Row>
            </>
            )}
            <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={17} lg={11} xl={11} xxl={9}>
                    <TextField
                        label="Votre numéro de téléphone"
                        placeholder=""
                        name="phone"
                        maxLength="10"
                        onChange={handlePhoneChange}
                        keyUp={(e) => {
                            setValue('phone', e.target.value.replace(/\D/g, ''))
                        }}
                        // keyPress={(e) => {
                        //     if (
                        //         e.key === ' ' ||
                        //         e.charCode < 48 ||
                        //         e.charCode > 57
                        //     )
                        //         e.preventDefault();
                        // }}
                    ></TextField>
                </Col>
                <Col xs={24} sm={24} md={17} lg={11} xl={11} xxl={9}>
                    <TextField
                        label="Confirmation de votre numéro de téléphone"
                        placeholder=""
                        name="confirmPhone"
                        maxLength="10"
                        keyUp={(e) => {
                            setValue('confirmPhone', e.target.value.replace(/\D/g, ''))
                        }}
                        // keyPress={(e) => {
                        //     if (
                        //         e.key === ' ' ||
                        //         e.charCode < 48 ||
                        //         e.charCode > 57
                        //     )
                        //         e.preventDefault();
                        // }}
                    ></TextField>
                </Col>
            </Row>
            <Row align="top" style={{marginBottom: '20px',float: 'right'}}>
                <Col xs={24} sm={24} md={22} lg={16} xl={16} xxl={14} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <ButtonWrapper>
                        <BackButton
                            type="button"
                            onClick={() =>{
                                if (orderType=="wac"){
                                    back();
                                    setValue('step',  0);
                                }else{
                                    navigate(-1)
                                }
                            }
                            }
                        >
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                    <ButtonWrapper>

                        <Button type="button" onClick={() => {
                                                handleNextClick();
                                                
                                                /*handleCodePhone(phoneValue);*/

                                            }}
                        >
                            Continuer
                        </Button>
                    </ButtonWrapper>
                </Col>
            </Row>
        </>
    );
};
