import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Landing from '../components/landing';
import Individual from '../components/individual';
import Pro from '../components/pro';
import ManualOrder from '../components/individual/manual-order';
import Go from '../components/go';
import { useQueryParam, StringParam } from 'use-query-params';
import { http } from '../components/http';
import { Modal } from 'antd';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '../components/b2c/index.styles';
import { navigate } from 'gatsby-link';
import { PrimaryButton } from '../components/common/SimpleRow/styles';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';

const Home = () => {
    const [data,] = useQueryParam('data', StringParam);
    const [codecmr,] = useQueryParam('codecmr', StringParam);
    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });
    useEffect(() => {
        var referrer = document.referrer;
        console.log("referrer url",referrer);
        if (data) {
            setState({
                isLoading: true
            })
            http.post('/card-order/payment', { data: data, codecmr: codecmr }).then(
                (res) => {
                    setState({
                        ...state,
                        isLoading: false,
                        data: res.data,
                    });
                }
            );
        }
    }, [])
    return (
        <Layout notTransparent={true}>
            <Landing></Landing>
            <Title style={{textAlign: 'center'}}>Solutions de paiements innovantes pour particuliers et professionnels</Title>
            <Go></Go>
            <Individual></Individual>
            <Pro></Pro>
            <ManualOrder />
            <Modal
                visible={!state.isLoading && data && state.data}
                centered
                onCancel={() => {
                    navigate('/');
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        minHeight: '250px',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <FontAwesomeIcon
                        icon={state.data?.orderState == "PAYE" ? faCheck : faTimes}
                        style={{
                            fontSize: '100px',
                            color: state.data?.orderState == "PAYE" ? "#63e6be" : "#de2c18",
                            margin: '20px',
                            width: '100px',
                        }}
                    />
                    <Title style={{ fontSize: '17px', color: '#212121' }}>

                        {state.data?.orderState == "PAYE" ? "Félicitations !" : "Votre paiement a échoué"}
                        <br />
                        <br />
                        {state.data?.orderState == "PAYE" ? "Votre commande est enregistrée avec succès !" : "Malheureusement, votre paiement en ligne a échoué, veuillez réessayer"}

                        <br />
                        <br />
                        {
                            state.data?.orderState == "PAYE" &&
                            <span style={{ fontSize: '14px', color: '#c4c4c4' }}>
                                Un email de confirmation a été envoyé à votre
                                adresse :
                                <br />
                                {state?.data?.email}
                                <br />
                                <br />À bientôt !
                            </span>

                        }
                        {
                            state.data?.orderState != "PAYE" &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                <PrimaryButton
                                    onClick={() => navigate(state.data?.retryLink)}
                                    style={{
                                        maxWidth: '190px',
                                        margin: '0 10px',
                                    }}
                                >
                                    Réessayer
                                </PrimaryButton>
                            </div>

                        }
                        <br />
                        <br />
                    </Title>
                </div>
            </Modal>
        </Layout>
    );
};

export default Home;
