import React from 'react';
import Layout from '../../components/layout';

import IndividualOrder from '../../components/individual/order';
import ManualOrder from '../../components/individual/manual-order';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
import VignetteSlide from '../../components/paiement-vignette-2023/vignette-slide';
import VignettePaiement from '../../components/paiement-vignette-2023/paiement';
import VignettePrint from '../../components/paiement-vignette-2023/print';
import VignetteService from '../../components/paiement-vignette-2023/service';
import VignetteGeolocation from '../../components/paiement-vignette-2023/geolocation';

const CasawePage = ({ location }) => {
    return (
        <>
            <Helmet>
            <title>Paiement de votre vignette 2023</title>
            <meta property="og:title" content="Paiement vignette 2023 en ligne ou en agence avec Naps" />
            <meta property="og:site_name" content="Paiement vignette 2023 en ligne ou en agence avec Naps"/>
            <meta property="twitter:title" content="Paiement vignette 2023 en ligne ou en agence avec Naps"/>
            <meta name="description" content="Payer la vignette automobile au Maroc pour l'année 2023. Naps met à votre disposition sa plateforme en ligne et ses agences connectées à la DGI." />
            <meta property="og:description" content="Payer la vignette automobile au Maroc pour l'année 2023. Naps met à votre disposition sa plateforme en ligne et ses agences connectées à la DGI." />
            <meta property="twitter:description" content="Payer la vignette automobile au Maroc pour l'année 2023. Naps met à votre disposition sa plateforme en ligne et ses agences connectées à la DGI." />

            </Helmet>
            
            <Layout location={location}>
                <VignetteSlide/>
                <VignettePaiement/>
                <VignettePrint/>
                <VignetteService/>
                <VignetteGeolocation/>
            </Layout>
        </>
    );
};

export default CasawePage;
