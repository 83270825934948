import styled from 'styled-components';

export const Input = styled.input`
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #5f5f5f90;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    background-color: transparent;

    width: 100%;
    /* background-color: transparent;
    padding: 10px 5px;
    border: 1px solid #5f5f5f; */
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#5f5f5f')};
    &:focus {
        outline: none;
        border: 1px solid #e66d30;
    }
`;
export const TextArea = styled.textarea`
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #5f5f5f90;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    background-color: transparent;
    width: 100%;
    /* padding: 10px 5px; */
    min-height: 100px;
    /* border: 1px solid #5f5f5f;
    background: transparent; */
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#5f5f5f')};
    &:focus {
        outline: none;
        border: 1px solid #e66d30;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#5f5f5f')};
`;
export const Error = styled.span`
    font-size: 11px;
    color: red;
`;
