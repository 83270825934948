import styled from 'styled-components';

export const ProTitle = styled.h1`
    color: #fff;
    padding: 10px 30px;
    border: 2px solid #fff;
    border-radius: 12px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin: 30px 0;
`;
export const ProSubTitle = styled.p`
    color: #fff;
    font-family: Rajdhani, sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 20px;
    text-align: center;
`;

export const PrimaryButton = styled.button`
    border-radius: 5px;
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    width: 200px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    box-shadow: #e96d25 0px 0px 54px -14px;
    margin-top: 1rem;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e96d25 0px 0px 20px -14px;
    }
`;
export const Button = styled.button`
    border-radius: 5px;
    background-color: #ffffff;
    min-width: 200px;
    border: none;
    padding: 0.6rem 1.5rem 0.5rem 1.5rem;
    color: #e66d30;
    box-shadow: #000 0px 0px 54px -14px;
    margin-top: 1rem;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    font-size: 15px;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #000 0px 0px 20px -14px;
    }
`;
