import styled from 'styled-components';
import { Row } from 'antd';


export const ArticleRow = styled(Row)`
    align-items: center;
    height: auto;
    z-index: 2;
    min-height: 100vh;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 43rem;
    overflow: auto;
    
`;

export const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 43rem;
    background-image: linear-gradient(
          rgba(0, 0, 0, 0.6), 
          rgba(0, 0, 0, 0.6)
        ), ${({ background }) => `url(${background})`} ; 
    background-size: cover;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: top;
    
`;
