import React from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StepCard from './step-card';
import { Timeline } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.css';
import { Fade, Bounce } from 'react-awesome-reveal';
import { Number } from './styles';
import { useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { useEffect } from 'react';
const Steps = () => {
    const [state, setState] = useState({
        timelineMode: 'alternate',
    });
    const { width, height } = useWindowSize();
    const { contentfulLanding, site } = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "steps" }) {
                id
                title
                subTitle
                offers {
                    name
                    icon {
                        file {
                            url
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    medias {
                        icon
                        link
                        media
                    }
                }
            }
        }
    `);
    useEffect(() => {
        if (width < 908) {
            setState({
                ...state,
                timelineMode: 'left',
            });
        } else {
            setState({
                ...state,
                timelineMode: 'alternate',
            });
        }
    }, [width]);
    return (
        <div style={{ marginTop: '20px' }}>
            <Timeline mode={state.timelineMode}>
                {contentfulLanding.offers.map((offer, index) => {
                    return (
                        <Timeline.Item
                            key={index}
                            dot={
                                <Fade
                                    big
                                    {...((index + 1) % 2 !== 0
                                        ? { left: true }
                                        : { right: true })}
                                >
                                    <Number> 0{index + 1}</Number>
                                </Fade>
                            }
                        >
                            <Fade
                                big
                                {...((index + 1) % 2 === 0
                                    ? { left: true }
                                    : { right: true })}
                            >
                                <StepCard
                                    text={offer.name}
                                    index={index}
                                    image={offer.icon && offer.icon.file.url}
                                    medias={index === 2 && site.siteMetadata.medias}
                                />
                            </Fade>
                        </Timeline.Item>
                    );
                })}
                <Timeline.Item></Timeline.Item>
            </Timeline>

            {/* <Timeline mode="alternate">
                <Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
                <Timeline.Item color="green">
                    Solve initial network problems 2015-09-01
                </Timeline.Item>
                <Timeline.Item>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo.
                </Timeline.Item>
               
            </Timeline> */}
        </div>
    );
};

export default Steps;
