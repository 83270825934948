import React from 'react';
import { Wrapper, Title, SubTitle } from './styles';
import { Row, Col } from 'antd';
import './styles.css';
import { ButtonWrapper, Button } from '../../../common/SimpleRow/styles';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Flip, Zoom, LightSpeed, Fade } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonIcon } from '../../../common/SimpleRow/styles';
import SEO from '../../../seo';
import { detect } from 'detect-browser';
import { useState } from 'react';
import { useEffect } from 'react';
const Landing = () => {
    const [state, setState] = useState({
        br: '',
    });
    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "b2b-landing" }) {
                id
                title
                subTitle
            }
        }
    `);

    return (
        <Wrapper className="b2b-landing">
            <SEO
                title="B2c"
                description={
                    data.contentfulLanding.title +
                    ',' +
                    data.contentfulLanding.subTitle
                }
                url={'/b2c'}
            />
            <Row
                justify="center"
                style={{
                    padding: `${state.br === 'ie' ? '20rem' : '70px'}  20px`,
                    width: '100%',
                }}
            >
                <Col lg={12} md={24} sm={24}></Col>
                <Col lg={12} md={24} sm={24}>
                    <Fade right>
                        <Title style={{ textShadow: '6px 0px 5px #00000040' }}>
                            {data.contentfulLanding.title}
                        </Title>
                    </Fade>
                    <Zoom>
                        <SubTitle>{data.contentfulLanding.subTitle}</SubTitle>
                    </Zoom>
                    <Fade bottom>
                        <ButtonWrapper>
                            <Link to="#accepte">
                                <Button>
                                    J’accepte des paiements
                                </Button>
                            </Link>
                            <Link to="#mets">
                                <Button>
                                    J'émets des paiements
                                </Button>
                            </Link>
                        </ButtonWrapper>
                    </Fade>
                </Col>
            </Row>
        </Wrapper>
    );
};

export default Landing;
