import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import ProductLanding from '../../../components/products/landing';
import PayByEmail from '../../../components/products/pay-by-email';
import { useStaticQuery, graphql } from 'gatsby';

const PayByEmailPage = () => {
    const { contentfulLanding } = useStaticQuery(graphql`
        query {
            contentfulLanding(
                templateKey: { eq: "primary-paybyemail-landing" }
            ) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Produits" url={'/products/pay-by-email'} />
            <ProductLanding pack="pay-by-email" {...contentfulLanding} />
            <PayByEmail />
        </Layout>
    );
};

export default PayByEmailPage;
