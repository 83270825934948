import React, { useState } from 'react';
import {
    Title,
    Button,
    ButtonWrapper,
    SubTitle,
    BackButton,
} from '../../styles';
import { Row, Col, Spin } from 'antd';
import { Select } from '../../../select';
import { TextField } from '../../../text-field';
import { CheckBoxField } from '../../../checkbox-field';
import { Link } from 'gatsby';
import tpes from '../tpes.json';
import { FileField } from '../../../file-field';
import RadioField from '../../../common/radio-field';
import MultipleSelectField from '../../../multiple-select-field';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'reactjs-popup';
import { CloseIcon } from '../../../common/vd-modal/styles';
import { useFormikContext } from 'formik';

const apps = [
    {
        value: true,
        label: 'Oui',
    },
    {
        value: false,
        label: 'Non',
    },
];
const tpeAvas = [
    { value: 'TPE brandé', label: 'TPE brandé' },

    { value: 'e-Publicité', label: 'e-Publicité' },

    { value: 'Fidélité', label: 'Fidélité' },
    { value: 'Gestion du Pourboire', label: 'Gestion du Pourboire' },
    { value: 'Satisfaction client', label: 'Satisfaction client' },
    { value: 'Support Express', label: 'Support Express' },
];
const ecommerceAvas = [
    { value: 'Paiement embarqué', label: 'Paiement embarqué' },
    { value: 'Paiement immersif', label: 'Paiement immersif' },
    { value: 'Outils analytiques', label: 'Outils analytiques   ' },
    { value: 'Paiement n-fois', label: 'Paiement n-fois' },
    { value: 'Paiement one-to-one', label: 'Paiement one-to-one' },
    { value: 'Paiement intégré', label: 'Paiement intégré' },
];

const antIcon = (
    <LoadingOutlined
        style={{ fontSize: 24, color: 'rgb(230, 109, 48)' }}
        spin
    />
);

const RequestForm = ({
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
    back,
    type,
    isClient,
    addedValueApp,
    errors,
    isLoading,
}) => {
    const [state, setState] = useState({
        openPopup: false,
    });

    const { submitForm } = useFormikContext();

    const handleBackClick = () => {
        back();
        setValue('step', 1);
    };

    const trySubmit = () => {
        setTouched({
            tpeModel: true,
            addedValueApp: true,
            numberAgence: true,
            avas: true,
            terms: true,
        });
        validate().then((err) => {
            if (Object.keys(err).length === 0) {
                if (type === 'partner') {
                    submitForm();
                } else {
                    setState({
                        ...state,
                        openPopup: true,
                    });
                }
            }
        });
    };
    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Title>
                        Votre{' '}
                        {type === 'partner' || type === 'e-commerce'
                            ? 'demande'
                            : 'commande'}
                    </Title>
                </Col>
            </Row>
            {type === 'tpe' &&
                ((typeof isClient == 'string' && isClient == 'false') ||
                    (typeof isClient == 'boolean' && !isClient)) && (
                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Select
                                data={tpes.tpes}
                                label="Modèle de TPE"
                                placeholder=""
                                name="tpeModel"
                            ></Select>
                        </Col>
                    </Row>
                )}

            {(type === 'tpe' || type === 'e-commerce') && (
                <>
                    {(type === 'e-commerce' ||
                        (type === 'tpe' &&
                            ((typeof isClient == 'string' &&
                                isClient == 'false') ||
                                (typeof isClient == 'boolean' &&
                                    !isClient)))) && (
                        <>
                            <Row
                                align="middle"
                                justify="center"
                                gutter={[16, 24]}
                            >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <SubTitle>
                                        Souhaitez-vous sélectionner une
                                        Application à Valeur Ajoutée (AVA) ?
                                    </SubTitle>
                                </Col>
                            </Row>
                            <Row align="top" justify="center" gutter={[16, 24]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <RadioField
                                        values={apps}
                                        name="addedValueApp"
                                    ></RadioField>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
            {type === 'partner' && (
                <>
                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <TextField
                                label="Nombre d’agences proposées"
                                placeholder=""
                                name="numberAgence"
                                type="number"
                                maxLength={10}
                                max={999}
                            ></TextField>
                        </Col>
                    </Row>
                </>
            )}
            {type === 'tpe' &&
                ((typeof isClient == 'string' && isClient == 'true') ||
                    (typeof isClient == 'boolean' && isClient) ||
                    addedValueApp) && (
                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <MultipleSelectField
                                label="Sélectionnez une application à valeur ajoutée (AVA)"
                                placeholder=""
                                name="avas"
                                data={type === 'tpe' ? tpeAvas : ecommerceAvas}
                                setValue={setValue}
                            ></MultipleSelectField>
                        </Col>
                    </Row>
                )}

            {type === 'e-commerce' && addedValueApp && (
                <Row align="top" justify="center" gutter={[16, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <MultipleSelectField
                            label="Sélectionnez une Application à Valeur Ajoutée (AVA)"
                            placeholder=""
                            name="avas"
                            data={type === 'tpe' ? tpeAvas : ecommerceAvas}
                            setValue={setValue}
                        ></MultipleSelectField>
                    </Col>
                </Row>
            )}
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <TextField
                        label="Commentaires"
                        placeholder={
                            type === 'partner'
                                ? 'Parlez-nous plus de vos motivations et de vos atouts et compétences.'
                                : 'Quelque chose à ajouter?'
                        }
                        name="comment"
                        maxLength={44}
                        textarea={true}
                    ></TextField>
                </Col>
            </Row>
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CheckBoxField
                        pre="J’ai lu et j’accepte la politique de confidentialité, notamment "
                        suf="personnelles conformément à la loi 09/08"
                        linkText="la mention relative à la protection des données"
                        to="/terms"
                        name="terms"
                    ></CheckBoxField>
                </Col>
            </Row>
            <Row align="top" justify="center" style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ButtonWrapper>
                        {isLoading ? (
                            <Spin indicator={antIcon} />
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    disabled={isLoading}
                                    onClick={trySubmit}
                                >
                                    Envoyer
                                </Button>

                                <BackButton
                                    type="button"
                                    onClick={handleBackClick}
                                    disabled={isLoading}
                                >
                                    Retour
                                </BackButton>
                            </>
                        )}
                    </ButtonWrapper>
                </Col>
            </Row>
            <Popup
                overlayStyle={{
                    background: '#00000090',
                }}
                modal
                nested
                open={state.openPopup}
                onClose={() =>
                    setState({
                        ...state,
                        openPopup: false,
                    })
                }
            >
                <div
                    style={{
                        width: '500px',
                        height: '125px',
                        background: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                    className="modal"
                >
                    <CloseIcon
                        className="close"
                        onClick={() =>
                            setState({
                                ...state,
                                showPopup: false,
                            })
                        }
                    >
                        &times;
                    </CloseIcon>
                    <p
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontFamily: 'Maven Pro',
                            fontWeight: '500',
                        }}
                    >
                        Êtes-vous sûr de vouloir confirmer votre demande ?
                    </p>

                    <ButtonWrapper>
                        {isLoading ? (
                            <Spin indicator={antIcon} />
                        ) : (
                            <>
                                <Button type="button" onClick={submitForm}>
                                    Confirmer
                                </Button>
                                <BackButton
                                    type="button"
                                    onClick={() =>
                                        setState({
                                            ...state,
                                            openPopup: false,
                                        })
                                    }
                                >
                                    Annuler
                                </BackButton>
                            </>
                        )}
                    </ButtonWrapper>
                </div>
            </Popup>
        </>
    );
};
export default RequestForm;
