import React from 'react';
import { Wrapper, IconWrapper, Text } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ServiceCard = ({ title, picture }) => {
    return (
        <Wrapper>
            <IconWrapper>
                <img src={picture} style={{maxWidth: '125px'}} />
            </IconWrapper>
            <div style={{width: '100px', borderTop: '5px solid #e66d30', borderRadius: '20px'}} ></div>
            <Text>{title}</Text>
        </Wrapper>
    );
};

export default ServiceCard;