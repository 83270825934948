import React, { useEffect } from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import Articles from '../../../components/news/articles';

const Article = () => {
    return (
        <Layout >
            <SEO title="Articles" url={'/news/articles'}/>
            <div style={{ padding: '150px 0' }} >
                <Articles/>
            </div>
        </Layout>
    );
};
export default Article;
