import React from 'react';
import Layout from '../../layout';
import { BLOCKS } from '@contentful/rich-text-types';

import {
    Wrapper,
    CategoryName,
    RowWrapper,
    ImageWrapper,
    Image,
    SolidBackground,
    ContentWrapper,
    ShortCategoryName,
    TpeName,
    ShortDetail,
    AddToCardWrapper,
    NumberInput,
    AddToCardButton,
    SeeMoreButton,
    ListItemRow,
} from './styles';
import { Row, Col } from 'antd';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from 'react-awesome-reveal';

const options = {
    renderNode: {
        [BLOCKS.LIST_ITEM]: (_, children) => (
            <ListItemRow>
                <FontAwesomeIcon icon="check" style={{ color: '#e96d25' }} />{' '}
                {children}
            </ListItemRow>
        ),
    },
};
const TpeDetailsTemplate = ({ pageContext }) => {
    const [state, setState] = useState({
        showMore: false,
        theme: 'light',
    });
    const {
        category,
        image,
        title,
        shortDetail,
        firstAdvanteg,
        secondAdvanteg,
    } = pageContext.tpe;

    return (
        <Layout>
            <Wrapper theme={state.theme}>
                <Row>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        className="justify-center"
                    >
                        <CategoryName>{category}</CategoryName>
                    </Col>
                </Row>
                <RowWrapper theme={state.theme}>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '25rem',
                            maxHeight: '25rem',
                        }}
                        xxl={6}
                        xl={7}
                        lg={9}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <Fade left>
                            <ImageWrapper>
                                <SolidBackground />
                                <Image src={image.file.url}></Image>
                            </ImageWrapper>
                        </Fade>
                    </Col>
                    <Col xxl={12} xl={15} lg={15} md={24} sm={24} xs={24}>
                        <Fade right>
                            <ContentWrapper>
                                <ShortCategoryName>
                                    {category
                                        .split('(')[0]
                                        .substring(
                                            category.indexOf('GAMME') + 6
                                        )}
                                </ShortCategoryName>
                                <TpeName>{title}</TpeName>
                                <ShortDetail>{shortDetail}</ShortDetail>
                                <AddToCardWrapper>
                                    <NumberInput
                                        type="number"
                                        min="1"
                                        defaultValue="1"
                                    />
                                    <AddToCardButton>
                                        Ajouter au panier
                                    </AddToCardButton>
                                </AddToCardWrapper>
                                {firstAdvanteg && (
                                    <div style={{ fontFamily: 'Maven Pro' }}>
                                        {documentToReactComponents(
                                            JSON.parse(firstAdvanteg.raw),
                                            options
                                        )}
                                    </div>
                                )}
                                {secondAdvanteg && (
                                    <>
                                        <SeeMoreButton
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    showMore: !state.showMore,
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                style={{ marginRight: '10px' }}
                                                icon={
                                                    state.showMore
                                                        ? 'caret-up'
                                                        : 'caret-down'
                                                }
                                            />
                                            {state.showMore
                                                ? 'Masquer'
                                                : 'Plus de détails'}
                                        </SeeMoreButton>
                                        {state.showMore && (
                                            <Fade>
                                                <div
                                                    style={{
                                                        fontFamily: 'Maven Pro',
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    {documentToReactComponents(
                                                        JSON.parse(secondAdvanteg.raw),
                                                        options
                                                    )}
                                                </div>
                                            </Fade>
                                        )}
                                    </>
                                )}
                            </ContentWrapper>
                        </Fade>
                    </Col>
                </RowWrapper>
            </Wrapper>
        </Layout>
    );
};

export default TpeDetailsTemplate;
