import { Row, Col } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 100vh;
    /* background-image: url('../../images/b2b_background.png'); */
    background-position: bottom;
    background-size: cover;
    background-color: #e66d30;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: #ffffff;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 3rem;
    margin: 0;
    font-weight: bold;
`;
export const SubTitle = styled.p`
    margin-top: 1rem;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3b;
    font-family: Rajdhani, sans-serif;
    font-weight: 500;
    color: #fff;
`;