import React, { useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Col } from 'antd';
import AdvantageCard from '../../../common/advantage-card';
import { Zoom } from 'react-awesome-reveal';
import './styles.css';
import { M3allemRow } from './styles';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
const Advantage = () => {
    const [state, setState] = useState({
        br: '',
    });

    const data = useStaticQuery(graphql`
        query {
            allContentfulParagraph(
                sort: { fields: number, order: ASC }
                filter: { key: { eq: "pro" } }
            ) {
                edges {
                    node {
                        number
                        title
                        text {
                            text
                        }
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    
    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    return (
        <M3allemRow
            br={state.br}
            theme={theme}
            justify="center"
            style={{ padding: '80px 0px 50px 0px' }}
        >
            {data.allContentfulParagraph.edges.map((item, index) => {
                return (
                    <Col
                        key={index}
                        className="justify-center"
                        lg={9}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <Zoom left opposite cascade>
                            <AdvantageCard
                                number={item.node.number}
                                title={item.node.title}
                                content={item.node.text.text}
                                image={
                                    item.node.image
                                        ? item.node.image.file.url
                                        : ''
                                }
                            />
                        </Zoom>
                    </Col>
                );
            })}
        </M3allemRow>
    );
};

export default Advantage;
