import React, { useState } from 'react';
import Presentation from './presentation';
import Offer from './offer';
import Advantage from './advantage';
import './styles.css';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../../seo';
const Entreprise = () => {
    const [state, setState] = useState({
        selected: 'card',
    });
    const changeSelected = (pack) => {
        setState({
            ...state,
            selected: pack,
        });
    };
    const { contentfulEntrepriseOffer } = useStaticQuery(graphql`
        query {
            contentfulEntrepriseOffer {
                title
                leftImage {
                    file {
                        url
                    }
                }
                rightImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <div className="pro-entreprise">
            <Presentation />
            <Offer
                title={contentfulEntrepriseOffer.title}
                leftPicture={contentfulEntrepriseOffer.leftImage?.file?.url}
                rightPicture={contentfulEntrepriseOffer.rightImage?.file?.url}
                showButton
                select={changeSelected}
                selected={state.selected}
            />
            <Advantage selected={state.selected} />
        </div>
    );
};

export default Entreprise;
