import React, { useRef } from 'react';
import { useField } from 'formik';
import {
    FileUpload,
    Wrapper,
    AddButton,
    FileText,
    Col,
    Icon,
    Error,
} from './file-field.styles';

export const FileField = ({
    title,
    setFieldValue,
    label,
    placeholder,
    onFileChange,
    ...props
}) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const fileRef = useRef();
    const showFileUpload = () => {
        if (fileRef) {
            fileRef.current.click();
        }
    };
    const handleImageChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            setFieldValue(field.name, e.currentTarget.files[0]);
        }
    };

    return (
        <Wrapper>
            <Icon style={{color: `${field.value ? '#00d447' : '#e66d30'}`, fontSize: '30px'}} className="material-icons">attachment</Icon>
            <Col>
                <FileText>{title}</FileText>
                <Error>{errorText ? errorText : ''}</Error>
            </Col>
            <Col>
                <FileUpload
                    ref={fileRef}
                    type="file"
                    accept=".doc,.jpg,.png,.jpge"
                    style={{ display: 'hidden' }}
                    onChange={onFileChange || handleImageChange}
                ></FileUpload>
                <AddButton type="button" onClick={showFileUpload}>
                    {field.value ? 'Remplacer' : 'Ajouter'}
                </AddButton>
            </Col>
            <Col>
                <FileText>{field.value ? field.value.name : ''}</FileText>
            </Col>
        </Wrapper>
    );
};
