import React from 'react';
import { Col } from 'antd';
import { ColContainer, FooterRow, FooterLink, PrivacyCol } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import {graphql, useStaticQuery, withPrefix} from 'gatsby';
import {useContext} from 'react';
import ThemeContext from '../theme/ThemeContext';
import { FooterText, PrivacyRow } from "./styles";
import useWindowSize from '../../hooks/useWindowSize'
import { StaticImage } from 'gatsby-plugin-image';
const Footer = () => {
    const { width } = useWindowSize();
    const { theme } = useContext(ThemeContext);
    const { site } = useStaticQuery(graphql`
        query FooterQuery {
            site {
                siteMetadata {
                    medias {
                        icon
                        link
                        media
                    }
                }
            }
        }
    `);
    return (
        <div>
            <FooterRow theme={theme} justify="center">
                <Col className="justify-center" lg={10} md={10} sm={24} xs={24}>
                    <ColContainer>
                        <StaticImage formats={["auto", "webp"]} placeholder='blurred' style={{ width: '150px' }} src='../../../images/naps-logo-footer.png' alt="Naps" />
                    </ColContainer>
                </Col>

                <Col className="justify-center" lg={10} md={10} sm={24} xs={24}>
                    <ColContainer>
                        <a style={{marginBottom: '15px'}} href="https://apps.apple.com/fr/app/naps/id1462285980" rel="noreferrer" target="_blank">
                            <StaticImage formats={["auto", "webp"]} placeholder='blurred' width={150} src='../../../images/available-apple-footer.png' alt="Apple" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.m2m.group.napsproject&hl=fr" rel="noreferrer" target="_blank">
                            <StaticImage formats={["auto", "webp"]} placeholder='blurred'  src='../../../images/available-android-footer.png' alt="Android" width={150} />
                        </a>
                    </ColContainer>
                </Col>
            </FooterRow>
            <div style={{ backgroundColor: '#e66d30', borderTop: '1px solid rgba(255, 255, 255, 0.2)' }}>
                <PrivacyRow>
                    <Col className="justify-center" style={{ alignItems: 'center' }} lg={10} md={10} sm={24} xs={24}>
                        <FooterText style={{ 'color': 'rgb(26 26 26)' }}>© 2022 NAPS - tous les droits sont réservés</FooterText>
                    </Col>
                    <Col style={{ justifyContent: 'space-between', paddingRight: '15px', flexDirection: width > 468 ? 'row' : 'column' }} className="justify-center" lg={10} md={10} sm={24} xs={24}>
                        <PrivacyCol>
                            <div style={{display: 'flex'}}>
                            {site.siteMetadata.medias.map((item) => {
                                return (
                                    <FooterLink
                                        key={item.media}
                                        href={item.link}
                                        theme={theme}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{fontSize: '19px',width: '40px', height: '40px'}}
                                    >
                                        <FontAwesomeIcon
                                            color="rgb(255, 255, 255)"
                                            style={{color: '#fff', 
                                                    width: '20px', 
                                                    height: '20px',
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'}}
                                            icon={['fa', item.icon]}
                                        />
                                    </FooterLink>
                                );
                            })}
                            </div>
                        </PrivacyCol>
                        <PrivacyCol>
                            <div style={{ display: 'flex' }}>
                                <a className="cgu-link"
                                    href={withPrefix('/CGU.pdf')}
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{
                                        color: '#000000',
                                        marginRight: '15px'
                                    }}
                                >
                                    CGU & Tarifs
                                </a>
                                <a className="cgu-link"
                                    href="/terms"
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#000000',
                                        fontWeight: 400,
                                    }}
                                >
                                    Confidentialité
                                </a>
                            </div>
                        </PrivacyCol>
                    </Col>
                </PrivacyRow>
            </div>
        </div>
    );
};
export default Footer;
