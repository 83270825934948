import React, { useState, useEffect } from 'react';
import { GlobalStyle } from './styles';
import Header from './header';
import Footer from './footer';
import './styles.css';
import ThemeContext from './theme/ThemeContext';

const Layout = ({ children, notTransparent }) => {
    const [state, setstate] = useState({
        sidebarCollapse: true,
        theme: 'light',
        showBanner: false,
    });
    const setCollapsed = (_) => {
        setstate({
            ...state,
            sidebarCollapse: !state.sidebarCollapse,
        });
    };
    const toggleTheme = () => {
        localStorage.setItem(
            'naps-theme',
            state.theme === 'light' ? 'dark' : 'light'
        );
        setstate({
            ...state,
            theme: state.theme === 'light' ? 'dark' : 'light',
        });
    };

    useEffect(() => {
        if (localStorage.getItem('naps-theme')) {
            setstate({
                ...state,
                theme: localStorage.getItem('naps-theme'),
                showBanner: true,
            });
        } else {
            localStorage.setItem('naps-theme', state.theme);
            setstate({
                ...state,
                showBanner: true,
            });
        }
    }, []);
    const value = { theme: state.theme, toggleTheme };
    return (
        <ThemeContext.Provider value={value}>
            <div className="main-ant-layoute">
                <div
                    style={{
                        height: '100%',
                        display: 'block',
                        maxWidth: '100vw',
                    }}
                >
                    <div>
                        <GlobalStyle theme={state.theme}></GlobalStyle>
                        <Header
                            notTransparent={notTransparent}
                            toggleSide={() => setCollapsed()}
                        ></Header>
                        <div className={(!state.sidebarCollapse) ? "body-content-with-menu sm:p-5 md:p-0 p-5 main-content" : "sm:p-5 md:p-0 p-5 main-content"}
                            style={{
                                display: 'block',
                                maxWidth: '100vw',
                                overflow: 'hidden',
                            }}
                        >
                            {children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </ThemeContext.Provider>

    );
};

export default Layout;
