import React, { useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { VdWrapper, CloseIcon } from './styles';
const VdModal = ({ showModal, hide, url }) => {
    return (
        <Popup
            overlayStyle={{ background: '#00000090' }}
            modal
            nested
            open={showModal}
            onClose={hide}
        >
            <VdWrapper className="modal">
                <CloseIcon className="close" onClick={hide}>
                    &times;
                </CloseIcon>
                {showModal && (
                    <iframe
                        width="100%"
                        height="100%"
                        src={`${url}?autoplay=1&rel=0`}
                        style={{ minHeight: '500px' }}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </VdWrapper>
        </Popup>
        // <Modal
        //     centered
        //     visible={showModal}
        //     style={{
        //         minHeight: '500px',
        //         overflow: 'hidden',
        //         background: 'trasparent',
        //     }}
        //     width="100%"
        //     keyboard={true}
        //     onCancel={hide}
        //     maskClosable={true}
        // >
        //     {showModal && (
        //         <iframe
        //             width="100%"
        //             height="100%"
        //             src={url}
        //             style={{ minHeight: '500px' }}
        //             frameborder="0"
        //             allow="autoplay"
        //             allowfullscreen
        //         ></iframe>
        //     )}
        // </Modal>
    );
};

export default VdModal;
