import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SimpleRow from '../../common/SimpleRow';
import VdModal from '../../common/vd-modal';
import { useState } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Casawe = () => {
    const [state, setState] = useState({
        showModal: false,

    });
    const { theme } = useContext(ThemeContext);

    const data = useStaticQuery(graphql`
            query {
                contentfulLanding(templateKey: { eq: "individual-casawe" }) {
                    id
                    title
                    description {
                    raw
                    }
                    image {
                        title
                        file {
                            url
                        }
                    }
                }
            }
        `);

    return (

        <div id="casawe">
            <SimpleRow
                title={data.contentfulLanding.title}
                subTitle={documentToReactComponents(JSON.parse(
                    data.contentfulLanding.description.raw)
                )}
                imageUrl={data.contentfulLanding.image.file.url}
                state="left"
                id="usage"
                videoButtonText="Je découvre en Vidéo"
                onVdClick={() => setState({ ...state, showModal: true })}
                buttonsToLeft={true}
            />
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/YAQiU2rB-9w"
            ></VdModal>
        </div>
    );
};
export default Casawe;
