import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import {
    Title,
    SubTitle,
    ImageWrapper,
    AnimatedImage,
} from '../../landing/styles';
import { InfoWrapper, ButtonWrapper, ProRow, ProBackground } from './styles';

import '../../landing/styles.css';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import './styles.css';
import SEO from '../../seo';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';

const ProLanding = ({ pack }) => {
    const { theme } = useContext(ThemeContext);

    const [state, setState] = useState({
        br: '',
    });

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "pro-landing" }) {
                id
                title
                subTitle
                image {
                    title
                    file {
                        url
                    }
                }
                darkImage {
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <ProRow justify="center" theme={theme}>
            <ProBackground br={state.br} theme={theme} />
            <SEO
                title="Pro"
                description={
                    data.contentfulLanding.title +
                    ',' +
                    data.contentfulLanding.subTitle
                }
                url={'/pro/' + pack}
            />
            <Col className="justify-center" lg={9} md={24} sm={24} xs={24}>
                <InfoWrapper
                    style={state.br === 'ie' ? { width: '25rem' } : {}}
                >
                    <Fade left big>
                        <Title theme={theme}>
                            {data.contentfulLanding.title}
                        </Title>
                    </Fade>
                    <Fade bottom big>
                        <SubTitle theme={theme}>
                            {data.contentfulLanding.subTitle}
                        </SubTitle>
                    </Fade>
                </InfoWrapper>
            </Col>
            <Col className="justify-center" lg={9} md={24} sm={24} xs={24}>
                <ImageWrapper>
                    <Fade right>
                        <AnimatedImage
                            src={
                                theme === 'dark'
                                    ? data.contentfulLanding.darkImage.file.url
                                    : data.contentfulLanding.image.file.url
                            }
                            alt={data.contentfulLanding.title}
                        />
                    </Fade>
                </ImageWrapper>
            </Col>
            <Col className="justify-center" lg={24}>
                <ButtonWrapper>
                    <button
                        className={`${
                            pack === 'entreprise' ? 'active' : 'pack'
                        }`}
                        onClick={() => navigate('/pro/entreprise')}
                    >
                        Entreprises
                    </button>
                    <button
                        className={`${pack === 'pro' ? 'active' : 'pack'}`}
                        onClick={() => navigate('/pro/m3allem')}
                    >
                        Professionnels
                    </button>
                    <button
                        className={`${
                            pack === 'institution' ? 'active' : 'pack'
                        }`}
                        onClick={() => navigate('/pro/campus')}
                    >
                        Institutions
                    </button>
                </ButtonWrapper>
            </Col>
        </ProRow>
    );
};
export default ProLanding;
