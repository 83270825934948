import React, { useEffect ,useState} from 'react';
import { Row, Col, DatePicker, Space ,Upload} from 'antd';
import { Title, SubTitle, ButtonWrapper, Button, BackButton } from '../styles';
import { TextField } from '../../text-field';
import RadioField from '../../common/radio-field';
import RechargeField from '../../common/recharge-field';
import {Select} from '../../select';
import cities from '../cities.json';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileIcon from '../../../images/files.inline.svg';
import InitRechargeIcon from '../../../images/initrecharge.inline.svg';
import locale from 'antd/es/date-picker/locale/fr_FR';
import '../styles.css';
import { Label } from "../../text-field/test-field.styles";
import moment from 'moment';
import { http } from '../../http';
import 'moment/locale/fr';
import {
    emailKeyPress,
    onlyNumbers,
    adresseKeyPress,
} from '../../helpers/only-characters';

moment.locale('fr');

const identityTypes = [
    { value: 'CIN', label: 'CINE' },
    { value: 'Passeport', label: 'Passeport' },
    { value: 'Carte de séjour', label: 'Carte de séjour' },
];
const accountTypePrice = {
    Express: 200,
    Confort: 5000,
    'Confort +': 20000,
};
function disabledDate(current) {
    return current && current < moment().endOf('day');
}

export const ContactForm = ({
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
    back,
    accountType,
    isNotInit,
    orderType,
    getFeeByCardType,
    getConfigurationByName,
    values,
    openNotification,
}) => {
    const phoneNumber = values.phone;
    //console.log("Numéro de téléphone:", phoneNumber);
    //console.log("Values:", values);
    const handleNextClick = () => {
        setTouched({
            adresse: true,
            city: true,
            identityType: true,
            identityNumber: true,
            deliveryDate: true,
            //expirationDate: true,
            identityFile: true,
            codeverif: true,
            //adresseProofFile: true,
            //initialRechargeAmount: true,
        });
        validate().then((errors) => {
            console.log(state.size)
            console.log(fileSize.fileSize)
            if (Object.keys(errors).length === 0 && state.size==true && fileSize.fileSize==true) {
                if(state.selectedFiles){
                    let formData = new FormData();
                    formData.append("files", state.selectedFiles[0]);
                    formData.append("files", state.selectedFiles[1]);
                    setValue('cinFiles',formData)
                }
                //submitForm()
                nextStep();
                setValue('step', orderType === 'wac' ? 3 : 2);
            } else {
                openNotification();
            }
        });
    };

    const handleBackClick = () => {
        back();
        setValue('step', orderType === 'wac' ? 1 : 0);
    };

    const handleFileChange = (e, name) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => setValue(name, reader.result.split(',')[1]);
    };

    const dateFormat = 'DD/MM/YYYY';
    const defaultDate = values.expirationDate ? moment(values.expirationDate, dateFormat) : moment();


     const [state, setState] = useState({
        selectedFiles: undefined,
        size:false,
        fileLength:null,
     });

     const [fileSize, setFileSize] = useState({
        fileSize:true,

     });

     const [fileSizeLimitState, setFileSizeLimitState] = useState({
        fileSizeLimit: 0,
        fileSizeLimitMo:0,
     });

     const checkType= (event) =>{
        if(orderType !="wac"){
            setState({
                size: false,
              });
        }
     }
     const handleIdCodeverif = (event) => {
        setValue('codeverif', event.target.value);
    };
    const handleNumPhone = (event) => {
        setValue('numPhone', phoneNumber);
    };
    const selectFile= (event) =>{
        if(event.target.files.length > 2 || event.target.files.length ==0){
            setState({
                size: false,
                fileLength: event.target.files.length ,
              });
        }else{
            setState({
              selectedFiles: event.target.files,
              size: true,
              fileLength: event.target.files.length ,
            });
            if(event.target.files.length==1 && event.target.files[0].size>fileSizeLimitState.fileSizeLimit){
                setFileSize({
                    ...fileSize,
                    fileSize: false,
                  });
            }else if(event.target.files.length==2 && (event.target.files[0].size>fileSizeLimitState.fileSizeLimit 
                        || event.target.files[1].size>fileSizeLimitState.fileSizeLimit)){
                setFileSize({
                    ...fileSize,
                    fileSize: false,
                    });
            }else{
                setFileSize({
                    ...fileSize,
                    fileSize: true,
                    });
            }
        }
      };

      const getFileSizeLimit=(option)=>{
        getConfigurationByName(option).then((res) => {           
            setFileSizeLimitState({
                ...fileSizeLimitState,
                fileSizeLimit: res.data * 1000000,
                fileSizeLimitMo: res.data * 1,
            });
        })
      }

    useEffect(() => {
        if (accountType === 'Express' || orderType==='wac'){
            setState({
                size: true,
              });
        }
        if (!isNotInit) {
            setValue('initialRechargeAmount', 0);
        }

        getFileSizeLimit('fileSizeLimit')
        // if (!values.identityType !=='CIN'){
        //     setState({
        //         size: true,
        //       });
        // }
        getFeeByCardType(orderType).then((res) => {
            setValue('fee', res.data);
         })
    }, [isNotInit]);

    return (
        <>
            <Row align="middle" className='field-title' gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{ padding: '0px 8px' }}
                >
                    <Title>
                        {orderType === 'family'
                            ? 'Commandez votre carte principale'
                            :  orderType === 'wac'
                            ? 'Identité':'Confirmation'}
                    </Title>
                </Col>
            </Row>
            <Row align="top" className='field-left' gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                            
                            <TextField
                                name="codeverif"
                                placeholder=""
                                label="Code vérification Tel"
                                //label={`Code vérification Tel (${phoneNumber})`}
                                maxLength={4} 
                                keyUp={(e) => {
                                    setValue('codeverif', e.target.value);
                                }}
                                setValue={setValue}
                                onChange={handleIdCodeverif}
                            ></TextField>                            
                        </Col>
                    </Row>
            {accountType !== 'Express' && (
                <>
                    
                    <Row align="middle" className='field-title' gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                            <SubTitle
                                style={{
                                    color: '#e66d30',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {/* <FontAwesomeIcon
                                    style={{
                                        fontSize: '25px',
                                        width: '40px',
                                        marginRight: '2px',
                                    }}
                                    icon={['fa', 'file']}
                                /> */}
                                 <FileIcon style={{ width: '35px' }} /> 
                                Pièces d’identité
                            </SubTitle>
                        </Col>
                    </Row>
                    <Row align="top" className='field-left' gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                        <div style={{paddingTop: '12px', paddingLeft: '8px'}}>
                            <p style={{ fontSize: '16px',
                                            fontWeight: '500',
                                            marginBottom: '10px',
                                            color: 'rgba(0,0,0,0.7)',
                                            fontFamily: 'Maven Pro'}}>
                                Type de pièce d’identité *
                            </p>
                            <RadioField
                                values={identityTypes}
                                label="Type de pièce d’identité"
                                placeholder=""
                                name="identityType"
                                onChange={checkType}
                            ></RadioField>
                            </div>
                        </Col>
                    </Row>

                    <Row align="top" className='field-left' gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                            <TextField
                                name="identityNumber"
                                placeholder=""
                                label="Numéro de la pièce d’identité"
                                maxLength={20}
                                keyPress={emailKeyPress}
                            ></TextField>
                        </Col>
                        {/* <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                            <div style={{ padding: "12px 8px" }}>
                                <Label>
                                    Date d'expiration *
                                </Label>
                                <DatePicker disabledDate={disabledDate} className="dateInput" defaultValue={defaultDate} allowClear={false} onChange={(date, dateStr) => {
                                    setValue("expirationDate", dateStr)
                                }} locale={locale} format={dateFormat} />
                            </div>
                        </Col> */}
                    </Row>
                    {orderType!=="wac" &&(
                    <Row align="top" className='field-left' gutter={[16, 24]}>
                        <Col xs={28} sm={28} md={14} lg={11} xl={11} xxl={10}>
                            <div style={{display:'flex', padding: "12px 0" }}>
                                <div style={{padding: "0 8px", margin: 'auto 0'}}>
                                    <Label>
                                        Télécharger
                                    </Label>
                                </div>
                                <div className="fileUpload">
                                    <input
                                        className="upload"
                                        accept="image/png, image/jpeg, .pdf" 
                                        type="file" onChange={selectFile} multiple/>
                                    <span style={{cursor: 'pointer', display:'table-cell', verticalAlign: 'middle' }}>
                                    <FontAwesomeIcon
                                    style={{
                                        fontSize: '25px',
                                        width: '40px',
                                        color:'#cdd1d5',
                                        marginRight: '2px',
                                    }}
                                    icon={['fa', 'upload']}
                                />
                                    </span>
                                </div>
                                {state.fileLength && state.fileLength!==0 && (
                                    <p style={{margin: '0.5rem'}}> {state.fileLength} Fichier </p>
                                )}
                            </div>
                            {!state.size && (
                            <p style={{fontSize: '11px',color:'#cf1500'}}
                            >Veuillez ajouter votre carte d'identité</p>
                            )}
                            {!fileSize.fileSize && (
                            <p style={{fontSize: '11px',color:'#cf1500'}}
                            >Le fichier est trop grand, la taille maximale autorisée pour les pièces jointes {fileSizeLimitState.fileSizeLimitMo} Mo</p>
                            )}
                        </Col>
                        <Col xs={20} sm={20} md={6} lg={3} xl={3} xxl={2}>
                        </Col>
                    </Row>
                    )}
                    
                    
                    {/* {accountType === 'Confort +' && (
                        <Row align="top" justify="center" gutter={[16, 24]}>
                            <Col
                                xs={24}
                                sm={24}
                                md={20}
                                lg={14}
                                xl={14}
                                xxl={12}
                            >
                                <FileField
                                    label="Justificatif de domicile"
                                    placeholder=""
                                    name="adresseProofFile"
                                    setFieldValue={setValue}
                                    title="Justificatif de domicile"
                                    onFileChange={(e) =>
                                        handleFileChange(e, 'adresseProofFile')
                                    }
                                ></FileField>
                            </Col>
                        </Row>
                    )} */}
                </>
            )}
                    <Row align="middle" className='field-title' gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                            <SubTitle
                                style={{
                                    color: '#e66d30',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: '25px',
                                        width: '40px',
                                        marginRight: '2px',
                                    }}
                                    icon={['fa', 'map-marker-alt']}
                                />
                                 {/* <FileIcon style={{ width: '40px' }} />  */}
                                 Adresse
                            </SubTitle>
                        </Col>
                    </Row>
             <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <TextField
                        label="Adresse"
                        placeholder=""
                        name="adresse"
                        maxLength={100}
                        keyPress={adresseKeyPress}
                    ></TextField>
                </Col>
            </Row>
            <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                    <Select
                        data={cities}
                        label="Ville"
                        placeholder=""
                        name="city"
                    ></Select>
                </Col>
                <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                    <TextField
                        name="zipCode"
                        placeholder=""
                        label="Code postal"
                        maxLength={10}
                        keyUp={(e) => {
                            setValue('zipCode', e.target.value.replace(/\D/g, ''))
                        }}
                        // keyPress={onlyNumbers}
                    ></TextField>
                </Col>
            </Row> 

            {/* <Row align="middle" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <SubTitle
                        style={{
                            color: '#e66d30',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <InitRechargeIcon style={{ width: '60px' }} />
                        Souhaitez-vous effectuer une recharge initiale ?
                    </SubTitle>
                </Col>
            </Row>

            <Row align="top" className='field-left' gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <RadioField
                        values={[
                            { value: true, label: 'Oui' },
                            { value: false, label: 'Non' },
                        ]}
                        name={`initialRecharge`}
                        onChange={(e) => {
                            setValue(
                                'initialRechargeAmount',
                                e.target.value ? 100 : 0
                            );
                        }}
                    ></RadioField>
                </Col>
            </Row>
            {isNotInit && (
                <Row align="top" className='field-left' gutter={[16, 24]}>
                    <Col
                        xs={24}
                        sm={24}
                        md={20}
                        lg={14}
                        xl={14}
                        xxl={12}
                        style={{ padding: 0 }}
                    >
                        <RechargeField
                            name="initialRechargeAmount"
                            setValue={setValue}
                            max={accountTypePrice[accountType]}
                            value={values.initialRechargeAmount}
                            accountType={accountType}
                        />
                    </Col>
                </Row>
            )}
*/}
            <Row align="top" justify="center" style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <ButtonWrapper>
                        <Button type="button" onClick={handleNextClick}>
                            Continuer
                        </Button>
                        <BackButton type="button" onClick={handleBackClick}>
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
            </Row> 
        </>
    );
}
