import React from 'react';
import './styles.css';


const Pagination = ({ blogsPerPage, totalBlogs, paginate ,pageSelected}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }
  const cc =(number)=>{
    paginate(number)
    console.log(pageSelected);
  }

  return (
    <nav style={{width: '100%'}}>
      <div className='pagination'>
          <div className='pages'>
            {pageNumbers.map(number => (
                <a key={number} onClick={() => cc(number)} className={pageSelected==number?'page page-active': 'page'}>
                {number}
                </a>
            ))}
          </div>
      </div>
    </nav>
  );
};

export default Pagination;