import styled from 'styled-components';

export const Content = styled.p`
    background: #ffffff;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    height: 200px;
    transition: 0.2s ease-in-out;
    text-align: center;
    top: 0;
    z-index: 0;
    transform: translateY(-300px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s ease-in-out;

    &:hover ${Content} {
        transform: translateY(-20px);
    }
`;

export const Header = styled.div`
    height: 100px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 7px 25px 0 rgba(255, 169, 84, 0.36);
    background-color: #ffa055;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h2`
    color: #fff;
    font-family: 'Maven Pro', sans-serif;
    text-align:center;
`;
