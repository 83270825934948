import styled from 'styled-components';

export const SelectField = styled.select`
    /* width: 100%;
    padding: 10px;
    border: .5px solid #5f5f5f90;
    border-radius: 7px; */
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #5f5f5f90;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    &:hover {
        border: 1px solid #e66d30;
    }
`;

export const Label = styled.label`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Maven Pro', sans-serif;
`;
export const Error = styled.span`
    font-size: 11px;
    color: #cf1500;
`;
