import styled from 'styled-components';
import { Link } from 'gatsby';

export const NextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    /* padding-right: 22px; */
    margin: 20px 0;
`;
export const NextContent = styled.p`
    all: unset;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #b5b5b5;
    margin-right: 4px;
    text-align: center;
    line-height: 27px;
`;

export const LinkWrapper = styled(Link)`
    all: unset;
    color: #ffffff;
    background: #b5b5b5;
    border-radius: 5px;
    padding: 5px 10px;
`;
