import React from 'react';
import Presentation from './presentation';
import Advantage from './advantage';
import Offer from '../entreprise/offer';
import { useStaticQuery, graphql } from 'gatsby';

const Pro = () => {
    const { contentfulM3AllemOffer } = useStaticQuery(graphql`
        query {
            contentfulM3AllemOffer {
                title
                leftImage {
                    file {
                        url
                    }
                }
                rightImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <>
            <Presentation />
            <Offer
                title={contentfulM3AllemOffer?.title}
                leftPicture={contentfulM3AllemOffer?.leftImage?.file?.url}
                rightPicture={contentfulM3AllemOffer?.rightImage?.file?.url}
            />
            <Advantage />
        </>
    );
};

export default Pro;
