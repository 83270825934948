import React from 'react';
import { useField, getIn } from 'formik';
import { Input, Label, Error, TextArea } from './test-field.styles';
import './styles.css';
export const TextField = ({
    textarea,
    label,
    placeholder,
    disabled,
    maxLength,
    onChange,
    keyPress,
    keyUp,
    overMax,
    accountType,
    max,
    ...props
}) => {
    const [field, meta] = useField(props);

    const errorText = meta.error && meta.touched ? meta.error : '';
    const getMinDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };
    return (
        <div style={{padding: "12px 8px"}}>
            <Label>
                {label} {!textarea && label !== 'Code postal' && '*'}
            </Label>
            {textarea ? (
                <TextArea
                    placeholder={placeholder}
                    {...field}
                    helperText={errorText}
                    error={!!errorText}
                    type={props.type}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            ) : (
                <Input
                    placeholder={placeholder}
                    {...field}
                    helperText={errorText}
                    error={!!errorText}
                    type={props.type}
                    disabled={disabled}
                    maxLength={maxLength}
                    onChange={onChange ? onChange : field.onChange}
                    onKeyPress={keyPress}
                    onKeyUp={keyUp}
                    {...(props.type === 'date' && { min: getMinDate() })}
                />
            )}

            <Error>{errorText}</Error>
            {overMax && (
                <Error>{`Le compte ${accountType} est plafonné à ${max} DHS`}</Error>
            )}
        </div>
    );
};
