import React from 'react';
import Layout from '../../../components/layout';
import ProLanding from '../../../components/pro/landing';
import Institution from '../../../components/pro/institution';

const CampusPage = () => {
    return (
        <Layout notTransparent={true}>
            <div className="compus-wrapper">
                {/* <ProLanding pack="institution" /> */}
                <Institution />
            </div>
        </Layout>
    );
};

export default CampusPage;
