import React from 'react';
import B2bForm from '../../../components/form/b2b';
import SEO from '../../../components/seo';
import Layout from '../../../components/layout';

const Acquisition = ({ location }) => {
    return (
        <>
            <SEO title="Acquisition" url="/b2b/acquisition" />
            <B2bForm type={location.state ? location.state.type : ''} />
        </>
    );
};

export default Acquisition;
