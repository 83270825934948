import React from 'react';
import { Row, Col } from 'antd';
import PackCard from '../../../common/pack-card';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Title, Button, ButtonIcon } from '../../../common/SimpleRow/styles';
import { Text } from '../../ecommerce/advantage/styles';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
const Pack = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulTpePack(sort: { fields: createdAt, order: ASC }) {
                nodes {
                    title
                    content
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    const br = detect();
    return (
        <>
            <Row className="" justify="center" style={{ padding: '50px 20px' }}>
                <Col lg={24}>
                    <Title theme={theme} style={{ textAlign: 'center' }}>
                        Une large gamme de TPE NAPS
                    </Title>
                </Col>
                <Col
                    lg={24}
                    className="justify-center"
                    style={{ margin: '30px 0' }}
                >
                    <Text theme={theme}>
                        Faîtes votre choix parmi une panoplie de modèles et
                        optez pour le TPE qui s’adapte le plus à votre activité.
                    </Text>
                </Col>
                <Col lg={24} className="justify-center">
                    <Link to="/tpes">
                        <Button br={br?.name}>
                            En savoir plus
                        </Button>
                    </Link>
                </Col>
            </Row>
        </>
    );
};
export default Pack;
