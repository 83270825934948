import React, { useState } from 'react';
import { FieldArray, Field } from 'formik';
import { Row, Col, Collapse } from 'antd';
import {
    Title,
    SubTitle,
    AddFamilyButton,
    ButtonWrapper,
    Button,
    BackButton,
} from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '../../select';
import { TextField } from '../../text-field';
import { FileField } from '../../file-field';
import RadioField from '../../common/radio-field';
import RechargeField from '../../common/recharge-field';
import cities from '../cities.json';
import Popup from 'reactjs-popup';
import { CloseIcon } from '../../common/vd-modal/styles';
const { Panel } = Collapse;
const civilities = [
    { value: 'M.', label: 'M.' },
    { value: 'Mme', label: 'Mme' },
    { value: 'Mlle', label: 'Mlle' },
];
const accountTypes = [{ value: 'Confort +', label: 'Confort +' }];
const accountTypePrice = {
    'Confort +': 20000,
};
const choises = [
    {
        value: 'online',
        label: 'Je souscris maintenant',
    },
    {
        value: 'inplace',
        label: 'Je souscris à la livraison',
    },
];
const FamilyForm = ({
    values,
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
    back,
    accountType,
    setFieldTouched,
    openNotification,
}) => {
    const defaultPanels = [];
    const [state, setState] = useState({
        selected: -1,
        showError: false,
        error: '',
        activePanels: [],
    });
    const handleChange = (e) => {
        setState({
            ...state,
            selected: e.target.value,
        });
    };
    for (let i = 0; i < values.family.length; i++) {
        if (defaultPanels.indexOf(i) >= 0 || i > 2) continue;
        defaultPanels.push(i);
    }
    const handleNextClick = () => {
        setTouched({
            family: true,
        });
        validate().then((errors) => {
            if (Object.keys(errors).length === 0) {
                nextStep();
                setValue('step', 3);
            } else if (typeof errors.family === 'string') {
                setState({
                    ...state,
                    showError: true,
                });
                openNotification();
            } else {
                setState({
                    ...state,
                    activePanels: [0, 1, 2],
                });
                openNotification();
            }
        });
    };
    const handleBackClick = () => {
        back();
        setValue('step', 1);
    };
    const handleFileChange = (e, name) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => setValue(name, reader.result.split(',')[1]);
    };
    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <Title>
                        Commandez des cartes pour les bénéficiaires du Pack
                    </Title>
                </Col>
            </Row>

            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <SubTitle>
                        Veuillez choisir le mode de souscription pour les 3
                        cartes secondaires de votre Pack NAPS Family
                    </SubTitle>
                </Col>
            </Row>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <RadioField values={choises} name="orderPlace"></RadioField>
                </Col>
            </Row>
            <FieldArray
                name="family"
                render={(arrayHelpers) => (
                    <>
                        <Row align="top" justify="center">
                            <Col
                                xs={24}
                                sm={24}
                                md={20}
                                lg={14}
                                xl={14}
                                xxl={12}
                            >
                                <Collapse
                                    ghost
                                    expandIconPosition="right"
                                    destroyInactivePanel={true}
                                    defaultActiveKey={[0, 1, 2]}
                                    // activeKey={state.activePanels}
                                >
                                    {values.family.map((family, index) => {
                                        return (
                                            <Panel
                                                style={{
                                                    fontFamily: 'Maven Pro',
                                                    fontWeight: 'bold',
                                                }}
                                                header={
                                                    family.firstName
                                                        ? family.firstName +
                                                          ' ' +
                                                          (family.lastName
                                                              ? family.lastName
                                                              : '')
                                                        : 'Membre ' +
                                                          (index + 1)
                                                }
                                                key={index}
                                                extra={
                                                    <FontAwesomeIcon
                                                        icon="trash"
                                                        style={{
                                                            fontSize: '20px',
                                                        }}
                                                        onClick={() => {
                                                            arrayHelpers.remove(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                <>
                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <Select
                                                                data={
                                                                    civilities
                                                                }
                                                                label="Civilité"
                                                                placeholder=""
                                                                name={`family.${index}.civility`}
                                                            ></Select>
                                                        </Col>
                                                    </Row>
                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <TextField
                                                                name={`family.${index}.firstName`}
                                                                placeholder=""
                                                                label="Prénom"
                                                                maxLength={25}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setValue(
                                                                        `family.${index}.firstName`,
                                                                        event
                                                                            .target
                                                                            .value
                                                                            ? event.target.value
                                                                                  .substring(
                                                                                      0,
                                                                                      1
                                                                                  )
                                                                                  .toUpperCase() +
                                                                                  event.target.value
                                                                                      .substring(
                                                                                          1
                                                                                      )
                                                                                      .toLowerCase()
                                                                            : ''
                                                                    );
                                                                }}
                                                            ></TextField>
                                                        </Col>
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <TextField
                                                                name={`family.${index}.lastName`}
                                                                placeholder=""
                                                                label="Nom"
                                                                maxLength={25}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setValue(
                                                                        `family.${index}.lastName`,
                                                                        event
                                                                            .target
                                                                            .value
                                                                            ? event.target.value.toUpperCase()
                                                                            : ''
                                                                    );
                                                                }}
                                                            ></TextField>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <Select
                                                                data={
                                                                    accountTypes
                                                                }
                                                                label="Type de compte"
                                                                placeholder=""
                                                                name={`family.${index}.accountType`}
                                                            ></Select>
                                                        </Col>
                                                    </Row> */}

                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <TextField
                                                                label="Numéro de téléphone"
                                                                placeholder=""
                                                                name={`family.${index}.phone`}
                                                                maxLength="10"
                                                            ></TextField>
                                                        </Col>
                                                    </Row>

                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <TextField
                                                                label="Confirmation du numéro de téléphone"
                                                                placeholder=""
                                                                name={`family.${index}.confirmPhone`}
                                                                maxLength="10"
                                                            ></TextField>
                                                        </Col>
                                                    </Row>
                                                    {/* {(values.family[index]
                                                        .accountType ===
                                                        'Confort' ||
                                                        values.family[index]
                                                            .accountType ===
                                                            'Confort +') && (
                                                        <Row
                                                            align="top"
                                                            justify="center"
                                                            gutter={[16, 24]}
                                                        >
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                            >
                                                                <FileField
                                                                    label="Type de pièce d’identité"
                                                                    placeholder="Type de pièce d’identité"
                                                                    name={`family.${index}.identityFile`}
                                                                    setFieldValue={
                                                                        setValue
                                                                    }
                                                                    title="Pièce d’identité"
                                                                    onFileChange={(
                                                                        e
                                                                    ) =>
                                                                        handleFileChange(
                                                                            e,
                                                                            `family.${index}.identityFile`
                                                                        )
                                                                    }
                                                                ></FileField>
                                                            </Col>
                                                        </Row>
                                                    )} */}
                                                    {/* {values.family[index]
                                                        .accountType ===
                                                        'Confort +' && (
                                                        <Row
                                                            align="top"
                                                            justify="center"
                                                            gutter={[16, 24]}
                                                        >
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                            >
                                                                <FileField
                                                                    label="Justificatif de domicile"
                                                                    placeholder="Justificatif de domicile"
                                                                    setFieldValue={
                                                                        setValue
                                                                    }
                                                                    name={`family.${index}.adresseProofFile`}
                                                                    title="Justificatif de domicile"
                                                                    onFileChange={(
                                                                        e
                                                                    ) =>
                                                                        handleFileChange(
                                                                            e,
                                                                            `family.${index}.adresseProofFile`
                                                                        )
                                                                    }
                                                                ></FileField>
                                                            </Col>
                                                        </Row>
                                                    )} */}
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <SubTitle
                                                                style={{
                                                                    color:
                                                                        '#e66d30',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    margin:
                                                                        '10px 0 -10px 0',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon="home"
                                                                    style={{
                                                                        fontSize:
                                                                            '40px',
                                                                        width:
                                                                            '50px',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                />
                                                                Adresse de
                                                                Livraison
                                                            </SubTitle>
                                                        </Col>
                                                    </Row>

                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <RadioField
                                                                values={[
                                                                    {
                                                                        value: true,
                                                                        label:
                                                                            'Même adresse que domicile',
                                                                    },
                                                                    {
                                                                        value: false,
                                                                        label:
                                                                            'Autre adresse',
                                                                    },
                                                                ]}
                                                                name={`family.${index}.useHomeAdresse`}
                                                            ></RadioField>
                                                        </Col>
                                                    </Row>
                                                    {!family.useHomeAdresse && (
                                                        <>
                                                            <Row
                                                                align="top"
                                                                justify="center"
                                                                gutter={[
                                                                    16,
                                                                    24,
                                                                ]}
                                                            >
                                                                <Col
                                                                    xs={24}
                                                                    sm={24}
                                                                    md={24}
                                                                    lg={24}
                                                                    xl={24}
                                                                >
                                                                    <TextField
                                                                        label="Adresse"
                                                                        placeholder=""
                                                                        maxLength={
                                                                            100
                                                                        }
                                                                        name={`family.${index}.deliveryAdresse`}
                                                                    ></TextField>
                                                                </Col>
                                                            </Row>

                                                            <Row
                                                                align="top"
                                                                justify="center"
                                                                gutter={[
                                                                    16,
                                                                    24,
                                                                ]}
                                                            >
                                                                <Col
                                                                    xs={24}
                                                                    sm={24}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <Select
                                                                        label="Ville"
                                                                        name={`family.${index}.deliveryCity`}
                                                                        data={
                                                                            cities
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    xs={24}
                                                                    sm={24}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <TextField
                                                                        placeholder=""
                                                                        label="Code postal"
                                                                        name={`family.${index}.deliveryZipCode`}
                                                                    ></TextField>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}

                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <SubTitle
                                                                style={{
                                                                    color:
                                                                        '#e66d30',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    margin:
                                                                        '10px 0 -10px 0',
                                                                }}
                                                            >
                                                                Souhaitez-vous
                                                                effectuer une
                                                                recharge
                                                                initiale ?
                                                            </SubTitle>
                                                        </Col>
                                                    </Row>

                                                    <Row
                                                        align="top"
                                                        justify="center"
                                                        gutter={[16, 24]}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                        >
                                                            <RadioField
                                                                values={[
                                                                    {
                                                                        value: true,
                                                                        label:
                                                                            'Oui',
                                                                    },
                                                                    {
                                                                        value: false,
                                                                        label:
                                                                            'Non',
                                                                    },
                                                                ]}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setValue(
                                                                        `family.${index}.initialRechargeAmount`,
                                                                        e.target
                                                                            .value
                                                                            ? 100
                                                                            : 0
                                                                    );
                                                                }}
                                                                name={`family.${index}.initialRecharge`}
                                                            ></RadioField>
                                                        </Col>
                                                    </Row>
                                                    {family.initialRecharge && (
                                                        <Row
                                                            align="top"
                                                            justify="center"
                                                            gutter={[16, 24]}
                                                        >
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                            >
                                                                <RechargeField
                                                                    name={`family.${index}.initialRechargeAmount`}
                                                                    setValue={
                                                                        setValue
                                                                    }
                                                                    max={
                                                                        accountTypePrice[
                                                                            family
                                                                                .accountType
                                                                        ]
                                                                    }
                                                                    value={
                                                                        family.initialRechargeAmount
                                                                    }
                                                                    accountType={
                                                                        family.accountType
                                                                    }
                                                                />
                                                                {/* <TextField
                                                                    label="Montant (DHS)"
                                                                    placeholder="Montant (DHS)"
                                                                    name={`family.${index}.initialRechargeAmount`}
                                                                    disabled={
                                                                        !family.initialRecharge
                                                                    }
                                                                    type="number"
                                                                ></TextField> */}
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </>
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </Col>
                        </Row>

                        <Row align="middle" justify="center" gutter={[16, 24]}>
                            <Col
                                xs={24}
                                sm={24}
                                md={20}
                                lg={14}
                                xl={14}
                                xxl={12}
                            >
                                {values.family.length < 3 &&
                                    values.orderPlace === 'online' && (
                                        <AddFamilyButton
                                            type="button"
                                            onClick={() => {
                                                if (values.family.length < 3) {
                                                    arrayHelpers.push({
                                                        firstName: '',
                                                        lastName: '',
                                                        civility: '',
                                                        accountType:
                                                            'Confort +',
                                                        phone: '',
                                                        confirmPhone: '',
                                                        useHomeAdresse: true,
                                                        deliveryAdresse: '',
                                                        deliveryCity: '',
                                                        deliveryZipCode: '',
                                                        identityFile: null,
                                                        initialRechargeAmount: 0,
                                                    });
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                style={{ marginRight: '20px' }}
                                                icon="plus"
                                            />
                                            Ajouter un membre
                                        </AddFamilyButton>
                                    )}
                            </Col>
                        </Row>
                        <Row
                            align="top"
                            justify="center"
                            style={{ marginBottom: '20px' }}
                        >
                            <Col
                                xs={24}
                                sm={24}
                                md={20}
                                lg={14}
                                xl={14}
                                xxl={12}
                            >
                                <ButtonWrapper>
                                    <Button
                                        type="button"
                                        onClick={handleNextClick}
                                    >
                                        Continuer
                                    </Button>

                                    <BackButton
                                        type="button"
                                        onClick={handleBackClick}
                                    >
                                        Retour
                                    </BackButton>
                                </ButtonWrapper>
                            </Col>
                        </Row>
                    </>
                )}
            />
            <Popup
                overlayStyle={{ background: '#00000090' }}
                modal
                nested
                open={state.showError}
                onClose={() => setState({ ...state, showError: false })}
            >
                <div
                    style={{
                        width: '500px',
                        height: '150px',
                        background: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '30px',
                    }}
                    className="modal"
                >
                    <CloseIcon
                        className="close"
                        onClick={() => setState({ ...state, showError: false })}
                    >
                        &times;
                    </CloseIcon>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: 'Maven Pro',
                            fontSize: '16px',
                        }}
                    >
                          Nous vous rappelons que le Pack Famille prévoit 3
                        cartes secondaires, en plus de la carte principale.
                    </p>
                    <BackButton
                        onClick={() => setState({ ...state, showError: false })}
                    >
                        OK
                    </BackButton>
                </div>
            </Popup>
        </>
    );
};

export default FamilyForm;
