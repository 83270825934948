import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const Pro = () => {
    useEffect(() => {
        navigate('/pro/entreprise');
    });
    return <></>;
};
export default Pro;
