/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, meta, title, url }) {
    const { site, logoOg } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        media {
                            twitter
                            facebook
                        }
                    }
                }
                logo: file(relativePath: { eq: "productslogo.webp" }) {
                    publicURL
                }
                logoOg: file(relativePath: { eq: "logo.webp" }) {
                    publicURL
                }
            }
        `
    );

    return (
        <Helmet>
            {/* <link
                rel="icon"
                type="image/png"
                href={`${site.siteMetadata.siteUrl}${logo.publicURL}`}
            />
            <meta
                name="image"
                content={`${site.siteMetadata.siteUrl}${logo.publicURL}`}
            />
            <meta name="title" content={`NAPS | ${title}`}></meta>
            <meta
                name="description"
                content={description || site.siteMetadata.description}
            />

            <meta
                property="og:url"
                content={`${site.siteMetadata.siteUrl}${url}`}
            />
            <meta property="og:site_name" content={'NAPS'} />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={`NAPS | ${title}`} />
            <meta
                property="og:description"
                content={site.siteMetadata.description}
            />
            <meta
                property="og:image"
                content={`${site.siteMetadata.siteUrl}${logo.publicURL}`}
            />
            <meta property="og:updated_time" content={`1613579044581`} />
            <link rel="canonical" href={site.siteMetadata.siteUrl + url} />

            <meta name="keywords" content={keywords || defaultKeywords} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={site.siteMetadata.author} />
            <meta name="twitter:title" content={`NAPS | ${title}`} />
            <meta
                name="twitter:description"
                content={description || site.siteMetadata.description}
            />
            <meta
                name="twitter:image:src"
                content={`${site.siteMetadata.siteUrl}${logo.publicURL}`}
            />

            <title>{`NAPS | ${title}`}</title>

            <html lang="fr" dir="ltr" /> */}

            <meta
                name="description"
                content={description || site.siteMetadata.description}
            />
            <meta
                name="image"
                content={`${site.siteMetadata.siteUrl}${logoOg.publicURL}`}
            />
            <meta
                property="og:site_name"
                content={meta}
            />
            <meta
                property="og:url"
                content={`${site.siteMetadata.siteUrl}${url}`}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={`NAPS | ${title}`} />
            <meta
                property="og:description"
                content={description || site.siteMetadata.description}
            />
            <meta
                property="og:image"
                content={`${site.siteMetadata.siteUrl}${logoOg.publicURL}`}
            />
            <meta
                property="fb:app_id"
                content={site.siteMetadata.media.facebook}
            />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={site.siteMetadata.author} />
            <meta
                name="twitter:site"
                content={site.siteMetadata.media.twitter}
            />
            <meta name="twitter:title" content={`NAPS | ${title}`} />
            <meta
                name="twitter:description"
                content={description || site.siteMetadata.description}
            />
            <meta
                name="twitter:image:src"
                content={`${site.siteMetadata.siteUrl}${logoOg.publicURL}`}
            />
            <meta name="facebook-domain-verification" content="eqbmbrjezq0chn1lnwv0f3zw8q5931" />
            <link rel="canonical" href={site.siteMetadata.siteUrl + url + "/"} />
            {/* <title>{`NAPS | ${title}`}</title> */}
            <title>{`NAPS | Solutions de paiements électroniques | Cartes, TPE, E-commerce`}</title>
            <html lang={'fr'} dir="ltr" />

        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `fr`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
