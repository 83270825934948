import styled from 'styled-components';

export const FileUpload = styled.input`
    display: none;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const AddButton = styled.div`
    background-color: #edf1f7;
    border-color: #edf1f7;
    color: #222b45;
    padding: 0.6875rem 1.125rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
`;
export const FileText = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #000000;
`;
export const Col = styled.div`
    margin-right:10px;
`;
export const Icon = styled.span`
    margin-right:10px;
`;
export const Error = styled.span`
    font-size: 11px;
    color: #cf1500;
`;
