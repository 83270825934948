import React, {useContext } from 'react';
import { Link } from 'gatsby';
import { StaticImage, } from 'gatsby-plugin-image';
import { InfoWrapper,Title,SubTitle,Button } from './styles';
import ThemeContext from '../../layout/theme/ThemeContext';
import './styles.css';
import {
    ImageWrapper,
} from '../../landing/styles';

const VignetteSlide = () => {
    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div
            className="container mx-auto auto-top-margin row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
        >
                <div className="justify-center">
                    <InfoWrapper>
                        <Title theme={theme} >
                            Paiement de votre vignette 2023 en ligne ou en agence Naps
                        </Title>
                        <SubTitle  style={{color: 'rgb(60, 60, 59)'}} >
                        Payer la vignette automobile n'a jamais été aussi simple.<br></br> En quelques clics et en 5 minutes, vous pouvez payer et imprimer votre quittance sur notre plateforme.
                        </SubTitle>
                        <div style={{ position: 'relative' }}>
                        <Link to="https://vignette.naps.ma">
                            <Button>
                                <h3 style={{ color: '#fff' }}>Payer ma vignette en ligne</h3>
                            </Button>
                        </Link>
                    </div>
                    </InfoWrapper>
                </div>
                <div
                    className="justify-center"
                    style={{ flexDirection: 'column' }}
                >
                <div className='slide-hearder'>
                    <ImageWrapper 
                        style={{
                            maxWidth: '100%',
                            paddingTop: '0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '5rem',
                        }}
                    > 
                            <StaticImage
                                    style={{
                                        width: '35rem',
                                        height: '100%',
                                    }}
                                    formats={["auto", "webp"]}
                                    placeholder='blurred'
                                    src='../../../images/slide/vignette-banner.webp'
                                    alt=''
                                />
                    </ImageWrapper>
                </div>
                <div className='slide-hearder-mobile'>
                    <ImageWrapper
                        style={{
                            maxWidth: '100%',
                            paddingTop: '0',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                        }}
                    > 
                            <StaticImage
                                    style={{
                                        width: '15rem',
                                        height: '100%',
                                        marginTop: '2rem',
                                    }}
                                    formats={["auto", "webp"]}
                                    placeholder='blurred'
                                    src='../../../images/slide/vignette-banner.webp'
                                    alt=''
                                />
                    </ImageWrapper>
                </div>
                </div>
            </div>
    </>
    );
};
export default VignetteSlide;
