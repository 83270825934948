import React from 'react';
import Layout from '../../components/layout';
import CasaweSlide from '../../components/casawe/casawe-slide';
import CasaweStade from '../../components/casawe/stade';
import Casawenotfound from '../../components/casawe/NoteFound';
import CasaweTheatres from '../../components/casawe/Theatres';
import CasaweBusTramway from '../../components/casawe/Tramway';
import CasaweAvantages from '../../components/casawe/avantages';
import IndividualOrder from '../../components/individual/order';
import ManualOrder from '../../components/individual/manual-order';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
import CasawePromo from '../../components/casawe/promo';
import CasaweGeolocation from '../../components/casawe/geolocation';


const CasawePage = ({ location }) => {
    return (
        <>
            <Helmet>
            <title>CasaWe - Ta carte d'accès aux grands événements à Casablanca</title>
            <meta property="og:title" content="CasaWe - Ta carte d'accès aux grands événements à Casablanca" />
            <meta name="description" content="Accès aux stades de foot, théâtre, évènements culturels et services de transports de la ville ainsi que des réductions chez tous partenaires de Naps!" />
            <meta property="og:site_name" content="La carte CasaWe de Naps - partenaire officiel de WeCasablanca"/>
            </Helmet>
            
            <Layout location={location}>
                {/*<CasaweSlide/>
                <CasaweStade/>
                <CasaweTheatres/>
                <CasaweBusTramway/>
                <CasaweAvantages/>
                <CasawePromo/>
                <IndividualOrder location={location} orderType={'casawe'}/>
                <CasaweGeolocation/>
                <ManualOrder />
                */}
                <Casawenotfound/>
            </Layout>
        </>
    );
};

export default CasawePage;
