import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
`;
export const Button = styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: #e66d30;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 40px;
    height: 60px;
    position: relative;
    top: 24px;
`;
export const Value = styled.div`
    margin: 0 20px 0 20px;
    color: #000;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 100;
    font-size: 25px;
    position: relative;
    top: 38px;
`;
