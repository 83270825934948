import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { http } from '../../../components/http';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal } from 'antd';
import { Title } from '../../../components/b2c/index.styles';
import SEO from '../../../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentSucceeded = () => {
    const [data, setNum] = useQueryParam('data', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });
    useEffect(() => {
        
        http.post('/card-order/checkIfTicketingPaymentSuccess/',{ data: data, codecmr: codecmr }).then(
            (res) => {
               var orderId =  res.data.orderId
                if(res.data.repauto ==="00"){
                    http.post('/card-order/paymentticketssucceeded/', { data: data, codecmr: codecmr }).then((response) => {
                        navigate('/billetterie', {state: { step: 2 ,data:response.data}})
                    });
                    
                }else{
                    // http.post('/card-order/paymentticketsunsucceeded/'+ orderId , null).then(
                    //     (response) => {
                    //         setState({
                    //             ...state,
                    //             isLoading: false,
                    //         });
                    //     }
                    // );
                    navigate('/billetterie', {state: { step: 0,message:"Votre commande n'a pas abouti. Merci de réessayer."},})
                }
            }
        );
        // http.post('/card-order/paymentticketssucceeded', { orderId: data }).then(
        //     (res) => {
        //         setState({
        //             ...state,
        //             isLoading: false,
        //         });
        //     }
        // );
    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Paiement succès" url={'/billetterie/success'} />
        </Layout>
    );
};

export default PaymentSucceeded;
