export const onlyCharacters = (e) => {
    const charCode = e.which;
    if (
        !(
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode == 32
        )
    )
        e.preventDefault();
};

export const emailKeyPress = (e) => {
    const charCode = e.which;
    if (
        !(
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode == 64 ||
            charCode == 46 ||
            charCode == 95 ||
            (charCode >= 48 && charCode <= 57)
        )
    )
        e.preventDefault();
};

export const adresseKeyPress = (e) => {
    const charCode = e.which;
    if (
        !(
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode == 64 ||
            charCode == 46 ||
            charCode == 95 ||
            charCode == 32 ||
            (charCode >= 48 && charCode <= 57)
        )
    )
        e.preventDefault();
};

export const onlyNumbers = (e) => {
    const charCode = e.which;
    if (!(charCode >= 48 && charCode <= 57))
        e.preventDefault();
};
