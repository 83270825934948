import styled, { css } from 'styled-components';
import { Row } from 'antd';
import { Link } from 'gatsby';

export const PrivacyCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    align-items: center;
`

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 250px; */
    align-items: center;
    width: 100%;
    padding: 30px;
`;
export const Text = styled.p`
    font-family: Rajdhani, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    margin: 24px 0;
`;

export const FooterRow = styled(Row)`
    background-color: ${({ theme }) =>
        theme === 'light' ? '#E66D30' : '#212121'};
    width: 100%;
`;
export const FooterLink = styled.a`
    font-family: Rajdhani, sans-serif;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
    color: ${({ theme }) => (theme === 'light' ? '#ffffff' : '#ffffff')};
`;
export const PrivacyRow = styled(Row)`
    width: 100%;
    min-height: 70px;
    justify-content: center;
`;

export const FooterText = styled.p`
    margin: 0;
    font-family: Rajdhani, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${({ theme }) => (theme === 'light' ? '' : 'rgb(43, 42, 42)')};
`;
