import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import BlogsHome from '../../components/blogs/blogs-home';
import BlogsArticle from '../../components/blogs/blogs-article';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';


const Blog = () => {
    return (
        <Layout >
            <SEO title="Blogs" url={'/blog'}/>
            <div style={{ padding: '150px 0' }}>
                <BlogsHome/>
            </div>
        </Layout>
    );
};
export default Blog;
