import styled from "styled-components";

export const Number = styled.p`
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    color: #fff;
    font-family: 'Maven Pro', sans-serif;
    background: #ffa055;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

`;