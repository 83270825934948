import React, { useEffect, useState } from 'react';
import { Wrapper } from '../my-space/styles';
import { Row, Col } from 'antd';
import AppCard from '../common/app-card';
import '../my-space/styles.css';
import { useContext } from 'react';
import ThemeContext from '../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { Title } from '../landing/styles';
import { NextWrapper, NextContent, LinkWrapper } from './styles';
export const MenuDetails = ({ type, data }) => {
    const [state, setState] = useState({
        br: '',
    });

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);
    const firstRowDetails = data
        .filter((i) => i.row === 1)
        .map((item, index) => {
            return (
                <Col
                    key={index}
                    md={8}
                    sm={12}
                    xs={24}
                    className="justify-center"
                >
                    <AppCard
                        title={item.title}
                        content={item.content}
                        image={item.icon && item.icon.file.url}
                        url={item.link}
                        from="details"
                        state={item.state && { pack: item.state }}
                        button={item.button}
                    />
                </Col>
            );
        });
    const secondRowDetails = data
        .filter((i) => i.row === 2)
        .map((item, index) => {
            return (
                <Col
                    key={index}
                    md={8}
                    sm={12}
                    xs={24}
                    className="justify-center"
                >
                    <AppCard
                        title={item.title}
                        content={item.content}
                        image={item.icon && item.icon.file.url}
                        url={item.link}
                        from="details"
                        state={item.state && { pack: item.state }}
                        button={item.button}
                    />
                </Col>
            );
        });

    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper br={state.br} theme={theme}>
            <Row
                style={{ width: '100%', paddingTop: '100px' }}
                justify="center"
            >
                <Col lg={18}>
                    <Row>
                        <Col md={24} xs={24}>
                            <Title
                                theme={theme}
                                style={{
                                    textAlign: 'center',
                                    fontSize: '30px',
                                    marginBottom: '50px',
                                }}
                            >
                                {type == 'pro'
                                    ? 'Découvrez nos offres dédiées aux Professionnels'
                                    : 'Découvrez nos offres dédiées aux Particuliers'}
                            </Title>
                        </Col>
                    </Row>
                    <Row
                        justify="center"
                        style={
                            state.br === 'ie'
                                ? { width: '65%' }
                                : { width: '100%' }
                        }
                        gutter={[24, 24]}
                    >
                        {firstRowDetails}
                    </Row>
                    <Row
                        justify="center"
                        style={
                            state.br === 'ie'
                                ? { width: '65%' }
                                : { width: '100%' }
                        }
                        gutter={[24, 24]}
                    >
                        {secondRowDetails}
                    </Row>
                    <Row justify="center">
                        <Col md={24} sm={24} xs={24}>
                            <NextWrapper>
                                <NextContent>
                                    {type !== 'pro' ? (
                                        <>
                                            Vous êtes un commerçant, un
                                            professionnel ou une entreprise,
                                            découvrez nos offres destinées aux{' '}
                                            <LinkWrapper
                                                style={{ color: '#fff' }}
                                                to={'/pro/details'}
                                            >
                                                Professionnels
                                            </LinkWrapper>{' '}
                                        </>
                                    ) : (
                                        <>
                                            Vous êtes Particulier, découvrez
                                            également nos produits et services
                                            pour{' '}
                                            <LinkWrapper
                                                style={{ color: '#fff' }}
                                                to={'/individual/details'}
                                            >
                                                Particuliers
                                            </LinkWrapper>
                                        </>
                                    )}
                                </NextContent>
                            </NextWrapper>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Wrapper>
    );
};
export default MenuDetails;
