import React from 'react';
import Layout from '../../components/layout';
import { Row, Col } from 'antd';
import { SubTitle as Header } from '../../components/form/styles';
import { Title, SubTitle } from '../../components/common/SimpleRow/styles';
import SEO from '../../components/seo';
import { useContext } from 'react';
import ThemeContext from '../../components/layout/theme/ThemeContext';

const Terms = () => {
    const { theme } = useContext(ThemeContext);
    return (
        <Layout>
            <SEO title="Termes" url="/terms" />
            <Row
                justify="center"
                style={{ marginTop: '100px', marginBottom: '100px', padding: '20px', flexDirection: 'column', alignContent: 'center' }}
            >
                <Col xxl={12} xl={12} lg={14} md={20} sm={24} xs={24}>
                    <Title theme={theme} >POLITIQUE DE CONFIDENTIALITÉ</Title>
                </Col>
                <Col
                    xxl={12}
                    xl={12}
                    lg={14}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Header theme={theme} >1. Notre engagement et notre responsabilité</Header>
                    <SubTitle theme={theme}>
                        NAPS SA, RC 130313, (« NAPS », « nous », « nos » ou «
                        notre ») s’engage à protéger et à respecter la vie
                        privée de toute personne dont nous traitons les
                        informations personnelles dans le cadre de la fourniture
                        de nos produits et services (collectivement, ci-après,
                        les « services »), ce terme englobant les produits et
                        services fournis par NAPS ou des sociétés filiales du
                        groupe M2M Group. Nous décrivons ci-après les
                        informations personnelles que nous traitons et pourquoi
                        nous les traitons, ainsi que les choix qui s’offrent à
                        vous. La présente Politique de confidentialité décrit le
                        traitement dans le cadre duquel NAPS est le responsable
                        du traitement. Nos sites web et services peuvent parfois
                        contenir des liens vers des sites web tiers sur lesquels
                        nous n’avons aucun contrôle. Si vous consultez ces sites
                        web ou utilisez ces services, sachez que la présente
                        Politique de confidentialité ne s’applique pas au
                        traitement par ces tiers de vos informations
                        personnelles et que c’est pourquoi nous vous conseillons
                        d’examiner attentivement la façon dont ces tiers
                        traitent les informations personnelles avant d’utiliser
                        leurs sites web ou services.
                    </SubTitle>
                </Col>

                <Col
                    xxl={12}
                    xl={12}
                    lg={14}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Header>
                        2. Informations personnelles traitées par nous
                    </Header>
                    <SubTitle theme={theme}>
                        Nous traitons les informations personnelles que vous
                        nous communiquez lors de votre inscription à nos
                        services et dans le cadre de vos contacts avec nous. Il
                        s’agit, à titre d’exemple des informations personnelles
                        que nous pouvons traiter, votre nom, votre numéro
                        d’identification personnel ou votre date de naissance,
                        l’adresse de votre domicile, votre adresse e-mail, votre
                        adresse IP, l’identifiant unique des appareils que vous
                        utilisez pour accéder aux services et à nos sites web et
                        pour les utiliser, vos coordonnées bancaires et
                        l’historique de vos transactions bancaires de et vers
                        nous.
                    </SubTitle>
                </Col>
                <Col
                    xxl={12}
                    xl={12}
                    lg={14}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Header>
                        3. Comment nous utilisons les informations personnelles
                    </Header>
                    <SubTitle theme={theme}>
                        Nous traitons toujours les informations personnelles
                        conformément à la loi en vigueur, en l’occurrence la Loi
                        09-08 relative à la protection à la protection des
                        personnes physiques à l’égard du traitement des données
                        à caractère personnel, et nous avons mis en œuvre des
                        mesures de sécurité visant à empêcher que vos
                        informations personnelles soient utilisées à des fins
                        illégitimes ou communiquées à des tiers non autorisés.
                        <br />
                        Nous traitons vos informations personnelles afin de vous
                        fournir nos services, pour exécuter les contrats que
                        nous avons conclus avec vous et encore pour gérer notre
                        relation avec vous. Les informations personnelles sont
                        également traitées pour nous permettre de vous faire
                        part des dernières actualités, informations et messages
                        marketing concernant nos services, et d’autres services
                        similaires. Nous pouvons également utiliser les
                        informations personnelles pour améliorer et développer
                        nos sites web et nos services.
                        <br />
                        En outre, nous pouvons traiter les informations
                        personnelles à des fins légitimes d’évaluation de la
                        solvabilité et de gestion du risque, pour empêcher toute
                        utilisation abusive de nos services et tout non- respect
                        des modalités associées, et pour remplir nos obligations
                        en vertu de la loi et des règlements publiés par la
                        banque centrale du Maroc BANK AL-MAGHRIB et les réseaux
                        de cartes concernés.
                    </SubTitle>
                </Col>

                <Col
                    xxl={12}
                    xl={12}
                    lg={14}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Header>4. Votre accord et vos choix</Header>
                    <SubTitle theme={theme}>
                        En vous inscrivant à l’un des services ou en
                        l’utilisant, vous consentez au traitement décrit dans la
                        présente Politique de confidentialité. Vous avez à tout
                        moment la possibilité nous informer que vous ne
                        souhaitez plus recevoir des messages publicitaires de
                        notre part.
                        <br />
                        Nous rectifierons et mettrons à jour vos informations
                        personnelles sur demande, de même que si nous apprenons
                        que certaines données ne sont plus exactes. Si vous
                        souhaitez rectifier ou mettre à jour certaines
                        informations personnelles, ou nous informer que vous ne
                        souhaitez plus recevoir de messages publicitaires de
                        notre part, nous vous invitons à nous contacter à :
                        <span style={{ fontWeight: 'bold', color: '#e66d30' }}>
                            info@naps.ma
                        </span>
                        <br />
                        Afin de vérifier que nous traitons des informations
                        personnelles exactes et à jour, vous avez le droit,
                        gratuitement et une fois par an, d’obtenir un extrait
                        des informations personnelles que nous traitons à votre
                        sujet. Vous devez signer en personne cette demande et
                        l’envoyer par courrier normal à l’adresse ci-dessous :
                        <br />
                        NAPS, Direction Conformité &amp Contrôle
                        <br />
                        16, Rue Abdelhak Ben Mehyou, Quartier Palmiers, 20040,
                        Casablanca, Maroc
                    </SubTitle>
                </Col>
                <Col
                    xxl={12}
                    xl={12}
                    lg={14}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Header>5. Cookies</Header>
                    <SubTitle theme={theme}>
                        Sur www.naps.ma, nous utilisons des cookies pour
                        mesurer, tester, personnaliser et faciliter
                        l'utilisation de notre site web et de nos services. Les
                        cookies nous permettent de comprendre comment nos
                        services en ligne sont utilisés, et de les développer et
                        les améliorer pour satisfaire au mieux les attentes des
                        utilisateurs. Nous utilisons également des cookies de
                        session pour vous garantir une sécurité renforcée
                        n'enregistrent aucune donnée personnelle vous
                        concernant, et disparaissent dès que vous quittez votre
                        navigateur.Voici quelques exemples de données que nous
                        recueillons :
                        <br />
                        Nous rectifierons et mettrons à jour vos informations
                        personnelles sur demande, de même que si nous apprenons
                        que certaines données ne sont plus exactes. Si vous
                        souhaitez rectifier ou mettre à jour certaines
                        informations personnelles, ou nous informer que vous ne
                        souhaitez plus recevoir de messages publicitaires de
                        notre part, nous vous invitons à nous contacter à :
                        <br />
                        • Le type de navigateur
                        <br />
                        • OS
                        <br />
                        • Des informations concernant l'appareil mobile utilisé
                        <br />
                        • Votre adresse IP
                        <br />
                        • Les pages que vous visitez
                        <br />
                        • Le temps passé sur chaque page
                        <br />
                        • Votre dernière visite
                        <br />
                        • Vos références (votre provenance)
                        <br />
                        En utilisant{' '}
                        <span style={{ fontWeight: 'bold', color: '#e66d30' }}>
                            www.naps.ma
                        </span>
                        , vous consentez à l'utilisation de cookies provenant de
                        notre site.
                    </SubTitle>
                </Col>
            </Row>
        </Layout>
    );
};
export default Terms;
