import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import {
    Title,
    UseCaseWrapper,
    NumbersWrapper,
    Number,
    HintImage,
    CategoryTitle,
} from './styles';
import { InfoWrapper } from '../SimpleRow/styles';
import CatalogItemCard from './catalog-card';
import Slider from 'react-slick';
import { Zoom } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import ThemeContext from '../../layout/theme/ThemeContext';
const TpeCatalogCard = ({ category, nodes }) => {
    const [state, setState] = useState({
        activeTpe: 0,
        showTpes: true,
    });

    let slider = null;
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,

        prevArrow: (
            <FontAwesomeIcon
                icon="caret-left"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        nextArrow: (
            <FontAwesomeIcon
                icon="caret-right"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        customPaging: (i) => <div className="custom_dot" style={{ backgroundColor: 'transparent' }}></div>,
        beforeChange: (current, next) =>
            setState({ ...state, activeTpe: next }),
    };
    const { theme } = useContext(ThemeContext);
    return (
        <Row
            justify="center"
            style={{
                margin: '20px 0',
                padding: '0 20px',
            }}
        >
            <Col xs={24} className="justify-center">
                <CategoryTitle
                    onClick={() =>
                        setState({ ...state, showTpes: !state.showTpes })
                    }
                >
                    {category}
                </CategoryTitle>
            </Col>
            {state.showTpes && (
                <>
                    <Col
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '20px',
                            padding: '10px',
                        }}
                        className="justify-center"
                        xxl={8}
                        xl={9}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <Zoom>
                            <InfoWrapper>
                                <Title theme={theme} style={{ textTransform: 'none' }} >
                                    Gamme recommandée pour les activités
                                    suivantes
                                </Title>
                                <UseCaseWrapper>
                                    {nodes.map((item, index) => {
                                        if (item.useCaseImages) {
                                            return (
                                                < >
                                                    {item.useCaseImages.map(
                                                        (img, imgIndex) => {
                                                            return (
                                                                <img
                                                                    alt={'tpe'}
                                                                    style={{
                                                                        width:
                                                                            '50px',
                                                                        margin:
                                                                            '10px',
                                                                    }}
                                                                    src={
                                                                        img.file
                                                                            .url
                                                                    }
                                                                    key={
                                                                        imgIndex
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </>
                                            );
                                        }
                                    })}
                                </UseCaseWrapper>
                                <NumbersWrapper style={{ marginTop: '30px' }}>
                                    {nodes.map((item, tpeIndex) => (
                                        <div
                                            key={tpeIndex}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Number
                                                onClick={() => {
                                                    slider.slickGoTo(tpeIndex);
                                                    setState({
                                                        ...state,
                                                        activeTpe: tpeIndex,
                                                    });
                                                }}
                                                style={
                                                    state.activeTpe === tpeIndex
                                                        ? {
                                                            backgroundColor:
                                                                'rgb(230, 109, 48)',
                                                            color: '#fff',
                                                            boxShadow:
                                                                '-1px 7px 19px 0px rgb(230, 109, 48)',
                                                            transform:
                                                                'scale(1.1)',
                                                        }
                                                        : {}
                                                }
                                                theme={theme}
                                            >
                                                <HintImage
                                                    src={
                                                        item.image &&
                                                        item.image.file.url
                                                    }
                                                />
                                            </Number>
                                            <span
                                                style={{
                                                    fontFamily: 'Maven Pro',
                                                    fontWeight: 'bold',
                                                    position: 'relative',
                                                    bottom: '-12px',
                                                    color: `${theme === 'dark' ? '#fff' : ''}`
                                                }}
                                            >
                                                {tpeIndex + 1}
                                            </span>
                                        </div>
                                    ))}
                                </NumbersWrapper>
                            </InfoWrapper>
                        </Zoom>
                    </Col>
                    <Col
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        className="justify-center"
                        xxl={8}
                        xl={9}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <div
                            style={{
                                paddingTop: '20px',
                                maxWidth: '24rem',
                            }}
                        >
                            <Zoom>
                                <Slider ref={(s) => (slider = s)} {...settings} adaptiveHeight={true} className="tpe-model-slider" >
                                    {nodes.map((item, tpeIndex) => (
                                        <CatalogItemCard
                                            key={tpeIndex}
                                            index={tpeIndex}
                                            title={item.title}
                                            image={
                                                item.image &&
                                                item.image.file.url
                                            }
                                            details={item.details.json}
                                            id={item.id}
                                        />
                                    ))}
                                </Slider>
                            </Zoom>
                        </div>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default TpeCatalogCard;
