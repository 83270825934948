import React, { useState } from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';

const CasawePromo = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "casawe-promo" }) {
                id
                title
                subTitle
                image {
                    title
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <>
            <SimpleRow
                title={data.contentfulLanding.title}
                subTitle={data.contentfulLanding.subTitle}
                imageUrl={data.contentfulLanding.image.file.url}
                state="right"
                id="stade"
            />
        </>
    );
};
export default CasawePromo;
