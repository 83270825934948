import styled from 'styled-components';

export const Wrapper = styled.div`
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-size: 40px;
    font-weight: bold;
    padding: 5px 20px;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : ''};
`

export const ImageWrapper = styled.div`
    max-width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 100%;
`;
export const Image = styled.img`
    max-width: 100%;
`;
