import React, { useState } from 'react';
import { Row, Col, Modal, Spin } from 'antd';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from './text-field';
import { Button, Title } from '../form/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { http } from '../http';
import { LoadingOutlined } from '@ant-design/icons';
import { onlyCharacters, emailKeyPress, adresseKeyPress } from '../helpers/only-characters';
import { Select } from './select';
import { CheckBoxField } from '../checkbox-field-white';

const antIcon = (
    <LoadingOutlined
        style={{ fontSize: 24, color: 'rgb(230, 109, 48)' }}
        spin
    />
);

const schema = Yup.object().shape({
    firstName: Yup.string().required('Ce champ est obligatoire'),
    lastName: Yup.string().required('Ce champ est obligatoire'),
    phone: Yup.string()
        .matches(
            /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
            'Veuillez indiquer un numéro de téléphone valide'
        )
        .required('Ce champ est obligatoire'),
    email: Yup.string()
        .email('Veuillez saisir un e-mail valide')
        .required('Ce champ est obligatoire'),
    organisation: Yup.string().required('Ce champ est obligatoire'),
    employeesNumber: Yup.string().required('Ce champ est obligatoire'),
    /*terms: Yup.boolean()
    .oneOf([true], "Vous devez accepter la politique de confidentialité.")
    .required('Vous devez accepter la politique de confidentialité.'),*/
    
    terms: Yup.boolean()
        .oneOf([true, null], 'Vous devez accepter la politique de confidentialité.')
        .required('Vous devez accepter la politique de confidentialité.'),

});


const employees = [
    { value: '0-10', label: '0 - 10' },
    { value: '11-50', label: '11 - 50' },
    { value: '51-500', label: '51 - 500' },
    { value: '500+', label: '500+' },
];

const B2cContactForm = ({ pack }) => {
    const handleCheckboxChange = (e) => {
        setValue('terms', e.target.checked);
    };
    const [state, setState] = useState({
        showModal: false,
        isLoading: false,
    });
    const handelSubmit = (values) => {
        setState({
            ...state,
            isLoading: true,
        });
        http.post('/contact/rdv', values).then((res) => {
            setState({
                ...state,
                showModal: true,
                isLoading: false,
            });
            values.firstName='';
            values.lastName='';
            values.phone='';
            values.email='';
            values.organisation='';
            values.employeesNumber='';
        });
    };
    return (
        <div style={{ marginTop: '40px' }}>
            <Formik
                initialValues={{
                    pack:
                        pack == 'pro'
                            ? 'M3allem'
                            : pack == 'institution'
                            ? 'Campus'
                            : pack== 'entreprise'
                                ? 'pro'
                                : pack,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    organisation: '',
                    employeesNumber: '',
                    terms:false,
                }}
                validationSchema={schema}
                onSubmit={handelSubmit} 
            >
                {({
                    isValid,
                    setFieldTouched,
                    errors,
                    setTouched,
                    validateForm,
                    setFieldValue,
                    values,
                }) => (
                    <Form autoComplete="on">
                        <Row justify={'center'}>
                            <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
                                <TextField
                                    name="firstName"
                                    label="Prénom*"
                                    placeholder="Prénom"
                                    maxLength={25}
                                    onChange={(event) => {
                                        setFieldValue(
                                            'firstName',
                                            event.target.value
                                                ? event.target.value
                                                      .substring(0, 1)
                                                      .toUpperCase() +
                                                      event.target.value
                                                          .substring(1)
                                                          .toLowerCase()
                                                : ''
                                        );
                                    }}
                                    keyPress={onlyCharacters}

                                ></TextField>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
                                <TextField
                                    name="lastName"
                                    label="Nom*"
                                    placeholder="Nom"
                                    maxLength={25}
                                    onChange={(event) => {
                                        setFieldValue(
                                            'lastName',
                                            event.target.value
                                                ? event.target.value.toUpperCase()
                                                : ''
                                        );
                                    }}
                                    keyPress={onlyCharacters}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col md={12} sm={24} xs={24}>
                                <TextField
                                    name="phone"
                                    label="Tél.*"
                                    placeholder="Tél."
                                    maxLength="10"
                                    keyPress={(e) => {
                                        if (
                                            e.key === ' ' ||
                                            e.charCode < 48 ||
                                            e.charCode > 57
                                        )
                                            e.preventDefault();
                                    }}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col md={12} sm={24} xs={24}>
                                <TextField
                                    name="email"
                                    label="E-mail*"
                                    placeholder="Adresse@adresse.com"
                                    keyPress={emailKeyPress}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col md={12} sm={24} xs={24}>
                                <TextField
                                    name="organisation"
                                    label="Etablissement*"
                                    placeholder="Etablissement"
                                    maxLength={25}
                                    keyPress={adresseKeyPress}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col md={12} sm={24} xs={24}>
                                <Select
                                    style={{color:'#ƒƒƒ'}}
                                    name="employeesNumber"
                                    label="Nombre de collaborateurs"
                                    placeholder="0-10"
                                    data={employees}
                                ></Select>
                            </Col>
                        </Row>
                         <Row justify={'center'}>
                            <Col md={12} sm={24} xs={24}>
                                <CheckBoxField
                                    value={values.terms}
                                    pre="J’ai lu et j’accepte la politique de confidentialité, notamment "
                                    suf="personnelles conformément à la loi 09/08."
                                    linkText="la mention relative à la protection des données"
                                    to="/terms"
                                    name="terms"
                                    onChange={handleCheckboxChange}
                                ></CheckBoxField>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col
                                md={12}
                                sm={24}
                                xs={24}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '5px',
                                }}
                            >
                                {state.isLoading ? (
                                    <Spin indicator={antIcon} />
                                ) : (
                                    <Button>Envoyer</Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            <Modal
                visible={state.showModal}
                centered
                onCancel={() => {
                    setState({ ...state, showModal: false });                       
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        minHeight: '250px',
                        flexDirection: 'column',
                        padding: '30px',
                    }}
                >
                    <FontAwesomeIcon
                        icon="check"
                        style={{
                            fontSize: '100px',
                            color: '#63e6be',
                            margin: '20px',
                            width: '100px',
                        }}
                    />
                    <Title style={{ textAlign: 'center', fontSize: '17px' }}>
                        Nous vous remercions pour votre intérêt. <br /> Vous
                        serez contactés par notre équipe commerciale dans les
                        plus brefs délais.
                    </Title>
                </div>
            </Modal>
        </div>
    );
};

export default B2cContactForm;
