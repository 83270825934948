import React from 'react';
import MenuDetails from '../../../components/menu-details';
import Layout from '../../../components/layout';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../../../components/seo';
const Details = () => {
    const { allContentfulMenuDetails } = useStaticQuery(graphql`
        query IndividualDetails {
            allContentfulMenuDetails(
                filter: { type: { eq: "individual" } }
                sort: { fields: order, order: ASC }
            ) {
                nodes {
                    title
                    content
                    button
                    link
                    state
                    row
                    icon {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Particuliers" />
            <MenuDetails
                data={allContentfulMenuDetails.nodes}
                type="individual"
            />
        </Layout>
    );
};

export default Details;
