import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
import { navigate } from 'gatsby';


const WecasaPage = () => {

    useEffect(() => {
        var referrer = document.referrer;
        console.log("referrer url",referrer);
        navigate('/b2c', {state: { step: 0,orderType:"wac",urlSource:referrer}})
    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Paiement succès" url={'/wecasa'} />
        </Layout>
    );
};

export default WecasaPage;

