import React from 'react';
import Layout from '../../../components/layout';
import ProLanding from '../../../components/pro/landing';
import Entreprise from '../../../components/pro/entreprise';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const EntreprisePage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "pro.webp" }) {
                childImageSharp {
                    fixed(width: 110) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return (
        <Layout notTransparent={true}>
            <div className={'pro-wrapper'}>
                {/* <ProLanding pack={'entreprise'} /> */}
                <Entreprise />
            </div>
        </Layout>
    );
};

export default EntreprisePage;
