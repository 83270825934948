import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import Layout from '../../components/layout';
import NewsTemplate from '../../components/news';
import SEO from '../../components/seo';

const News = () => {
    return (
        <Layout>
            <SEO title="Actualités" url="news" />
            <NewsTemplate />
        </Layout>
    );
};

export default News;
