import styled from 'styled-components';

export const Wrapper = styled.div`
    width: auto;
    min-height: 150px;
    background: ${({ theme }) => (theme === 'light' ? '#fff' : '#212121')};
    box-shadow: #000 0px 0px 17px -14px;
    margin: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    flex-direction: column;
    color: #3c3c3b;
`;
export const Content = styled.p`
    margin: 0;
    padding: 0;
    font-size: 15px;
    text-align: center;
    width: 100%;
`;
export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #3c3c3b;
`;
