import React, { useContext } from 'react';
import { Wrapper, Content, Name } from './styles';
import ThemeContext from '../../layout/theme/ThemeContext';
const PartnerCard = ({ name, feedback }) => {
    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper theme={theme}>
            <Content>{`" ${feedback} "`}</Content>
            <Name>{`@${name}`}</Name>
        </Wrapper>
    );
};

export default PartnerCard;
