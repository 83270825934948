import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Col } from 'antd';
import AdvantageCard from '../../../common/advantage-card';
import '../../pro/advantage/styles.css'
import { M3allemRow } from '../../pro/advantage/styles';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
const Advantage = () => {
    const [state, setState] = useState({
        br: '',
    });

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);
    const data = useStaticQuery(graphql`
        query {
            allContentfulParagraph(
                sort: { fields: number, order: ASC }
                filter: { key: { eq: "institution" } }
            ) {
                edges {
                    node {
                        number
                        title
                        text {
                            text
                        }
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);

    return (
        <M3allemRow br={state.br} theme={theme} justify="center" style={{ padding: '80px 0px 50px 0px' }} >
            <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" >
                {data.allContentfulParagraph.edges.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="justify-center"
                        >
                            <AdvantageCard
                                number={item.node.number}
                                title={item.node.title}
                                content={item.node.text.text}
                                image={item.node.image ? item.node.image.file.url : ''}
                            />
                        </div>
                    );
                })}
            </div>
        </M3allemRow>
    );
};

export default Advantage;
