import styled from 'styled-components';
import { Row } from 'antd';
export const Wrapper = styled.div`
    padding: 20px;
    padding-top: 100px;
    background-color: ${({ theme }) =>
        theme === 'dark' ? 'rgb(43,42,42)' : '#f7f7f7'};
    min-height: 100%;
    z-index: 2;
`;
export const CategoryName = styled.h1`
    background-color: rgba(230, 109, 48, 0.9);
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    height: fit-content;
    padding: 10px;
    min-width: 33rem;
    border-radius: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-align: center;
    &:hover {
        box-shadow: -1px 7px 19px 0px rgb(230, 109, 48);
    }
    @media (max-width: 485px) {
        min-width: 100%;
        width: 85vw;
    }
    @media (max-width: 617px) {
        min-width: 100%;
        width: 85vw;
    }
`;

export const RowWrapper = styled(Row)`
    background-color: ${({ theme }) => (theme === 'dark' ? '#212121' : '#fff')};
    border-radius: 50px;
    padding: 80px 20px;
    margin-top: -40px;
`;
export const ImageWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    max-width: 100%;
    @media (max-width: 582px) {
        width: 100%;
    }
`;
export const Image = styled.img`
    max-width: 100%;
    max-height: 25rem;
    z-index: 2;
`;
export const SolidBackground = styled.div`
    background-color: #ffa055;
    height: 200px;
    width: 80%;
    position: absolute;
    top: 130px;
    z-index: 0;
    left: 0;
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 40px;
`;

export const ShortCategoryName = styled.h1`
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
    color: #ffa055;
    font-size: 30px;
    margin: 0;
`;
export const TpeName = styled.h2`
    font-weight: bold;
    font-family: 'Maven Pro',sans-serif;
    color: #000;
    font-size: 25px;
    margin: 0;
`;
export const ShortDetail = styled.p`
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', sans-serif;
    margin: 0;
    font-size: 15px;
`;
export const AddToCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 25px 0;
`;
export const NumberInput = styled.input`
    border: 1px solid #000;
    border-radius: 10px;
    color: #000;
    font-weight: 500;
    width: 70px;
    padding: 5px 10px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    &:focus {
        outline: none;
    }
`;

export const AddToCardButton = styled.button`
    background-color: #fff;
    color: #e96d25;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    margin-left: 20px;
    padding: 0 20px;
    box-shadow: #000 0px 0px 54px -14px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #000 0px 0px 100px -14px;
    }
`;
export const SeeMoreButton = styled.div`
    background-color: transparent;
    color: #e96d25;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s ease-in-out;
    &:focus {
        outline: none;
    }
`;
export const ListItemRow = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 10px 0;
    p {
        margin: 0;
        margin-left: 10px;
    }
`;
