import React from 'react';
import { Wrapper, CheckBox } from './checkbox.styles';
import { Label, Error } from '../text-field/test-field.styles';
import { useField } from 'formik';
import { Link } from 'gatsby';

export const CheckBoxField = ({
    label,
    placeholder,
    linkText,
    to,
    pre,
    suf,
    theme,
    ...props
}) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <>
            <Wrapper>
                <CheckBox
                    placeholder={placeholder}
                    {...field}
                    helperText={errorText}
                    error={!!errorText}
                    type="checkbox"
                />
                {linkText && (
                    <Label theme={theme}>
                        <span style={{color: '#fff'}}>{pre}</span> <a style={{color: '#0390fc'}} href={to} rel="noreferrer" target="_blank">{linkText}</a> <span style={{color: '#fff'}}>{suf}</span>
                    </Label>
                )}
                {!linkText && <Label> {label} </Label>}
            </Wrapper>
            <Error>{errorText}</Error>
        </>
    );
};
