import React, { useEffect,useState } from 'react';
import { Row, Col, Input, AutoComplete } from 'antd';
import { Title, ButtonWrapper, Button, BackButton } from '../styles';
import { TextField,Label } from '../../text-field';
import { Select } from '../../select';
import { notification } from 'antd';
import RadioField from '../../common/radio-field';
import ShipAdresseIcon from '../../../images/shipadresse.inline.svg';
import cities from '../cities.json';
import { adresseKeyPress, onlyNumbers } from '../../helpers/only-characters';

const deliveryTypes = [
    // {value: true, label: 'Même adresse que mon domicile'},
    // { value: 'false', label: 'Autre adresse' },
    { value: true, label: 'Livraison à domicile ( +30dh)' },
];

const casaCityOnly = [
    {value: 'Casablanca', label: 'Casablanca'},
];

export const ContractForm = ({
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
    back,
    accountType,
    useHomeAdresse,
    getAllAgencies,
    getAllCityHasAgencies,
    getAgencyByCity,
    values,
    getFeeByCardType,
    adresse,
    city,
    cityOptions,
    zipCode,
    orderType,
    openNotification,
}) => {
    const handleNextClick = () => {
        setTouched({
            deliveryAdresse: true,
            deliveryCity: true,
            deliveryZipCode: true,
            agency: true,
            selectedAgency:true,
        });
        validate().then((errors) => {
            console.log(errors)
            if(!values.selectedAgency && useHomeAdresse === 'à l’agence'){
                notification.open({
                    message: 'Erreur',
                    description:
                      'Veuillez sélectionner votre agence de livraison',
                    onClick: () => {
                    },
                  });
            } else {
                if (Object.keys(errors).length === 0) {
                    nextStep();
                    setValue('step', orderType === 'wac' ? 4 : 3);
                    //setValue('step', orderType === 'family' ? 4 : 3);
                } else {
                        openNotification();
                }
            }
        });
    };
    const wacAgenciesOption = [
        {value: "Complexe Mohammed V", label: "Complexe Mohammed V"},
        {value: "Complexe Mohamed BENJELLOUN", label: "Complexe Mohamed BENJELLOUN"},
        {value: "Agence Naps Dar Lamane", label: "Agence Naps Dar Lamane"},
        {value: "Agence Naps Sidi Moumen", label: "Agence Naps Sidi Moumen"},
        {value: "Agence Naps Mly Rachid", label: "Agence Naps Mly Rachid"},
        {value: "Agence Naps Gauthier", label: "Agence Naps Gauthier"},
        {value: "Agence Naps Hay moulay Abdellah", label: "Agence Naps Hay moulay Abdellah"},
    ];
    const [state, setState] = useState({
        cityOptions: [],
        agenciesOptions: [],
        widadAgenciesOptions: [],
        disabled:true,
        selectedAdresse: null,
        selectedCity:null,
        selectedAgency: null,
    });
    const handleChange = (e) => {
        if(!e.target.value){
            setState({
                ...state,
                selectedAdresse: null,
            });
        }
    };
    useEffect(() => {
        if((orderType==='casawe'|| orderType==='wac')&& deliveryTypes.length<2){
            //deliveryTypes.push({ value: 'à l’agence', label: 'En agence Naps' })
            deliveryTypes.push({ value: 'à l’agence', label: 'Retrait sur point de vente (Gratuit)' })
        }
        if (useHomeAdresse == true) {
            setValue('deliveryType', null);
            // setValue('deliveryAdresse', adresse);
            // setValue('deliveryCity', city);
            // setValue('deliveryZipCode', zipCode);
        } else if(useHomeAdresse ==='à l’agence') {
            setValue('deliveryType', 'à l’agence');
        }
        //  else {
        //     setValue('deliveryAdresse', '');
        //     setValue('deliveryCity', '');
        //     setValue('deliveryZipCode', '');
        //     setValue('deliveryType', null);
        // }
        
        getAllCityHasAgencies().then((res) => {
           const cities = res.data;
           const options = cities.map((city) => {
                       return {
                           value: city,
                           label: (
                               <p style={{ color: '#212121' }}>
                                   {city}
                               </p>
                           ),
                           city: city,
                       };
           });

           setState({
               ...state,
               cityOptions: options,
           });
           getFeeByCardType(orderType).then((res) => {
            const fee = res.data;
            if(useHomeAdresse !=='à l’agence'){
                setValue('fee', fee);
            }else {
                setValue('fee', 0);
                console.log(values)
            }
         })
       })
       if(values.selectedCity !==''){
        getAgencies(values.selectedCity);
       }
    }, [useHomeAdresse]);
    const handleBackClick = () => {
        back();
        setValue('step', orderType === 'wac' ? 2 : 1);
    };
    const selectedCity = (option) => {
        setValue('deliveryCity', option);
        setValue('selectedCity',option);
        setState({
            ...state,
            selectedCity: option,
        });
        setValue('selectedDisabled',false)
        getAgencies(option);

     };
    
    const getCities = ()=>{
        getAllCityHasAgencies().then((res) => {
            const cities = res.data;
            const options = cities.map((city) => {
                        return {
                            value: city,
                            label: (
                                <p style={{ color: '#212121' }}>
                                    {city}
                                </p>
                            ),
                            city: city,
                        };
            });
 
            setState({
                ...state,
                cityOptions: options,
            });
        })
    }


    const getAgencies=(option)=>{
        getAgencyByCity(option).then((res) => {
            const agencies = res.data;
            console.log(agencies)
           const options = agencies.map((agency) => {
                       return {
                           value: agency.agency,
                           label: (
                               <p style={{ color: '#212121' }} >
                                   {agency.agency}
                               </p>
                           ),
                           adresse: agency.address,
                       };
           });
           console.log(options)
           setState({
               ...state,
               agenciesOptions: options,
           });
       })
    } 
    const setWidadCardDeliveryAdresse =  (adresse, option)  => {
        console.log(option.value)
        values.deliveryAdresse = option.value;
        values.deliveryZipCode = '';
        values.selectedAgency = option.value;
        setState({
            ...state,
            selectedAdresse: option.value,

        });
        console.log(values)

     };
    const setDeliveryAdresse = (adresse, option) => {
        setValue('deliveryAdresse', option.adresse);
        setValue('deliveryZipCode', '');
        setValue('selectedAgency',option.value)
        setState({
            ...state,
            selectedAdresse: option.adresse,

        });
     };
    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <Title
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#e66d30',
                        }}
                    >
                        <ShipAdresseIcon style={{ width: '60px' }} /> A quelle
                        adresse souhaitez-vous être livré ?
                    </Title>
                </Col>
            </Row>

            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <RadioField
                        values={deliveryTypes}
                        name="useHomeAdresse"
                    ></RadioField>
                </Col>
            </Row>

            {useHomeAdresse === 'à l’agence' && (
                     <>
                       <Row align="middle" justify="center" gutter={[16, 24]} 
                 style={{ marginTop: '20px' }}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <label style={{ fontSize: '16px',
                                    fontWeight: '500',
                                    color: 'rgba(0,0,0,0.7)',
                                    fontFamily: 'Maven Pro',}}>
                         Sélectionner votre point de retrait *
                     </label>
                     <AutoComplete
                            options={wacAgenciesOption}
                            filterOption={(inputValue, option) => {
                                const label =
                                    typeof option.value === 'string'
                                        ? option.value
                                        : '';
                                return (
                                    label &&
                                    label
                                        .toUpperCase()
                                        .indexOf(inputValue?.toUpperCase()) !==
                                    -1
                                );
                            }}
                            className="input"
                            onSelect={setWidadCardDeliveryAdresse}
                            value={values.selectedAgency}x
                            dropdownMatchSelectWidth={500}
                            style={{ width: '99%',margin: '1rem 0', border: 'none',}}
                        >
                            <Input.Search
                                name="agency"
                                size="large"
                                onChange={(event) => {
                                    setValue(
                                        'selectedAgency',
                                        event.target.value
                                            ? event.target.value
                                            : ''
                                    );
                                }}
                                placeholder="Sélectionner votre agence de livraison"
                                style={{ color: '#212121', width: '100%' }}
                            />
                    </AutoComplete>
                </Col>
            </Row>
          {/* <Row align="middle" justify="center" gutter={[16, 24]} 
                 style={{ marginTop: '20px' }}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <label style={{ fontSize: '16px',
                                    fontWeight: '500',
                                    color: 'rgba(0,0,0,0.7)',
                                    fontFamily: 'Maven Pro',}}>
                         Sélectionner votre ville de livraison *
                     </label>
                    <AutoComplete
                            options={state.cityOptions}
                            filterOption={(inputValue, option) => {
                                const label =
                                    typeof option.value === 'string'
                                        ? option.value
                                        : '';
                                return (
                                    label &&
                                    label
                                        .toUpperCase()
                                        .indexOf(inputValue?.toUpperCase()) !==
                                    -1
                                );
                            }}
                            className="input"
                            onSelect={selectedCity}
                            value={values.selectedCity}
                            dropdownMatchSelectWidth={500}
                            style={{width: '99%',margin: '1rem 0',border: 'none',}}
                        >
                            <Input.Search
                                name="agency"
                                size="large"
                                onChange={(event) => {
                                    getCities()
                                    setValue(
                                        'selectedCity',
                                        event.target.value
                                            ? event.target.value
                                            : ''
                                    );
                                    setValue(
                                        'selectedDisabled', true
                                    );
                                    setValue(
                                        'selectedAgency', ''
                                    );
                                }}
                                placeholder="Sélectionner votre ville de livraison"
                                style={{ color: '#212121', width: '100%' }}
                            />
                    </AutoComplete>
                </Col>
            </Row>
              <Row align="middle" justify="center" gutter={[16, 24]} 
                 style={{ marginTop: '20px' }}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <label style={{ fontSize: '16px',
                                    fontWeight: '500',
                                    color: 'rgba(0,0,0,0.7)',
                                    fontFamily: 'Maven Pro',}}>
                         Sélectionner votre point de retrait *
                     </label>
                    <AutoComplete
                            options={state.agenciesOptions}
                            filterOption={(inputValue, option) => {
                                const label =
                                    typeof option.value === 'string'
                                        ? option.value
                                        : '';
                                return (
                                    label &&
                                    label
                                        .toUpperCase()
                                        .indexOf(inputValue?.toUpperCase()) !==
                                    -1
                                );
                            }}
                            disabled={values.selectedDisabled}
                            className="input"
                            onSelect={setDeliveryAdresse}
                            value={values.selectedAgency}
                            dropdownMatchSelectWidth={500}
                            style={{ width: '99%',margin: '1rem 0', border: 'none',}}
                        >
                            <Input.Search
                                name="agency"
                                size="large"
                                onChange={(event) => {
                                    setValue(
                                        'selectedAgency',
                                        event.target.value
                                            ? event.target.value
                                            : ''
                                    );
                                }}
                                placeholder="Sélectionner votre agence de livraison"
                                style={{ color: '#212121', width: '100%' }}
                            />
                    </AutoComplete>
                </Col>
            </Row> */}
            </>
            )}

        {useHomeAdresse !== 'à l’agence' && (
                <>
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <TextField
                        label="Adresse"
                        placeholder=""
                        name="deliveryAdresse"
                        value={values.deliveryAdresse}
                        maxLength={100}
                        //disabled={useHomeAdresse==true?true:false}
                        keyPress={adresseKeyPress}
                    ></TextField>
                </Col>
            </Row>

            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                    <Select
                        name="deliveryCity"
                        // data={cities}
                        data={casaCityOnly}
                        label="Ville"
                    />
                </Col>
                <Col xs={24} sm={24} md={10} lg={7} xl={7} xxl={6}>
                    <TextField
                        name="deliveryZipCode"
                        placeholder=""
                        label="Code postal"
                        keyPress={onlyNumbers}
                    ></TextField>
                </Col>
            </Row>
            </>
               )}
            <Row align="top" justify="center">
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <ButtonWrapper>
                        <Button type="button" onClick={handleNextClick}>
                            Continuer
                        </Button>

                        <BackButton type="button" onClick={handleBackClick}>
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
            </Row>
        </>
    );
};
