import React, { Component } from 'react';
import { Row, Layout, Steps } from 'antd';
import { ImageWrapper } from '../../components/b2c/index.styles';
import { WelcomeForm } from '../../components/ticketing-form/welcom-form';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import '../../components/b2c/style.css';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { http } from '../../components/http';
import { Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
import { Title } from '../../components/individual/order/styles';
import SEO from '../../components/seo';
import { ReservationForm } from '../../components/ticketing-form/reservation-form';
import { PaymenReceipt } from '../../components/ticketing-form/paymen-receipt';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';



const ticketingUrl= process.env.TICKETING_URL;
const { Header, Content, Footer, Sider } = Layout;
const { Step } = Steps;


const Image = () => {
    const { contentfulLogo } = useStaticQuery(graphql`
        query {
            contentfulLogo {
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <img
            src={contentfulLogo.image.file.url}
            alt="naps"
            style={{ maxWidth: '100%' }}
        />
    );
};


const schema = Yup.object().shape({
    step: Yup.number(),
    clientId: Yup.string().when('step', {
        is: (step) => step == 1,
        then: Yup.string()
            .required('Ce champ est obligatoire'),
    }),

    zone: Yup.string().when('step', {
        is: (step) =>
            step === 1 ,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),

});

class TicketingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            lastStepIndex: 3,
            accountType: '',
            orderType: '',
            showModal: false,
            data: '',
            receipt:{},
            codecmr:'',
            values: {},
            openPopup: false,
            isLoading: false,
            messageError:'',
        };

        if (typeof window !== `undefined`){
        if (window.performance) {
            if (performance.navigation.type == 1) {
                this.state.currentStep = 0;
                this.state.messageError = ''
            } else {
                console.log(this.props.location.state)
                if(this.props.location.state != null){
                   // window.location.reload(false);
                    //this.state.codecmr = this.props.location.state.codecmr
                    this.state.receipt = this.props.location.state.data
                    if(this.props.location.state.step !== undefined && this.props.location.state.step !== null) {
                        this.state.currentStep = this.props.location.state.step
                        this.state.messageError = this.props.location.state.message
                    }
                    else{
                        this.state.currentStep = 0;
                    }    
                    
                }
            }
        }
    }   
    }

    openNotification = () => {
        notification.open({
          message: 'Erreur',
          description:
            'Une erreur s\'est produite, veuillez vérifier vos entrées',
          onClick: () => {
          },
        });
      };

    handleNextClick = () => {
        if (this.state.currentStep < this.state.lastStepIndex)
            this.setState({
                currentStep: this.state.currentStep + 1,
            });
    };
    handleBackClick = () => {
        if (this.state.currentStep > 0)
            this.setState({
                currentStep: this.state.currentStep - 1,
            });
    };
    handleBackToFirstClick = () => {
            this.setState({
                currentStep: 0,
            });
    };
    formatDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

     getAllEvents () {
        return http.get('/card-order/allevents');
    };
    
    openConfirmPopup(values) {
        this.setState({
            openPopup: true,
            values: values,
        });
    }

    render() {
        return (
            <Layout style={{maxWidth: '100%'}} className="container layout">
                <SEO title="Billetterie et tickets en ligne" url="/billetterie"/>
                <Helmet>
                    <title>{`Billetterie de Naps`}</title>
                    <meta name="description"content={`La billetterie en ligne de Naps pour réserver vos tickets pour les plus grands matchs et rendez-vous sportifs de la ville de Casablanca`} />
                </Helmet>
                <Sider
                    breakpoint="xs sm"
                    className="container side-bar"
                    style={{
                        backgroundColor: '#1c5188',
                        backgroundImage: 'none',
                        width: 'auto',
                        flex: '0 0 300px',
                        maxWidth: '300px',
                    }}
                >
                    <ImageWrapper>
                        <Link to="/">
                        <StaticImage
                            className='animated-img'
                            style={{
                                width: '11rem',
                                height: '100%'
                            }}
                            formats={["auto", "webp"]}
                            placeholder='blurred'
                            src="../../images/white-logo-wecasa.webp"
                            alt="La billetterie en ligne de Naps pour réserver vos tickets pour les plus grands matchs et rendez-vous sportifs de la ville de Casablanca"
                            width='350'
                        />
                            {/* <Image></Image> */}
                        </Link>
                    </ImageWrapper>
                    <Steps
                        progressDot
                        direction="vertical"
                        current={this.state.currentStep}
                        className="steps"
                    >
                        <Step title="Bienvenue" />

                        <Step title="Réservation" />

                        {/* <Step title="Paiement" /> */}

                        <Step title="Reçu de paiement" />
                    </Steps>
                </Sider>
                <Content
                    style={{ padding: '10px 10px 30px 10px' }}
                    className="container content"
                >
                    <div className="form-wrapper">
                        <Formik
                            initialValues={{
                                step: this.state.currentStep,
                                fullName:'',
                                event:{},
                                receipt:this.state.receipt,
                                eventsList:[],
                                clientId:'',
                                zone:'',
                                amount:'',
                                eventName:'',
                                eventdate:'',
                                codecmr:this.state.codecmr,
                                data:this.state.data,
                                orderId:'',
                                description:'',
                                picEvent:'',
                                zones:[],
                                getwayUrl:'',
                                messageError:this.state.messageError,
                            }}
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                isValid,
                                setFieldTouched,
                                errors,
                                setTouched,
                                validateForm,
                                setFieldValue,
                                values,
                                isSubmitting,
                                submitForm,
                            }) => (
                                <Form style={{height:'100%'}} autoComplete="on">
                                    {this.state.currentStep === 0 && (
                                        <WelcomeForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            getAllEvents={this.getAllEvents}
                                            setTouched={setTouched}
                                            setValue={setFieldValue}
                                            values={values}
                                        />
                                    )}
                                    {this.state.currentStep === 1 && (
                                        <ReservationForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            values={values}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                    {/* {this.state.currentStep === 2 && (
                                        <PaymentPage
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            values={values}
                                            openNotification={this.openNotification}                                        />
                                    )} */}
                                    {this.state.currentStep === 2 && (
                                        <PaymenReceipt
                                        nextStep={this.handleNextClick}
                                        isValid={isValid}
                                        setTouched={setTouched}
                                        validate={validateForm}
                                        setValue={setFieldValue}
                                        back={this.handleBackToFirstClick}
                                        values={values}
                                        openNotification={this.openNotification}                                        />
                                    )}
                                    <Modal
                                        visible={this.state.showModal}
                                        centered
                                        onCancel={() => {
                                            this.setState({ showModal: false });
                                            navigate('/');
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                minHeight: '250px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon="check"
                                                style={{
                                                    fontSize: '100px',
                                                    color: '#63e6be',
                                                    margin: '20px',
                                                    width: '100px',
                                                }}
                                            />
                                            <Title
                                                style={{
                                                    fontSize: '17px',
                                                    color: '#212121',
                                                }}
                                            >
                                                Félicitations !
                                                <br />
                                                <br />
                                                Votre commande est enregistrée
                                                avec succès !
                                                <br />
                                                <br />
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#c4c4c4',
                                                    }}
                                                >
                                                    Un email de confirmation a
                                                    été envoyé à votre adresse :
                                                    <br />
                                                    {values.email}
                                                    <br />
                                                    <br />À bientôt !
                                                </span>
                                                <br />
                                                <br />
                                            </Title>
                                        </div>
                                    </Modal>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default TicketingForm;
