import React, { useState } from 'react';
import './styles.css';
import NewHeader from './new';
const Header = ({ notTransparent, toggleSide }) => {
    const [state, setState] = useState({
        style: { display: 'flex', justifyContent: 'space-between' },
        width: 0,
        showSide: false,
        isTransparent: !notTransparent,
    });

    
    return (
        <>
            <NewHeader
                openMenu={() => {
                    toggleSide();
                    setState({ ...state, showSide: true, isTransparent: false });
                }}
                isTransparent={false}
            />
        </>
    );
};

export default Header;
