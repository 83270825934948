import React from 'react';
import { Row, Col, Button } from 'antd';
import {
    AnimatedImage,
    InfoWrapper,
    SubTitle,
    Title,
    ButtonWrapper,
    ButtonIcon,
} from '../../common/SimpleRow/styles';
import { ImageWrapper, ProductLandingRow } from './styles';
import { Roll, LightSpeed, Zoom, Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql, navigate, Link } from 'gatsby';
import { ButtonsWrapper } from '../../individual/order/styles';
import '../../individual/order/styles.css';
import { useQueryParam, StringParam } from 'use-query-params';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import imgtpe from "../../../images/slide/banner-TPE.webp"
import { detect } from 'detect-browser';
import { Button as FormButton } from '../../common/SimpleRow/styles';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ProductLanding = ({ pack, title, subTitle, image }) => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "product-landing" }) {
                id
                title
                subTitle
                offers {
                    offer_name
                }
                image {
                    file {
                        url
                    }
                }
                tpeImage {
                    file {
                        url
                    }
                }
                darkImage {
                    file {
                        url
                    }
                }
                tpeDarkImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    const br = detect();
    return (
        <ProductLandingRow className="row-index" theme={theme}>
              {pack==="tpe" ?(
              <>
                <StaticImage
                    style={{
                        width: '100%',
                        marginTop: '2rem',
                        height: '100%'
                    }}
                    formats={["auto", "webp"]}
                    placeholder='blurred'
                    src='../../../images/slide/banner-TPE.webp'
                    alt='tpe'
                />
                    <InfoWrapper className='tpeBtn'>
                            <ButtonWrapper style={{marginTop:'0', marginBottom:'1rem'}}>
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'tpe' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris{' '}
                                    </FormButton>
                                </Link>
                                <Link to="#avas">
                                    <FormButton br={br?.name}>
                                        Je découvre les AVA
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        {pack === 'e-commerce' && (
                            <ButtonWrapper
                                style={{ justifyContent: 'flex-start  ' }}
                            >
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'e-commerce' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        )}
                        {pack === 'pay-by-email' && (
                            <ButtonWrapper
                                style={{ justifyContent: 'flex-start  ' }}
                            >
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'e-commerce' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        )}
                    </InfoWrapper>
                </>):(
            <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" justify="center">
                <div className="justify-center" lg={10} md={24} sm={24} xs={24}>
                    <ImageWrapper>
                        <Fade left>
                            {image ?(
                                <AnimatedImage
                                    src={image.file.url}
                                    style={
                                        br?.name === 'ie'
                                            ? { width: '25rem' }
                                            : { maxHeight: '20rem' }
                                    }
                                />
                            ) : (
                                <AnimatedImage
                                    src={
                                        pack === 'tpe'
                                            ? theme === 'dark'
                                                ? data.contentfulLanding
                                                    .tpeDarkImage.file.url
                                                : data.contentfulLanding
                                                    .tpeImage.file.url
                                            : theme === 'dark'
                                                ? data.contentfulLanding.darkImage
                                                    .file.url
                                                : data.contentfulLanding.image.file
                                                    .url
                                    }
                                    style={
                                        br?.name === 'ie'
                                            ? { width: '25rem' }
                                            : { maxHeight: '20rem' }
                                    }
                                />
                            )}
                        </Fade>
                    </ImageWrapper>
                </div>
                <div className="justify-center" lg={10} md={24} sm={24} xs={24}>
                    <InfoWrapper pack={pack}>
                    {pack !== 'tpe' && (
                        <>
                            <Fade right>
                                <Title theme={theme}>
                                    {title ? title : data.contentfulLanding.title}
                                </Title>
                            </Fade>
                            <Fade bottom>
                                <SubTitle theme={theme}>
                                    {subTitle
                                        ? subTitle
                                        : data.contentfulLanding.subTitle}
                                </SubTitle>
                            </Fade>
                        </>)}
                        {pack === 'tpe' && (
                            <ButtonWrapper style={{marginTop:'0', marginBottom:'1rem'}}>
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'tpe' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris{' '}
                                    </FormButton>
                                </Link>
                                <Link to="#avas">
                                    <FormButton br={br?.name}>
                                        Je découvre les AVA
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        )}
                        {pack === 'e-commerce' && (
                            <ButtonWrapper
                                style={{ justifyContent: 'flex-start  ' }}
                            >
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'e-commerce' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        )}
                        {pack === 'pay-by-email' && (
                            <ButtonWrapper
                                style={{ justifyContent: 'flex-start  ' }}
                            >
                                <Link
                                    to="/b2b/acquisition"
                                    state={{ type: 'e-commerce' }}
                                >
                                    <FormButton br={br?.name}>
                                        Je souscris
                                    </FormButton>
                                </Link>
                            </ButtonWrapper>
                        )}
                    </InfoWrapper>
                </div>
            </div>)}
            <ButtonsWrapper>
                <Button
                    onClick={() => navigate('/products/tpe')}
                    className={`right-button row-index ${pack === 'tpe' ? 'selected-botton' : ''
                        }`}
                    shape="round"
                    size="large"
                >
                    TPE
                </Button>
                <Button
                    onClick={() => navigate('/products/e-commerce')}
                    className={`left-button row-index ${pack === 'e-commerce' ? 'selected-botton' : ''
                        }`}
                    shape="round"
                    size="large"
                >
                    E-commerce
                </Button>
                <Button
                    onClick={() => navigate('/products/pay-by-email')}
                    className={`left-button row-index ${pack === 'pay-by-email' ? 'selected-botton' : ''
                        }`}
                    shape="round"
                    size="large"
                >
                    PayByEmail
                </Button>
            </ButtonsWrapper>
        </ProductLandingRow>
    );
};

export default ProductLanding;
