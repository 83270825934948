import React from 'react';
import { Row, Col } from 'antd';
import {
    InfoWrapper,
    Title,
    ImageWrapper,
    AnimatedImage,
    SubTitle,
    Button,
    PrimaryButton,
    ButtonWrapper,
    ButtonIcon,
} from '../../../common/SimpleRow/styles';
import { LightSpeed, Zoom, Roll, Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Wrapper } from './styles';
import './styles.css';
import VdModal from '../../../common/vd-modal';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EcommerceRow } from '../../ecommerce/landing/styles';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
const Landing = () => {
    const [state, setState] = useState({
        showModal: false,
    });
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "product-landing-tpe" }) {
                id
                title
                subTitle
                description {
                    raw
                }
                offers {
                    offer_name
                }
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    const br = detect();
    return (
        <>
            <EcommerceRow br={br?.name} theme={theme} justify="center">
                <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
                    <div className="justify-center">
                        <InfoWrapper>
                            <Fade left>
                                <Title theme={theme}>
                                    {data.contentfulLanding.title}
                                </Title>
                            </Fade>
                            <Fade bottom>
                                <SubTitle theme={theme}>
                                    {
                                       documentToReactComponents(JSON.parse( data.contentfulLanding.description.raw))
                                    }
                                </SubTitle>
                            </Fade>
                            <ButtonWrapper>
                            <Link to="/b2b/acquisition" state={{ type: 'tpe' }}>
                                <Button br={br?.name}>
                                    Je souscris{' '}
                                </Button>
                            </Link>
                            <Link to="#avas">
                                <Button br={br?.name}>
                                    Je découvre les AVA
                                </Button>
                            </Link>
                        </ButtonWrapper>
                        </InfoWrapper>
                    </div>
                    <div className="justify-center">
                        <ImageWrapper>
                            <Fade right>
                                <AnimatedImage
                                    style={
                                        br?.name === 'ie' ? { width: '24rem' } : {}
                                    }
                                    src={data.contentfulLanding.image.file.url}
                                />
                            </Fade>
                        </ImageWrapper>
                    </div>
                </div>
            </EcommerceRow>
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://drive.google.com/file/d/18bHMBf6Xguj8NuxWsMajJT50_2t65SR7/view?usp=sharing"
            />
        </>
    );
};

export default Landing;
