import styled from 'styled-components';
import { Row } from 'antd';

export const Text = styled.p`
    font-family: Rajdhani, sans-serif;
    font-size: 22px;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#383838')};
    border-bottom: 1px solid #d1d1d1;
    margin-top: 0rem;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 20px;
`;

export const GoogleButton = styled.button`
    border-radius: 5px;
    background-color: #327caf;
    max-width: 200px;
    font-size: 12px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    margin: auto;
    transition: all 1s ease-out;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: #144a6e;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
        max-width: 19rem;
    }
`;
export const Stick = styled.div`
    position: absolut;
    bottom: 0;
`;

export const RowWrapper = styled(Row)`
    max-width: 100%;
    width: ${({ br }) => (br === 'ie' ? '100%' : '')};
    /* background-color: #f3f3f3; */
    border-radius: 10px;
    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const Info = styled.p`
    all: unset;
    border-radius: 20px;
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    margin: 20px 0;
    font-size: 20px;
    cursor: pointer;
`;
