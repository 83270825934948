import React from 'react';
import Layout from '../../components/layout';
import TpesCatalog from '../../components/tpes-catalog';
import SEO from '../../components/seo';

const Tpes = () => {
    return (
        <Layout>
            <SEO title="Tpes" url="tpes" />
            <TpesCatalog />
        </Layout>
    );
};

export default Tpes;
