import styled from 'styled-components';
import { Select } from 'antd';
export const MultiSelect = styled(Select)`
    min-width: 100%;
    
   /*  background-color: #f6f6f6;
    padding: 10px 5px;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    &:focus {
        outline-color: #e66d30;
        outline-width: 1px;
    } */
`;
