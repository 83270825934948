import React from 'react';
import {
    Title,
    SubTitle,
    Button,
    ImageWrapper,
    AnimatedImage,
} from '../../landing/styles';
import { InfoWrapper } from './styles';

import '../../landing/styles.css';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Zoom, Fade } from 'react-awesome-reveal';
import SEO from '../../seo';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { useEffect } from 'react';
import { useState } from 'react';
const IndividualLanding = () => {
    const [state, setState] = useState({
        br: '',
    });
    const { theme } = useContext(ThemeContext);
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "individual-landing" }) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);

    useEffect(() => {
        const browser = detect();
        setState({
            ...state,
            br: browser?.name,
        });
    }, []);

    return (
        <div
            className="container mx-auto auto-top-margin row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
        >
            <SEO
                title="Carte"
                description={
                    data.contentfulLanding.title +
                    ',' +
                    data.contentfulLanding.subTitle
                }
                url={'/individual'}
            />
            <div
                className="justify-center"
            >
                <InfoWrapper>
                    <Fade left>
                        <Title theme={theme}>
                            {data.contentfulLanding.title}
                        </Title>
                    </Fade>
                    <Zoom right>
                        <SubTitle style={{color: 'inherit'}} >
                            {data.contentfulLanding.subTitle}
                        </SubTitle>
                    </Zoom>

                    <div style={{ position: 'relative' }}>
                        <Link style={{ color: '#fff' }} to="#order">
                            <Button>
                                Je commande{' '}
                            </Button>
                        </Link>
                    </div>
                </InfoWrapper>
            </div>
            <div
                className="justify-center"
            >
                <ImageWrapper style={{ maxWidth: '100%' }}>
                    <Fade right>
                        <AnimatedImage
                            loading="lazy"
                            src={data.contentfulLanding.image.file.url}
                            alt={data.contentfulLanding.title}
                        />
                    </Fade>
                </ImageWrapper>
            </div>
        </div>
    );
};
export default IndividualLanding;
