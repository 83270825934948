import React, { useState } from 'react';
import {
    Title,
    Button,
    ArticleWrapper,
    SubTitle,
    MWrapper,
} from './styles';
import { Col } from 'antd';
import Popup from 'reactjs-popup';
import { CloseIcon } from '../vd-modal/styles';
import Banner from '../../banner';

export const ArticleImageCard = ({
    title,
    content,
    button,
    link
}) => {


    const [state, setState] = useState({
        displayContent: '',
        showAll: false,
    });
    return (
        <>
            <ArticleWrapper>
                <Col className="wrapper-article scroll-article">
                    <div className="style-article">
                        <Title>
                            {title}
                        </Title>

                        <SubTitle>
                            {state.showAll ? content : content?.substring(0, 200) + '...'}
                        </SubTitle>
                        <a href={link} rel="noreferrer" target="_blank">
                            <Button>
                                Lire Article
                            </Button>
                        </a>
                    </div>
                </Col>
            </ArticleWrapper>
        </>
    );
};

export default ArticleImageCard;
