import React, { useState, Component } from 'react';
import { Layout, Steps, Modal } from 'antd';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import '../../b2c/style.css';
import { useStaticQuery, graphql, navigate, Link } from 'gatsby';
import { http } from '../../http';
import { ImageWrapper } from '../../b2c/index.styles';
import WelcomForm from './welcom-form';
import InformationForm from './information-form';
import RequestForm from './request-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '../../common/SimpleRow/styles';

const { Header, Content, Footer, Sider } = Layout;
const { Step } = Steps;

const Image = () => {
    const { contentfulLogo } = useStaticQuery(graphql`
        query {
            contentfulLogo {
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <img src={contentfulLogo.image.file.url} style={{ maxWidth: '100%' }} />
    );
};

const schema = Yup.object().shape({
    step: Yup.number(),
    type: Yup.string(),
    isClient: Yup.boolean().when(['step', 'type'], {
        is: (step, type) => step === 0 && type === 'tpe',
        then: Yup.boolean().required('Ce champ est obligatoire'),
    }),
    firstName: Yup.string().when('step', {
        is: (step) => step == 0,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    lastName: Yup.string().when('step', {
        is: (step) => step == 0,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    phone: Yup.string().when('step', {
        is: (step) => step == 0,
        then: Yup.string()
            .matches(
                /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
                'Veuillez indiquez un numéro de téléphone valide'
            )
            .required('Ce champ est obligatoire'),
    }),
    email: Yup.string().when('step', {
        is: (step) => step == 0,
        then: Yup.string()
            .email('votre email ne correspond pas à un email valide')
            .required('Ce champ est obligatoire'),
    }),
    confirmEmail: Yup.string().when('step', {
        is: (step) => step == 0,
        then: Yup.string()
            .oneOf(
                [Yup.ref('email'), null],
                'Les deux e-mails saisis ne sont pas identiques'
            )
            .required('Ce champ est obligatoire'),
    }),
    sector: Yup.string().when('step', {
        is: (step) => step == 1,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),

    function: Yup.string().when('step', {
        is: (step) => step == 1,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),

    organization: Yup.string().when('step', {
        is: (step) => step == 1,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    tpeModel: Yup.string().when(['step', 'type', 'isClient'], {
        is: (step, type, isClient) => step == 2 && type === 'tpe' && !isClient,
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    volume: Yup.string().when(['step', 'type', 'isClient'], {
        is: (step, type, isClient) =>
            step == 1 && (type === 'tpe' || type === 'e-commerce'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    addedValueApp: Yup.bool().when(['step', 'type', 'isClient'], {
        is: (step, type, isClient) =>
            step == 2 &&
            ((type === 'tpe' && !isClient) || type === 'e-commerce'),
        then: Yup.boolean().required('Ce champ est obligatoire'),
    }),
    url: Yup.string().when(['step', 'type'], {
        is: (step, type) => step === 1 && type === 'e-commerce',
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    terms: Yup.boolean().when(['step'], {
        is: (step, type) => step === 2,
        then: Yup.boolean()
            .required('Ce champ est obligatoire')
            .oneOf([true, null], 'Veuillez accepter les termes et conditions'),
    }),
    // agency: Yup.string().when(['step'], {
    //     is: (step, type) => step === 2,
    //     then: Yup.string().required('Veuillez sélectionner votre agence de livraison'),
    // }),
    numberAgence: Yup.number().when(['step', 'type'], {
        is: (step, type) => step === 2 && type === 'partner',
        then: Yup.number('Ce champ doit être un nombre')
            .required('Ce champ est obligatoire')
            .moreThan(0, 'Ce champ est obligatoire'),
    }),
    cv: Yup.mixed().when(['step', 'type'], {
        is: (step, type) => step === 1 && type === 'partner',
        then: Yup.mixed().required('Ce champ est obligatoire'),
    }),
    avas: Yup.array().when(['step', 'type', 'isClient', 'addedValueApp'], {
        is: (step, type, isClient, addedValueApp) =>
            step === 2 &&
            ((type === 'e-commerce' && addedValueApp) ||
                (type === 'tpe' && (isClient || addedValueApp))),
        then: Yup.array()
            .required('Ce champ est obligatoire')
            .min(1, 'Veuillez sélectionner une AVA'),
    }),
});

class B2bForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            lastStepIndex: 3,
            showModal: false,
            isLoading: false,
        };
    }

    handleNextClick = () => {
        if (this.state.currentStep < this.state.lastStepIndex)
            this.setState({
                currentStep: this.state.currentStep + 1,
            });
    };
    handleBackClick = () => {
        if (this.state.currentStep > 0)
            this.setState({
                currentStep: this.state.currentStep - 1,
            });
    };
    handelSubmit = (values) => {
        this.setState({
            isLoading: true,
        });
        if (values.type == 'partner') {
            const formData = new FormData();
            formData.append('cv', values.cv);
            delete values.cv;
            const partnerSteingJSon = JSON.stringify(values);
            formData.append('partner', partnerSteingJSon);
            http.post(`/${values.type}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then((res) => {
                this.setState({
                    showModal: true,
                    isLoading: false,
                });
            });
        } else {
            http.post(`/${values.type}`, values).then((res) => {
                this.setState({
                    showModal: true,
                    isLoading: false,
                });
            });
        }
    };

    componentDidMount() {
        if(!this.props.type) {
            navigate("/products");
        }
    }

    render() {
        return (
            <Layout className="container layout">
                <Sider breakpoint="xs sm" className="container side-bar">
                    <ImageWrapper>
                        <Link to="/">
                            <Image></Image>
                        </Link>
                    </ImageWrapper>
                    <Steps
                        progressDot
                        direction="vertical"
                        current={this.state.currentStep}
                        className="steps"
                    >
                        <Step title="Bienvenue" />
                        <Step title="Informations" />
                        <Step title="Demande" />
                    </Steps>
                </Sider>
                <Content className="container content">
                    <div className="form-wrapper">
                        <Formik
                            initialValues={{
                                step: this.state.currentStep,
                                isClient: false,
                                type: this.props.type,
                                firstName: '',
                                lastName: '',
                                phone: '',
                                email: '',
                                confirmEmail: '',
                                sector: '',
                                function: '',
                                organization: '',
                                tpeModel: '',
                                volume: '',
                                addedValueApp: false,
                                comment: '',
                                url: '',
                                terms: false,
                                avas: [],
                                numberAgence: 0,
                                cv: null,
                            }}
                            validationSchema={schema}
                            onSubmit={this.handelSubmit}
                        >
                            {({
                                isValid,
                                setFieldTouched,
                                errors,
                                setTouched,
                                validateForm,
                                setFieldValue,
                                values,
                                isSubmitting,
                            }) => (
                                <Form autoComplete="on">
                                    {this.state.currentStep === 0 && (
                                        <WelcomForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            isNotInit={values.initialRecharge}
                                            type={values.type}
                                        />
                                    )}
                                    {this.state.currentStep === 1 && (
                                        <InformationForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            isNotInit={values.initialRecharge}
                                            type={values.type}
                                            values={values}
                                        />
                                    )}
                                    {this.state.currentStep === 2 && (
                                        <RequestForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            isNotInit={values.initialRecharge}
                                            type={values.type}
                                            isClient={values.isClient}
                                            addedValueApp={values.addedValueApp}
                                            errors={errors}
                                            isLoading={this.state.isLoading}
                                        />
                                    )}
                                    <Modal
                                        visible={this.state.showModal}
                                        centered
                                        onCancel={() => {
                                            this.setState({ showModal: false });
                                            navigate('/');
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                minHeight: '250px',
                                                flexDirection: 'column',
                                                padding: '30px',
                                                paddingTop: '0',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon="check"
                                                style={{
                                                    fontSize: '100px',
                                                    color: '#63e6be',
                                                    margin: '20px',
                                                    width: '100px',
                                                }}
                                            />
                                            <Title
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: '17px',
                                                    color: '#212121',
                                                }}
                                            >
                                                Félicitations !
                                                <br />
                                                <br />
                                                <>
                                                    Vous avez complété la
                                                    première étape{' '}
                                                    {values.type === 'tpe'
                                                        ? 'de souscription '
                                                        : "d'adhésion "}
                                                    avec succès !
                                                    <br />
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: '15px',
                                                            color: '#b8b8b8',
                                                        }}
                                                    >
                                                        Un email de confirmation
                                                        a été envoyé à votre
                                                        adresse: {values.email}
                                                        <br />
                                                        <br />A bientôt !
                                                    </span>
                                                </>
                                            </Title>
                                        </div>
                                    </Modal>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default B2bForm;
