import React from 'react'
import Layout from '../../components/layout';
import ClientSpace from '../../components/my-space';
import SEO from '../../components/seo';

const MySpace = () => {
    return (
        <Layout>
            <SEO title="Mon Espace" url="my-space" />
            <ClientSpace />
        </Layout>
    )
}

export default MySpace;