import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 430px;
    max-height: 185px;
    border-radius: 27px;
    background-color: transparent;
    box-shadow: ${({ theme }) =>
            theme === 'light' ? '#00000020' : '#00000070'}
        0px 0px 19px -2px;
    margin: 20px 0;
    transition: 0.2s ease-in-out;
    @media (max-width: 907px) {
        min-width: 300px;
        width: 350px;
    }
    @media (max-width: 415px) {
        min-width: 150px;
        width: 300px;
    }
`;

export const ImageWrappwe = styled.div`
    width: 240px;
    height: 145px;
    min-width: 120px;
    max-width: 120px;
    border-radius: 15px;
    box-shadow: -6px 6px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffa055;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
`;
export const Text = styled.p`
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.64px;
    text-align: justify;
    color: ${({ theme }) => (theme === 'light' ? '#383838' : '#fff')};
    margin: 0;
    padding: 0 20px;
    width: 100%;
    ${({ br }) => br === 'ie' && css`
        width: 300px;
    `}
`;
