import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { http } from '../../../components/http';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal } from 'antd';
import { Title } from '../../../components/b2c/index.styles';
import SEO from '../../../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentRecall = () => {
    const [orderId, setNum] = useQueryParam('id_commande', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });
    useEffect(() => {
        // http.post('/card-order/paymentticketsunsucceeded/'+orderId, null).then(
        //     (res) => {
        //         setState({
        //             ...state,
        //             isLoading: false,
        //         });
        //     }
        // );
        navigate('/billetterie', {state: { step: 0,message:"Votre commande n'a pas abouti. Merci de réessayer."},})
    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Rappeler de paiement" url={'/billetterie/recall'} />     
        </Layout>
    );
};

export default PaymentRecall;
