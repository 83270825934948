import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

export const Image = ({ largeImage, smallImage, alt }) => {
    const images = withArtDirection(getImage(largeImage), [
        {
            media: "(max-width: 1024px)",
            image: getImage(smallImage),
        },
    ])
    return <GatsbyImage image={images} alt={alt} />
}
