import styled from 'styled-components';

export const ImageWrapper = styled.div`
    width: 100%;
    padding: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 29rem;
    @media (max-width: 767px) {
        min-height: auto;
    }
`;

export const ProductLandingRow = styled.div`
    background-color: ${({ theme }) =>
        theme === 'dark' ? '#212121' : '#f7f7f7'};
    padding: 60px 20px 10px 20px;
    z-index: 5;
`;
