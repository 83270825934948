import React, { useState } from 'react';
import { Wrapper, Details, Title, PlusButton, Image } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import './styles.css';
import { navigate } from 'gatsby';
const CatalogItemCard = ({ id, title, image, details }) => {
    const [state, setState] = useState({
        showDetails: true,
    });
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
                marginTop: '-87px',
            }}
        >
            <Image src={image} />
            <Wrapper>
                <Details>
                    <Title>{title}</Title>
                    <PlusButton onClick={() => navigate(`/tpes/${id}`)}>
                        <FontAwesomeIcon icon="plus" style={{color: '#fff'}} />
                    </PlusButton>
                    {state.showDetails && (
                        <>{documentToReactComponents(JSON.parse(details), {})}</>
                    )}
                </Details>
            </Wrapper>
        </div>
    );
};

export default CatalogItemCard;
