import React, { useContext } from 'react';
import SimpleRow from '../../../common/SimpleRow';
import { useStaticQuery, graphql } from 'gatsby';
import ThemeContext from '../../../layout/theme/ThemeContext';
const Activities = () => {
    const { contentfulLanding } = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "tpe-activities" }) {
                id
                title
                subTitle
                image {
                    title
                    file {
                        url
                    }
                }
                darkImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    return (
        <SimpleRow
            title={contentfulLanding.title}
            subTitle={contentfulLanding.subTitle}
            imageUrl={
                contentfulLanding.image && (theme === 'dark' ? contentfulLanding.darkImage.file.url : contentfulLanding.image.file.url)
            }
            state="left"
            id="tpe-activities"
        />
    );
};
export default Activities;
