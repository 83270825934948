import React, { useState, useContext } from 'react';
import { Row, Col, Spin, Modal } from 'antd';
import { Title } from './styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from './text-field';
import { Select } from './select';
import { CheckBoxField } from '../../checkbox-field';
import { Button } from '../../form/styles';
import { http } from '../../http';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loadable from "@loadable/component";
import { LoadingOutlined } from '@ant-design/icons';
import ThemeContext from '../../layout/theme/ThemeContext';
import {
    onlyCharacters,
    emailKeyPress,
    onlyNumbers,
    adresseKeyPress,
} from '../../helpers/only-characters';


const FormReCaptcha = Loadable(() => import('../../common/form-recaptcha'));

const antIcon = (
    <LoadingOutlined
        style={{ fontSize: 24, color: 'rgb(230, 109, 48)' }}
        spin
    />
);

const schema = Yup.object().shape({
    firstName: Yup.string().required('Ce champ est obligatoire'),
    lastName: Yup.string().required('Ce champ est obligatoire'),
    email: Yup.string()
        .email('Veuillez saisir un e-mail valide')
        .required('Ce champ est obligatoire'),
    emailConfirmation: Yup.string()
        .oneOf(
            [Yup.ref('email'), null],
            "L'email que vous avez entré est invalide"
        )
        .required('Ce champ est obligatoire'),
    phone: Yup.string()
        .matches(
            /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
            'Veuillez indiquer un numéro de téléphone valide'
        )
        .required('Ce champ est obligatoire'),
    intrestedIn: Yup.string().required('Ce champ est obligatoire'),
    organisation: Yup.string().required('Ce champ est obligatoire'),
    terms: Yup.boolean()
        .oneOf([true, null], 'Ce champ est obligatoire')
        .required('Ce champ est obligatoire'),
    //recaptcha: Yup.string().required('Ce champ est obligatoire'),
});

const intrests = [
    { value: 'Paiement sur TPE', label: 'Paiement sur TPE' },
    { value: 'Paiement en ligne', label: 'Paiement en ligne' },
    { value: 'Carte de paiement', label: 'Carte de paiement' },
    {
        value: 'Application à valeur ajoutée',
        label: 'Application à valeur ajoutée',
    },
    { value: 'Franchise', label: 'Franchise' },
    { value: 'Demande d\'assistance technique', label: 'Demande d\'assistance technique' },
    { value: 'Autre', label: 'Autre' },
];
const ContactForm = () => {
    const [state, setState] = useState({
        showModal: false,
        isLoaidng: false,
        values: {},
    });

    const { theme } = useContext(ThemeContext);

    const handelSubmit = (values) => {
        setState({
            ...state,
            isLoaidng: true,
            values: values,
        });
        http.post('/contact', values).then((res) => {
            setState({
                ...state,
                showModal: true,
                isLoaidng: false,
            });
        });
    };
    return (
        <>
            <Formik
                initialValues={{
                    fistName: '',
                    lastName: '',
                    email: '',
                    emailConfirmation: '',
                    phone: '',
                    intrestedIn: '',
                    organisation: '',
                    comment: '',
                    terms: false,
                    recaptcha: '',
                }}
                validationSchema={schema}
                onSubmit={handelSubmit}
            >
                {({
                    isValid,
                    setFieldTouched,
                    errors,
                    setTouched,
                    validateForm,
                    setFieldValue,
                    values,
                    isSubmitting,
                }) => (
                    <Form autoComplete="on" style={{ width: '100%' }}>
                        <Row
                            justify="center"
                            style={{
                                width: '100%',
                                height: '100%',
                                alignContent: 'center',
                                padding: '50px',
                            }}
                        >
                            <Col
                                md={10}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="lastName"
                                    label="Nom*"
                                    placeholder=""
                                    keyPress={onlyCharacters}
                                ></TextField>
                            </Col>
                            <Col
                                md={10}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="firstName"
                                    label="Prénom*"
                                    placeholder=""
                                    keyPress={onlyCharacters}
                                ></TextField>
                            </Col>

                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="email"
                                    label="E-mail*"
                                    placeholder=""
                                    keyPress={emailKeyPress}
                                ></TextField>
                            </Col>
                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="emailConfirmation"
                                    label="Confirmation e-mail*"
                                    placeholder=""
                                    keyPress={emailKeyPress}
                                ></TextField>
                            </Col>
                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="phone"
                                    label="Téléphone mobile*"
                                    placeholder=""
                                    maxLength="10"
                                    keyPress={onlyNumbers}
                                ></TextField>
                            </Col>

                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <Select
                                    name="intrestedIn"
                                    label="Je suis intéressé(e) par*"
                                    placeholder=""
                                    data={intrests}
                                ></Select>
                            </Col>

                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="organisation"
                                    label="Organisme/Commerce*"
                                    placeholder=""
                                    keyPress={adresseKeyPress}
                                ></TextField>
                            </Col>

                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{ marginBottom: '25px' }}
                            >
                                <TextField
                                    name="comment"
                                    label="Quelque chose à ajouter ?"
                                    placeholder="Parlez-nous plus de votre besoin"
                                    textarea={true}
                                    keyPress={adresseKeyPress}
                                ></TextField>
                            </Col>
                            <Col xs={20} style={{ marginBottom: '25px' }}>
                                <FormReCaptcha
                                    name="recaptcha"
                                    setValue={setFieldValue}
                                />
                            </Col>
                            <Col
                                xs={20}
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '25px',
                                }}
                            >
                                <CheckBoxField
                                    pre="J’ai lu et j’accepte la politique de confidentialité, notamment "
                                    suf="personnelles conformément à la loi 09/08"
                                    linkText="la mention relative à la protection des données"
                                    to="/terms"
                                    name="terms"
                                    theme={theme}
                                ></CheckBoxField>
                            </Col>
                            <Col
                                md={20}
                                sm={24}
                                xs={24}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginBottom: '25px' 
                                }}
                            >
                                {!state.isLoaidng ? (
                                    <Button>Envoyer</Button>
                                ) : (
                                    <Spin indicator={antIcon} />
                                )}
                            </Col>
                        </Row>
                        <input
                            type="hidden"
                            name="token"
                            value="df88d1218ec6b9cac629b21aae781794"
                        />
                        <Modal
                            visible={state.showModal}
                            centered
                            onCancel={() => {
                                setState({ ...state, showModal: false });
                                navigate('/');
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    minHeight: '250px',
                                    flexDirection: 'column',
                                    padding: '30px',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="check"
                                    style={{
                                        fontSize: '100px',
                                        color: '#63e6be',
                                        margin: '20px',
                                        width: '100px',
                                    }}
                                />
                                <Title style={{ fontSize: '20px' }}>
                                    Merci pour votre message!
                                    <br />
                                    <br />
                                    <span
                                        style={{
                                            fontSize: '15px',
                                            color: '#b8b8b8',
                                        }}
                                    >
                                        Un conseiller NAPS vous contactera dans
                                        les plus brefs délais.
                                    </span>
                                </Title>
                                <br />
                            </div>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ContactForm;
