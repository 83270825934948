import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    overflow: auto;
    height: 100vh;
`;
export const Side = styled.div`
    width: 100%;
    background-color: rgba(216, 216, 216, 0.2);
`;
export const Title = styled.h1`
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    color: rgba(0, 0, 0, 0.7);
`;
export const ImageWrapper = styled.div`
    padding: 10px 50px;
`;

export const BottomImageWrapper = styled.div`
    
    bottom: 1.5rem;
    padding: 10px 10px;
`;
