import React, { useState } from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';
import VdModal from '../../common/vd-modal';

const IndividualUsage = () => {
    const [state, setState] = useState({
        showModal: false,
    });
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "individual-usage" }) {
                id
                title
                subTitle
                image {
                    title
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <>
            <SimpleRow
                title={data.contentfulLanding.title}
                subTitle={data.contentfulLanding.subTitle}
                imageUrl={data.contentfulLanding.image.file.url}
                state="left"
                storeButtons={true}
                id="usage"
                videoButtonText="Je découvre en Vidéo"
                onVdClick={() => setState({ ...state, showModal: true })}
                buttonsToLeft={true}
            />
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/J0bFHctYTNs"
            ></VdModal>
        </>
    );
};
export default IndividualUsage;
