import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import VdModal from '../../../common/vd-modal';

import {
    ProTitle,
    ProSubTitle,
    PrimaryButton,
    Button,
} from '../../entreprise/presentation/styles';
import { ButtonWrapper, ButtonIcon } from '../../../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import B2cContactForm from '../../../b2c-contact-form';
import { detect } from 'detect-browser';
import { useEffect } from 'react';
import SEO from '../../../seo';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Presentation = () => {
    const [state, setState] = useState({
        showModal: false,
        showForm: false,
        br: '',
    });
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "institution" }) {
                title
                description {
                    raw
                }
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    return (
        <div className="pro-entreprise">
            <SEO
                title="Campus"
                // description={
                //     data.contentfulLanding.title +
                //     ',' +
                //     data.contentfulLanding.description.content[0].content[0]
                //         .value
                // }
                keywords={
                    'naps, naps maroc, payment, paiement, carte, recharge, e-commecre, tpe, naps campus, campus'
                }
                url={'/pro/campus'}
            />
            <Row justify="center">
                <Col className="justify-center" xxl={24} sm={24}>
                    <ProTitle>{data.contentfulLanding.title}</ProTitle>
                </Col>
                <Col className="justify-center" md={12} sm={24}>
                    <ProSubTitle>
                        {
                            documentToReactComponents(JSON.parse(data.contentfulLanding.description.raw))
                        }
                    </ProSubTitle>
                </Col>

                <Col className="justify-center" md={13} sm={24}>
                    <ButtonWrapper>
                        <Button
                            br={state.br}
                            onClick={() =>
                                setState({
                                    ...state,
                                    showForm: !state.showForm,
                                })
                            }
                        >
                            Je prends RDV
                        </Button>
                        <PrimaryButton
                            onClick={() =>
                                setState({ ...state, showModal: true })
                            }
                            br={state.br}
                        >
                            Je découvre en vidéo
                        </PrimaryButton>
                    </ButtonWrapper>
                </Col>
            </Row>
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/Rxdl2yEvSz4"
            />
            {state.showForm && <B2cContactForm pack="institution" />}
        </div>
    );
};
export default Presentation;
