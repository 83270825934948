import styled, { keyframes } from 'styled-components';
// import { bounceInLeft, zoomInUp, fadeIn, rotateIn } from 'react-animations';

// const bounceInLeftAnimation = keyframes`${bounceInLeft}`;
// const zoomInUpAnimation = keyframes`${zoomInUp}`;
// const fadeAnimation = keyframes`${fadeIn}`;
// const rotateInAnimation = keyframes`${rotateIn}`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
`;

export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
`;

export const SubTitle = styled.p`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3b;
    font-family: Rajdhani, sans-serif;
    font-weight: 500;
`;
export const Button = styled.button`
    border-radius: 13px;
    background-color: #ffffff;
    max-width: 300px;
    border: none;
    padding: 0.5rem 2rem;
    color: #e66d30;
    box-shadow: #000 0px 0px 54px -14px;
    margin-top: 1rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #000 0px 0px 20px -14px;
    }
`;
export const ImageWrapper = styled.div`
    width: 100%;
    padding: 40px;
    justify-content: center;
    display: flex;
`;

export const AnimatedImage = styled.img`
    max-width: 100%;
    /* width:30rem; */
`;

export const SubTitleWrapper = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    color: #3c3c3b;
`;
