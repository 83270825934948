import React, { useContext, useEffect, useState } from 'react';

import './styles.css';

import { Link,graphql, useStaticQuery } from 'gatsby';
import ThemeContext from '../../layout/theme/ThemeContext';
import Pagination from './pagination';
import { http } from '../../../components/http';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'


import {
    ArticleRow,
    InfoWrapper,
    Title,
    ArticleFeatured,
    ImageWrapper,
    GridWrapper,
    SubTitle,
    Badge,
    Button,
} from './styles';
import { Col } from 'antd';
import {BrowserRouter as Router,NavLink,Route} from 'react-router-dom'
import { navigate } from 'gatsby';

const BlogsHome = () => {
        const [currentPage, setCurrentPage] = useState(1);
        const [blogPerPage] = useState(9);
        const { blogs,categories } = useStaticQuery(graphql`
                query {
                blogs: allContentfulBlogPost(sort: {fields: publishDate, order: DESC}) {
                    nodes {
                    id    
                    title
                    slug
                    publishDate(formatString: "DD/MM/yyyy")
                    heroImage {
                        description
                        file {
                        url
                        }
                    }
                    body {
                        raw
                    }
                    description {
                        description
                    }
                    category
                    categoryUrl
                    featured
                    selection
                    metaTitle
                    metaDescription
                    }
                }
                categories: allContentfulBlogCategories {
                    nodes {
                    name
                    slug
                    }
                }
                }
            `);
        let indexOfLastBlog = currentPage * blogPerPage;
        let indexOfFirstBlog = indexOfLastBlog - blogPerPage;
        const paginate = pageNumber => {
            setCurrentPage(pageNumber);
            indexOfLastBlog = pageNumber * blogPerPage;
            indexOfFirstBlog = indexOfLastBlog - blogPerPage;
            setState({
                ...state,
                filtredBlogs:state.totalBlogs.slice(indexOfFirstBlog, indexOfLastBlog)
            });
        };
        const selectedCategory = (category) => {
            let fBlog =[]
            if(category ==='all'){
                fBlog = blogs.nodes;
            }else{
                fBlog = blogs.nodes?.filter((e) => e.category === category);
            }
            
            setState({
                ...state,
                categorySelected: category,
                totalBlogs:fBlog,
                filtredBlogs:fBlog.slice(indexOfFirstBlog, indexOfLastBlog)

            });
        }    

        const [readBlog, setReadBlog] = useState({
            topReadBlog:[]
        });
        const [state, setState] = useState({
            featureBlog: null,
            categorySelected:'all',
            filtredBlogs:[],
            totalBlogs:[],
            selectionBlogs:[],
        });
        const [category, setCategories] = useState({
            categories:[]
        });

        const options = {
            renderMark: {
                [MARKS.BOLD]: (text) => <b>{text}</b>,
            },
            renderNode: {
                [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} target="_blank" style={{color:'rgb(0, 89, 200,0.87)'}}>
                    {children}
                    </a>
                )
                },
            },
            
            }
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        let cat = Array.from(new Set(blogs?.nodes?.map(c => c.category)));

        setCategories({
            ...category,
            categories : cat,
        });
        http.get('/blogviews/count').then((res) => {
            let ids = res.data.map(d => d.blogId)
            setReadBlog({
                ...readBlog,
                topReadBlog: blogs.nodes?.filter((e) => ids.includes(e.id)),
            });

        })
        setState({
            ...state,
            totalBlogs:blogs.nodes,
            featureBlog: blogs.nodes?.filter((e) => e.featured === true)[0],
            filtredBlogs:blogs.nodes?.slice(indexOfFirstBlog, indexOfLastBlog),
            selectionBlogs: blogs.nodes?.filter((e) => e.selection === true),
        });
    }, []);

    return (
        <div className="blog-article" >
                <ArticleFeatured theme={theme} justify="center">
                    <Col className="justify-center" lg={16} md={12} sm={27} xs={27}>
                        <InfoWrapper className='side-blog-card' style={{width: '100%',height: '32rem',overflow:'hidden'}}>
                            {/* <Title style={{padding: '1rem', margin: '0 auto'}}>Article à la une</Title> */}
                            {state.featureBlog &&(
                            <>
                            <div style={{padding: '0 1.5em'}}>
                                <h2 className='title'>{state.featureBlog.title}</h2>
                                <p className="album-date">{state.featureBlog?.publishDate}</p>
                            </div>
                            <div className="album">
                                <img className="album-artwork" src={state.featureBlog.heroImage?.file.url} />
                                <div>
                                <p className="short-description-slide">{documentToReactComponents(JSON.parse(state.featureBlog?.body?.raw),options)}</p>
                                <Badge style={{color:'#000',cursor: 'auto'}}>{state.featureBlog.category}</Badge>
                                <Link className='read-btn' state={state.featureBlog} to={`/blog/${state.featureBlog.categoryUrl}/${state.featureBlog.slug}`} >Lire</Link>
                                </div>
                            </div>
                            </>
                            )}
                        </InfoWrapper>
                    </Col>
                    <Col className="justify-center" lg={6} md={6} sm={21} xs={21} >
                        <InfoWrapper style={{width: '100%'}}>
                            <div>
                                <div className='side-title'>
                                    <span>Les plus lus</span>
                                </div>
                                {readBlog.topReadBlog?.map((item, index) => {
                                return (
                                    <div className='side-blog-card' onClick={()=>  navigate(`/blog/${item.slug}`)} key={index}>
                                        <p style={{fontWeight: '500',marginBottom: '0'}}>{item.title}</p>
                                        <p style={{marginBottom: 'unset'}} >{item.category}</p>
                                    </div>
                                    );
                                })}
                            </div>
                            <div>
                                <div style={{marginTop:'0.5em'}} className='side-title'>
                                    <span>La sélection</span>
                                </div>
                                {state.selectionBlogs?.slice(0, 3).map((item, index) => {
                                return (
                                    <div className='side-blog-card' onClick={()=>  navigate(`/blog/${item.slug}`)} key={index} >
                                        <p style={{fontWeight: '500',marginBottom: '0'}}>{item.title}</p>
                                        <p style={{marginBottom: 'unset'}} >{item.category}</p>
                                    </div>
                                    );
                                })}
                            </div>
                        </InfoWrapper>
                    </Col>
                </ArticleFeatured>
                <ArticleRow theme={theme} >
                        <GridWrapper className='template-columns'>
                        {blogs?.nodes?.slice(0, 3).map((item, index) => {
                            return (
                            <div onClick={()=>  navigate(`/blog/${item.categoryUrl}/${item.slug}`, {state: { blog: item }})} key={index} className='card'>
                                    
                                    <img className="card-image" src={item.heroImage.file.url}/>
                                    <div className="card-description-hidden">
                                    <span className="text-title">{item.title}</span>
                                    </div>
                                    <div className="card-description">
                                    <span className="text-title">{item.title}</span>
                                    <Badge style={{color:'#000',width:'fit-content'}}>{item.category}</Badge>
                                    <span className="short-description">{documentToReactComponents(JSON.parse(item.body?.raw),options)}</span>
                                    </div>
                            </div>
                                );
                            })}
                        </GridWrapper>
                </ArticleRow>
                <ArticleRow theme={theme} style={{margin: '0 3rem'}} >
                        <Title style={{padding: '1rem',width: '100%', textAlign: 'center'}}>Explorer les catégories</Title>
                        <div style={{ margin:'auto'}}>
                        <Badge onClick={() => selectedCategory('all')} className='badge' style={{ margin:'0.3rem',padding: '1em 1rem 1em 1em'}}>All</Badge>
                        {category.categories?.map((item, index) => {
                            return (
                                <Badge onClick={() => selectedCategory(item)} key={index} className='badge' style={{ margin:'0.3rem',padding: '1em 1rem 1em 1em'}}>{item}</Badge>
                            );
                        })}
                        </div>
                        <GridWrapper className='template-columns'>
                        {state.filtredBlogs?.map((item, index) => {
                            return (
                            <div onClick={()=>  navigate(`/blog/${item.categoryUrl}/${item.slug}`)} key={index} className='card'>
                                <img className="card-image" src={item.heroImage.file.url}/>
                                <div className="card-description">
                                    <span className="text-title">{item.title}</span>
                                    <Badge style={{color:'#000',width:'fit-content'}}>{item.category}</Badge>
                                    <span className="short-description">{documentToReactComponents(JSON.parse(item.body?.raw))}</span>
                                </div>
                            </div>
                                );
                            })}
                        </GridWrapper>
                        <Pagination
                            pageSelected={currentPage}
                            blogsPerPage={blogPerPage}
                            totalBlogs={state.totalBlogs?.length}
                            paginate={paginate}
                        />
                </ArticleRow>
        </div>
    );
};

export default BlogsHome;
