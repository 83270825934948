import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import TpeCard from '../../../common/tpe-card';
import { useStaticQuery, graphql } from 'gatsby';
import {
    Title,
    InfoWrapper,
    SubTitle,
    Button,
    ButtonIcon,
} from '../../../common/SimpleRow/styles';
import { Text } from '../../ecommerce/advantage/styles';
import Slider from 'react-slick';
import { NumbersWrapper, Number } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
const Tpes = () => {
    let slider = null;
    const [state, setState] = useState({
        activeTpe: 0,
        showAva: false,
    });
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        prevArrow: (
            <FontAwesomeIcon
                icon="caret-left"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        nextArrow: (
            <FontAwesomeIcon
                icon="caret-right"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="custom_dot"></div>,
        beforeChange: (current, next) =>
            setState({ ...state, activeTpe: next }),
    };
    const data = useStaticQuery(graphql`
        query {
            allContentfulTpe(sort: { fields: createdAt, order: ASC }) {
                nodes {
                    title
                    content
                    image {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const { theme } = useContext(ThemeContext);

    const br = detect();
    return (
        <>
            <Row
                className=""
                justify="center"
                id="avas"
                style={{ padding: '50px 20px', paddingTop: '100px' }}
            >
                <Col lg={24}>
                    <Title theme={theme} style={{ textAlign: 'center' }}>
                        Des Applications à Valeur Ajoutée
                    </Title>
                </Col>
                <Col
                    lg={24}
                    className="justify-center"
                    style={{ margin: '30px 0' }}
                >
                    <Text theme={theme}>
                        Découvrez nos innovations exclusives, conçues pour
                        dynamiser votre commerce.
                    </Text>
                </Col>
                <Col lg={24} className="justify-center">
                    <Button
                        onClick={() =>
                            setState({ ...state, showAva: !state.showAva })
                        }
                        br={br?.name}
                    >
                        En savoir plus
                    </Button>
                </Col>
            </Row>
            {state.showAva && (
                <Row
                    justify="center"
                    style={{ margin: '20px 0', padding: '0 20px' }}
                >
                    <Col
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        className="justify-center"
                        xxl={8}
                        xl={9}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <InfoWrapper style={{ maxWidth: '100%' }}>
                            <Title
                                style={{
                                    borderRadius: '5px',
                                    color: '#fff',
                                    backgroundColor: '#e66d30',
                                    textAlign: 'center',
                                    padding: '10px 30px',
                                    display: 'inline',
                                    width: 'fit-content',
                                    fontSize: '25px',
                                }}
                                theme={theme}
                            >
                                {
                                    data.allContentfulTpe.nodes[state.activeTpe]
                                        .title
                                }
                            </Title>
                            <SubTitle
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '19px',
                                    minHeight: '90px',
                                }}
                                theme={theme}
                            >
                                {
                                    data.allContentfulTpe.nodes[state.activeTpe]
                                        .content
                                }
                            </SubTitle>
                            <NumbersWrapper>
                                {data.allContentfulTpe.nodes.map(
                                    (item, index) => {
                                        return (
                                            <Number
                                                onClick={() =>
                                                    slider &&
                                                    slider.slickGoTo(index)
                                                }
                                                style={
                                                    state.activeTpe === index
                                                        ? {
                                                              backgroundColor:
                                                                  'rgb(230, 109, 48)',
                                                              color: '#fff',
                                                              boxShadow:
                                                                  '-1px 7px 19px 0px rgb(230, 109, 48)',
                                                              transform:
                                                                  'scale(1.1)',
                                                          }
                                                        : {}
                                                }
                                                key={index}
                                                index={index}
                                            >
                                                {index + 1}
                                            </Number>
                                        );
                                    }
                                )}
                            </NumbersWrapper>
                        </InfoWrapper>
                    </Col>
                    <Col
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        className="justify-center"
                        xxl={8}
                        xl={9}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <div
                            style={{
                                padding: '20px',
                                maxWidth: '25rem',
                            }}
                        >
                            <Slider ref={(s) => (slider = s)} {...settings}>
                                {data.allContentfulTpe.nodes.map(
                                    (item, index) => {
                                        return (
                                            <TpeCard
                                                key={index}
                                                index={index}
                                                imgUrl={item.image.file.url}
                                            ></TpeCard>
                                        );
                                    }
                                )}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Tpes;
