import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ImageWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: calc(100% - 120px);
    background: #ffa055;
    z-index: 1;
    transition: 0.2s ease-in-out;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    transform: translateY(-200px);
`;
export const Wrapper = styled.div`
    width: 200px;
    height: 370px;
    background-color: #ffa055;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    margin: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    &:hover ${ImageWrapper} {
        transform: translateY(10px);
    }
`;
export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    letter-spacing: -0.17px;
    color: #fff;
    padding: 15px 10px;
    font-weight: 100;
    max-height: 70px;
    min-height: 70px;
    width: 100%;
    text-align: center;
    line-height: 21px;
`;

export const Image = styled.img`
    max-width: 80%;
`;
export const Content = styled.p`
    font-family: Rajdhani, sans-serif;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    min-height: 200px;
    text-align: justify;
`;
export const IconWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Icon = styled(FontAwesomeIcon)`
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    font-size: 25px;
    color: #e66d30;
    min-width: 25px;
    height: 25px;
`;

export const BoldTitle = styled.span`
    font-weight: bold;
    font-size: 22px;
`;
