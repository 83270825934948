import React, { useState } from 'react';
import {
    Title,
    Button,
    ButtonWrapper,
    SubTitle,
    BackButton,
} from '../../styles';
import { Row, Col } from 'antd';
import { Select } from '../../../select';
import { TextField } from '../../../text-field';
import PhoneIcon from '../../../../images/phone.inline.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onlyCharacters, emailKeyPress } from '../../../helpers/only-characters';
import {navigate} from "@reach/router";
const checkClient = [
    {
        value: false,
        label: "Je ne suis pas encore client, je souhaite m'équiper en TPE",
    },
    { value: true, label: "Je suis client, je souhaite  m'équiper en AVA" },
];
const WelcomForm = ({
    type,
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
}) => {
    const [state, setState] = useState({
        step: type === 'tpe' ? 0 : 1,
    });

    const handleNextClick = () => {
        setTouched({
            isClient: true,
            firstName: true,
            lastName: true,
            phone: true,
            email: true,
            confirmEmail: true,
        });
        validate().then((errors) => {
            if (Object.keys(errors).length === 0) {
                nextStep();
                setValue('step', 1);
            }
        });
    };

    return (
        <>
            {state.step === 0 && (
                <>
                    <Row align="middle" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title>Êtes-vous déja client NAPS ?</Title>
                        </Col>
                    </Row>
                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Select
                                data={checkClient}
                                label="Que souhaitez vous faire ?"
                                placeholder=""
                                name="isClient"
                            ></Select>
                        </Col>
                    </Row>

                    <Row align="top" justify="center">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{display: 'flex'}}>
                            <ButtonWrapper>
                                <BackButton
                                    type="button"
                                    onClick={() =>
                                        navigate(-1)
                                    }
                                >
                                    Retour
                                </BackButton>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        setState({ ...state, step: 1 })
                                    }
                                >
                                    Continuer
                                </Button>
                            </ButtonWrapper>
                        </Col>
                    </Row>
                </>
            )}
            {state.step === 1 && (
                <>
                    <Row align="middle" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title
                                style={{
                                    textAlign: 'center',
                                    margin: '0 0 -10px 0',
                                }}
                            >
                                Commençons par faire connaissance
                            </Title>
                        </Col>
                    </Row>
                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            <TextField
                                name="firstName"
                                placeholder=""
                                label="Prénom"
                                onChange={(event) => {
                                    setValue(
                                        'firstName',
                                        event.target.value
                                            ? event.target.value
                                                  .substring(0, 1)
                                                  .toUpperCase() +
                                                  event.target.value
                                                      .substring(1)
                                                      .toLowerCase()
                                            : ''
                                    );
                                }}
                                maxLength={20}
                                keyPress={onlyCharacters}
                            ></TextField>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            <TextField
                                name="lastName"
                                placeholder=""
                                label="Nom"
                                onChange={(event) => {
                                    setValue(
                                        'lastName',
                                        event.target.value
                                            ? event.target.value.toUpperCase()
                                            : ''
                                    );
                                }}
                                maxLength={20}
                                keyPress={onlyCharacters}
                            ></TextField>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <SubTitle
                                style={{
                                    color: '#e66d30',
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '10px 0 -10px 0',
                                }}
                            >
                                <PhoneIcon style={{ width: '60px' }} />{' '}
                                Téléphone
                            </SubTitle>
                        </Col>
                    </Row>

                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <TextField
                                label="Votre numéro de téléphone"
                                placeholder=""
                                name="phone"
                                maxLength="10"
                                keyPress={(e) => {
                                    if (
                                        e.key === ' ' ||
                                        e.charCode < 48 ||
                                        e.charCode > 57
                                    )
                                        e.preventDefault();
                                }}
                            ></TextField>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <SubTitle
                                style={{
                                    marginBottom: '0px',
                                    color: '#e66d30',
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '10px 0 -10px 0',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: '40px',
                                        width: '50px',
                                        marginRight: '10px',
                                    }}
                                    icon={['fa', 'envelope']}
                                />
                                Email
                            </SubTitle>
                        </Col>
                    </Row>

                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <TextField
                                label="Votre e-mail"
                                placeholder=""
                                name="email"
                                keyPress={emailKeyPress}
                                maxLength={44}
                            ></TextField>
                        </Col>
                    </Row>

                    <Row align="top" justify="center" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <TextField
                                label="Confirmation de votre e-mail"
                                placeholder=""
                                name="confirmEmail"
                                keyPress={emailKeyPress}
                                maxLength={44}
                            ></TextField>
                        </Col>
                    </Row>
                    <Row
                        align="top"
                        justify="center"
                        style={{ marginBottom: '20px' }}
                    >
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <ButtonWrapper>
                                <Button type="button" onClick={handleNextClick}>
                                    Continuer
                                </Button>
                                {type === 'tpe' && (
                                    <BackButton
                                        type="button"
                                        onClick={() =>
                                            setState({ ...state, step: 0 })
                                        }
                                    >
                                        Retour
                                    </BackButton>
                                )}
                            </ButtonWrapper>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
export default WelcomForm;
