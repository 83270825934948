import React, { useState } from 'react';
import {
    Title,
    VideoWrapper
} from './styles';
import { Col } from 'antd';
import Play from '../../../images/play.inline.svg';
import VdModal from '../vd-modal';
import { StaticImage } from 'gatsby-plugin-image';

export const ArticleListVideo = ({
    title,
    videoId,
    onHover,
}) => {

    const [state, setState] = useState({
        showModal: false,
        browser: '',
    });

    return (
        <>
            <VideoWrapper onMouseEnter={onHover} >
                <Col className="justify-center wrapper-article">
                    <div className="style-video-list">
                        <Title>
                            {title}
                        </Title>
                        {state.browser === 'ie' ? (
                            <span
                                class="material-icons"
                                style={{
                                    position: 'absolute',
                                    left: 'calc(50% - 30px)',
                                    top: 'calc(50% - 35px)',
                                    fontSize: '50px',
                                    cursor: 'pointer',
                                    width: '100px',
                                    zIndex: 2,
                                }}
                            >
                                play_arrow
                            </span>
                        ) : (
                            <StaticImage
                                src='../../../images/play.inline.png'
                                formats={["auto", "webp"]}
                                onClick={() =>
                                    setState({ ...state, showModal: true })
                                }
                                style={{
                                    position: 'absolute',
                                    left: 'calc(50% - 30px)',
                                    top: 'calc(50% - 30px)',
                                    fontSize: '50px',
                                    cursor: 'pointer',
                                    width: '60px',
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </div>
                </Col>
            </VideoWrapper>
            <VdModal
                url={"https://www.youtube.com/embed/" + videoId}
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
            ></VdModal>
        </>


    );
};

export default ArticleListVideo;
