import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import ProductCard from '../../../common/product-card';
import { Title } from '../../../common/SimpleRow/styles';
import { Text } from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { faDivide } from '@fortawesome/free-solid-svg-icons';
const Advantage = () => {
    const { allContentfulProductAdvantage } = useStaticQuery(graphql`
        query {
            allContentfulProductAdvantage(
                filter: { key: { eq: "e-commerce" } }
                sort: { order: ASC, fields: order }
            ) {
                nodes {
                    title
                    content
                    orangeImage {
                        file {
                            url
                        }
                    }
                    lightImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-3 md:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" justify="center">
                {allContentfulProductAdvantage.nodes.map((item, index) => {
                    return (
                        <div
                            className="justify-center"
                            style={{ padding: '20px' }}

                            key={index}
                        >
                            <ProductCard
                                title={item.title}
                                content={item.content}
                                image={item.orangeImage.file.url}
                                lightImage={
                                    item.lightImage && item.lightImage.file.url
                                }
                            />
                        </div>
                    );
                })}
            </div>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-1 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" justify="center" >
                <div lg={24}>
                    <Title theme={theme} style={{ textAlign: 'center' }}>
                        Composez votre solution e-commerce avec <br /> nos
                        offres pré-packagées !
                    </Title>
                </div>
                <div
                    lg={24}
                    className="justify-center"
                    style={{ margin: '30px 0' }}
                >
                    <Text theme={theme}>
                        Quel que soit votre secteur ou votre modèle
                        opérationnel, nous vous apportons des solutions qui
                        simplifient vos opérations et garantissent les meilleurs
                        niveaux de satisfaction client.
                    </Text>
                </div>
            </div>
        </>
    );
};
export default Advantage;
