import React from 'react';
import MenuDetails from '../../../components/menu-details';
import Layout from '../../../components/layout';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../../../components/seo';
const Details = () => {
    const { allContentfulMenuDetails } = useStaticQuery(graphql`
        query ProDetails {
            allContentfulMenuDetails(
                filter: { type: { eq: "pro" } }
                sort: {fields: order, order: ASC}
            ) {
                nodes {
                    title
                    content
                    button
                    link
                    state
                    row
                    icon {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Professionnels"  />
            <MenuDetails data={allContentfulMenuDetails.nodes} type="pro" />
        </Layout>
    );
};

export default Details;
