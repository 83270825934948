import styled from 'styled-components';

export const Title = styled.h1`
    font-family: 'Maven Pro', sans-serif;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    margin: 20px 0 5px 0;
    color: #5f5f5f;
`;
export const SubTitle = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#a9a9a9')};
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 8px;
`;
export const Button = styled.button`
    height: 40px;
    padding: 0 30px;
    background-color: #e66d30;
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
`;

export const BackButton = styled.button`
    background-color: transparent;
    opacity: 0.85;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    padding: 10px 0 10px;
    border: 0.5px solid #5f5f5f;
    height: 40px;
    padding: 0 30px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export const AddFamilyButton = styled.button`
    width: 100%;
    height: 60px;
    text-align: start;
    border: 1px dotted #dedede;
    background: #fff;
    border-radius: 12px;
    padding: 10px 20px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export const LinkButton = styled.button`
    width: 100%;
    height: 60px;
    text-align: start;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;
