import React from "react"

import NotFound from '../images/notfound.inline.svg'
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    {/* <SEO title="404: Not found" url="/404" /> */}
    <div className="notfound">
      <StaticImage width="200" formats={["auto", "webp"]} src="../images/notfound.inline.png" placeholder="blurred" />
    </div>

  </Layout>
)

export default NotFoundPage
