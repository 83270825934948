import React from 'react';
import {
    Wrapper,
    Title,
    ImageWrapper,
    Image,
    Content,
    IconWrapper,
    Icon,
    BoldTitle,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Zoom } from 'react-awesome-reveal';
const PaymentCard = ({ title, content, imgUrl }) => {
    return (
        <Zoom>
            <Wrapper>
                <Title>
                    {title != 'Outils analytiques' ? (
                        <>
                            Paiement <br /> <BoldTitle>{title}</BoldTitle>{' '}
                        </>
                    ) : (
                        <>
                         Outils <br /> <BoldTitle>Analytiques</BoldTitle>{' '}
                        </>
                    )}
                </Title>
                <Image src={imgUrl}></Image>
                <Content>{content}</Content>
            </Wrapper>
        </Zoom>
    );
};

export default PaymentCard;
