import React, { useState } from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { navigate } from 'gatsby';


const VignettePaiement = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulVignetteLanding(templateKey: { eq: "vignette-paiement" }) {
                id
                title
                description {
                    raw        
                  }
                image {
                    title
                    file {
                        url
                    }
                }
            }
        }
    `);
    const [state, setState] = useState({
        showModal: false,

    });

    return (
        <>
            <SimpleRow
                title={data.contentfulVignetteLanding.title}
                subTitle={documentToReactComponents(JSON.parse(data.contentfulVignetteLanding.description?.raw))}
                imageUrl={data.contentfulVignetteLanding.image.file.url}
                state="right"
                id="vignette-paiement"
                videoButtonText="Payer ma vignette en ligne"
                onVdClick={()=>  navigate(`https://vignette.naps.ma`)}
                buttonsToLeft={true}
            />
        </>
    );
};
export default VignettePaiement;
