import React from 'react';
import {
    Wrapper,
    Title,
    ImageWrapper,
    Image,
    Content,
    IconWrapper,
    Icon,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Zoom } from 'react-awesome-reveal';
const TpeCard = ({ title, content, imgUrl }) => {
    return (
        <Wrapper className="tpe-card">
            <ImageWrapper>
                <Image src={imgUrl || ''}></Image>
            </ImageWrapper>
        </Wrapper>
    );
};

export default TpeCard;
