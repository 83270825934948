import React from 'react';
import { Wrapper, ImageWrappwe, Text } from './styles';
import { useInView } from 'react-intersection-observer';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
const StepCard = ({ image, text, index, medias }) => {
    const [ref, inView, entry] = useInView({
        threshold: 1,
        rootMargin: '-150px 0px -150px 0px',
    });
    let justifyContent = (index + 1) % 2 !== 0 ? 'flex-start' : 'flex-end';

    const { theme } = useContext(ThemeContext);
    const br = detect();
    const childs =
        (index + 1) % 2 === 0 ? (
            <>
                <ImageWrappwe>
                    <img
                        src={image}
                        style={{ maxWidth: '100%', padding: '20px', width: '200px' }}
                        alt={text}

                    />
                </ImageWrappwe>
                {inView && (
                    <Fade>
                        <Text br={br?.name} theme={theme}>{text}</Text>
                    </Fade>
                )}
            </>
        ) : (
            <>
                {inView && (
                    <Fade>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text br={br?.name} theme={theme}>{text}</Text>
                            <div style={{ margin: '15px 0 0 0' }}>
                                {medias &&
                                    medias.map((item, i) => {
                                        return (
                                            <Link
                                                className="link"
                                                key={i}
                                                to={item.link}
                                            >
                                                <FontAwesomeIcon 
                                                    style={{
                                                        marginRight: '10px',
                                                        fontSize: '17px',
                                                    }}
                                                    icon={['fa', item.icon]}
                                                />
                                            </Link>        
                                        );
                                    })}
                            </div>
                        </div>
                    </Fade>
                )}
                <ImageWrappwe>
                    <img
                        src={image}
                        style={{ maxWidth: '100%', padding: '20px', width: '200px' }}
                        alt={text}
                    />
                </ImageWrappwe>
            </>
        );
    return (
        <Wrapper
            theme={theme}
            ref={ref}
            style={
                !inView
                    ? { boxShadow: 'none', justifyContent: justifyContent }
                    : {}
            }
        >
            {childs}
        </Wrapper>
    );
};

export default StepCard;
