import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';

const Products = () => {

    useEffect(() => {
        navigate('/products/e-commerce');
    });
    return <Layout></Layout>;
};
export default Products;
