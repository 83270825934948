import React from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const IndividualSubscription = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "individual-subscription" }) {
                id
                title
                subTitle
                image {
                    title
                    file {
                        url
                    }
                }
                description {
                    raw
                }
            }
        }
    `);

    return (
        <SimpleRow
            title={data.contentfulLanding.title}
            subTitle={documentToReactComponents(JSON.parse(
                data.contentfulLanding.description.raw)
            )}
            state='right'
            imageUrl={data.contentfulLanding.image.file.url}
        />
    );
};
export default IndividualSubscription;
