import React, { useEffect, useState,useContext } from 'react';
import { Row, Col, Spin } from 'antd';
import { Title, SubTitle,InfoWrapper, ButtonWrapper, Button, BackButton } from '../styles';
import { CheckBoxField } from '../../checkbox-field';
import { Input, AutoComplete } from 'antd';
import RadioField from '../../common/radio-field';
import RecapIcon from '../../../images/orderrecap.inline.svg';
import { mapValues,groupBy,omit } from 'lodash';
import ShipAdresseIcon from '../../../images/shipadresse.inline.svg';
import ThemeContext from '../../layout/theme/ThemeContext';
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import Popup from 'reactjs-popup';
import { CloseIcon } from '../../common/vd-modal/styles';
import { http } from '../../../components/http';

const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: '#e66d30' }} spin />
);


export const OrderForm = ({
    setValue,
    back,
    values,
    orderType,
    location,
    isSubmitting,
    accountType,
    submitForm,
    openNotification,
    setTouched,
    validate,
    fromMobile
}) => {
    const phoneClient =values.phone;
    const [errors, setErrors] = useState({});
    const handleSendSMS = async (phoneClient) => {
        try {
            const res = await http.get(`/code-phone/confirmation/${phoneClient}`);
            //console.log("Réponse de l'API: ", resphone.data);
        } catch (error) {
            console.error('Erreur lors de l\'appel à l\'API :', error);
        }
    };
    const [state, setState] = useState({
        //tarif: 0,
        openPopup: false,
    });
    useEffect(() => {
        // if (values.paymentType === 'Je paie à la livraison')
        //     setState({
        //         ...state,
        //         tarif: 0,
        //     });
        // if (values.paymentType === 'Je paie en ligne')
        //     setState({
        //         ...state,
        //         tarif: 0.0065,
        //     });

        
    }, [values.paymentType]);
    const handleBackClick = () => {
        back();
        setValue('terms',false)
        setValue('step', orderType === 'wac' ? 3 : 2);
    };
    let cardsPrice = 99;
    let pack = values.orderType === 'wac' && values.accountType==='PELOUSE' ? 550 : values.orderType === 'wac' && values.accountType==='TRIBUNE' ? 1550 : orderType === 'casawe' ? 99 : cardsPrice;
    let recharges = values.initialRechargeAmount;
    if (orderType === 'family') {
        values.family.forEach((f) => (cardsPrice += parseInt(cardsPrice)));
        recharges += values.family.reduce((mb1, mb2) => {
            return parseFloat(mb1) + parseFloat(mb2.initialRechargeAmount);
        }, 0);
    }
    if(orderType === 'casawe'){
        values.paymentType ='Je paie en ligne';
    }
    const { theme } = useContext(ThemeContext);
    const handleCheckboxChange = (e) => {
        setValue('terms', e.target.checked);
    };

    if(fromMobile) {
        return (<>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <Title
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#e66d30',
                        }}
                    >
                        <ShipAdresseIcon style={{ width: '60px' }} /> 
                        Validation de votre carte
                    </Title>
                </Col>
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <CheckBoxField
                        value={values.terms}
                        pre="J’ai lu et j’accepte la politique de confidentialité, notamment "
                        suf="personnelles conformément à la loi 09/08."
                        linkText="la mention relative à la protection des données"
                        to="/terms"
                        name="terms"
                        onChange={handleCheckboxChange}
                    ></CheckBoxField>
                    {errors.terms && (
                        <div style={{ color: 'red', marginTop: '6px', marginLeft:'20px', fontSize:'12px' }}>
                            {errors.terms}
                        </div>
                    )}
                </Col>
            </Row>
            </Row>
            <Row align="top" justify="center">
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <ButtonWrapper>
                        {isSubmitting ? (
                            <Spin indicator={antIcon} />
                        ) : (
                            <Button
                                type="button"
                                onClick={() => {
                                    validate().then((errors) => {
                                        console.log(errors)
                                        const newErrors = { ...errors };
                                        if (!values.terms) {
                                            newErrors.terms = "Vous devez accepter les termes et conditions.";
                                        }
                                        setErrors(newErrors);
                                        if (Object.keys(newErrors).length === 0 ) {
                                            console.log("OPEN POPUP")
                                            setState({
                                                ...state,
                                                openPopup: true,
                                            });
                                        }
                                    });
                                }}
                            >
                                Commander
                            </Button>
                        )}
                        <BackButton type="button" onClick={handleBackClick}>
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
            </Row>
            {/* Deduplicate the popup */}
                <Popup
                    overlayStyle={{
                        background: '#00000090',
                    }}
                    modal
                    nested
                    open={state.openPopup}
                    onClose={() =>
                        setState({
                            ...state,
                            openPopup: false,
                        })
                    }
                >
                    <div
                        style={{
                            width: '500px',
                            height: '125px',
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                        className="modal"
                    >
                        <CloseIcon
                            className="close"
                            onClick={() =>
                                setState({
                                    ...state,
                                    openPopup: false,
                                })
                            }
                        >
                            &times;
                        </CloseIcon>
                        <p
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                fontFamily: 'Maven Pro',
                                fontWeight: '500',
                            }}
                        >
                            Êtes-vous sûr de vouloir confirmer la commande ?
                        </p>

                        <ButtonWrapper>
                            {isSubmitting ? (
                                <Spin indicator={antIcon} />
                            ) : (
                                <>
                                    {/*<Button type="button" onClick={submitForm}>
                                        Confirmer
                                    </Button>*/}
                                     {/*<Button type="button" onClick={() => { submitForm(); handleSendSMS(phoneClient); }}>
                                        Confirmer
                                    </Button>*/}
                                    <Button type="button" onClick={() => { 
                                        submitForm()
                                            .then(() => {
                                                handleSendSMS(phoneClient);
                                            })
                                            .catch((error) => {
                                                // Gérer les erreurs de la méthode submitForm() ici
                                                console.error("Une erreur s'est produite lors de la soumission du formulaire:", error);
                                            });
                                    }}>
                                        Confirmer
                                    </Button>
                                    <BackButton
                                        type="button"
                                        onClick={() =>
                                            setState({
                                                ...state,
                                                openPopup: false,
                                            })
                                        }
                                    >
                                        Annuler
                                    </BackButton>
                                </>
                            )}
                        </ButtonWrapper>
                    </div>
                </Popup>
            </>)

    }
    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <Title
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#e66d30',
                        }}
                    >
                        <ShipAdresseIcon style={{ width: '60px' }} /> 
                        Récapitulatif de votre commande
                    </Title>
                </Col>
            </Row>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{ textAlign: 'left' }}
                >
                    <table>
                        <tr style={{ width: '100%' }}>
                            <td style={{ width: '80%' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    Prix de la carte
                                </SubTitle>
                            </td>
                            <td style={{ width: '20%', textAlign: 'start' }}>
                            {/* {orderType == 'casawe' ?(
                                 <> */}
                                    <SubTitle
                                        style={{
                                            color: '#000',
                                            margin: '0',
                                            padding: '0',
                                        }}
                                    >
                                    {pack}
                                    </SubTitle>
                            </td>
                        </tr>
                        {orderType === 'family' && (
                            <>
                                <tr style={{ width: '100%' }}>
                                    <td style={{ width: '80%' }}>
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            Nombre de cartes commandées
                                        </SubTitle>
                                    </td>
                                    <td
                                        style={{
                                            width: '20%',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            4
                                        </SubTitle>
                                    </td>
                                </tr>

                                <tr style={{ width: '100%' }}>
                                    <td style={{ width: '80%' }}>
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            Total prix des cartes
                                        </SubTitle>
                                    </td>
                                    <td
                                        style={{
                                            width: '20%',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            {396} DHS
                                        </SubTitle>
                                    </td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td style={{ width: '80%' }}>
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            Prix du Pack
                                        </SubTitle>
                                    </td>
                                    <td
                                        style={{
                                            width: '20%',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            250 DHS
                                        </SubTitle>
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr style={{ width: '100%' }}>
                            <td style={{ width: '80%' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    Type de {' '}
                                    {values.orderType ==='wac'
                                        ? 'Carte': 'Compte'}
                                    :{' '}
                                    {values.orderType === 'family' && (
                                        <>Carte principale</>
                                    )}{' '}
                                    :
                                </SubTitle>
                            </td>
                            <td style={{ width: '20%', textAlign: 'start' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    {values.accountType}
                                </SubTitle>
                            </td>
                        </tr>
                        {orderType === 'wac' && (
                            <>
                                <tr style={{ width: '100%' }}>
                                    <td style={{ width: '80%' }}>
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            Choix de la zone
                                        </SubTitle>
                                    </td>
                                    <td
                                        style={{
                                            width: '20%',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <SubTitle
                                            style={{
                                                color: '#000',
                                                margin: '0',
                                                padding: '0',
                                            }}
                                        >
                                            {values.widadCardZone}
                                        </SubTitle>
                                    </td>
                                </tr>
                            </>
                        )}
                        {/* {values.orderType === 'family' &&
                            values.family.map((membre, index) => {
                                return (
                                    <tr style={{ width: '100%' }} key={index}>
                                        <td style={{ width: '80%' }}>
                                            <SubTitle
                                                style={{
                                                    color: '#000',
                                                    margin: '0',
                                                    padding: '0',
                                                }}
                                            >
                                                Type de Compte Carte membre{' '}
                                                {index + 1} :
                                            </SubTitle>
                                        </td>
                                        <td
                                            style={{
                                                width: '20%',
                                                textAlign: 'start',
                                            }}
                                        >
                                            <SubTitle
                                                style={{
                                                    color: '#000',
                                                    margin: '0',
                                                    padding: '0',
                                                }}
                                            >
                                                {membre.accountType}
                                            </SubTitle>
                                        </td>
                                    </tr>
                                );
                            })} */}
                        <tr>
                            <td style={{ width: '80%' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    Recharge{' '}
                                    {values.orderType === 'family' && (
                                        <>Carte principale</>
                                    )}{' '}
                                    :
                                </SubTitle>
                            </td>
                            <td style={{ width: '20%', textAlign: 'start' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    {values.orderType === 'family' ? (
                                        <>{values.initialRechargeAmount}</>
                                    ) : (
                                        <>{recharges}</>
                                    )}{' '}
                                    DHS
                                </SubTitle>
                            </td>
                        </tr>

                        {values.orderType === 'family' &&
                            values.family.map((membre, index) => {
                                return (
                                    <tr key={index}>
                                        <td
                                            style={{
                                                width: '80%',
                                                paddingLeft: '20px',
                                            }}
                                        >
                                            <SubTitle
                                                style={{
                                                    color: '#000',
                                                    margin: '0',
                                                    padding: '0',
                                                }}
                                            >
                                                Recharge Carte membre{' '}
                                                {index + 1} :
                                            </SubTitle>
                                        </td>
                                        <td
                                            style={{
                                                width: '20%',
                                                textAlign: 'start',
                                            }}
                                        >
                                            <SubTitle
                                                style={{
                                                    color: '#000',
                                                    margin: '0',
                                                    padding: '0',
                                                }}
                                            >
                                                {membre.initialRechargeAmount}{' '}
                                                DHS
                                            </SubTitle>
                                        </td>
                                    </tr>
                                );
                            })}
                        {values.orderType === 'family' && (
                            <tr>
                                <td style={{ width: '80%' }}>
                                    <SubTitle
                                        style={{
                                            color: '#000',
                                            margin: '0',
                                            padding: '0',
                                        }}
                                    >
                                        Total recharge :
                                    </SubTitle>
                                </td>
                                <td
                                    style={{ width: '20%', textAlign: 'start' }}
                                >
                                    <SubTitle
                                        style={{
                                            color: '#000',
                                            margin: '0',
                                            padding: '0',
                                        }}
                                    >
                                        {recharges} DHS
                                    </SubTitle>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td style={{ width: '80%' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    Frais de{' '}
                                    {values.paymentType ===
                                    'Je paie à la livraison' || orderType ==='casawe'
                                        ? 'livraison'
                                        : orderType ==='wac'
                                            ? 'livraison'
                                            : 'service'}
                                    :{' '}
                                </SubTitle>
                            </td>
                            <td style={{ width: '20%', textAlign: 'start' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    <NumberFormat
                                        // value={parseFloat(
                                        //     recharges * state.tarif
                                        // )}
                                        value={parseFloat(
                                            values.fee
                                        )}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                    />{' '}
                                    DHS
                                </SubTitle>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr style={{ height: '100px' }}>
                            <td style={{ width: '80%' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    TOTAL À PAYER DH TTC :
                                </SubTitle>
                            </td>
                            <td style={{ width: '20%', textAlign: 'start' }}>
                                <SubTitle
                                    style={{
                                        color: '#000',
                                        margin: '0',
                                        padding: '0',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <NumberFormat
                                        // value={
                                        //     pack +
                                        //     (parseFloat(recharges) +
                                        //         parseFloat(
                                        //             state.tarif * recharges
                                        //         ))
                                        // }
                                        value={
                                            pack +
                                            (parseFloat(recharges) +
                                                parseFloat(
                                                    values.fee 
                                                ))
                                        }
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                    />{' '}
                                    DHS
                                </SubTitle>
                            </td>
                        </tr>
                        {values.orderType === 'family' &&
                            values.family.map((membre, index) => {
                                return <tr key={index}></tr>;
                            })}
                    </table>
                </Col>
            </Row>
            {/* {values.orderType !== 'casawe' && (
                 <>
                <Row align="middle" justify="center" gutter={[16, 24]}>
                    <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                        <Title style={{ margin: '0px' }}>Paiement</Title>
                    </Col>
                </Row>
                <Row
                    align="top"
                    justify="center"
                    gutter={[16, 24]}
                    style={{ marginBottom: '20px' }}
                >
                    <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                        <RadioField
                            values={paymentTypes}
                            name="paymentType"
                        ></RadioField>
                    </Col>
                </Row>
            </>
            )} */}
        
            {/* <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <CheckBoxField
                        value={values.terms}
                        pre="J’ai lu et j’accepte la politique de confidentialité, notamment "
                        suf="personnelles conformément à la loi 09/08."
                        linkText="la mention relative à la protection des données"
                        to="/terms"
                        name="terms"
                    ></CheckBoxField>
                </Col>
            </Row> */}
            {/* <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    {values.paymentType === 'Je paie en ligne' && (
                        <p style={{ margin: '0' }}>
                            {' '}
                            * Frais de services bancaires : 0,65% du montant de
                            la recharge.
                        </p>
                    )}
                </Col>
            </Row> */}
            <Row align="top" justify="center">
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12}>
                    <ButtonWrapper>
                        {isSubmitting ? (
                            <Spin indicator={antIcon} />
                        ) : (
                            <Button
                                type="button"
                                onClick={() => {
                                    setTouched({
                                        terms: true,
                                    });

                                    validate().then((errors) => {
                                        if (Object.keys(errors).length === 0 ) {
                                            setState({
                                                ...state,
                                                openPopup: true,
                                            });
                                        }
                                    });
                                }}
                            >
                                Commander
                            </Button>
                        )}
                        <BackButton type="button" onClick={handleBackClick}>
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
                <Popup
                    overlayStyle={{
                        background: '#00000090',
                    }}
                    modal
                    nested
                    open={state.openPopup}
                    onClose={() =>
                        setState({
                            ...state,
                            openPopup: false,
                        })
                    }
                >
                    <div
                        style={{
                            width: '500px',
                            height: '125px',
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                        className="modal"
                    >
                        <CloseIcon
                            className="close"
                            onClick={() =>
                                setState({
                                    ...state,
                                    openPopup: false,
                                })
                            }
                        >
                            &times;
                        </CloseIcon>
                        <p
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                fontFamily: 'Maven Pro',
                                fontWeight: '500',
                            }}
                        >
                            Êtes-vous sûr de vouloir confirmer la commande ?
                        </p>

                        <ButtonWrapper>
                            {isSubmitting ? (
                                <Spin indicator={antIcon} />
                            ) : (
                                <>
                                    <Button type="button" onClick={submitForm}>
                                        Confirmer
                                    </Button>
                                    <BackButton
                                        type="button"
                                        onClick={() =>
                                            setState({
                                                ...state,
                                                openPopup: false,
                                            })
                                        }
                                    >
                                        Annuler
                                    </BackButton>
                                </>
                            )}
                        </ButtonWrapper>
                    </div>
                </Popup>
            </Row>
        </>
    );
};
