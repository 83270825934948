import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import { Row, Col } from 'antd';
import VdModal from '../../../common/vd-modal';
import { ProTitle, ProSubTitle, PrimaryButton, Button } from './styles';
import { ButtonWrapper, ButtonIcon } from '../../../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import B2cContactForm from '../../../b2c-contact-form';
import { detect } from 'detect-browser';
import SEO from '../../../seo';
import logo from '../../../../images/pro.svg';

const Presentation = () => {
    const [state, setState] = useState({
        showModal: false,
        showForm: false,
    });
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding: contentfulLanding(templateKey: { eq: "entreprise" }) {
                title
                description {
                    raw
                }
                image {
                    file {
                        url
                    }
                }
            },
            logo: contentfulLanding(templateKey: { eq: "landing" }) {
                image {
                    title
                    file {
                        url
                    }
                }
            }
        }
    `);

    const br = detect();
    return (
        <>
            <SEO
                title="Entreprise"
                // description={
                //     data.contentfulLanding.title +
                //     ',' +
                //     data.contentfulLanding.description.content[0].content[0]
                //         .value
                // }
                keywords={
                    'naps, naps maroc, payment, paiement, carte, recharge, e-commecre, tpe, naps Pro, entreprise'
                }
                url={'/pro/entreprise'}
            />
            <Row justify="center">
                <Col style={{ display: 'flex', flexDirection: 'column' }} className="justify-center" xxl={24} sm={24}>
                    <img style={{ width: "200px" }} src={data.logo.image.file.url} alt="logo naps" />
                    <ProTitle>{data.contentfulLanding.title}</ProTitle>
                </Col>
                <Col className="justify-center" md={12} sm={24}>
                    {/* <ProSubTitle>
                        {
                            data.contentfulLanding.description.content[0]
                                .content[0].value
                        }
                    </ProSubTitle> */}
                </Col>

                <Col className="justify-center" md={13} sm={24}>
                    <ButtonWrapper>
                        <div style={{ position: 'relative' }}>
                            <Button
                                onClick={() =>
                                    setState({
                                        ...state,
                                        showForm: !state.showForm,
                                    })
                                }
                                br={br?.name}
                            >
                                Je prends RDV
                            </Button>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <PrimaryButton
                                onClick={() =>
                                    setState({ ...state, showModal: true })
                                }
                                br={br?.name}
                            >
                                Je découvre en vidéo
                            </PrimaryButton>
                        </div>
                    </ButtonWrapper>
                </Col>
            </Row>

            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/ugsG5jXem-o"
            />
            {state.showForm && <B2cContactForm pack="entreprise" />}
        </>
    );
};
export default Presentation;
