import React from 'react';
import { Row, Col } from 'antd';
import {
    InfoWrapper,
    Title,
    SecondTitle,
    SubTitle,
    Button,
    ImageWrapper,
    AnimatedImage,
    PrimaryButton,
    ButtonWrapper,
    StoreButton,
    SubTitleWrapper,
} from './styles';
import { Zoom, LightSpeed, Roll, Fade } from 'react-awesome-reveal';
import './styles.css';
import { Link } from 'gatsby';
import { ButtonIcon } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import SEO from '../../seo';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { useState,useEffect } from 'react';
import Img from 'gatsby-image';
import { StaticImage } from 'gatsby-plugin-image';


const SimpleRow = ({
    title,
    subTitle,
    buttonText,
    imageUrl,
    state,
    videoButtonText,
    style,
    id,
    link,
    buttonsToLeft,
    onVdClick,
    storeButtons,
    payment,
    imgClassName,
}) => {
    const [cmState, setState] = useState({
        br: '',
    });
    const [ref, inView, entry] = useInView({
        threshold: 0,
    });
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        console.log('ana : ' + imageUrl)
        const br = detect();
        setState({
            ...cmState,
            br: br?.name,
        });
    }, []);

    const textCol = (
        <div
            className="justify-center"
        >
            <InfoWrapper>
                <Fade right when={inView}>
                    <SecondTitle theme={theme}>{title}</SecondTitle>
                </Fade>
                <Fade left cascade when={inView}>
                    <SubTitleWrapper
                        theme={theme}
                        style={payment && { color: '#000' }}
                    >
                        {subTitle}
                        {payment && (
                            <div style={{ display: 'flex' }}>
                                NAPS est membre de :
                                <svg
                                    version="1.1"
                                    id="Calque_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    width="40px"
                                    viewBox="0 0 256.19 172.51"
                                    style={{
                                        enableBackground:
                                            'new 0 0 256.19 172.51',
                                    }}
                                    space="preserve"
                                >
                                    <path
                                        d="M154.35,38.46c-9.34,0-18.5,2.77-26.26,7.88c-7.76-5.1-16.92-7.88-26.26-7.88c-26.35,0-47.79,21.44-47.79,47.79
	c0,26.35,21.44,47.79,47.79,47.79c9.34,0,18.5-2.77,26.26-7.87c7.76,5.1,16.92,7.87,26.26,7.87c26.36,0,47.79-21.44,47.79-47.79
	C202.15,59.9,180.71,38.46,154.35,38.46L154.35,38.46z M101.83,121.92c-19.67,0-35.66-16-35.66-35.66c0-19.67,16-35.67,35.66-35.67
	c5.91,0,11.55,1.4,16.63,4.1c-0.02,0.02-0.04,0.04-0.05,0.06c-0.52,0.59-1.02,1.2-1.51,1.81c-0.16,0.21-0.32,0.41-0.48,0.62
	c-0.34,0.44-0.68,0.89-1,1.34c-0.17,0.24-0.34,0.48-0.5,0.72c-0.31,0.45-0.61,0.9-0.9,1.36c-0.15,0.23-0.3,0.46-0.44,0.7
	c-0.34,0.56-0.67,1.13-0.99,1.7c-0.07,0.13-0.15,0.26-0.23,0.39c-0.39,0.71-0.75,1.43-1.1,2.15c-0.09,0.2-0.18,0.4-0.27,0.6
	c-0.25,0.54-0.49,1.07-0.72,1.62c-0.11,0.26-0.21,0.52-0.32,0.79c-0.2,0.5-0.39,1.01-0.57,1.52c-0.1,0.26-0.19,0.53-0.28,0.8
	c-0.19,0.56-0.37,1.13-0.53,1.7c-0.06,0.21-0.13,0.41-0.19,0.62c-0.22,0.78-0.42,1.56-0.6,2.36c-0.03,0.15-0.06,0.31-0.09,0.46
	c-0.14,0.64-0.27,1.29-0.38,1.94c-0.05,0.27-0.09,0.54-0.13,0.82c-0.09,0.55-0.16,1.09-0.23,1.64c-0.03,0.29-0.07,0.58-0.1,0.88
	c-0.06,0.57-0.1,1.14-0.14,1.71c-0.02,0.26-0.04,0.52-0.05,0.78c-0.04,0.82-0.07,1.65-0.07,2.48c0,0.83,0.03,1.66,0.07,2.48
	c0.01,0.26,0.04,0.52,0.05,0.78c0.04,0.57,0.09,1.14,0.14,1.71c0.03,0.29,0.06,0.58,0.1,0.88c0.07,0.55,0.15,1.09,0.23,1.64
	c0.04,0.27,0.08,0.55,0.13,0.82c0.11,0.65,0.24,1.29,0.38,1.93c0.03,0.16,0.06,0.31,0.1,0.47c0.18,0.79,0.38,1.57,0.6,2.35
	c0.06,0.21,0.13,0.42,0.19,0.64c0.17,0.57,0.34,1.13,0.53,1.69c0.09,0.27,0.19,0.54,0.28,0.81c0.18,0.51,0.37,1.01,0.57,1.51
	c0.11,0.27,0.21,0.53,0.32,0.8c0.23,0.54,0.47,1.07,0.71,1.6c0.09,0.21,0.18,0.41,0.28,0.62c0.35,0.72,0.71,1.44,1.1,2.14
	c0.07,0.14,0.16,0.27,0.23,0.41c0.32,0.57,0.64,1.13,0.99,1.69c0.15,0.23,0.29,0.47,0.44,0.7c0.29,0.46,0.59,0.91,0.9,1.36
	c0.16,0.24,0.33,0.48,0.5,0.71c0.32,0.46,0.66,0.91,1,1.35c0.16,0.2,0.31,0.41,0.47,0.61c0.5,0.62,1.01,1.25,1.54,1.85
	c0.01,0.01,0.01,0.02,0.02,0.03c0,0,0,0,0,0C113.39,120.52,107.74,121.92,101.83,121.92L101.83,121.92z M137.45,88.13
	c-0.01,0.2-0.03,0.4-0.04,0.59c-0.03,0.43-0.06,0.85-0.11,1.27c-0.02,0.23-0.05,0.45-0.08,0.68c-0.05,0.4-0.1,0.79-0.17,1.19
	c-0.04,0.22-0.07,0.45-0.11,0.67c-0.07,0.41-0.16,0.82-0.24,1.23c-0.04,0.19-0.08,0.39-0.12,0.58c-0.13,0.54-0.27,1.09-0.42,1.62
	c-0.01,0.05-0.02,0.1-0.04,0.15c-0.17,0.59-0.36,1.17-0.56,1.75c-0.05,0.17-0.12,0.34-0.18,0.5c-0.14,0.41-0.3,0.81-0.46,1.21
	c-0.08,0.2-0.17,0.4-0.25,0.6c-0.16,0.37-0.32,0.74-0.48,1.09c-0.09,0.2-0.19,0.4-0.29,0.6c-0.18,0.37-0.37,0.73-0.56,1.09
	c-0.1,0.18-0.19,0.36-0.29,0.54c-0.25,0.45-0.5,0.88-0.77,1.32c-0.06,0.09-0.1,0.17-0.15,0.25c-0.32,0.51-0.66,1.03-1.01,1.53
	c-0.1,0.14-0.2,0.28-0.3,0.42c-0.26,0.36-0.52,0.71-0.78,1.06c-0.14,0.18-0.28,0.35-0.42,0.52c-0.25,0.31-0.5,0.61-0.76,0.91
	c-0.15,0.18-0.31,0.36-0.47,0.54c-0.09,0.11-0.19,0.21-0.29,0.31c-0.09-0.1-0.2-0.2-0.29-0.31c-0.16-0.18-0.32-0.36-0.47-0.54
	c-0.26-0.3-0.5-0.6-0.75-0.9c-0.14-0.18-0.29-0.35-0.43-0.53c-0.27-0.34-0.52-0.69-0.78-1.04c-0.1-0.15-0.21-0.29-0.32-0.43
	c-0.35-0.5-0.68-1-1-1.52c-0.06-0.09-0.11-0.19-0.16-0.28c-0.26-0.43-0.52-0.86-0.76-1.3c-0.1-0.18-0.19-0.37-0.29-0.55
	c-0.19-0.36-0.38-0.72-0.55-1.08c-0.1-0.2-0.2-0.41-0.29-0.62c-0.17-0.35-0.32-0.72-0.48-1.08c-0.09-0.2-0.18-0.41-0.26-0.62
	c-0.16-0.4-0.3-0.79-0.45-1.19c-0.06-0.18-0.13-0.35-0.19-0.52c-0.2-0.57-0.38-1.16-0.55-1.74c-0.02-0.06-0.03-0.12-0.05-0.18
	c-0.15-0.53-0.29-1.06-0.41-1.59c-0.05-0.2-0.09-0.4-0.13-0.6c-0.09-0.4-0.17-0.8-0.24-1.21c-0.04-0.23-0.08-0.45-0.11-0.68
	c-0.06-0.39-0.12-0.79-0.16-1.18c-0.03-0.23-0.06-0.45-0.08-0.68c-0.04-0.42-0.07-0.84-0.11-1.27c-0.01-0.2-0.03-0.4-0.04-0.6
	c-0.03-0.62-0.05-1.24-0.05-1.87c0-0.62,0.02-1.25,0.05-1.87c0.01-0.2,0.03-0.4,0.04-0.6c0.03-0.43,0.06-0.85,0.11-1.27
	c0.02-0.23,0.05-0.45,0.08-0.68c0.05-0.4,0.1-0.79,0.16-1.18c0.04-0.23,0.07-0.46,0.11-0.68c0.07-0.41,0.15-0.81,0.24-1.22
	c0.04-0.2,0.08-0.39,0.13-0.59c0.12-0.54,0.26-1.07,0.41-1.59c0.01-0.06,0.03-0.12,0.05-0.18c0.17-0.58,0.36-1.16,0.55-1.74
	c0.06-0.18,0.13-0.35,0.19-0.53c0.14-0.4,0.29-0.8,0.45-1.19c0.09-0.21,0.17-0.41,0.26-0.62c0.15-0.36,0.31-0.72,0.47-1.07
	c0.1-0.21,0.2-0.41,0.3-0.62c0.18-0.36,0.36-0.72,0.55-1.07c0.1-0.19,0.2-0.37,0.3-0.56c0.24-0.44,0.5-0.87,0.76-1.3
	c0.05-0.09,0.1-0.18,0.16-0.27c0.32-0.52,0.66-1.02,1-1.52c0.1-0.14,0.21-0.29,0.31-0.43c0.25-0.35,0.51-0.7,0.78-1.05
	c0.14-0.18,0.28-0.35,0.42-0.53c0.25-0.31,0.5-0.61,0.76-0.91c0.15-0.18,0.3-0.36,0.46-0.53c0.1-0.11,0.2-0.21,0.3-0.32
	c0.1,0.11,0.2,0.21,0.3,0.32c0.16,0.18,0.31,0.35,0.46,0.52c0.26,0.3,0.51,0.61,0.76,0.91c0.14,0.18,0.28,0.35,0.42,0.53
	c0.26,0.34,0.52,0.69,0.78,1.05c0.1,0.14,0.21,0.28,0.31,0.43c0.35,0.5,0.68,1,1,1.52c0.06,0.09,0.11,0.2,0.17,0.29
	c0.26,0.43,0.51,0.85,0.75,1.29c0.1,0.19,0.2,0.38,0.3,0.56c0.19,0.35,0.37,0.71,0.55,1.07c0.1,0.21,0.2,0.42,0.3,0.63
	c0.16,0.35,0.32,0.71,0.47,1.07c0.09,0.21,0.18,0.41,0.26,0.62c0.16,0.39,0.3,0.78,0.45,1.18c0.07,0.18,0.13,0.35,0.2,0.54
	c0.2,0.57,0.38,1.15,0.55,1.73c0.02,0.07,0.03,0.14,0.05,0.2c0.14,0.52,0.28,1.04,0.4,1.57c0.05,0.2,0.09,0.4,0.13,0.61
	c0.09,0.4,0.17,0.8,0.24,1.21c0.04,0.23,0.08,0.45,0.11,0.68c0.06,0.39,0.12,0.78,0.16,1.18c0.03,0.23,0.06,0.46,0.08,0.68
	c0.04,0.42,0.07,0.84,0.11,1.27c0.01,0.2,0.03,0.4,0.04,0.6c0.03,0.62,0.05,1.25,0.05,1.87C137.5,86.88,137.48,87.5,137.45,88.13
	L137.45,88.13z M154.35,121.92c-5.91,0-11.55-1.4-16.63-4.1c0,0,0,0,0,0c0.01-0.01,0.02-0.02,0.02-0.03
	c0.53-0.61,1.04-1.22,1.54-1.85c0.16-0.2,0.32-0.41,0.48-0.62c0.34-0.44,0.67-0.89,1-1.34c0.17-0.24,0.34-0.48,0.51-0.73
	c0.3-0.44,0.6-0.89,0.89-1.35c0.15-0.24,0.3-0.47,0.45-0.71c0.33-0.55,0.65-1.1,0.96-1.66c0.08-0.15,0.17-0.29,0.25-0.44
	c0.38-0.7,0.75-1.41,1.09-2.13c0.1-0.21,0.2-0.43,0.29-0.64c0.24-0.52,0.48-1.04,0.7-1.57c0.11-0.27,0.22-0.54,0.33-0.81
	c0.2-0.5,0.39-0.99,0.56-1.49c0.1-0.28,0.2-0.55,0.29-0.83c0.19-0.55,0.36-1.11,0.53-1.66c0.06-0.22,0.14-0.44,0.2-0.67
	c0.22-0.77,0.42-1.55,0.6-2.34c0.04-0.16,0.07-0.33,0.1-0.49c0.14-0.63,0.27-1.26,0.38-1.91c0.05-0.27,0.09-0.55,0.13-0.83
	c0.08-0.54,0.16-1.09,0.23-1.63c0.04-0.29,0.07-0.59,0.1-0.88c0.06-0.57,0.11-1.13,0.14-1.7c0.02-0.26,0.04-0.52,0.05-0.79
	c0.04-0.82,0.07-1.65,0.07-2.48c0-0.83-0.03-1.66-0.07-2.49c-0.01-0.26-0.04-0.52-0.05-0.78c-0.04-0.57-0.08-1.14-0.14-1.7
	c-0.03-0.29-0.07-0.59-0.1-0.88c-0.07-0.55-0.14-1.1-0.23-1.64c-0.04-0.27-0.08-0.55-0.13-0.82c-0.11-0.65-0.24-1.29-0.38-1.93
	c-0.03-0.15-0.06-0.31-0.09-0.46c-0.18-0.79-0.38-1.58-0.61-2.36c-0.06-0.21-0.13-0.42-0.19-0.63c-0.17-0.57-0.35-1.14-0.54-1.7
	c-0.09-0.27-0.19-0.53-0.28-0.8c-0.18-0.51-0.38-1.02-0.58-1.52c-0.1-0.26-0.21-0.52-0.32-0.79c-0.23-0.55-0.47-1.09-0.72-1.62
	c-0.09-0.2-0.18-0.39-0.27-0.59c-0.35-0.73-0.71-1.44-1.1-2.16c-0.07-0.12-0.14-0.25-0.21-0.38c-0.32-0.58-0.66-1.15-1.01-1.72
	c-0.14-0.23-0.29-0.45-0.43-0.68c-0.3-0.46-0.6-0.93-0.91-1.39c-0.16-0.23-0.32-0.46-0.49-0.69c-0.33-0.47-0.68-0.93-1.02-1.38
	c-0.15-0.2-0.3-0.39-0.45-0.59c-0.51-0.64-1.03-1.27-1.57-1.89c5.08-2.7,10.73-4.1,16.63-4.1c19.67,0,35.67,16,35.67,35.67
	C190.02,105.92,174.02,121.92,154.35,121.92L154.35,121.92z M154.35,121.92"
                                    />
                                </svg>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="40px"
                                    viewBox="0 0 135.2 60.5"
                                    style={{
                                        enableBackground: 'new 0 0 135.2 60.5',
                                        color: '#000',
                                    }}
                                >
                                    <path
                                        d="M13.1,17.6l4.6,33.2h11.4L50.4,9.7h-12l-9,19.1c-1.3,2.7-2.4,5.2-3.3,7.4c-0.2-2.3-0.4-4.6-0.6-6.9L23.6,9.7
	H5.4l18.9,15L13.1,17.6z"
                                    />
                                    <polygon points="58.6,50.8 66.4,9.7 55.7,9.7 47.8,50.8 " />
                                    <path
                                        d="M64.8,48.4c2.5,1.9,6.9,3,11.8,3c10.1,0,17-5.7,17-14.3c0-4.7-2.7-8.4-8.3-11.3c-3.1-1.8-5-2.9-5-4.4
	c0-1.4,1.5-2.9,4.9-2.9c3.4,0,5.9,1.3,6.4,1.6l1.2,0.7l3.4-9.2l-0.9-0.5C92.8,9.7,89.6,9,85.6,9c-9.7,0-16.5,5.7-16.5,13.8
	c0,5.9,4.6,9.2,8.7,11.4c3.8,2,4.6,3.1,4.6,4.4c0,2.5-2.7,3.3-5,3.3c-3,0-6.1-0.8-8.5-2.3l-1.2-0.7l-3.6,8.9L64.8,48.4z"
                                    />
                                    <path
                                        d="M104,50.8l4.6-10.1h9.3l0.8,10.1h11.1L125,9.7h-12.5l-20,41.2H104z M115.2,25.4c0.4-0.9,0.9-2,1.3-3.1
	c0.1,1,0.2,1.9,0.2,2.7l0.6,7h-5.2L115.2,25.4z"
                                    />
                                </svg>
                            </div>
                        )}
                    </SubTitleWrapper>
                </Fade>
                <ButtonWrapper
                    style={{
                        justifyContent: buttonsToLeft
                            ? 'flex-start'
                            : 'space-around',
                    }}
                >
                    {buttonText &&
                        (link ? (
                            <Link to={link}>
                                <Button>
                                    {buttonText}
                                </Button>
                            </Link>
                        ) : (
                            <Button>
                                {buttonText}
                            </Button>
                        ))}
                    {videoButtonText && (
                        <PrimaryButton onClick={onVdClick}>
                            {videoButtonText}
                        </PrimaryButton>
                    )}
                </ButtonWrapper>
            </InfoWrapper>
        </div>
    );
    const imageCol = (
        <div
            className="justify-center"
            style={{ flexDirection: 'column', alignItems: 'center' }}
        >
            <ImageWrapper>
                {imageUrl && (
                    <Fade left when={inView}>
                        <AnimatedImage
                            className={imgClassName}
                            style={{ width: '25rem' }}
                            src={imageUrl}
                            alt={title || "naps"}
                        />
                    </Fade>
                )}
            </ImageWrapper>
            {storeButtons && (
                <ButtonWrapper
                    style={{
                        justifyContent: 'center',
                        marginBottm: '20px',
                        marginTop: '-60px',
                    }}
                >
                    <StoreButton className="play-store-button"></StoreButton>
                    <StoreButton className="app-store-button"></StoreButton>
                </ButtonWrapper>
            )}
        </div>
    );
    return (
        <div className="container mx-auto" ref={ref}>
            <div
                justify="center"
                className=" row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
                style={{ ...style }}
                id={id}
            >
                {state === 'left' ? (
                    <>
                        {imageCol} {textCol}
                    </>
                ) : (
                    <>
                        {textCol} {imageCol}
                    </>
                )}
            </div>
        </div>
    );
};

export default SimpleRow;
