import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    /* margin-top: 75px; */
`;
export const Info = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
`;
export const WrapTitle = styled.h1`
    font-size: 35px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    color: transparent;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke: 1px #ffffff;
    letter-spacing: 2px;
`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const Line = styled.div`
    border-top: 3px solid #fff;
    width: 82px;
    border-radius: 100px;
    margin-right: 24px;
`;
export const HelpText = styled.p`
    margin: 0px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #e66d30;
    text-align: center;
    margin-top: 50px;
`;
export const BottomText = styled.p`
    margin: 0px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
`;
export const ContactText = styled.h1`
    font-size: 35px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    color: transparent;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
`;
