import React, { useContext } from 'react';
import { Wrapper, Title } from './styles';
import Slider from 'react-slick';
import PartnerCard from '../../../common/partner-card';
import { useStaticQuery, graphql } from 'gatsby';
import { LightSpeed } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../../../layout/theme/ThemeContext';
const Partner = () => {
    const { allContentfulPartnerFeedback } = useStaticQuery(graphql`
        query {
            allContentfulPartnerFeedback {
                nodes {
                    name
                    feedback
                }
            }
        }
    `);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        centerPadding: '10px',
        cssEase: 'linear',
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="custom_dot"></div>,
        prevArrow: (
            <FontAwesomeIcon
                icon="caret-left"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        nextArrow: (
            <FontAwesomeIcon
                icon="caret-right"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        responsive: [
            {
                breakpoint: 1570,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1204,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const { theme } = useContext(ThemeContext);

    return (
        <Wrapper>
            <LightSpeed>
                <Title theme={theme}>Ils nous font confiance</Title>
            </LightSpeed>

            <div
                style={{
                    maxWidth: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    marginBottom: '100px',
                }}
            >
                <Slider className="slider" {...settings}>
                    {allContentfulPartnerFeedback.nodes.map((item, index) => {
                        return (
                            <PartnerCard
                                index={index}
                                key={index}
                                name={item.name}
                                feedback={item.feedback}
                            />
                        );
                    })}
                </Slider>
            </div>
        </Wrapper>
    );
};
export default Partner;
