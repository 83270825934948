import styled from 'styled-components';

export const Input = styled.input`
    width: 100%;
    background-color: transparent;
    /* padding: 10px 5px;
    border: 1px solid #fff; */
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    background-color: transparent; 
    color: #fff;
    &:focus {
        outline: none;
        border: 1px solid #e66d30;
    }
`;
export const TextArea = styled.textarea`
    width: 100%;
    background-color: #f6f6f6;
    padding: 10px 5px;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    min-height: 100px;
    &:focus {
        outline-color: #e66d30;
        outline-width: 1px;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
`;
export const Error = styled.span`
    font-size: 11px;
    color: #fff;
`;
