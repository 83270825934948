import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import ProductLanding from '../../../components/products/landing';
import Ecommerce from '../../../components/products/ecommerce';
import { useStaticQuery, graphql } from 'gatsby';

const EcommercePage = () => {
    const { contentfulLanding } = useStaticQuery(graphql`
        query {
            contentfulLanding(
                templateKey: { eq: "primary-ecommerce-landing" }
            ) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Produits" url={'/products/e-commerce'} />
            <ProductLanding pack="e-commerce" {...contentfulLanding} />
            <Ecommerce />
        </Layout>
    );
};

export default EcommercePage;
