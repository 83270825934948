import React, { useEffect, useState, useContext } from 'react';

import { Row, Col, Modal, notification } from 'antd';
import { TextField } from '../../text-field';
import { http } from '../../http';
import RadioField from '../../common/radio-field';
import '../styles.css';

import {
    emailKeyPress,
} from '../../helpers/only-characters';
import {
    Title,
    Wrapper,
    HeaderCard,
    BodyCard,
    ZoneCard,
    Label,
    EventName,
    Offer,
    Button,
    EventDate, SubTitle, ButtonWrapper, BackButton
} from '../styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../../layout/theme/ThemeContext';
import { any } from 'prop-types';

const marchantId = process.env.NAPS_GATEWAY_MARCHANT;
const apiKey = process.env.API_KEY;
const ticketingUrl = process.env.TICKETING_URL;


export const ReservationForm = ({
    nextStep,
    back,
    isValid,
    getAllEvents,
    setTouched,
    validate,
    values,
    setValue,
}) => {
    const handleNextClick = () => {
        setTouched({
            clientId: true,
            zone: true,
        });
        validate().then((errors) => {
            if (Object.keys(errors).length === 0) {
                let zone = values.event.zones.find((zone) => zone.zone === values.zone);
                values.amount = zone.price;
                setState({
                    ...state,
                    isLoading: true,
                });
                http.post('/card-order/ticketing', values).then((res) => {
                    setValue("orderId", res.data.orderid)
                    setState({
                        ...state,
                        showModal: res.data.status == "success" ? false : true,
                        isLoading: false,
                        data: res.data,
                    });
                    if(res.data.status == "success"){
                        http.post('/card-order/reservation/' + res.data.orderid, values).then((response) => {
                            window.location.href = response.data;
                        });
                    }
                    
                });
            } else {
                if (values.clientId == '' && values.zone == '') {
                    openErrorNotification("Id Client et la zone sont obligatoires");
                } else if (values.clientId == '') {
                    openErrorNotification("Id client est obligatoire");
                } else if (values.zone == '') {
                    openErrorNotification("la zone est obligatoire");
                }
            }
        });

    };
    const openErrorNotification = (message) => {
        notification.open({
            message: 'Erreur',
            description: message,
            onClick: () => {
            },
        });
    };
    const getInfosByCLientId = (clientId) => {
        http.get('/card-order/ticketing/' + clientId).then((res) => {
            setValue("fullName", '')
            if (res.data) {
                setValue("fullName", res.data.holderName)
            }
        });
    }
    const handleBackClick = () => {
        back();
        setValue("clientId","")
        setValue("fullName","")
        setValue("email","")
        setValue("zone","")
        
        setValue('step', 0);
    };
    const buyingTickets = () => {
        if (state.data.status == "success") {
            http.post('/card-order/reservation/' + state.data.orderid, values).then((res) => {
                //   nextStep();
                //    setValue('step', 2);
                window.location.href = res.data;
            });
        } else {
            setState({ ...state, showModal: false })
        }
    }

    const { theme } = useContext(ThemeContext);


    const [state, setState] = useState({
        showModal: false,
        isLoading: false,
        data: '',
    });
    function counter(date) {
        var diff = {}
        var d1 = new Date(date)
        var d2 = new Date()
        var tmp = d1 - d2;

        tmp = Math.floor(tmp / 1000);
        diff.sec = tmp % 60;

        tmp = Math.floor((tmp - diff.sec) / 60);
        diff.min = tmp % 60;

        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = tmp % 24;

        tmp = Math.floor((tmp - diff.hour) / 24);
        diff.day = tmp;

        return diff;
    }
    return (
        <>
            <Row align="middle" gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{ padding: '0px 8px', marginBottom: '8px' }}
                >
                    <Title>Réserver votre place </Title>
                </Col>
            </Row>

            <Wrapper style={{ margin: 'auto' }} theme={theme}>
                <Offer style={{ width: '100%' }}>
                    <HeaderCard style={{ backgroundImage: `url('${values.event.image}')` }}>
                        {/* <EventName>
                                    {values.event.name}
                                </EventName>
                                <p style={{textAlign:'center',  margin:'auto',padding: '0 1rem', width:'50%',fontWeight: '500'}}>{values.event.location}</p> 
                                <p style={{margin:'auto',
                                    fontSize:'1rem',
                                    backgroundColor: '#205993',
                                    color: '#fff',
                                    padding: '0.3rem 1rem'}}>
                                <img
                                    src={whiteHourglass}
                                    style={{display: 'inline', marginRight: '0.5rem'}}
                                />
                                    {counter(values.event.eventdate).day + " : "+ counter(values.event.eventdate).hour + " : "+ counter(values.event.eventdate).min + " : "+ counter(values.event.eventdate).sec}
                                </p>      */}
                    </HeaderCard>
                    <Row align="top" gutter={[16, 24]}>
                        <Col xs={20} sm={20} md={11} lg={8} xl={8} xxl={6}>
                            <TextField
                                name="clientId"
                                placeholder=""
                                label="Id client :"
                                maxLength={8}
                                keyUp={(e) => {
                                    getInfosByCLientId(e.target.value)
                                }}
                                keyPress={(e) => {
                                    if (
                                        e.key === ' ' ||
                                        e.charCode < 48 ||
                                        e.charCode > 57
                                    )
                                        e.preventDefault();
                                }}
                            ></TextField>
                        </Col>
                        <Col xs={20} sm={20} md={11} lg={8} xl={8} xxl={6}>
                            <TextField
                                name="fullName"
                                placeholder=""
                                label="Nom et prénom :"
                                maxLength={8}
                                disabled
                            ></TextField>
                        </Col>
                        <Col xs={20} sm={20} md={11} lg={8} xl={8} xxl={6}>
                            <TextField
                                name="email"
                                placeholder=""
                                label="E-mail"
                                maxLength={60}
                                keyPress={emailKeyPress}
                            ></TextField>
                        </Col>
                    </Row>
                    <p style={{ marginLeft: '0.5rem', fontWeight: '600' }}>Choisir la zone </p>
                    <BodyCard>
                        <ZoneCard>
                            <RadioField
                                values={values.zones}
                                placeholder=""
                                name="zone"
                                flexDirection='column'
                            ></RadioField>
                        </ZoneCard>
                        <ButtonWrapper>
                            <BackButton style={{ marginTop: '2rem', marginRight: '0.5rem' }}
                                type="button"
                                onClick={handleBackClick}
                            >
                                Retour
                            </BackButton>
                            <Button style={{ marginTop: '2rem', marginRight: '0.5rem' }} onClick={handleNextClick} type="button" >
                                Réserver
                            </Button>
                        </ButtonWrapper>
                    </BodyCard>
                </Offer>
            </Wrapper>
            <Modal className='dialog'
                width='350px'
                visible={state.showModal}
                centered
                onCancel={() => {
                    setState({ ...state, showModal: false });
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '7rem',
                        flexDirection: 'column',
                    }}
                >
                    <FontAwesomeIcon
                        icon={state.data.status == "success" ? "check" : "times"}
                        style={{
                            fontSize: '30px',
                            color: state.data.status == "success" ? "#63e6be" : "#FF0000",
                            width: '30px',
                        }}
                    />
                    <Title style={{ textAlign: 'center', fontSize: '20px', margin: 'unset' }}>
                        {state.data.title}
                    </Title>
                    <p style={{ textAlign: 'center', fontSize: '15px' }}>
                        {state.data.message}
                    </p>
                </div>
                <Button style={{ marginRight: '0', marginLeft: 'auto', display: 'block', marginTop: '0.5rem', height: '35px', padding: '0 40px', fontWeight: '500' }}
                    onClick={buyingTickets} type="button" >
                    {state.data.status == "success" ? "Payer" : "Réessayer"}
                </Button>
            </Modal>
        </>
    );
};
