import styled from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 20px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
        max-width: 19rem;
    }
`;
export const Stick = styled.div`
    position: absolut;
    bottom: 0;
`;

export const Button = styled.button`
    border-radius: 5px;
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: none;
    padding: 0.6rem 2rem 0.5rem 2rem;
    color: #fff;
    box-shadow: #e66d30 0px 0px 19px -5px;
    margin-top: 1rem;
    transition: all 1s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e66d30 0px 0px 19px -2px;
    }
`;

export const GoogleButton = styled.button`
    border-radius: 5px;
    background-color: #327caf;
    max-width: 200px;
    font-size: 12px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    margin: auto;
    transition: all 1s ease-out;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: #144a6e;
    }
`;