import styled, { css } from 'styled-components';
export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
`;

export const SecondTitle = styled.h2`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
`;

export const ThirdTitle = styled.h3`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
`;

export const SubTitle = styled.p`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3b;
    font-family: Rajdhani, sans-serif;
    font-weight: 500;
`;
export const Button = styled.button`
    border-radius: 5px;
    background-color: #ffffff;
    min-width: 182px;
    border: none;
    padding: 0.6rem 1.5rem 0.5rem 1.5rem;
    color: #e66d30;
    box-shadow: #000 0px 0px 25px -14px;
    margin-top: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    font-size: 19px;
    position: relative;
    ${({ br }) =>
        br === 'ie' &&
        css`
            width: 231px;
        `}
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #000 0px 0px 20px -14px;
    }
`;
export const ImageWrapper = styled.div`
    width: 100%;
    padding: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
`;

export const AnimatedImage = styled.img`
    max-width: 100%;
    /* width:30rem; */
    @media(max-width: 432px) {
        width: 15rem;
    }
`;

export const PrimaryButton = styled.button`
    border-radius: 5px;
    /* background-color: #e96d25; */
    width: 230px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    box-shadow: #e96d25 0px 0px 54px -14px;
    margin-top: 1rem;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e96d25 0px 0px 20px -14px;
    }
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    font-family: 'Maven Pro', sans-serif;
    width: 100%;
`;
export const StoreButton = styled.button`
    background-color: transparent;
    background-size: contain;
    width: 100px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    box-shadow: #fff 0px 0px 54px -14px;
    margin-top: 1rem;
    height: 28px;
    cursor: pointer;
    background-repeat: no-repeat;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #fff 0px 0px 20px -14px;
    }
`;

export const ButtonIcon = styled.div`
    background-color: #e96d25;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 25px -8px #000;
    position: absolute;
    top: -17px;
    ${({ br }) =>
        br === 'ie' &&
        css`
            left: 100px;
        `}
`;

export const SubTitleWrapper = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3b;
    font-family: Rajdhani, sans-serif;
`;
