import styled from 'styled-components';
export const Wrapper = styled.div`
    display: flex; 
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;

`;

export const SubTitle = styled.div`
    width: 40rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    text-align: center;
    color: '#343131' 
    @media (max-width:801px) {
        font-size: 14px;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    padding: 40px;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    z-index: 2;
    &:focus {
        outline: none;
    }
`;