import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { LoadingOutlined } from '@ant-design/icons';
import SEO from '../../../../components/seo';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentSucceeded = () => {
    const [data, setNum] = useQueryParam('data', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    useEffect(() => {
        
        navigate('/b2c', {state: { step: 5,data:data ,codecmr:codecmr,orderType:"wac"}})
        // http.post('/card-order/paymentticketssucceeded', { orderId: data }).then(
        //     (res) => {
        //         setState({
        //             ...state,
        //             isLoading: false,
        //         });
        //     }
        // );
    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Paiement succès" url={'/wac/b2c/success'} />
        </Layout>
    );
};

export default PaymentSucceeded;
