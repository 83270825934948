import styled, { keyframes } from 'styled-components';
import { Row } from 'antd';
// import { bounceInLeft, zoomInUp, fadeIn, rotateIn } from 'react-animations';

// const bounceInLeftAnimation = keyframes`${bounceInLeft}`;
// const zoomInUpAnimation = keyframes`${zoomInUp}`;
// const fadeAnimation = keyframes`${fadeIn}`;
// const rotateInAnimation = keyframes`${rotateIn}`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
`;

export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: 'inherit';
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2.5rem;
    margin: 0;
    font-weight: bold;
    @media (max-width:801px) {
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }
`;

export const SecendTitle = styled.h2`
    padding: 0;
    margin: 0 0 1.45rem;
    color: 'inherit';
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2.5rem;
    margin: 0;
    font-weight: bold;
    @media (max-width:801px) {
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }
`;


export const TextBold = styled.p`
    padding: 0 0.5rem;
   color: #3c3c3b;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    line-height: 1.1;
    font-size: 0.8rem;
    width: 70%;
    margin: 0;
    font-weight: bold;
`;

export const SubTitle = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    color: #fff;
    @media (max-width:801px) {
        font-size: 14px;
    }
`;


export const Fluidity = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 8rem;
    margin:0.5rem auto;
`;

export const LegendTitle = styled.div`
    fontSize: 15px;
    fontWeight: bold;
    text-align: center;
    padding: 0 80px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    margin-top: 1rem;
    color: #3c3c3b;
    @media (max-width:801px) {
        font-size: 13px;
        display: none;
    }
`

export const Button = styled.button`
    border-radius: 5px;
    /* background-color: #e66d30; */
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    width: 242px;
    border: none;
    padding: 0.6rem 2rem 0.5rem 2rem;
    color: #fff;
    box-shadow: #e66d30 0px 0px 19px -5px;
    margin-top: 1rem;
    transition: all 1s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e66d30 0px 0px 19px -2px;
    }
`;
export const ImageWrapper = styled.div`
    width: 100%;
    padding: 40px;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    z-index: 2;
    &:focus {
        outline: none;
    }
`;

export const Cell = styled.div`
    width: 40%;
    display: flex;
    margin:0 1.5rem;
    justify-content: start;
    align-items: center;

`;

export const AnimatedImage = styled.img`
    max-width: 100%;
    cursor: pointer;

    z-index: 1;
`;

export const ButtonIcon = styled.div`
    background-color: #fff;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    margin-top: -25px;
`;
export const LandingRow = styled(Row)`
    min-height: calc(75vh);
    align-items: center;
    /* background-color: #f6f6f6; */
    height: auto;
    /* clip-path: polygon(0% 14%, 100% 0px, 100% 84%, 0px 100%); */
    /* background: ${({ theme }) =>
        theme === 'light'
            ? `linear-gradient(to bottom left, #f6f6f6 49.8%, #f6f6f6 32%) top /
            100% 34.1%,
        linear-gradient(to top left, transparent 49.8%, #f6f6f6 50%) bottom/100%
            66%,
        center/100% 30%`
            : `linear-gradient(to bottom left, #212121 49.8%, #212121 32%) top /
            100% 34.1%,
        linear-gradient(to top left, transparent 49.8%, #212121 50%) bottom/100%
            66%,
        center/100% 30%`}; */
    /* background:   ; */
    /* background: linear-gradient(to bottom left,rgb(33, 33, 33)41.8%,rgb(33, 33, 33) 32%) top   /100% 34.1%,
     linear-gradient(to top left,transparent 49.8%,rgb(33, 33, 33) 50%) bottom/100% 66%,
      center/100% 47%; */
    background-repeat: no-repeat;
    padding: 0 2rem;
    padding-top: 130px;
    padding-bottom: 50px;
    margin-bottom: 20px;
    z-index: 2;
    @media (max-width: 1193px) {
        padding-top: 5px !important;
    }
`;
