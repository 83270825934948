import React, { Component } from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import {BrowserRouter as Router,Link,Route} from 'react-router-dom'
import BlogsArticle from '../../../components/blogs/blogs-article';

const BlogArticle = props => {
      console.log("hprop")
      console.log(props)
        return (
            <Layout>
                {/* <SEO title="Blog" url={'/blog/blog-article'}/> */}
                 <div style={{ padding: '150px 0' }}>
                    <BlogsArticle blogId={props.pageContext.id}/>
                </div>
            </Layout>
        );
    }
export default BlogArticle;
