import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { SubTitle, Wrapper,ImageWrapper} from './styles';
import { useStaticQuery, graphql } from 'gatsby';

import './styles.css';
import OrderCard from '../../common/order-card';
import { StaticImage} from 'gatsby-plugin-image';
const WydadiOrder = () => {
    const [state,] = useState({
        orderType: 'wac',
        accountType: '',
    });
    const data = useStaticQuery(graphql`
        query {
            allContentfulOffer(sort: { fields: order, order: ASC }) {
                nodes {
                    accountType
                    price
                    priceString
                    offers {
                        name
                    }
                }
            }
        }
    `);
    return (
        <Wrapper
            id="order"
            style={{ display: 'flex', justifyContent: 'center' }}
            className="container mx-auto"
        >
            <Row justify="center">
            <ImageWrapper
                style={{
                    maxWidth: '100%',
                    paddingTop: '5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}
            >
                <StaticImage
                    className='animated-img'
                    style={{
                        // boxShadow: '#00000020 0px 0px 19px -2px',
                        width: '18rem',
                        height: '100%'
                    }}
                    formats={["auto", "webp"]}
                    placeholder='blurred'
                    src="../../../images/slide/Carte-Wydad.webp"
                    alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                    width='350'
                />
            </ImageWrapper>
            <SubTitle theme={'dark'}>
                        Intégrez sans plus attendre la famille des supporters Wydadis en vous dotant de la carte d’abonnement du Wydad Athletic Club.
                        <p style={{ marginBottom: 'unset' }}>Fini les longues files d’attente, la carte WAC vous permet d’accéder aux matchs en toute sérénité !</p>
            </SubTitle>
            </Row>
            <Row justify="center">
                {data.allContentfulOffer.nodes.map((offer, index) => {
                    return (
                        !(
                            (state.orderType === 'wac' &&
                            (offer.accountType === 'Express' ||
                                offer.accountType === 'Confort' || 
                                offer.accountType === 'Confort +')
                            )||
                            (state.orderType === 'individual' &&
                            (offer.accountType === 'TRIBUNE' ||
                                offer.accountType === 'PELOUSE')
                            )||
                            (
                                state.orderType === 'casawe' &&
                                (offer.accountType === 'Express' ||
                                offer.accountType === 'TRIBUNE' ||
                                offer.accountType === 'PELOUSE')
                            
                        )) && (
                            <Col
                                key={index}
                                className="justify-center"
                                xxl={6}
                                xl={8}
                                lg={10}
                                md={12}
                                sm={24}
                                xs={24}
                            >
                                <OrderCard
                                    orderType={state.orderType}
                                    accountType={offer.accountType}
                                    price={offer.priceString}
                                    offers={offer.offers}
                                    pack={state.orderType}
                                />
                            </Col>
                        )
                    );
                })}
            </Row>
        </Wrapper>
    );
};

export default WydadiOrder;
