import React from 'react';
import { useField } from 'formik';
import { SelectField, Label, Error } from './select.styles';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
export const Select = ({ label, placeholder, data, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const options = data && data.map((option,i) => <option key={i} value={option.value} > {option.label} </option>)
    
    const { theme } = useContext(ThemeContext);

    return (
        <div style={{padding: '5px'}}>
            <Label theme={theme}> { label } </Label>
            <SelectField
                placeholder={placeholder}
                {...field}
                helperText={errorText}
                error={!!errorText}
            >
                <option value="" ></option>
                {options}
            </SelectField>
            <Error>{errorText}</Error>
            
        </div>
    );
};
