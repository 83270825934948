import React, { useState, useEffect,useRef } from 'react';
import { SecondTitle, ThirdTitle,SubTitle } from '../../common/SimpleRow/styles';
import { Wrapper, InfoWrapper, Stick, Info,GoogleButton } from './styles';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Input, AutoComplete } from 'antd';
import { http } from '../../../components/http';
import './styles.css';
import { detect } from 'detect-browser';
import { mapValues, groupBy } from 'lodash';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { StaticImage } from 'gatsby-plugin-image';
import marker from '../../../images/marker.png';
import markerLocation from '../../../images/marker_location.png';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import useGeoLocation from "../../hooks/useGeoLocation";
import osmProviders from '../../helpers/osm-providers';

var markerIcon;
var markerLocationIcon;
if (typeof window !== 'undefined') {
   markerIcon = new L.Icon({
    iconUrl: marker,
    iconSize: [30, 30]
  });

   markerLocationIcon = new L.Icon({
    iconUrl: markerLocation,
    iconSize: [25, 20]
  }); 
}

const ManualOrder = () => {
    const [center,] = useState({ lat: 32.5722875, lng: -7.5869925});
    const ZOOM_LEVEL = 6;
    const ZOOM_LOCATION_LEVEL = 13;
    const mapRef = useRef();
    const location = useGeoLocation();
    const isBrowser = typeof window !== "undefined"
 
    
    const [state, setState] = useState({
        options: [],
        selectedAdresse: null,
        br: '',
    });

    const [agencyState, setAgencyState] = useState({
        agencies: [],
    });
    const {
        napsInShort,
        contentfulManualOrder,
    } = useStaticQuery(graphql`
        query {
            contentfulManualOrder {
                email
                phone
                image {
                    file {
                        url
                    }
                }
                icon {
                    file {
                        url
                    }
                }
            }
            napsInShort: contentfulLanding(
                templateKey: { eq: "naps-in-short" }
            ) {
                title
                description {
                raw
                }
                image {
                    file {
                        url
                    }
                }
            }
            mastercard: file(relativePath: { eq: "mastercard.svg" }) {
                publicURL
            }
            visa: file(relativePath: { eq: "visa.svg" }) {
                publicURL
            }
        }
    `);
    useEffect(() => {
        const br = detect();
        http.get('/agency').then((res) => {
            setAgencyState({
                agencies: res.data,
            });
            const agences = res.data;
            const grouped = mapValues(
                groupBy(agences, 'city'),
            );

            const options = Object.keys(grouped).map((val) => {
                return {
                    label: val,
                    options: grouped[val].map((agence, index) => {
                        return {
                            value: agence.agency + ' - ' + agence.address,
                            label: (
                                <p style={{ color: '#212121' }} key={val + index}>
                                    {agence.agency + ' - ' + agence.address}
                                </p>
                            ),
                            adresse: agence.address,
                            lat: agence.gps_L,
                            lng: agence.gps_I
                        };
                    }),

                };
            });
            setState({
                ...state,
                options: options,
                br: br?.name,
            });
            

            if (location.loaded && !location.error) {
                mapRef.current.leafletElement.flyTo(
                  [location.coordinates.lat, location.coordinates.lng],
                  ZOOM_LOCATION_LEVEL,
                  { animate: true }
                );
              } else {
                mapRef.current.leafletElement.flyTo(
                    [32.5722875,-7.5869925],
                    ZOOM_LEVEL,
                    { animate: true }
                  );
              }
        })
    }, [location.loaded]);
    const handleAdresseSelect = (_, option) => {
        mapRef.current.leafletElement.flyTo(
            [option.lat, option.lng],
            ZOOM_LOCATION_LEVEL,
            { animate: true }
          );
    };
    const openOnGoogleMap = (agancy) => {
        if (location.loaded && !location.error) {
            window.open('https://www.google.com/maps/dir/'+location.coordinates.lat+',' +location.coordinates.lng+'/' +agancy.gps_L+',' +agancy.gps_I , '_blank');
          } else {
            window.open('https://www.google.com/maps?q='+agancy.gps_L+',' +agancy.gps_I+'&z=17&hl=fr' , '_blank');
          }
    };
    const NearestAgencies = () => {
        if (location.loaded && !location.error) {
          mapRef.current.leafletElement.flyTo(
            [location.coordinates.lat, location.coordinates.lng],
            ZOOM_LOCATION_LEVEL,
            { animate: true }
          );
        } else {
          alert(location.error.message);
        }
      };
    return (
        <Wrapper className="container mx-auto">
            <SecondTitle style={{ color: '#eb6807' }}>
                NAPS EN BREF
            </SecondTitle>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-3 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" >
                <div
                    className="justify-center"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <InfoWrapper
                        style={{
                            borderRight: `2px solid #fff`,
                            padding: '10px',
                            backgroundColor: `#f3f3f3`,
                            borderRadius: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <SubTitle style={{ color: '#000' }}>
                            {documentToReactComponents(JSON.parse(
                                napsInShort.description.raw)
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    WebkitAlignItems: 'center',
                                }}
                            >
                                NAPS est membre de :
                                <StaticImage
                                    alt="Mastercard"
                                    formats={["auto", "webp"]}
                                    style={{
                                        width: '35px',
                                        height: '100%',
                                        margin: '0 0 0 10px',
                                    }}
                                    src='../../../images/mastercard.png'
                                    width="35"
                                ></StaticImage>
                                <StaticImage
                                    formats={["auto", "webp"]}
                                    alt="Visa"
                                    style={{
                                        width: '35px',
                                        height: '100%',
                                        margin: '0 0 0 10px',
                                    }}
                                    src='../../../images/visa.png'
                                    width="35"
                                ></StaticImage>
                            </div>
                        </SubTitle>
                    </InfoWrapper>
                </div>
                <div
                    className="justify-center"

                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <InfoWrapper
                        style={{
                            borderRight: `2px solid #fff`,
                            padding: '15px',
                            backgroundColor: ` #f3f3f3`,
                            borderRadius: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <ThirdTitle

                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                margin: '10px 0 10px 0',
                            }}
                        >
                            Trouver une agence
                        </ThirdTitle>
                        <Info style={{
                                width: '80%',
                                padding: '8px 0px',
                                margin: '20px auto',
                            }}
                                    onClick={
                                        NearestAgencies
                                    }
                                >
                                    Agences autour de moi
                                </Info>
                        <AutoComplete
                            style={{
                                width: '100%',
                                border: 'none',
                            }}
                            options={state.options}
                            filterOption={(inputValue, option) => {
                                const label =
                                    typeof option.value === 'string'
                                        ? option.value
                                        : '';
                                return (
                                    label &&
                                    label
                                        .toUpperCase()
                                        .indexOf(inputValue?.toUpperCase()) !==
                                    -1
                                );
                            }}
                            className="input"
                            onSelect={handleAdresseSelect}
                            dropdownMatchSelectWidth={700}
                        >
                            <Input.Search
                                size="large"
                                placeholder="Recherche d'agence par ville"
                                style={{ color: '#212121', width: '100%' }}
                            />
                        </AutoComplete>
                        {state.selectedAdresse}
                        <div  style={{ marginTop: '20px', zIndex: '0' }} >
        {isBrowser && <Map center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                            <TileLayer
                                url={osmProviders.maptiler.url} 
                                attribution={osmProviders.maptiler.attribution}
                            />
                            {location.loaded && !location.error && (
                                    <Marker
                                    icon={markerLocationIcon}
                                    position={[
                                        location.coordinates.lat,
                                        location.coordinates.lng,
                                    ]}>
                                    <Popup>
                                        <b>
                                        Votre position
                                        </b>
                                    </Popup>
                                    </Marker>
                                )}
                            {agencyState.agencies.map((agancy, idx) => (
                                <Marker
                                position={[agancy.gps_L,agancy.gps_I]}
                                icon={markerIcon}
                                key={idx}
                                >
                                <Popup>
                                <div
                                style={{
                                    display: 'flex',
                                    WebkitAlignItems: 'center',
                                    margin: '0.5em 0'
                                }}
                                      >
                                    <StaticImage
                                        alt="Naps"
                                        formats={["auto", "webp"]}
                                        style={{
                                            width: '70px',
                                            height: '100%',
                                        }}
                                        src='../../../images/logo.webp'>
                                    </StaticImage>
                                    <ThirdTitle  style={{
                                            fontSize: '18px',
                                            margin: '0 0 0 15px',
                                        }}
                                    >{agancy.agency}</ThirdTitle>
                                </div>
                                    <p style={{display: 'flex',
                                            WebkitAlignItems: 'center',
                                            margin: '0.5em 0'}}>
                                    - {agancy.address}
                                    </p>
                                    <p style={{margin: '0.5em 0'}}>
                                    - {agancy.district}
                                    </p>
                                    <p style={{margin: '0.5em 0'}}>
                                    - {agancy.city}
                                    </p>
                                    <GoogleButton  onClick={() => openOnGoogleMap(agancy)}>
                                            Ouvrir dans google map
                                    </GoogleButton>
                                </Popup>
                                </Marker>
                            ))}
                        </Map>}
                            {/* <StaticImage width={220} formats={["auto", "webp"]} placeholder='blurred' src='../../../images/card.inline.png' alt='naps en bref' style={{ padding: '50px' }} /> */}
                        </div>
                    </InfoWrapper>
                </div>
                <div
                    className="justify-center"

                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <InfoWrapper
                        style={{
                            borderRight: `2px solid #fff`,
                            padding: '30px',
                            backgroundColor: `#f3f3f3`,
                            borderRadius: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <ThirdTitle

                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                margin: '10px 0 30px 0',
                            }}
                        >
                            Commander à distance
                        </ThirdTitle>
                        <Stick>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <ThirdTitle
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        fontSize: '1.5rem',
                                    }}

                                >
                                    En ligne
                                </ThirdTitle>
                                <Info
                                    onClick={() =>
                                        navigate('/individual/#order')
                                    }
                                >
                                    Je commande maintenant
                                </Info>
                                <ThirdTitle
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        fontSize: '1.5rem',
                                    }}

                                >
                                    Ou appeler le
                                </ThirdTitle>
                                <Info>{contentfulManualOrder.phone}</Info>
                            </div>
                        </Stick>
                    </InfoWrapper>
                </div>
            </div>
        </Wrapper>
    );
};
export default ManualOrder;
