import axios from 'axios';

export const http = axios.create({

        baseURL: 'https://naps.ma/api',
        /*baseURL: 'http://localhost:8080/api',*/
     
        
        
        //baseURL: 'http://172.18.37.20:8085/api',
        //baseURL: 'https://10.2.10.28/api',
        //baseURL: 'http://192.168.1.109:8090/api',
});
