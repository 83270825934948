import React from 'react';
import SimpleRow from '../../../common/SimpleRow';
import { useStaticQuery, graphql } from 'gatsby';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ReceivePayment = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "recive-payment" }) {
                id
                title
                description {
                    raw
                  }
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <SimpleRow
            title={data.contentfulLanding.title}
            subTitle={documentToReactComponents(JSON.parse(
                data.contentfulLanding.description.raw)
            )}
            imageUrl={data.contentfulLanding.image.file.url}
            buttonText="En savoir plus"
            state="left"
            id="mets"
            link="/pro/entreprise"
            buttonsToLeft={true}
        />
    );
};

export default ReceivePayment;
