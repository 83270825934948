import React from 'react';
import Presentation from './presentation';
import Advantage from './advantage';
import { useStaticQuery, graphql } from 'gatsby';
import Offer from '../entreprise/offer';

const Institution = () => {
    const { contentfulCampusOffer } = useStaticQuery(graphql`
        query {
            contentfulCampusOffer {
                title
                leftImage {
                    file {
                        url
                    }
                }
                rightImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    
    return (
        <>
            <Presentation />
            <Offer
                title={contentfulCampusOffer?.title}
                leftPicture={contentfulCampusOffer?.leftImage?.file?.url}
                rightPicture={contentfulCampusOffer?.rightImage?.file?.url}
            />
            <Advantage />
        </>
    );
};
export default Institution;
