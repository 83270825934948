import styled from 'styled-components';
import { Row } from 'antd';


export const ArticleFeatured = styled(Row)`
    align-items: center;
    height: auto;
    z-index: 2;

`;

export const ArticleRow = styled(Row)`
    display: flex;
    height: auto;
    z-index: 2;

`;

export const GridWrapper = styled(Row)`
    display: grid !important;
    padding: 10px;
    height: auto;
    z-index: 2;
    width: 100%;
    justify-content: center;

`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 33rem;
    overflow: auto;
    
`;

export const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: 12rem;
    background-image: linear-gradient(
          rgba(0, 0, 0, 0.6), 
          rgba(0, 0, 0, 0.6)
        ), ${({ background }) => `url(${background})`} ; 
    background-size: cover;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: top;
    
`;


export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
    @media (max-width:801px) {
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }
`;

export const SubTitle = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    color: #3c3c3b;
    @media (max-width:801px) {
        font-size: 14px;
    }
`;

export const Badge = styled.button`
    background-color: #f5f8fa;
    border-radius: 0.1875rem;
    box-shadow: 0 1px 5px 0 #cbd6e2;
    padding: 0.5em 1em 0.5em 1em; 
    width: auto;
    font-size: 1em;
    font-weight: 700;
    color: inherit;
    position: relative;
    display: inline-block;
    border-left: 5px solid #ec7032;
    cursor: pointer;
`;


export const Button = styled.button`
    border-radius: 2px;
    background-color: #e66d2f;
    width:fit-content;
    margin-top: 2rem;
    margin-left:auto;
    padding: 0.3rem 1.5rem;
    color: #fff;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    font-size: 15px;
    position: relative;
`;
