import React from 'react'
import Layout from '../../layout'
import Landing from './landing';
import AcceptPayment from './accept-payment';
import ReceivePayment from './receive-payment';
import Partner from './partner';

const B2b = () => {
    return (
        <Layout notTransparent={true}>
            <Landing />
            <AcceptPayment />
            <ReceivePayment />
            {/*<Partner />*/}
            {/* <PartnerSlide /> */}
        </Layout>
    )
}

export default B2b;
