import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { http } from '../../../components/http';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'



import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
   
  } from "react-share";

import './styles.css';

import ThemeContext from '../../layout/theme/ThemeContext';
import {
    ArticleRow,
    InfoWrapper,
    Title,
    SecTitle,
    ArticleHeader,
    ImageWrapper,
    SimpleText,
    Badge,
    SubTitle,
    CorpsText,
    GridWrapper,
} from './styles';
import { Col } from 'antd';
    
    const BlogsArticle= ({ blogId }) => {
        const { blogs } = useStaticQuery(graphql`
        query {
            blogs: allContentfulBlogPost(sort: {fields: publishDate, order: DESC}) {
                nodes {
                id
                title
                slug
                publishDate(formatString: "DD/MM/yyyy")
                heroImage {
                    description
                    file {
                    url
                    }
                }
                body {
                    raw
                    references {
                        ... on ContentfulAsset {
                          contentful_id
                          __typename
                          title
                          url
                        }
                      }        
                  }
                description {
                    description
                }
                category
                categoryUrl
                featured
                selection
                metaTitle
                metaDescription
                }
            }
    
            }
        `);

    const { theme } = useContext(ThemeContext);
    const blog = blogs.nodes?.filter((e) => e.id === blogId)[0]

    const richTextImages = {};

    const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        console.log(uri)

        if(uri?.toString().startsWith("keyid=")){
            const id = uri?.toString().replace('keyid=','');
            return (
                <span style={{scrollMarginTop: '6rem'}} id={id}>
                {children}
                </span>
            )      
        }else 
        if(uri?.toString().startsWith("#")){
            return (
                <a href={uri} style={{color:'rgb(0, 89, 200,0.87)'}}>
                {children}
                </a>
            )      
        }else {
            return (
                <a href={uri} target="_blank" style={{color:'rgb(0, 89, 200,0.87)'}}>
                {children}
                </a>
            )
        }
        },
        [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 style={{fontSize: '2em'}}>{children}</h1>
        },
        [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 style={{fontSize: '1.8em'}}>{children}</h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 style={{fontSize: '1.5em'}}>{children}</h3>
        },
        [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 style={{fontSize: '1em'}}>{children}</h4>
        },
        [BLOCKS.EMBEDDED_ASSET]: (node,children) => {
            const imageUrl = blog.body.references.filter((e) => e.contentful_id === node.data.target.sys.id)[0]?.url
            console.log(imageUrl);
            console.log(node.data.target.sys.id)
            return  <div><img style={{margin: '1.5rem auto'}} src= {imageUrl} /></div>
        },
        [BLOCKS.UL_LIST]: (node, children) => (
            <ul style={{listStyle: 'unset'}}>{children}</ul>
          ),
          [BLOCKS.OL_LIST]: (node, children) => (
            <ol style={{listStyle: 'auto'}}>{children}</ol>
          ),
    },
    
    }
 
    function copy() {
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

    const [state, setState] = useState({
        shareUrl:'',
    });
      
    useEffect(() => {
        if (typeof window !== `undefined`){
            setState({
                ...state,
                shareUrl:window.location.href
            });
        }
        fetch('https://api.ipify.org?format=jsonp?callback=?', {
            method: 'GET',
            headers: {},
          })
          .then(res => {
            return res.text()
          }).then(ip => {
            http.post(`/blogviews/save`, { addressIp: ip, blogSlug: blog.slug,blogId:blog.id });
          });
    }, []);

    return (
        <>
        <Helmet>
        <title>{blog?.title}</title>
        <meta property="og:title" content={blog?.metaTitle} />
        <meta name="description"content={blog?.metaDescription} />
        </Helmet>
        <div className="blog-article">
            <ArticleHeader theme={theme} justify="center">
                <div className='frame-width'>
                    <Title style={{fontWeight:'bold',marginBottom: '0',fontSize:'2rem'}}>{blog?.title}</Title>
                    <Badge style={{color:'#000',cursor: 'auto'}}>{blog?.category}</Badge>
                    <SubTitle style={{display:'flex',fontSize:'1rem', margin:'0.5rem 0'}} >{blog?.publishDate}</SubTitle>
                </div>
                <ImageWrapper className='frame-width' style={{margin: '1rem',height:'25rem'}} background=
                                {blog?.heroImage?.file?.url} >
                </ImageWrapper>

            </ArticleHeader>
            <ArticleRow theme={theme} justify="center">
                    <InfoWrapper className='frame-width'>
                        <CorpsText style={{width:'65%'}}>{documentToReactComponents(JSON.parse(blog?.body?.raw),options)}</CorpsText>
                    </InfoWrapper>
            </ArticleRow>
            {/* <ArticleRow theme={theme} justify="center">


                <div class="about-authorth">
                    <img src={blog?.author?.image.file?.url}/>
                    <h2>{blog?.author?.name} <br/><span>{blog?.author?.title}</span></h2>
                </div>
            </ArticleRow> */}
            <ArticleRow theme={theme} justify="center">
                <InfoWrapper className='frame-width'>
                    <SecTitle style={{paddingTop: '1rem',paddingLeft: '1rem',margin: '0 auto'}}>Partagez cet article</SecTitle>
                    <div style={{display:'flex',margin: '0 auto'}}>
                        <div style={{display:'flex', flexDirection: 'column',margin: '1rem 0.5rem'}}>
                            <FacebookShareButton url={state.shareUrl}>
                                <button style={{background: '#3b5998'}} className="btn btn-sm btn-share">
                                    <i className="fa fa-facebook" /> <span>Facebook</span>
                                </button>
                            </FacebookShareButton>
                        </div>
                        <div style={{display:'flex', flexDirection: 'column',margin: '1rem 0.5rem'}}>
                            <TwitterShareButton url={state.shareUrl}>
                                 <button style={{background: '#00acee'}} className="btn btn-sm btn-share">
                                    <i className="fa fa-twitter-square" /> <span>Twitter</span>
                                </button>
                            </TwitterShareButton>
                        </div>
                        <div style={{display:'flex', flexDirection: 'column',margin: '1rem 0.5rem'}}>
                            <EmailShareButton url={state.shareUrl}>
                                <button style={{background: '#dd4b39'}} className="btn btn-sm btn-share">
                                    <i class="fa fa-envelope"></i> <span>Email</span>
                                </button>
                            </EmailShareButton>
                        </div>
                        <div style={{display:'flex', flexDirection: 'column',margin: '1rem 0.5rem'}}>
                            <button onClick={copy} style={{background: 'rgb(60 77 170)'}} className="btn btn-sm btn-share">
                                    <i class="fa fa-link"></i> <span>Copier le lien</span>
                            </button>
                        </div>

                    </div>
                </InfoWrapper>

            </ArticleRow>
            <ArticleRow theme={theme} justify="center">
                <InfoWrapper className='frame-width'>
                <SecTitle style={{paddingTop: '1rem',paddingLeft: '1rem',margin: '0 auto'}}>Nos derniers articles</SecTitle>
                    <GridWrapper className='template-columns'>
                        {blogs?.nodes?.slice(0, 3).map((item, index) => {
                            return (
                            <div style={{height: '350px', width: '300px'}} onClick={()=>  navigate(`/blog/${item.categoryUrl}/${item.slug}`)} key={index} className='card'>
                                <img className="card-image" src={item.heroImage.file.url}/>
                                {/* <div className="card-description-hidden">
                                    <span className="text-title">{item.title}</span>
                                </div> */}
                                <div className="card-description">
                                    <span className="text-title">{item.title}</span>
                                    <span style={{color:'#e36525'}}className="text-body"><span>&bull;</span> {item.category}</span>
                                    <span style={{display: '-webkit-box', webkitLineClamp: '2', lineClamp: '2'}} className="short-description">{documentToReactComponents(JSON.parse(item?.body?.raw))}</span>
                                </div>
                            </div>
                                );
                            })}
                    </GridWrapper>
                </InfoWrapper>

            </ArticleRow>
        </div>
        </>
    );
};

export default BlogsArticle;