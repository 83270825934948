import styled from 'styled-components';

export const Title = styled.div`
    padding: 0;
    margin: 0 0 1.45rem;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.3;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : 'rgb(43,42,42)'};
    display: inline;
    /* border-bottom: 5px solid #e66d30; */
    // &::selection {
    //     background-color:#e66d30;
    // }
`;

export const OfferList = styled.ul`
    margin: 30px 0;
    list-style: none;
    padding: 0;
`;
export const Option = styled.li`
    padding: 8px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    background: transparent;
    align-items: center;
`;

export const Button = styled.h1`
    padding: 10px 20px;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : '#383838'} ;
    font-size: 30px;
    font-family: 'Maven Pro', sans-serif;
    /* background: #fff; */
    transition: 0.2s ease-in-out;
    letter-spacing: -0.99px;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
`;

export const SubTitle = styled.p`
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3b;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    max-width: 500px;
    padding: 20px 20px;
    text-align: center;
    background: rgb(255, 255, 255);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.9934348739495799) 45%,
        rgba(255, 255, 255, 0.2819502801120448) 84%
    );
`;

export const Image = styled.img`
    max-width: 60px;
    min-width: 60px;
    background-color: #e66d30;
    padding: 5px;
    border-radius: 10px;
`;
export const OfferText = styled.p`
    padding: 0 0 0 15px;
    margin: 0 0 0 10px;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : '#383838'};
`;
