import React from 'react'
import Landing from './landing';
import Advantage from './advantage';
import Payment from './payment';
import { useStaticQuery, graphql } from 'gatsby';

const Ecommerce = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(
                templateKey: { eq: "product-landing-ecommerce" }
            ) {
                id
                title
                subTitle
                offers {
                    offer_name
                }
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <>
            <Landing data={data} />
            <Advantage />
            <Payment />
        </>
    )
}

export default Ecommerce;