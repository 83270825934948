import React, { useEffect } from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Button, ButtonIcon } from '../common/SimpleRow/styles';
import { useContext } from 'react';
import ThemeContext from '../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { useSpring, animated } from 'react-spring';
import useMeasure from '../common/hooks/useMeasure';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => (
    <p className="align-center" style={{ fontWeight: 100 }}>
        {children}
    </p>
);

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
};
const Detail = ({ jsonContent }) => {
    const [state, setState] = useState({
        showDetail: false,
    });
    const br = detect();
    return (
        <>
            <Button
                onClick={() =>
                    setState({ ...state, showDetail: !state.showDetail })
                }
                style={{ marginBottom: '20px' }}
                br={br?.name}
            >
                {state.showDetail ? 'Lire moins' : 'Lire plus'}
            </Button>
            {jsonContent &&
                state.showDetail &&
                documentToReactComponents(JSON.parse(jsonContent), options)}
        </>
    );
};
const NewsTemplate = () => {
    const props = useSpring({
        config: { duration: 900 },
        from: { display: 'none', opacity: 0, height: '0%', overflow: 'hidden' },
        to: { display: 'block', opacity: 1,  height: '100%', overflow: 'none' },
    });

    const { theme } = useContext(ThemeContext);

    const { allContentfulNews } = useStaticQuery(graphql`
        query NewsQuery {
            allContentfulNews(sort: { fields: order, order: ASC }) {
                nodes {
                    order
                    title
                    position
                    contentHtml {
                    raw
                    }
                    image {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <animated.div
            className="news-wrapper"
            style={{ paddingTop: '100px', ...props }}
        >
            <VerticalTimeline className="news-element">
                {allContentfulNews.nodes.map((item, index) => {
                    return (
                        <VerticalTimelineElement
                            key={index}
                            className="news-element"
                            contentStyle={{
                                fontFamily: 'Maven Pro',
                                fontSize: '20px',
                                textAlign: 'justify',
                                minHeight: '400px',
                                display: 'block',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                flexDirection: 'column',
                                color: theme === 'dark' ? '#fff' : '#000',
                                fontWeight: '100',
                            }}
                            contentArrowStyle={{
                                background: 'transparent',
                            }}
                            date={''}
                            iconStyle={{
                                background: '#e66d30',
                                color: '#fff',
                            }}
                            dateClassName={
                                item.position === 'left'
                                    ? 'news-date'
                                    : 'news-date-right'
                            }
                            icon={
                                <FontAwesomeIcon
                                    icon={['fa', 'calendar-alt']}
                                    style={{
                                        fontSize: '26px',
                                        color: '#fff',
                                    }}
                                />
                            }
                            position={item.position}
                        >
                            <h1
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    fontSize: '26px',
                                    textAlign: 'left',
                                    color: theme === 'dark' ? '#fff' : '#000',
                                    fontWeight: 'bold',
                                }}
                            >
                                {item.title}
                            </h1>
                            <Detail
                                jsonContent={
                                    item.contentHtml && item.contentHtml.json
                                }
                            />
                            <div
                                className={
                                    item.position === 'left'
                                        ? 'image-wrapper'
                                        : 'image-wrapper-right'
                                }
                            >
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '20rem',
                                    }}
                                    src={item.image.file.url}
                                    alt={item.title}
                                />
                            </div>
                        </VerticalTimelineElement>
                    );
                })}
                <VerticalTimelineElement
                    iconStyle={{
                        background: '#e66d30',
                        color: '#fff',
                        position: 'absolute',
                        top: '50px',
                    }}
                ></VerticalTimelineElement>
            </VerticalTimeline>
           
        </animated.div>
    );
};

export default NewsTemplate;
