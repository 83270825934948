import React from 'react';
import { Wrapper, TitleWrapper, Number, Title, Content } from './styles';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { detect } from 'detect-browser';

export const AdvantageCard = ({ number, title, content, image }) => {
    const [state, setState] = useState({
        br: '',
    });
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);
    return (
        <Wrapper br={state.br} theme={theme}>
            <TitleWrapper>
                <Number src={image}></Number>
                <Title theme={theme}>{title}</Title>
            </TitleWrapper>
            <div style={{ padding: '10px' }}>
                <Content theme={theme}>{content}</Content>
            </div>
        </Wrapper>
    );
};
export default AdvantageCard;
