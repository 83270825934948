import React, { Component } from 'react';
import { Row, Layout, Steps } from 'antd';
import { ImageWrapper,BottomImageWrapper } from '../../components/b2c/index.styles';
import { WacOrderForm } from '../../components/form/wac-order-form';
import { WelcomeForm } from '../../components/form/welcom-form';
import { ContactForm } from '../../components/form/contact-form';
import { CongratulationForm } from '../../components/form/congratulation-form';
import { ContractForm } from '../../components/form/contract-forn';
import { OrderForm } from '../../components/form/order-form';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import '../../components/b2c/style.css';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { http } from '../../components/http';
import FamilyForm from '../../components/form/family-form';
import { Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
import { Title } from '../../components/individual/order/styles';
import SEO from '../../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { values } from 'lodash';
import { string } from 'prop-types';

const { Header, Content, Footer, Sider } = Layout;
const { Step } = Steps;
let globalPhoneValue = '';
const Image = () => {
    const { contentfulLogo } = useStaticQuery(graphql`
        query {
            contentfulLogo {
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <img
            src={contentfulLogo.image.file.url}
            alt="naps"
            style={{ maxWidth: '100%' }}
        />
    );
};
const schema = Yup.object().shape({
    step: Yup.number(),
    orderType: Yup.string(),
    civility: Yup.string()
    .oneOf(['M.', 'Mme'], 'Civilité est doit etre Mr ou Mme')
    .when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    operator: Yup.string()
    .oneOf(['IAM', 'INWI','ORANGE'], "l'opérateur est doit etre IAM ou INWI ou ORANGE")
    .when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    firstName: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    lastName: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    adresse: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 1 && orderType != 'wac') ||
        (step === 2 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    city: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 1 && orderType != 'wac') ||
        (step === 2 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    phone: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac'),
        then: Yup.string()
            .matches(
                /(0)([ \-/]*)(6|7)([ \-/]*)(\d[ \-_/]*){8}/,
                'Veuillez indiquer un numéro de téléphone valide'
            )
            .test(
                'check-phone-exist',
                'Ce numéro de téléphone est déjà associé à une carte NAPS. Veuillez utiliser un autre numéro de téléphone pour une nouvelle souscription.',
                async function (value) {
                    globalPhoneValue = value;
                    const res = await http.get(`/card-order/${value}`);
                    /*if (/^\d{10}$/.test(value)) {
                        const resphone = await http.get(`/code-phone/check-phone/${value}`);
                    }*/
                    return !(res.data === 'FOUND');
                }
            )
            .required(
                'Ce champ est obligatoire. Veuillez le renseigner pour poursuivre la souscription'
            ),
    }).when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 1 && orderType == 'wac'),
        then: Yup.string()
            .matches(
                /(0)([ \-/]*)(6|7)([ \-/]*)(\d[ \-_/]*){8}/,
                'Saisie incorrecte. Utilisez un format compatible avec ce champs.'
            )
            .required(
                'Ce champ est obligatoire. Veuillez le renseigner pour poursuivre la souscription'
            ),
    }),
    confirmPhone: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string()
            .matches(
                /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
                'Veuillez indiquer un numéro de téléphone valide'
            )
            .oneOf(
                [Yup.ref('phone'), null],
                'Les deux numéros de téléphone saisis ne sont pas identiques.'
            )
            .required('Ce champ est obligatoire'),
    }),
    email: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string()
            .email('Cet email est déjà associé à une carte NAPS. Veuillez utiliser un autre email pour une nouvelle souscription.')
            .required('Ce champ est obligatoire'),
    }),
    confirmEmail: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType != 'wac') ||
        (step === 1 && orderType == 'wac'),
        then: Yup.string()
            .oneOf(
                [Yup.ref('email'), null],
                'Les deux e-mails saisis ne sont pas identiques'
            )
            .required('Ce champ est obligatoire'),
    }),
    identityType: Yup.string().when(['step', 'accountType','orderType'], {
        is: (step, accountType,orderType) => 
        ((step === 1 && orderType != 'wac') ||
        (step === 2 && orderType == 'wac')) && justificationAccountType.includes(accountType),
        then: Yup.string().required('Ce champ est obligatoire'),
    }), 
    codeverif: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
            (step === 1 && orderType !== 'wac') || (step === 2 && orderType === 'wac'),
        then: Yup.string()
            .test(
                'check-codeverif',
                'Le code fourni est incorrect',
                async function (value) {
                    //console.log('phone: ',globalPhoneValue);
                    if (/^\d{4}$/.test(value)) {                        
                        const res = await http.get(`/code-phone/check-codeverif/${value}/${globalPhoneValue}`);
                        if (res.data === 'CODEEXPERE') {
                            throw new Yup.ValidationError('Le code fourni est expiré', value, this.path);
                        }
                        return res.data === 'CORRECT';
                    }
                }
            )
            .required('Ce champ est obligatoire'),
    }),
    
    // identityNumber: Yup.string().when(['step', 'accountType'], {
    //     is: (step, accountType) =>
    //         step === 1 && justificationAccountType.includes(accountType),
    //     then: Yup.string().required('Ce champ est dobligatoire'),
    // }),
    idClient: Yup.string().when(['step', 'fromMobile'], {
        is: (step, fromMobile) =>
             (step === 0 && fromMobile),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    fourLastCardDigits: Yup.string().when(['step', 'fromMobile'], {
        is: (step, fromMobile) =>
             (step === 0 && fromMobile),
        then: Yup.string().test(
            'check-card-exist',
            'Impossible de trouver une carte correspondante. Veuillez vérifier votre identifiant client ou les 4 derniers chiffres de votre carte',
            async function (value) {
                const res = await http.post(`/card-order/card-number`, { fourLastCardDigits: value, idClient: this?.parent?.idClient });
                return res.data.found;
            }).required('Ce champ est obligatoire')
    }),
    identityNumber: Yup.string().when(['step','identityType','accountType','orderType'], {
        is: (step ,identityType,accountType,orderType) =>
            (step === 1 && orderType != 'wac') && justificationAccountType.includes(accountType) && identityType ==='CIN',
        then: Yup.string().test(
            'check-cine-exist',
            'CINE est déjà associé à une carte NAPS. Veuillez utiliser un autre numéro de CINE pour une nouvelle souscription.',
            async function (value) {
                const res = await http.post(`/card-order/identity-number`, { identityNumber: value, identityType: 'CIN' });
                return !(res.data === 'FOUND');
            }
        ).required('Ce champ est obligatoire'),
    }).when(['step','identityType', 'accountType','orderType'], {
        is: (step ,identityType,accountType,orderType) =>
        (step === 1 && orderType != 'wac') && justificationAccountType.includes(accountType) && identityType ==='Passeport',
    then: Yup.string().test(
        'check-passeport-exist',
        'Passeport est déjà associé à une carte NAPS. Veuillez utiliser un autre passeport pour une nouvelle souscription.',
        async function (value) {
            const res = await http.post(`/card-order/identity-number`, { identityNumber: value, identityType: 'Passeport' });
            return !(res.data === 'FOUND');
        }
    ).required('Ce champ est obligatoire'),
    }).when(['step','identityType', 'accountType','orderType'], {
        is: (step ,identityType,accountType,orderType) =>
        (step === 1 && orderType != 'wac') && justificationAccountType.includes(accountType) && identityType ==='Carte de séjour',
    then: Yup.string().test(
        'check-carte-sejour-exist',
        'Carte de séjour est déjà associé à une carte NAPS. Veuillez utiliser un autre carte de séjour pour une nouvelle souscription.',
        async function (value) {
            const res = await http.post(`/card-order/identity-number`, { identityNumber: value, identityType: 'Carte de séjour' });
            return !(res.data === 'FOUND');
        }
    ).required('Ce champ est obligatoire'),
    }).when(['step','identityType','accountType','orderType'], {
        is: (step ,identityType,accountType,orderType) =>
            (step === 2 && orderType == 'wac') && justificationAccountType.includes(accountType) && (identityType ==='Carte de séjour' || identityType ==='Passeport' || identityType ==='CIN'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    deliveryDate: Yup.date().when(['step', 'accountType','orderType'], {
        is: (step, accountType) =>
            step === 1 && justificationAccountType.includes(accountType),
        then: Yup.date().required('Ce champ est obligatoire'),
    }),
    useHomeAdresse: Yup.string(),
    deliveryAdresse: Yup.string().when(
        ['step','useHomeAdresse', 'orderType'],
        {
            is: (step,useHomeAdresse,orderType) =>
                (useHomeAdresse=='false' && step === 2 && orderType !== 'wac') ||
                (useHomeAdresse=='false' && step === 3 && orderType === 'wac'),
            then: Yup.string().required('Ce champ est obligatoire'),
        }
    ),
    deliveryCity: Yup.string().when(['step', 'useHomeAdresse', 'orderType'], {
        is: (step, useHomeAdresse, orderType) =>
            (useHomeAdresse =='false' && step === 2 && orderType !== 'wac') ||
            (useHomeAdresse =='false'&& step === 3 && orderType === 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    initialRecharge: Yup.boolean(),
    initialRechargeAmount: Yup.number().when(
        ['step', 'initialRecharge', 'orderType'],
        {
            is: (step, initialRecharge) =>
                initialRecharge && step === 1,
            then: Yup.number()
                .required(
                    'Veuillez choisir le montant de recharge initiale pour cette carte.'
                )
                .min(
                    1,
                    'Merci de préciser le montant de la recharge initiale que vous souhaitez effectuer.'
                ),
        }
    ),
    paymentType: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) =>
            (step === 3 && orderType !== 'family') ||
            (step === 4 && orderType === 'family'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),
    // terms: Yup.boolean().when(['step', 'orderType'], {
    //     is: (step, orderType) =>
    //         (step === 3 && orderType != 'family') ||
    //         (step === 4 && orderType == 'family'),
    //     then: Yup.boolean()
    //         .required('Ce champ est obligatoire')
    //         .oneOf([true, null], 'Veuillez accepter les termes et conditions'),
    // }),
    // agency: Yup.string().when(['step','state.selectedAdresse'], {
    //     is: (step, selectedAdresse) => (step === 3 && selectedAdresse == null),
    //     then: Yup.string().required('Veuillez sélectionner votre agence de livraison'),
    // }),

    orderPlace: Yup.string().when(['step', 'orderType'], {
        is: (step, orderType) => step === 2 && orderType === 'family',
        then: Yup.string()
            .required('Ce champ est obligatoire')
            .oneOf(['online', 'inplace'], 'Ce champ est obligatoire'),
    }),
    family: Yup.array().when(['step', 'orderType'], {
        is: (step, orderType) => step === 2 && orderType === 'family',
        then: Yup.array().of(
            Yup.object({
                civility: Yup.string()
                    .oneOf(
                        ['M.', 'Mme', 'Mlle'],
                        'Civilité est doit etre Mr ou Mmd'
                    )
                    .required('Ce champ est obligatoire'),

                firstName: Yup.string().required('Ce champ est obligatoire'),
                lastName: Yup.string().required('Ce champ est obligatoire'),
                accountType: Yup.string()
                    .required('Ce champ est obligatoire')
                    .oneOf(
                        ['Express', 'Confort', 'Confort +'],
                        'Type de compte est doit étre Express, Confort ou Confort +'
                    ),
                phone: Yup.string()
                    .matches(
                        /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
                        'Veuillez indiquez un numéro de téléphone valide'
                    )
                    .test(
                        'unique-phone-number',
                        'Le numéro de téléphone saisi existe déjà.',
                        async function (val) {
                            const res = await http.get(`/card-order/${val}`);
                            if (res.data === 'FOUND') return false;
                            return !(
                                val === this.options.from[1].value.phone ||
                                this.options.from[1].value.family
                                    .map(
                                        (f, index) =>
                                            index !== this.options.index &&
                                            f.phone
                                    )
                                    .find((e) => e === val)
                            );
                        }
                    )
                    .required('Ce champ est obligatoire'),
                confirmPhone: Yup.string()
                    .matches(
                        /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
                        'Veuillez indiquez un numéro de téléphone valide'
                    )
                    .oneOf(
                        [Yup.ref('phone'), null],
                        'Les deux numéros de téléphone saisis ne sont pas identiques.'
                    )
                    .required('Ce champ est obligatoire'),
                initialRecharge: Yup.boolean(),
                initialRechargeAmount: Yup.number().when(['initialRecharge'], {
                    is: (initialRecharge) => initialRecharge,
                    then: Yup.number()
                        .required('Ce champ est obligatoire')
                        .min(
                            1,
                            'Merci de préciser le montant de la recharge initiale que vous souhaitez effectuer.'
                        ),
                }),
            })
        ),
        // .min(3, 'Veuillez ajouter trois membres')
        // .max(3, 'Veuillez ajouter trois membres'),
    }),
    wydadTerms: Yup.boolean().when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType === 'wac'),
        then: Yup.boolean()
            .required('Ce champ est obligatoire')
            .oneOf([true, null], 'Veuillez accepter les Conditions générale de vente'),
    }),

    
    accountType: Yup.string()
    .when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }),

    widadCardZone: Yup.string()
    .when(['step', 'orderType'], {
        is: (step, orderType) =>
        (step === 0 && orderType == 'wac'),
        then: Yup.string().required('Ce champ est obligatoire'),
    }), 
});
const allowedAccountTypes = ['Confort +', 'Confort', 'Express','PELOUSE','TRIBUNE'];
const justificationAccountType = ['Confort +', 'Confort','PELOUSE','TRIBUNE'];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
class B2cForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            lastStepIndex: 3,
            accountType: '',
            orderType: 'wac',
            urlSource:'',
            showModal: false,
            codecmr:'',
            gData:'',
            orderId:'',
            data: {},
            values: {},
            openPopup: false,
            isLoading: false,
            message:'',
            hasLoaded:false,
            fromMobile: false,
        };    
        console.log(props.location.search)
        if(props.location.search) {
            const query = new URLSearchParams(props.location.search);
            console.log(query.get("fromMobile"))
            if(query.get("fromMobile")) {
                this.state.fromMobile = true;
                this.state.orderType = 'individual';
                this.state.accountType = 'Confort';
                this.state.paymentType = 'AUCUN'
                return
            }
        }
        if (props.location.state) {
            const { accountType, orderType ,urlSource} = props.location.state;
            this.state.orderType = orderType;
            this.state.urlSource = urlSource;
            if (orderType === 'family') {
                this.state.lastStepIndex = 4;
            }
            if (orderType === 'wac') {
                this.state.lastStepIndex = 5;
            }
            if (allowedAccountTypes.indexOf(accountType) >= 0) {
                this.state.accountType = accountType;
            }
        }

        if (typeof window !== `undefined`){
            if (window.performance) {
                if (performance.navigation.type == 1) {
                    this.state.currentStep = 0;
                    this.state.message = ''
                } else {
                    if(this.props.location.state != null){
                       // window.location.reload(false);
                        this.state.codecmr = this.props.location.state.codecmr
                        this.state.gData = this.props.location.state.data
                        this.state.orderId = this.props.location.state.orderId
                        if(this.props.location.state.step !== undefined && this.props.location.state.step !== null) {
                            this.state.currentStep = this.props.location.state.step
                            this.state.message = this.props.location.state.message
                            this.state.accountType = 'Confort'
                            this.state.orderType = this.props.location.state.orderType
                        }
                        else{
                            this.state.currentStep = 0;
                        }    
                        
                    }
                }
            }
        }   
    }
    
    componentDidMount() {
        if (this.state.accountType === '') {
            window.location.href = '/wecasa';
        }
        this.setState({hasLoaded: true})
    }
    openNotification = () => {
        notification.open({
          message: 'Erreur',
          description:
            'Une erreur s\'est produite, veuillez vérifier vos entrées',
          onClick: () => {
          },
        });
      };

    handleNextClick = () => {
        if (this.state.currentStep < this.state.lastStepIndex)
            this.setState({
                currentStep: this.state.currentStep + 1,
            });
    };
    submitMobileBoarding = () => {

    }
    handleBackClick = () => {
        if (this.state.currentStep > 0)
            this.setState({
                currentStep: this.state.currentStep - 1,
            });
    };
    formatDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };


    getConfigurationByName = (name) => {
        return http.get(`configuration/${name}`);
    };

    getAllAgencies () {
        return http.get('/agency');
    }
    getAllCityHasAgencies () {
        return http.get('/agency/cities');
    }
    getAgencyByCity = (city) => {
        return http.get(`agency/${city}`);
    };

    getFeeByCardType = (cardType) => {
        return http.get(`card/fee/${cardType}`);
    };
    handleSubmit = (values) => {
        // const data = new FormData();
        // data.append(
        //     'cardOrder',
        //     JSON.stringify({
        //         ...values,
        //         identityFile: null,
        //         adresseProofFile: null,
        //     })
        // );
        // data.append('identityFile', values.identityFile);
        // data.append('adresseProofFile', values.adresseProofFile);

        this.setState({
            isLoading: true,
        });
        if(values.orderType ==='casawe'){
            values.accountType = values.orderType + ' - ' + values.accountType 
        }

        if (values.accountType === 'Express' || values.orderType ==='wac'){
            http.post('/card-order', values).then((res) => {
                if (values.paymentType === 'Je paie en ligne' && !values.fromMobile) {
                    window.location.href = res.data;
                } else {
                    this.setState({
                        showModal: true,
                        data: res.data,
                        openPopup: false,
                        isLoading: false,
                    });
                }
            });
        }else{
            http.post('/attachments/upload/'+values.identityNumber, values.cinFiles).then((res) => {
                values.attachments = res.data;
                    http.post('/card-order', values).then((res) => {
                        if (values.paymentType === 'Je paie en ligne' && !values.fromMobile) {
                            window.location.href = res.data;
                        } else {
                            this.setState({
                                showModal: true,
                                data: res.data,
                                openPopup: false,
                                isLoading: false,
                            });
                        }
                    });
            });
        }

        // if(values.cinFiles){
        //     http.post('/attachments/upload', values.cinFiles).then((res) => {
        //         values.attachments = res.data;
        //         http.post('/card-order', values).then((res) => {
        //             if (values.paymentType === 'Je paie en ligne') {
        //                 window.location.href = res.data;
        //             } else {
        //                 this.setState({
        //                     showModal: true,
        //                     data: res.data,
        //                     openPopup: false,
        //                     isLoading: false,
        //                 });
        //             }
        //         });
        //     })
        // }else{
        //     http.post('/card-order', values).then((res) => {
        //         if (values.paymentType === 'Je paie en ligne') {
        //             window.location.href = res.data;
        //         } else {
        //             this.setState({
        //                 showModal: true,
        //                 data: res.data,
        //                 openPopup: false,
        //                 isLoading: false,
        //             });
        //         }
        //     });
        // }
    };

    openConfirmPopup(values) {
        this.setState({
            openPopup: true,
            values: values,
        });
    }

    render() {
        return (
            <Layout style={{maxWidth: '100%', padding: 0}} className="container layout">
                <SEO title="B2c" url="/b2c" />
                {this.state.hasLoaded === true && (
                    <>
                <Sider
                    breakpoint="xs sm" 
                    className={classNames(
                        this.state.orderType!=="wac" 
                            ? 'side-bar'
                            : 'side-bar-wac',
                        'container',
                    )}
                    style={{
                        width: 'auto',
                        flex: '0 0 300px',
                        maxWidth: '300px',
                        padding: '0',
                    }}
                >
                    <ImageWrapper>
                        <Link to="/">
                        {this.state.orderType==="wac" ? (
                            <StaticImage
                            className='animated-img'
                            style={{
                                width: '11rem',
                                height: '70%'
                            }}
                            formats={["auto", "webp"]}
                            placeholder='blurred'
                            src="../../images/slide/wydad-logo.webp"
                            alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                            width='350'
                        />
                        ):(
                            <Image></Image>
                            
                        )}
                        </Link>
                    </ImageWrapper>
                    <Steps
                        progressDot
                        direction="vertical"
                        current={this.state.currentStep}
                        className="steps"
                    >
                        {this.state.orderType === 'wac' && (
                            <Step title="Commande" />
                        )}
                        <Step title="Coordonnées" />
                        <Step
                            title={
                                this.state.orderType === 'family'
                                    ? 'Carte principale'
                                    : this.state.orderType === 'wac'
                                    ? 'Identité':
                                    'Confirmation'
                            }
                            
                        />
                        {this.state.orderType === 'family' && (
                            <Step title="Cartes secondaires" />
                        )}
                        {this.state.orderType === 'wac' && (
                            <Step title="Livraison" />
                        )}
                        {/* <Step title="Livraison" /> */}

                        <Step title={this.state.fromMobile ? "Validation" : "Paiement"} />
                    </Steps>
                    {this.state.orderType==="wac" && (
                    <BottomImageWrapper>
                            <StaticImage
                                        className='animated-img'
                                        style={{
                                            width: '6.5rem',
                                            height: '100%',
                                            margin:'0 1rem',
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src="../../images/white-naps-logo.webp"
                                        alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                                        width='350'
                                    />
                            <StaticImage
                                        className='animated-img'
                                        style={{
                                            width: '6.5rem',
                                            height: '100%',
                                            margin:'0 1rem',
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src="../../images/white-logo-wecasa.webp"
                                        alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                                        width='350'
                                    />
                    </BottomImageWrapper>
                    )}
                </Sider>
                <Content
                    style={{ padding: '10px 10px 30px 10px', paddingBottom: '100px' }}
                    className="container content"
                >
                    <div className="form-wrapper">
                        <Formik
                            initialValues={{
                                step: this.state.currentStep,
                                accountType: this.state.accountType,
                                orderType: this.state.orderType,
                                urlSource: this.state.urlSource,
                                civility: '',
                                firstName: '',
                                lastName: '',
                                adresse: '',
                                codeverif: '',
                                city: '',
                                zipCode: '',
                                phone: '',
                                operator:'',
                                confirmPhone: '',
                                email: '',
                                confirmEmail: '',
                                selectedAgency:'',
                                selectedCity:'',
                                selectedDisabled:true,
                                terms:false,
                                wydadTerms:false,
                                identityType:
                                    this.state.orderType === 'family'
                                        ? 'CIN'
                                        : '',
                                identityNumber: '',
                                deliveryDate: this.formatDate(),
                                expirationDate: undefined,
                                identityFile: undefined,
                                adresseProofFile: '',
                                useHomeAdresse: true,
                                deliveryAdresse: '',
                                widadCardZone:'',
                                deliveryCity: '',
                                deliveryZipCode: '',
                                initialRecharge: false,
                                initialRechargeAmount: 0,
                                orderPlace: 'online',
                                family: [],
                                paymentType: 'Je paie en ligne',
                                codecmr:this.state.codecmr,
                                gData:this.state.gData,
                                orderId:this.state.orderId,
                                cinFiles:undefined,
                                attachments:[],
                                message:this.state.message,
                                fromMobile: this.state.fromMobile,
                            }}
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                isValid,
                                setFieldTouched,
                                errors,
                                setTouched,
                                validateForm,
                                setFieldValue,
                                values,
                                isSubmitting,
                                submitForm,
                            }) => (
                                <Form autoComplete="on">
                                    {this.state.orderType === 'wac' &&
                                     this.state.currentStep === 0 && (
                                        <WacOrderForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            values={values}
                                            setValue={setFieldValue}
                                            accountType={this.state.accountType}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                    {this.state.orderType === 'wac' &&
                                     this.state.currentStep === 1 && (
                                        <WelcomeForm
                                            back={this.handleBackClick}
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            orderType={this.state.orderType}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                      {this.state.orderType === 'wac' &&
                                       this.state.currentStep === 2 && (
                                        <ContactForm
                                            nextStep={this.handleNextClick}
                                            submitForm={submitForm}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            accountType={this.state.accountType}
                                            isNotInit={values.initialRecharge}
                                            orderType={this.state.orderType}
                                            values={values}
                                            getFeeByCardType={this.getFeeByCardType}
                                            getConfigurationByName={this.getConfigurationByName}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                    {this.state.orderType !== 'wac' &&
                                     this.state.currentStep === 0 && (
                                        <WelcomeForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            orderType={this.state.orderType}
                                            openNotification={this.openNotification}
                                            showIdClient={this.state.fromMobile}
                                        />
                                    )}
                                    {this.state.orderType !== 'wac' &&
                                     this.state.currentStep === 1 && (
                                        <ContactForm
                                            nextStep={this.handleNextClick}
                                            submitForm={submitForm}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            accountType={this.state.accountType}
                                            isNotInit={values.initialRecharge}
                                            orderType={this.state.orderType}
                                            values={values}
                                            getFeeByCardType={this.getFeeByCardType}
                                            getConfigurationByName={this.getConfigurationByName}
                                            openNotification={this.openNotification}
                                            fromMobile={this.state.fromMobile}
                                        />
                                    )}
                                    {this.state.orderType === 'wac' &&
                                       this.state.currentStep ===
                                        this.state.lastStepIndex - 2 && (
                                        <ContractForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            accountType={this.state.accountType}
                                            useHomeAdresse={
                                                values.useHomeAdresse
                                            }
                                            values={values}
                                            adresse={values.adresse}
                                            city={values.city}
                                            orderType={values.orderType}
                                            zipCode={values.zipCode}
                                            getAllAgencies={this.getAllAgencies}
                                            getAllCityHasAgencies={this.getAllCityHasAgencies}
                                            getAgencyByCity={this.getAgencyByCity}
                                            getFeeByCardType={this.getFeeByCardType}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                    {/*{this.state.orderType === 'family' &&
                                        this.state.currentStep ===
                                            this.state.lastStepIndex - 2 && (
                                            <FamilyForm
                                                nextStep={this.handleNextClick}
                                                isValid={isValid}
                                                setTouched={setTouched}
                                                validate={validateForm}
                                                setValue={setFieldValue}
                                                values={values}
                                                back={this.handleBackClick}
                                                accountType={
                                                    this.state.accountType
                                                }
                                                setFieldTouched={
                                                    setFieldTouched
                                                }
                                                openNotification={this.openNotification}
                                            />
                                        )}*/}
                                    {this.state.currentStep ===
                                        this.state.lastStepIndex - 1 &&(
                                        <OrderForm
                                            nextStep={this.handleNextClick}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            values={values}
                                            orderType={this.state.orderType}
                                            isSubmitting={this.state.isLoading}
                                            accountType={this.state.accountType}
                                            submitForm={submitForm}
                                            openNotification={this.openNotification}
                                            fromMobile={this.state.fromMobile}
                                        />
                                    )} 
                                    {this.state.currentStep ===
                                        this.state.lastStepIndex && (
                                        <CongratulationForm
                                           // nextStep={this.handleNextClick}
                                            submitForm={submitForm}
                                            isValid={isValid}
                                            setTouched={setTouched}
                                            validate={validateForm}
                                            setValue={setFieldValue}
                                            back={this.handleBackClick}
                                            accountType={this.state.accountType}
                                            isNotInit={values.initialRecharge}
                                            orderType={this.state.orderType}
                                            values={values}
                                            openNotification={this.openNotification}
                                        />
                                    )}
                                    <Modal
                                        visible={this.state.showModal}
                                        centered
                                        onCancel={() => {
                                            this.setState({ showModal: false });
                                            navigate('/');
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                minHeight: '250px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon="check"
                                                style={{
                                                    fontSize: '100px',
                                                    color: '#63e6be',
                                                    margin: '20px',
                                                    width: '100px',
                                                }}
                                            />
                                            <Title
                                                style={{
                                                    fontSize: '17px',
                                                    color: '#212121',
                                                }}
                                            >
                                                Félicitations !
                                                <br />
                                                <br />
                                                Votre commande est enregistrée
                                                avec succès !
                                                <br />
                                                <br />
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#c4c4c4',
                                                    }}
                                                >
                                                    Un email de confirmation a
                                                    été envoyé à votre adresse :
                                                    <br />
                                                    {values.email}
                                                    <br />
                                                    <br />À bientôt !
                                                </span>
                                                <br />
                                                <br />
                                            </Title>
                                        </div>
                                    </Modal>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Content>
                </>)}
            </Layout>
            
        );
    }
}

export default B2cForm;
