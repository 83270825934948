import styled from 'styled-components';
import Background from '../../images/myspacebackround.svg';
import BackgroundDark from '../../images/myspacebackrounddark.svg';
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    background-image: ${({ theme }) =>
        theme === 'dark' ? `url(${BackgroundDark})` : `url(${Background})` };
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${({ br, theme }) => br === 'ie' && (theme === 'dark' ? '#212121' : '#f3f3f3')};
    padding-top: 50px;
    z-index: 3;
    @media (max-height: 716px) {
        padding-top: 80px;
    }
    @media (max-width: 991px) {
        padding-top: 90px;
    }
`;
