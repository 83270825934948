import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'gatsby';
import { Wrapper, InfoWrapper, Stick, Button,GoogleButton } from './styles';
import { Input, AutoComplete } from 'antd';
import ThemeContext from '../../layout/theme/ThemeContext';
import { useContext } from 'react';
import { http } from '../../../components/http';
import { detect } from 'detect-browser';
import { mapValues, groupBy } from 'lodash';
import { StaticImage } from 'gatsby-plugin-image';
import marker from '../../../images/marker.png';
import markerLocation from '../../../images/marker_location.png';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import osm from "../../helpers/osm-providers";
import useGeoLocation from "../../hooks/useGeoLocation";
import './styles.css';
import {
    SubTitleWrapper,
    SecondTitle, ThirdTitle
} from '../../common/SimpleRow/styles';


var markerIcon;
var markerLocationIcon;
if (typeof window !== 'undefined') {
   markerIcon = new L.Icon({
    iconUrl: marker,
    iconSize: [30, 30]
  });

   markerLocationIcon = new L.Icon({
    iconUrl: markerLocation,
    iconSize: [25, 20]
  }); 
}
  const maps = {
    base: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
  };
const VignetteGeolocation = () => {
    const [center, setCenter] = useState({ lat: 32.5722875, lng: -7.5869925});
    const ZOOM_LEVEL = 6;
    const ZOOM_LOCATION_LEVEL = 13;
    const mapRef = useRef();
    const location = useGeoLocation();
 
    
    const [state, setState] = useState({
        options: [],
        selectedAdresse: null,
        br: '',
    });

    const isBrowser = typeof window !== "undefined"

    const [agencyState, setAgencyState] = useState({
        agencies: [],
    });
    useEffect(() => {
        const br = detect();
        http.get('/agency').then((res) => {
            setAgencyState({
                agencies: res.data,
            });
            const agences = res.data;
            const grouped = mapValues(
                groupBy(agences, 'city'),
            );

            const options = Object.keys(grouped).map((val) => {
                return {
                    label: val,
                    options: grouped[val].map((agence, index) => {
                        return {
                            value: agence.agency + ' - ' + agence.address,
                            label: (
                                <p style={{ color: '#212121' }} key={val + index}>
                                    {agence.agency + ' - ' + agence.address}
                                </p>
                            ),
                            adresse: agence.address,
                            lat: agence.gps_L,
                            lng: agence.gps_I
                        };
                    }),

                };
            });
            setState({
                ...state,
                options: options,
                br: br?.name,
            });
            

            if (location.loaded && !location.error) {
                mapRef.current.leafletElement.flyTo(
                  [location.coordinates.lat, location.coordinates.lng],
                  ZOOM_LOCATION_LEVEL,
                  { animate: true }
                );
              } else {
                mapRef.current.leafletElement.flyTo(
                    [32.5722875,-7.5869925],
                    ZOOM_LEVEL,
                    { animate: true }
                  );
              }
        })
    }, [location.loaded]);
    const handleAdresseSelect = (_, option) => {
        mapRef.current.leafletElement.flyTo(
            [option.lat, option.lng],
            ZOOM_LOCATION_LEVEL,
            { animate: true }
          );
    };
    const openOnGoogleMap = (agancy) => {
        if (location.loaded && !location.error) {
            window.open('https://www.google.com/maps/dir/'+location.coordinates.lat+',' +location.coordinates.lng+'/' +agancy.gps_L+',' +agancy.gps_I , '_blank');
          } else {
            window.open('https://www.google.com/maps?q='+agancy.gps_L+',' +agancy.gps_I+'&z=17&hl=fr' , '_blank');
          }
    };
    const NearestAgencies = () => {
        if (location.loaded && !location.error) {
          mapRef.current.leafletElement.flyTo(
            [location.coordinates.lat, location.coordinates.lng],
            ZOOM_LOCATION_LEVEL,
            { animate: true }
          );
        } else {
          alert(location.error.message);
        }
      };
    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper
            id="order"
            style={{ display: 'flex', justifyContent: 'center'}}
            className="container mx-auto"
        >
            <SecondTitle theme={theme}>Payer votre vignette automobile en agence</SecondTitle>
            <SubTitleWrapper
                        style={{ color: '#000' ,marginBottom :'2rem'}}
                    >
                   <div  style={{width: '75%', margin: 'auto', textAlign: 'center'}}>Si vous souhaitez payer votre vignette automobile en cash dans une de nos agences, c'est aussi très simple.<br></br><br></br>
                    Une fois à l'agence, présentez-vous au guichet avec les informations relatives à votre véhicule et notre agent vous prendra en charge pour le règlement.<br></br><br></br>
                    Vous pouvez demander votre quittance de paiement sur place dès que le règlement est effectué.</div> 
            </SubTitleWrapper>
            <div
                justify="center"
                className="geolocation-container container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
                br={state.br}

            >
                <div
                    className="footer-card justify-center"
                >
                    <InfoWrapper
                        style={{
                            borderRight: `2px solid #fff`,
                            padding: '30px',
                            backgroundColor: `#f3f3f3`,
                            borderRadius: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <SecondTitle

                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                margin: '10px 0 30px 0',
                            }}
                        >
                            Perte de votre reçu
                        </SecondTitle>
                        <Stick>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                        <div  style={{margin: 'auto', textAlign: 'center'}}>
                        vous pouvez demander un duplicata auprès de nos agences Naps ou le télécharger directement depuis notre plateforme
                        </div>
                        <Link style={{marginTop: '5rem'}} to="https://vignette.naps.ma">
                            <Button>
                                <h3 style={{ color: '#fff' }}>Imprimer ma quittance</h3>
                            </Button>
                        </Link>
                            </div>
                        </Stick>
                    </InfoWrapper>
                </div>
                <div
                    className=" footer-card justify-center"
                >
                    <InfoWrapper
                        style={{
                            borderRight: `2px solid #fff`,
                            padding: '15px',
                            backgroundColor: ` #f3f3f3`,
                            borderRadius: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <SecondTitle

                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                margin: '10px 0 30px 0',
                            }}
                        >
                            Trouver une agence
                        </SecondTitle>
                        <AutoComplete
                            style={{
                                width: '100%',
                                border: 'none',
                            }}
                            options={state.options}
                            filterOption={(inputValue, option) => {
                                const label =
                                    typeof option.value === 'string'
                                        ? option.value
                                        : '';
                                return (
                                    label &&
                                    label
                                        .toUpperCase()
                                        .indexOf(inputValue?.toUpperCase()) !==
                                    -1
                                );
                            }}
                            className="input"
                            onSelect={handleAdresseSelect}
                            dropdownMatchSelectWidth={700}
                        // style={{ width: '100%' }}
                        >
                            <Input.Search
                                size="large"
                                placeholder="Recherche d'agence par ville"
                                style={{ color: '#212121', width: '100%' }}
                            />
                        </AutoComplete>
                        {state.selectedAdresse}
                        <div  style={{ marginTop: '20px', zIndex: '0'  }} >
        {isBrowser && <Map center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                            <TileLayer
                                url={osm.maptiler.url}
                                attribution={osm.maptiler.attribution}
                            />
                            {location.loaded && !location.error && (
                                    <Marker
                                    icon={markerLocationIcon}
                                    position={[
                                        location.coordinates.lat,
                                        location.coordinates.lng,
                                    ]}>
                                    <Popup>
                                        <b>
                                        Votre position
                                        </b>
                                    </Popup>
                                    </Marker>
                                )}
                            {agencyState.agencies.map((agancy, idx) => (
                                <Marker
                                position={[agancy.gps_L,agancy.gps_I]}
                                icon={markerIcon}
                                key={idx}
                                >
                                <Popup>
                                <div
                                style={{
                                    display: 'flex',
                                    WebkitAlignItems: 'center',
                                    margin: '0.5em 0'
                                }}
                                      >
                                    <StaticImage
                                        alt="Naps"
                                        formats={["auto", "webp"]}
                                        style={{
                                            width: '70px',
                                            height: '100%',
                                        }}
                                        src='../../../images/logo.webp'>
                                    </StaticImage>
                                    <ThirdTitle  style={{
                                            fontSize: '18px',
                                            margin: '0 0 0 15px',
                                        }}
                                    >{agancy.agency}</ThirdTitle>
                                </div>
                                    <p style={{display: 'flex',
                                            WebkitAlignItems: 'center',
                                            margin: '0.5em 0'}}>
                                    - {agancy.address}
                                    </p>
                                    <p style={{margin: '0.5em 0'}}>
                                    - {agancy.district}
                                    </p>
                                    <p style={{margin: '0.5em 0'}}>
                                    - {agancy.city}
                                    </p>
                                    <GoogleButton  onClick={() => openOnGoogleMap(agancy)}>
                                            Ouvrir dans google map
                                    </GoogleButton>
                                </Popup>
                                </Marker>
                            ))}
                        </Map>}
                        </div>
                    </InfoWrapper>
                </div>
            </div>
            <ThirdTitle style={{backgroundColor: 'rgb(230 109 48)',
                                         color:"#fff",
                                         textAlign:'center',
                                         fontSize: '20px',
                                         padding: '0.5rem 2rem',
                                         borderRadius: '10px',
                                         marginTop: '2rem'}} 
                        className='geolocation-container' >
                          Toute l'équipe de Naps reste à votre service pour vous faciliter le service de paiement de votre vignette, en ligne ou dans nos agences.
            </ThirdTitle>
        </Wrapper>
    );
};
export default VignetteGeolocation;
