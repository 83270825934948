import React, { useContext } from 'react';

import {
    Wrapper,
    Offer,
    Span,
    AccountTypeName,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bounce } from 'react-awesome-reveal';
import { Link } from 'gatsby';
import ThemeContext from '../../layout/theme/ThemeContext';
const OrderCard = ({ accountType, offers, orderType, pack}) => {
    const { theme } = useContext(ThemeContext);
    return (
        <Bounce bottom>
            <Wrapper style={{padding: '40px 1rem 1.5rem 1.5rem'}} theme={theme}>
                    <Link
                        style={{ color: '#fff', marginBottom: '30px' }}
                        to="/b2c"
                        state={{
                            accountType: accountType,
                            orderType: orderType,
                        }}
                    >
                       <Span>Je commande !</Span> 
                    </Link>
                {pack !== 'family' && (
                    <>
                        <AccountTypeName>
                            {orderType ==="wac"? 'Carte ' + accountType:'Compte ' + accountType}
                        </AccountTypeName>
                    </>
                )}

                {/* <AccountType theme={theme}>{accountType}</AccountType> */}
                {/* <Description>Plafond</Description>
                <Price>{price} DHS</Price> */}

                <div style={{ width: '100%' }}>
                    {offers.map((offer, index) => {
                        return (
                            <Offer key={index}>
                                <FontAwesomeIcon
                                    style={{
                                        marginRight: '5px',
                                        color: '#e96d25',
                                        position: 'absolute',
                                        marginTop: '5px',
                                    }}
                                     icon={['fa', 'check-circle']}
                                />
                                <p style={{ margin: '0 0 0 25px' }}>
                                    {offer.name}
                                </p>
                            </Offer>
                        );
                    })}
                </div>
            </Wrapper>
        </Bounce>
    );
};

export default OrderCard;
