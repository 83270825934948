import React, { useContext,useEffect, useState} from 'react';
import stade from '../../../images/stade.webp'
import moment from "moment";
import {
    Wrapper,
    FooterCard,
    BodyCard,
    EventName,
    Offer,
    Button,
    EventDate,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bounce } from 'react-awesome-reveal';
import blackHourglass from '../../../images/hourglass-black.svg'
import { Link } from 'gatsby';
import ThemeContext from '../../layout/theme/ThemeContext';
const EventBooking = ({event,index,toBuy}) => {
    const { theme } = useContext(ThemeContext);
    const [sec, setSec] = useState(() => new Date().getSeconds());
    const [min, setMin] = useState(() => new Date().getMinutes());
    const [hour, setHour] = useState(() => new Date().getHours());
    const [day, setDay] = useState(() => new Date().getDay());


    useEffect(() => {
        var d1 = new Date(new Date(event.eventdate).toISOString().slice(0, -1));

        const intervalId = setInterval(function () {
        var diff = {} 
        var d2 = new Date()
        var tmp = d1- d2;
        tmp = Math.floor(tmp/1000);   
        diff.sec = tmp % 60;
        setSec(diff.sec);
        tmp = Math.floor((tmp-diff.sec)/60);
        diff.min = tmp % 60;  
        setMin(diff.min);
        tmp = Math.floor((tmp-diff.min)/60);
        diff.hour = tmp % 24;   
        setHour(diff.hour);
        tmp = Math.floor((tmp- diff.hour)/24);  
        diff.day = tmp
        setDay(diff.day);
        }, 1000);
        return ()=>{
        clearInterval(intervalId);
        }
        
     },[event.eventdate]);

    return (
        <Bounce  bottom>
            <Wrapper theme={theme}>

                <div style={{ width: '100%' }}>
                            <Offer key={index}>
                                {/* <ColTicketingCard style={{backgroundImage: ` linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url('${event.image}')` }}> */}
                                <BodyCard style={{backgroundImage: `url('${event.image}')` }}>
                                        {/* <EventDate>{moment(event.eventdate).format("YYYY/MM/DD")}</EventDate>
                                        <EventName>
                                           {event.name}
                                        </EventName> */}
                                </BodyCard>
                                <FooterCard>
                                {/* <p style={{textAlign: 'center',padding: '0 1rem'}}>{event.description}</p> */}

                                    <div style={{width: '100%', margin:'0.5rem', paddingTop: '0.5rem', display:'flex',flexDirection: 'row'}}>
                                        <p style={{textAlign: 'center',marginBottom: 'unset'}}>
                                        <img
                                            src={blackHourglass}
                                            style={{display: 'inline', marginRight: '0.5rem'}}
                                        />
                                           {day + " : "+ hour + " : "+ min + " : "+ sec}
                                        </p>
                                        <Button onClick={() => toBuy(event)} type="button" >
                                        Réserver
                                        </Button>
                                    </div>
                                </FooterCard>
                            </Offer>
                </div>
            </Wrapper>
        </Bounce>
    );
};

export default EventBooking;
