import styled, { css } from 'styled-components';
import { Row } from 'antd';
import BackImg from '../../../../images/liner.svg';
import BackDarkImg from '../../../../images/linerdark.svg';
export const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f7f7f7;
`;
export const EcommerceRow = styled.div`
    background-color: ${({ theme }) =>
        theme === 'dark' ? '#212121' : '#f7f7f7'};
    padding: 0 20px 60px 20px;
    ${({ br }) =>
        br !== 'ie' &&
        css`
            background-image: ${({ theme }) =>
                theme === 'dark' ? `url(${BackDarkImg})` : `url(${BackImg})`};
        `}

    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
`;
