import React from 'react';
import { Row, Col } from 'antd';
import PaymentCard from '../../../common/product-card/payment-card';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
const Payment = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulProductPayment(
                filter: { key: { eq: "product" } }
                sort: { fields: createdAt, order: ASC }
            ) {
                nodes {
                    title
                    description {
                        raw
                      }
                    image {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <div justify="center" className="container mx-auto dispal-grid grid lg:grid-cols-6 md:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1" >
            {data.allContentfulProductPayment.nodes.map((item, index) => {
                return (
                    <div
                        className="justify-center"
                        style={{ padding: '20px' }}
                        key={index}
                    >
                        <PaymentCard
                            title={item.title}
                            content={documentToReactComponents(JSON.parse(
                                item.description.raw)
                            )}
                            imgUrl={item.image.file.url}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Payment;
