import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    min-height: 300px;
    width: 300px;
    margin: 10px;
    transition: 0.2s ease-in-out;
    margin-bottom: 150px;
    transform: ${(props) => (props.isActive ? 'scale(1.1) ' : 'none')};
    z-index: ${(props) => (props.isActive ? 2 : 1)};
    position: relative;
    @media (max-width: 465px) {
        min-width: 300px;
        min-height: 300px;
        width: 300px;
    }
`;
export const Image = styled.img`
    max-width: 100%;
    max-height: 300px;
    /* width: ${({ isActive }) => (!isActive ? '100%' : '320px')}; */
    width: 320px;
    filter: ${(props) =>
        props.isActive ? 'none' : 'blur(5px) grayscale(80%)'};
    box-shadow: ${(props) =>
        props.isActive ? '0 8px 82px -27px rgba(0,0,0,0.5)' : 'none'};
`;

export const Name = styled.p`
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
    font-size: 15px;
    color: #fff;
    position: absolute;
    top: 35%;
    text-align: center;
    padding: 50px;
    display: ${(props) => (props.isActive ? 'none' : 'inline')};
    text-shadow: 1px 1px 10px #000000;
    max-width: 300px;
    z-index: 2;
    width: 100%;
    left: 0;
    @media (max-width: 465px) {
        top: 20%;
    }
`;
export const SubName = styled.p`
    font-weight: 400;
    font-family: 'Maven Pro', sans-serif;
    font-size: 12px;
    color: #d4d4d4;
    position: absolute;
    top: 55%;
    text-align: center;
    padding: 20px;
    display: ${(props) => (props.isActive ? 'none' : 'inline')};
    text-shadow: 1px 1px #00000040;
    z-index: 2;
    width: 100%;
    left: 0;
    text-align: center;
    @media (max-width: 465px) {
        top: 33%;
    }
`;

export const Button = styled.button`
    width: 100%;
    padding: 15px;
    border: 1px solid ${({ theme }) => (theme === 'dark' ? '#ffff' : '#000000')};
    color: ${({ theme }) => (theme === 'dark' ? '#ffff' : '#000000')};
    font-family: 'Maven Pro', sans-serif;
    background: none;
    margin: 10px 0;
    display: ${({ isActive }) => (!isActive ? 'none' : 'inline-block')};
    font-size: 17px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
    &:focus {
        outline: none;
    }
`;
export const Filter = styled.div`
    position: absolute;
    top: ${({ from }) => from === 'partner' ? '50px' : '0'};
    height: ${({ from }) => from === 'partner' ? '200px' : '300px'};
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    ${({ br }) =>
        br === 'ie' &&
        css`
            left: 0;
        `};
`;
