import styled from 'styled-components';

export const Wrapper = styled.div`
    width: ${({ br }) => br === 'ie' ? '28rem' : '100%'};
    display: flex;
    flex-direction: column;
    padding: 20px;

`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Number = styled.img`
    margin: 0;
    color: #e66d30;
    font-size: 28px;
    font-family: 'Maven Pro', sans-serif;
    margin-right: 10px;
    font-weight: 500;
    max-width: 50px;
`;

export const Title = styled.p`
    margin: 0;
    font-size: 25px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : ''};
`;

export const Content = styled.p`
    color: ${({ theme }) => theme === 'dark' ? '#fff' : '#383838'};
    font-family: Rajdhani, sans-serif;
    border-left: 4px solid #d8d8d8;
    padding: 15px;
    text-align: justify;
    
`;