import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { InfoWrapper, Title } from '../common/SimpleRow/styles';
import TpeCatalogCard from '../common/tpe-catalog-card';
import { useStaticQuery, graphql } from 'gatsby';
import TpeCategoryCard from '../common/tpe-caterogy-card';
import Slider from 'react-slick';
import './styles.css';
import { CategoryName } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../layout/theme/ThemeContext';
const TpesCatalog = () => {
    const [state, setState] = useState({
        activeStep: 0,
        showDetails: false,
    });
    const seeDetailsClick = () => {
        setState({
            ...state,
            showDetails: true,
        });
    };
    const settings = {
        focusOnSelect: true,
        dots: true,
        className: 'center',
        centerMode: true,
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        centerPadding: '60px',
        autoplaySpeed: 1000,
        prevArrow: (
            <FontAwesomeIcon
                icon="caret-left"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        nextArrow: (
            <FontAwesomeIcon
                icon="caret-right"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        customPaging: (i) => <div className="taps_paging"></div>,
        afterChange: (current, next) =>
            setState({ ...state, activeStep: current, showDetails: false }),
        responsive: [
            {
                breakpoint: 1472,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1319,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1204,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1104,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    // slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    const {
        allContentfulTpeCatalog,
        allContentfulTpeCategory,
    } = useStaticQuery(graphql`
        query TpeCatalogQuery {
            allContentfulTpeCatalog(sort: { fields: order, order: ASC }) {
                group(field: category) {
                    fieldValue
                    nodes {
                        id
                        title
                        details {
                        raw
                        }
                        image {
                            file {
                                url
                            }
                        }
                        useCaseImages {
                            file {
                                url
                            }
                        }
                    }
                }
            }
            allContentfulTpeCategory {
                nodes {
                    categoryName
                    image {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const { theme } = useContext(ThemeContext);

    return (
        <>
            <div
                style={{
                    maxWidth: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    height: `fit-content`,
                    marginTop: '100px',
                    overflowY: 'hidden',
                    zIndex: 2,
                }}
            >
                <Slider className="slider" {...settings} autoplay={!state.showDetails}>
                    {allContentfulTpeCategory.nodes.map((item, index) => {
                        return (
                            <TpeCategoryCard
                                key={index}
                                isActive={index === state.activeStep}
                                image={item.image.file.url}
                                name={item.categoryName}
                                seeDetailsClick={seeDetailsClick}
                            />
                        );
                    })}
                </Slider>
                <CategoryName theme={theme}>
                    {
                        allContentfulTpeCategory.nodes[state.activeStep]
                            .categoryName
                    }
                </CategoryName>
            </div>
            {state.showDetails && (
                <div style={{ paddingTop: '100px' }} id="category-details">
                    {allContentfulTpeCatalog.group.map((category, index) => {
                        if (
                            category.fieldValue.toLowerCase() !==
                            allContentfulTpeCategory.nodes[
                                state.activeStep
                            ].categoryName.toLowerCase()
                        )
                            return;
                        return (
                            <TpeCatalogCard
                                key={index}
                                category={category.fieldValue}
                                nodes={category.nodes}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default TpesCatalog;
