import React from 'react';
import { Row, Col } from 'antd';
import ServiceCard from '../../common/service-card';
import { Button } from '../landing/styles';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { useState } from 'react';
import TpeCategoryCard from '../../common/tpe-caterogy-card';
import Slider from 'react-slick';
import { CategoryName } from '../../tpes-catalog/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
import useWindowSize from '../../hooks/useWindowSize';

const Services = () => {
    const [state, setState] = useState({
        firstActiveStep: 0,
        secondActiveStep: 0,
    });

    const { theme } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const data = useStaticQuery(graphql`
        query {
            allContentfulService {
                nodes {
                    key
                    title
                    picture {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const settings = {
        focusOnSelect: false,
        dots: true,
        className: 'center',
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        centerPadding: '10px',
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToScroll: 1,
        prevArrow: (
            <FontAwesomeIcon
                icon="caret-left"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        nextArrow: (
            <FontAwesomeIcon
                icon="caret-right"
                style={{ color: '#000', fontSize: '10rem', width: '10px' }}
            />
        ),
        customPaging: (i) => <div className="taps_paging"></div>,
        responsive: [
            {
                breakpoint: 1472,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1319,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1204,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1104,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    // slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    const first = data.allContentfulService.nodes.filter(
        (item) => item.key === '1st'
    );
    const second = data.allContentfulService.nodes.filter(
        (item) => item.key === '2end'
    );
    return (
        <div style={{ marginTop: "30px" }} className="container mx-auto dispal-grid grid lg:grid-cols-1 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
            <div xxl={24}>
                <Button
                    theme={theme}
                    style={{ textAlign: 'center', marginBottom: '-70px' }}
                >
                    {' '}
                    Un large potentiel sur les marchés B2C & B2B
                </Button>
                {width <= 678 &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }} >
                        {first.map((item, index) => {
                            return (
                                <TpeCategoryCard
                                    style={{ margin: 0 }}
                                    key={'first_mobile' + index}
                                    image={item.picture.file.url}
                                    name={item.title}
                                    from="partner"
                                    noBlur={true}
                                />
                            );
                        })}

                    </div>
                }
                {width > 678 &&


                    <div
                        style={{
                            maxWidth: '100%',
                            width: '100%',
                            overflowX: 'hidden',
                            height: `fit-content`,
                            marginTop: '100px',
                            overflowY: 'hidden',
                        }}
                    >
                        <Slider
                            className="slider"
                            id="first-slider"
                            key="first-slider"
                            {...settings}
                            beforeChange={(current, next) =>
                                setState({ ...state, firstActiveStep: next })
                            }
                        >
                            {first.map((item, index) => {
                                return (
                                    <TpeCategoryCard
                                        key={'first_' + index}
                                        index={index}
                                        isActive={index === state.firstActiveStep}
                                        image={item.picture.file.url}
                                        name={item.title}
                                        from="partner"
                                        noBlur={true}
                                    />
                                );
                            })}
                        </Slider>
                        <CategoryName theme={theme}>
                            {[state.firstActiveStep].title}
                        </CategoryName>
                    </div>
                }
            </div>

            <div
                xxl={24}
                lg={24}
                xs={24}
                className="justify-center"
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Button
                    style={{
                        marginBottom: '-70px',
                        marginTop: '70px',
                        textAlign: 'center',
                    }}
                    theme={theme}
                >
                    Un programme d’animation innovant
                </Button>

                {width <= 678 &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }} >
                        {second.map((item, index) => {
                            return (
                                <TpeCategoryCard
                                    key={index}
                                    image={item.picture.file.url}
                                    name={item.title}
                                    from="partner"
                                    noBlur={true}
                                    style={{ margin: 0 }}
                                />
                            );
                        })}

                    </div>
                }
                {width > 678 &&
                    <div
                        style={{
                            maxWidth: '100%',
                            width: '100%',
                            overflowX: 'hidden',
                            height: `fit-content`,
                            margin: '0 auto',
                            marginTop: '100px',
                            overflowY: 'hidden',
                        }}
                    >
                        <Slider
                            className="slider"
                            id="second-slider"
                            key="second-slider"
                            {...settings}
                            autoplay={true}
                            autoplaySpeed={1000}
                            afterChange={(current, next) =>
                                setState({ ...state, secondActiveStep: current })
                            }
                        >
                            {second.map((item, index) => {
                                return (
                                    <TpeCategoryCard
                                        key={index}
                                        index={index}
                                        isActive={index === state.secondActiveStep}
                                        image={item.picture.file.url}
                                        name={item.title}
                                        from="partner"
                                        noBlur={true}
                                    />
                                );
                            })}
                        </Slider>
                        <CategoryName theme={theme}>
                            {second[state.secondActiveStep].title}
                        </CategoryName>
                    </div>}
            </div>
            {/* {second.map((item, index) => {
                return (
                    <Col
                        className="justify-center"
                        xl={4}
                        lg={5}
                        md={8}
                        sm={12}
                        xs={24}
                        key={index}
                    >
                        <ServiceCard
                            title={item.title}
                            picture={item.picture.file.url}
                        />
                    </Col>
                );
            })} */}
            <Col
                className="justify-center"
                lg={24}
                xs={24}
                style={{ margin: '40px 0 40px 0' }}
            >
                <Link to="/b2b/acquisition" state={{ type: 'partner' }}>
                    <Button
                        style={{
                            border: '1px solid #e66d30',
                            borderRadius: '40px',
                            fontSize: '20px',
                            color: '#e66d30',
                            fontWeight: '500',
                        }}
                    >
                        Ce projet m’intéresse !
                    </Button>
                </Link>
            </Col>
        </div>
    );
};
export default Services;
