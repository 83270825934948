import React from 'react';
import Landing from './landing';
import Services from './services';

const FranchisesTemplate = () => {
    return (
        <>
            <Landing />
            <Services />
        </>
    );
};

export default FranchisesTemplate;