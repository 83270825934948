import { Button as SimpleButton } from '../common/SimpleRow/styles';
import React, { useState, useEffect, useContext } from 'react';
import {
    InfoWrapper,
    Title,
    SubTitle,
    ImageWrapper,
    Fluidity,
    LegendTitle
} from './styles';
import './styles.css';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import VdModal from '../common/vd-modal';
import SEO from '../seo';
import ThemeContext from '../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import Popup from 'reactjs-popup';
import { CloseIcon } from '../common/vd-modal/styles';
import Banner from '../banner';
import Slider from 'react-slick';
import { Image } from '../image';
import { StaticImage, getImage, GatsbyImage, withArtDirection} from 'gatsby-plugin-image';

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import useWindowSize from '../hooks/useWindowSize';

const Landing = () => {

    const { width } = useWindowSize()
    const fluidityImageStyle = {
        width: '2rem',
        height: 'auto',
        margin: 'auto'
    }
    const fluidityImagePlaceholder = 'blurred';
    const fluidityImageAlt = 'Carte CasaWe smart city pour les événements et services de la ville de Casablanca';
    const fluidityWacImageAlt = 'La carte Naps pour les fans du Wydad Athletic Club sera lancée'
    const fluidity = [
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/icon-football.webp' alt={fluidityImageAlt}
            />),
            description: 'Stades et événements sports',
        },
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/theater.webp' alt={fluidityImageAlt}
            />),
            description: 'Théâtres et événements culturels',
        },
        // {
        //     icon: (<StaticImage style={fluidityImageStyle}
        //         placeholder={fluidityImagePlaceholder}
        //         src='../../images/slide/icon-bus.webp' alt={fluidityImageAlt}
        //     />),
        //     description: 'Bus et Tramway',
        // },
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/icon-bien-plus.webp' alt={fluidityImageAlt}
            />),
            description: 'Et bien plus...',
        },
    ];

    const wacFluidity = [
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/wac-football-icon.webp' alt={fluidityWacImageAlt}
            />),
            description: 'Stades et événements sports',
        },
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/wac-tram-icon.webp' alt={fluidityWacImageAlt}
            />),
            description: 'Bus et Tramway',
        },
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/wac-store-icon.webp' alt={fluidityWacImageAlt}
            />),
            description: 'Paiment sur les magasins et magasins électroniques',
        },
        {
            icon: (<StaticImage style={fluidityImageStyle}
                placeholder={fluidityImagePlaceholder}
                src='../../images/slide/wac-plus-icon.webp' alt={fluidityWacImageAlt}
            />),
            description: 'Et bien plus...',
        },
    ];
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        prevArrow: (
            <></>
        ),
        nextArrow: (
            <></>
        ),
    };
    const { theme } = useContext(ThemeContext);
    const [state, setState] = useState({
        showModal: false,
        showModalFamily: false,
        browser: '',
        showBanner: false,
    });

    useEffect(() => {
        const browser = detect();
        setState({
            ...state,
            browser: browser?.name,
        });
    }, []);

    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "landing" }) {
                id
                title
                subTitle
                largeImage: image {
                    gatsbyImageData(width: 500, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 70)
                }
                smallImage: image {
                    gatsbyImageData(width: 250, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 50)
                }
            }
            casawiBackground: file(relativePath: { eq: "slide/backgraound-casawi.webp" }) {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              wacBackground: file(relativePath: { eq: "slide/wydad-background.webp" }) {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              tpeBackground: file(relativePath: { eq: "slide/banner-TPE.webp" }) {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              tpeBackgroundMobile: file(relativePath: { eq: "slide/TPE-banner-mobile.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              vignetteBackground: file(relativePath: { eq: "bg.webp" }) {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
        }
    `);
    const casawiImage = getImage(data.casawiBackground)
    const casaBg = convertToBgImage(casawiImage)
    const wacImage = getImage(data.wacBackground)
    const wacBg = convertToBgImage(wacImage)
    const tpeImage = getImage(data.tpeBackground)

    const vignetteImage = getImage(data.vignetteBackground)
    const vignetteBg = convertToBgImage(vignetteImage)
    return (
        <>
            <SEO
                title="Accueil"
                description={
                    data.contentfulLanding.title +
                    ',' +
                    data.contentfulLanding.subTitle
                }
                keywords={
                    'naps, naps maroc, payment, paiement, carte, recharge, e-commecre, tpe'
                }
                url={''}
            />
            <div className="container mx-auto auto-top-margin">
                <Slider {...settings}>
                    <div
                        key={-5}
                        theme={theme}
                        justify="center"
                        className="row-padding">
                        <div className='flex-flow' style={{alignContent: 'center', display: 'flex', justifyContent: 'space-around', height: 'auto'}}>
                            <StaticImage
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        placeholder='blurred'
                                        formats={["auto", "webp"]}
                                        src='../../images/slide/banner-TPE.webp'
                                        aspectRatio="16/9"
                                        height="100%"
                                        layout="fullWidth"
                                        alt='tpe'
                                    />
                        
                            <div className="justify-center" lg={10} md={24} sm={0} xs={0}>
                                {/* <InfoWrapper>
                                    <Title theme={theme} >
                                        Des TPE "Confort", pour encore plus de confort
                                    </Title>
                                    <SubTitle  style={{color: 'rgb(60, 60, 59)'}} >
                                        Pour une gestion simplifiée de votre encaissement. Nos TPE sont mis en service sous 24H avec une tarification adaptée
                                    </SubTitle>
                                </InfoWrapper> */}
                            </div>
                            <div
                                className="justify-center"
                                style={{ flexDirection: 'column' }}
                                lg={10}
                                md={24}
                                sm={24}
                                xs={24}
                            >

                                {/* <ImageWrapper
                                    style={{
                                        maxWidth: '100%',
                                        paddingTop: '0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => navigate('/products/tpe')}
                                >
                                    <StaticImage
                                        style={{
                                            // boxShadow: '#00000020 0px 0px 19px -2px',
                                            width: '25rem',
                                            height: '100%'
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src='../../images/slide/tpe.png'
                                        alt={data.contentfulLanding.title}
                                    />
                                </ImageWrapper> */}

                                {/* <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        padding: '0 30px',
                                    }}
                                > */}
                                    <Link
                                        to={'/products/tpe'}
                                        state={{ pack: 'tpe' }}
                                    >
                                        <SimpleButton  className='tpeBtnBanner'
                                            style={{
                                                maxWidth: '190px',
                                                margin: '0 10px'
                                            }}
                                        >
                                            En savoir plus
                                        </SimpleButton>
                                    </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <BackgroundImage
                        tag='div'
                        {...casaBg}
                        preserveStackingContext
                        key={-4}
                        theme={theme}
                        justify="center"
                        className="auto-height row-padding" >
                        <div className='flex-flow' style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-around' }}>
                            <div style={{ flexDirection: 'column', position: 'relative' }}>
                                <div className='casawe-slide-hearder'>
                                    <StaticImage style={{
                                        width: '9rem',
                                        height: '100%',
                                    }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        className='center-img' src="../../images/slide/logo-casawe.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca' />

                                    <StaticImage style={{
                                        width: '16rem',
                                        height: '6rem',
                                        margin: '0 4rem',
                                        marginTop: '2.5rem'

                                    }}
                                        formats={["auto", "webp"]} placeholder='blurred' src="../../images/slide/3ich-Casa.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca' />
                                    <StaticImage style={{
                                        width: '9rem',
                                        height: '100%',
                                    }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        className='center-img'
                                        src="../../images/logo.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                    />
                                </div>
                                <div className='casawe-slide-hearder-mobile'>
                                    <div style={{ display: 'flex' }}>
                                        <StaticImage style={{
                                            marginRight: 'auto',
                                            width: '4rem',
                                            height: '100%',
                                        }}
                                            formats={["auto", "webp"]}
                                            placeholder='blurred'
                                            className='center-img'
                                            src="../../images/slide/logo-casawe.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                        />
                                        <StaticImage style={{
                                            width: '8rem',
                                            height: '3rem',
                                            margin: 'auto',
                                            marginTop: '0.1rem'
                                        }}
                                            formats={["auto", "webp"]}
                                            placeholder='blurred'
                                            className='center-img'
                                            src="../../images/slide/3ich-Casa.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                        />
                                        <StaticImage style={{
                                            marginLeft: 'auto',
                                            width: '4rem',
                                            height: '100%',
                                        }}
                                            formats={["auto", "webp"]}
                                            placeholder='blurred'
                                            className='center-img'
                                            src="../../images/logo.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                        />
                                    </div>
                                </div>
                                <InfoWrapper>

                                    <SubTitle className='duepuntozero' style={{ fontSize: '1.8rem', textAlign: 'center', color: '#343131' }} theme={'dark'}>
                                        Casablancais, vivez l'expérience de la &lt; Smart City &gt;
                                        <p style={{ marginBottom: 'unset' }}>avec la carte Naps/Casawe </p>
                                        et accéder en toute fluidité aux :
                                    </SubTitle>
                                    <div className='fluidity-cards'>
                                        {fluidity.map((item, i) => (
                                            <Fluidity key={i}>
                                                {item.icon}
                                                <div style={{
                                                    height: '2.5rem',
                                                    alignItems: 'center'
                                                }}>
                                                    <p style={{ fontSize: '0.7rem', textAlign: 'center', color: '#000', fontFamily: 'Maven Pro', fontWeight: '900' }} theme={'dark'}>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </Fluidity>
                                        ))}
                                    </div>
                                </InfoWrapper>
                            </div>
                            <div className="justify-center"
                                style={{ flexDirection: 'column' }}
                            >
                                <ImageWrapper
                                    style={{
                                        // maxWidth: '100%',
                                        padding: 'unset',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    <StaticImage
                                        className='animated-img'
                                        style={{
                                            // boxShadow: '#00000020 0px 0px 19px -2px',
                                            width: '18rem',
                                            height: '100%'
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        // src="../../images/slide/casawe-carte-gold.webp"
                                        src='../../images/slide/carte-casawe-promo.webp'
                                        alt="Carte CasaWe smart city pour les événements et services de la ville de Casablanca"
                                        width='350'
                                    />
                                </ImageWrapper>
                                    <Link
                                        to={'casawe#order'}
                                        state={{ pack: 'casawe' }}
                                    >
                                        <SimpleButton
                                            className='simple-button'
                                            style={{
                                                padding: '0.3rem 0.5rem',
                                                maxWidth: '300px',
                                                margin: '10px 0 0 10px !important',
                                                color: '#fff',
                                                fontWeight: '500',
                                                background: '#e66d30',
                                                fontSize: '16px',
                                                marginBottom: '15px'
                                            }}
                                        >
                                            Je commande ma carte maintenant
                                        </SimpleButton>
                                    </Link>

                            </div>
                        </div>
                    </BackgroundImage>
                    <BackgroundImage
                        Tag='div'
                        {...wacBg}
                        preserveStackingContext
                        key={-3}
                        theme={theme}
                        justify="center"
                        className="auto-height row-padding" >
                        <div className='flex-flow' style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ flexDirection: 'column', position: 'relative' }}>
                                <div className='casawe-slide-hearder' style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <StaticImage style={{
                                        width: '9rem',
                                        height: '100%',
                                        marginRight: 'auto',
                                    }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        className='center-img' src="../../images/slide/logo-casawe.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca' />

                                    <StaticImage style={{
                                        width: '6rem',
                                        height: '100%',
                                        marginLeft: 'auto',
                                    }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        className='center-img'
                                        src="../../images/slide/wydad-logo.webp" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                    />
                                </div>
 
                                <InfoWrapper>

                                    <SubTitle className='duepuntozero' style={{ fontSize: '1.8rem', textAlign: 'center', color: '#343131' }} theme={'dark'}>
                                        Intégrez la famille du WYDAD ATHLETIC CLUB en
                                        <p style={{ marginBottom: 'unset' }}>vous dotant de la nouvelle carte intelligente qui</p>
                                        vous facilitera l’accès au stade. 
                                    </SubTitle>
                                    <div className='fluidity-cards'>
                                        {wacFluidity.map((item, i) => (
                                            <Fluidity key={i}>
                                                {item.icon}
                                                <div style={{
                                                    height: '2.5rem',
                                                    alignItems: 'center'
                                                }}>
                                                    <p style={{ fontSize: '0.7rem', textAlign: 'center', color: '#000', fontFamily: 'Maven Pro', fontWeight: '900' }} theme={'dark'}>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </Fluidity>
                                        ))}
                                    </div>
                                </InfoWrapper>
                            </div>
                            <div className="justify-center"
                                style={{ flexDirection: 'column' }}
                            >
                                <ImageWrapper
                                    style={{
                                         maxWidth: '100%',
                                        padding: 'unset',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    <StaticImage
                                        className='animated-img'
                                        style={{
                                            // boxShadow: '#00000020 0px 0px 19px -2px',
                                            width: '18rem',
                                            height: '100%'
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src="../../images/slide/Carte-Wydad.webp"
                                        alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                                        width='350'
                                    />
                                </ImageWrapper>
                                    <Link
                                        //to="https://casaticketing.ma"
                                        to="/b2c"
                                        state={{
                                            orderType: "wac",
                                            accountType: "TRIBUNE",
                                        }}
                                    >
                                        <SimpleButton
                                            className='simple-button'
                                            style={{
                                                padding: '0.3rem 0.5rem',
                                                maxWidth: '300px',
                                                margin: '10px 0 0 10px !important',
                                                color: '#fff',
                                                fontWeight: '500',
                                                background: '#ed2c35',
                                                fontSize: '16px',
                                                marginBottom: '15px'
                                            }}
                                        >Je commande ma carte
                                             
                                        </SimpleButton>
                                    </Link>

                            </div>
                        </div>
                    </BackgroundImage>
                    <BackgroundImage
                        Tag='div'
                        {...vignetteBg}
                        preserveStackingContext
                        key={-2}
                        theme={theme}
                        justify="center"
                        className="auto-height row-padding"
                    >
                        <div className='flex-flow' style={{ padding: '1rem', alignContent: 'center', display: 'flex', justifyContent: 'space-around' }}>

                            <div className="justify-center">
                                <InfoWrapper>
                                    <Title style={{ color: '#fff' }} >
                                        Vignette 2023 <br /><span style={{ color: '#e66d30', fontWeight: '300' }} >Payez en ligne ou en agence</span>
                                    </Title>

                                    <StaticImage style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src="../../images/vg.png" alt='Carte CasaWe smart city pour les événements et services de la ville de Casablanca'
                                    />
                                    <SubTitle>
                                        Pour plus d'informations, contactez-nous au : 05 22 91 74 74
                                    </SubTitle>
                                </InfoWrapper>
                            </div>
                            <div className="justify-center"
                                style={{ flexDirection: 'column' }}
                            >
                                <StaticImage formats={["auto", "webp"]} placeholder='blurred' style={{ width: '100%', height: '100%' }} src="../../images/dgi.png" alt="dgi" />
                                <ImageWrapper
                                    style={{
                                        // maxWidth: '100%',
                                        paddingTop: '0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    <StaticImage
                                        style={{
                                            width: '25rem',
                                            height: '100%'
                                        }}
                                        formats={["auto", "webp"]}
                                        placeholder='blurred'
                                        src='../../images/slide/vignette-banner.webp'
                                        alt={data.contentfulLanding.title}
                                    />

                                </ImageWrapper>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        padding: '0 30px',
                                    }}
                                >
                                    <a
                                        href='https://vignette.naps.ma/'
                                        rel="noreferrer"
                                        target='_blank'
                                    >
                                        <SimpleButton
                                            style={{
                                                margin: '0 10px',
                                            }}
                                        >
                                            Payez votre vignette
                                        </SimpleButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <div
                        key={-1}
                        theme={theme}
                        justify="center"
                        className="row-padding"
                        style={{ alignContent: 'center', display: 'flex',justifyContent: 'space-around' }}
                    >
                        <div className='flex-flow' style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-around' }}>
                            <div className="justify-center" lg={10} md={24} sm={24} xs={24}>
                                <InfoWrapper>
                                    <Title theme={theme} >
                                        Simplifiez, gérez, napser vos notes de frais
                                    </Title>
                                    <SubTitle style={{color: 'rgb(60, 60, 59)'}}>
                                        Reprenez le contrôle sur les dépenses en cash de votre entreprise et la gestion de vos notes de frais, salaires et avances sur salaires
                                    </SubTitle>
                                </InfoWrapper>
                            </div>
                            <div
                                className="justify-center"
                                style={{ flexDirection: 'column' }}
                                lg={10}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Title
                                        theme={theme}
                                        style={{ color: '#e66d30', textAlign: 'center' }}
                                    >
                                        Nouveau !
                                    </Title>
                                    <LegendTitle
                                        theme={theme}
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            color: '#e66d30',
                                            padding: '0 80px',
                                        }}
                                    >
                                        Découvrez NAPS Pro, <br />
                                        <span
                                            style={{
                                                color: `${theme === 'dark' ? '#fff' : '#000'
                                                    }`,
                                                maxWidth: '150px',
                                            }}
                                        >
                                            {' '}
                                            La solution qui vous permet de simplifier,
                                            maîtriser et automatiser la gestion des notes de
                                            frais et des salaires.
                                        </span>
                                    </LegendTitle>
                                </div>

                                <ImageWrapper
                                    style={{
                                        // maxWidth: '100%',
                                        paddingTop: '0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}
                                    onClick={() => setState({ ...state, showModal: true })}
                                >
                                    {(data.contentfulLanding.largeImage && data.contentfulLanding.smallImage) &&
                                        <Image
                                            largeImage={data.contentfulLanding.largeImage}
                                            smallImage={data.contentfulLanding.smallImage}
                                            alt={data.contentfulLanding.title}
                                        />
                                    }

                                    {state.browser === 'ie' ? (
                                        <span
                                            className="material-icons"
                                            style={{
                                                position: 'absolute',
                                                left: 'calc(50% - 30px)',
                                                top: 'calc(50% - 35px)',
                                                fontSize: '50px',
                                                cursor: 'pointer',
                                                width: '60px',
                                                zIndex: 2,
                                            }}
                                        >
                                            play_arrow
                                        </span>
                                    ) : (
                                        <StaticImage
                                            src='../../images/play.inline.png'
                                            onClick={() =>
                                                setState({ ...state, showModal: true })
                                            }
                                            formats={["auto", "webp"]}
                                            style={{
                                                position: 'absolute',
                                                left: 'calc(50% - 30px)',
                                                top: 'calc(50% - 30px)',
                                                fontSize: '50px',
                                                cursor: 'pointer',
                                                width: '60px',
                                                zIndex: 10,
                                            }}
                                            alt=''
                                        />
                                    )}
                                </ImageWrapper>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        padding: '0 30px',
                                    }}
                                >
                                    <Link
                                        to={'/pro/entreprise'}
                                        state={{ pack: 'family' }}
                                    >
                                        <SimpleButton
                                            style={{
                                                maxWidth: '190px',
                                                margin: '0 10px',
                                            }}
                                        >
                                            En savoir plus
                                        </SimpleButton>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

            <VdModal
                url="https://www.youtube.com/embed/oJpPl4gSagQ"
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
            ></VdModal>
            <VdModal
                url="https://www.youtube.com/embed/COIQPN4neOw"
                showModal={state.showModalFamily}
                hide={() => setState({ ...state, showModalFamily: false })}
            ></VdModal>
            <Popup
                overlayStyle={{ background: '#00000090' }}
                modal
                nested
                onClose={() => setState({ ...state, showBanner: false })}
                open={state.showBanner}
            >
                <CloseIcon
                    className="close"
                    onClick={() => setState({ ...state, showBanner: false })}
                >
                    &times;
                </CloseIcon>
                <Banner />
            </Popup>
        </>
    );
};
export default Landing;
