import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  body {
      margin:0;
      color: rgba(0,0,0,.8);
      font-family: Roboto,Helvetica,sans-serif;
      font-weight: 400;
      min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
      background: ${({ theme }) =>
    theme === 'light' ? '' : 'rgb(43, 42, 42)'};
     /* overflow-x: hidden; */
      /* overflow-y: auto; */
  }
  html {
    font: 112.5%/1.45em georgia,serif;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
      /* overflow-y: auto; */ 
  }
  
  .activeLink {
    border-bottom:2px solid #e66d30;
    padding-bottom: 3px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal {
    width: 100%;
  }
  .ant-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
  }
  .ant-modal-body {
    width: 100%;
    padding: 0;
  }
  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover {
    border-bottom: none;
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item a:hover, .menu-horizontal > .ant-menu-submenu:hover {
    color: #e66d30;
  }
  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }
  .menu-horizontal > .ant-menu-submenu:hover {
    color: #e66d30;
  }
  .ant-menu-submenu-title:hover {
    color: #e66d30;
  }
  .ant-menu-submenu-title {
    color: ${({ theme }) => (theme === 'light' ? '#000000' : '#ffffff')};
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Maven Pro', sans-serif;
  }
  .ant-menu-horizontal > .ant-menu-item a {
    color: #000000;
  }
  .ant-menu-horizontal {
    border-bottom: none;
  }
  a {
  &:hover {
    color: #d4d4d4;
  }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #fff;
  }
  .ant-modal-close-x {
    color: #dedede;
    margin-top: 10px;
  }
  .main-ant-layoute {
    background-color: #ffffff;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
  }
    .ant-drawer-body {
        background: ${({ theme }) => (theme === 'dark' ? '#212121' : '#fff')};
    }
    .vertical-timeline-element-content-arrow {
      background-color: transparent;
      border-right: none;
    }
    .proback {
      fill: ${({ theme }) => (theme === 'dark' ? '#212121' : '#F6F6F6')};
    }
    .ant-menu-item a {
      color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#212121')};
    }
    .ant-menu-submenu > .ant-menu {
      background-color: ${({ theme }) =>
    theme === 'dark' ? '#212121' : '#fff'}
    }
    .row-index {
      z-index: 1;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
      border-bottom: none;
      color: none;
    }
    .ant-drawer.ant-drawer-left.ant-drawer-open.no-mask.styles__SideDrawer-NCln.dHJIVP.drawer.no-mask {
      width: 100% !important;
    }
      .auto-top-margin {
        margin-top: 5rem !important;
      }
    @media (max-width: 801px){
      h1 {
        font-size: 1.5rem !important;
        padding-top: 1rem !important;
      }
      div {
        font-size: 14px !important;
      }
      .auto-top-margin {
        margin-top: 5rem !important;
      }
      .slick-prev {
        display: none !important;
      }
      .slick-next {
        display: none !important;
      }
    }
    @media (max-width: 1592px, min-width: 1536px) {
      .main-content {
        padding: 5rem;
      }
    }
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 70vh;
    background: ${({ theme }) => (theme === 'light' ? '' : 'rgb(43, 42, 42)')};
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
