import React from 'react';
import Layout from '../../components/layout';
import FranchisesTemplate from '../../components/franchises';

const Franchises = () => {
    return (
        <Layout>
            <FranchisesTemplate />
        </Layout>
    );
};

export default Franchises;
