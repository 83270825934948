import React from 'react';
import Layout from '../../components/layout';
import IndividualLanding from '../../components/individual/landing';
import IndividualUsage from '../../components/individual/usage';
import IndividualSubscription from '../../components/individual/subscription';
import IndividualRecharge from '../../components/individual/recharge';
import IndividualOrder from '../../components/individual/order';
import ManualOrder from '../../components/individual/manual-order';
import Family from '../../components/individual/family';
import Casawe from '../../components/individual/casawe';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';
const IndividualPage = ({ location }) => {
    return (
        <Layout location={location}>
            <IndividualLanding />
            <IndividualUsage />
            <IndividualRecharge />
            <Casawe />
            {/* <Family /> */}
            <IndividualSubscription />
            <IndividualOrder location={location} />
            <ManualOrder />
        </Layout>
    );
};

export default IndividualPage;
