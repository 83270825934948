const { default: styled } = require('styled-components');

export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: inherit;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 1rem;
    margin: 0;
    font-weight: bold;
    text-align: center;
`;
