import styled from 'styled-components';

export const CategoryName = styled.h1`
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-family: 'Maven Pro', sans-serif;
    margin-top: -60px;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : ''};
    @media(max-width: 538px) {
        font-size: 18px;
        margin: 0;
    }
`;
