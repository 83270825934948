import React from 'react';
import {
    InfoWrapper,
    Title,
    SecendTitle,
    Button,
    ImageWrapper,
} from '../landing/styles';
import './styles.css';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ThemeContext from '../layout/theme/ThemeContext';
import { SubTitleWrapper } from '../common/SimpleRow/styles';
import { Image } from '../image';

const Pro = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "pro" }) {
                id
                title
                subTitle
                largeImage: image {
                    gatsbyImageData(width: 500, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 70)
                }
                smallImage: image {
                    gatsbyImageData(width: 250, formats: [AUTO, WEBP],placeholder: BLURRED, quality: 50)
                }
                description {
                    raw
                }
            }
        }
    `);

    return (
        <div className="container mx-auto row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" justify="center">
            <div
                className="justify-center"
                xxl={9}
                xl={10}
                lg={12}
                md={12}
                sm={24}
                xs={24}
            >
                <InfoWrapper>
                    <SecendTitle >
                        {data.contentfulLanding.title}
                    </SecendTitle>
                    <SubTitleWrapper >
                        {documentToReactComponents(JSON.parse(
                            data.contentfulLanding.description.raw)
                        )}
                    </SubTitleWrapper>
                    <Link to="/b2b">
                        <Button>Ceci est pour vous !</Button>
                    </Link>
                </InfoWrapper>
            </div>
            <div
                className="justify-center"
                xxl={9}
                xl={10}
                lg={12}
                md={12}
                sm={24}
                xs={24}
            >
                <ImageWrapper style={{ maxWidth: '80%', padding: '0px' }}>
                    {(data.contentfulLanding.largeImage && data.contentfulLanding.smallImage) && (
                        <Image
                            largeImage={data.contentfulLanding.largeImage}
                            smallImage={data.contentfulLanding.smallImage}
                            alt={data.contentfulLanding.title}
                        />
                    )}
                </ImageWrapper>
            </div>
        </div>
    );
};
export default Pro;
