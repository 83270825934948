import React, { useEffect } from 'react';
import { Wrapper, Button, Value } from './styles';
import { TextField } from '../../text-field';
import { useState } from 'react';
const RechargeField = ({ value, setValue, max, name, accountType }) => {
    const [state, setState] = useState({
        overMax: false,
        isFirst: true,
    });
    const handPlusClick = () => {
        if (parseFloat(value + 100) <= max) {
            setValue(name, value + 100);
            setState({
                ...state,
                overMax: false,
                isFirst: false,
            });
        } else {
            setState({
                ...state,
                overMax: true,
                isFirst: false,
            });
        }
    };
    useEffect(() => {
        setValue(name, 100);
    }, [accountType]);
    const handMinsClick = () => {
        if (value > 0 && parseFloat(value - 100) >= 0) {
            setValue(name, value - 100);
            setState({
                ...state,
                overMax: false,
                isFirst: false,
            });
        }
    };
    return (
        <Wrapper>
            <Button onClick={handMinsClick} type="button">
                -
            </Button>
            <TextField
                label="Montant (DHS)"
                placeholder="Montant (DHS)"
                name={name}
                type="number"
                overMax={state.overMax}
                max={max}
                accountType={accountType}
                keyPress={(e) => {
                    if (parseFloat(value + e.key) > max) {
                        e.preventDefault();
                        setState({
                            ...state,
                            overMax: true,
                            isFirst: false,
                        });
                    } else {
                        setState({
                            ...state,
                            overMax: false,
                            isFirst: false,
                        });
                    }
                    
                }}
            ></TextField>
            <Value>DHS</Value>
            <Button onClick={handPlusClick} type="button">
                +
            </Button>
        </Wrapper>
    );
};
export default RechargeField;
