import React from 'react';
import Layout from '../../components/layout';
import ContactTemplate from '../../components/contact';
import SEO from '../../components/seo';

const Contact = () => {
    return (
        <Layout notTransparent={true}>
            <SEO
                title="Contact"
                description="Vous avez besoin d'un conseil ou d'un
                            accompagnement ?, Remplissez le formulaire ci-après, nous serons ravis
                            de vous contacter !"
                url="/contact"
            />
            <ContactTemplate />
        </Layout>
    );
};
export default Contact;
