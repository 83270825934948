import React, { useState } from 'react';
import {Row, Col,Spin} from 'antd';
import {TextField} from '../../text-field';
import {Select} from '../../select';
import {Title, ButtonWrapper, Button, BackButton} from '../styles';
import { StaticImage } from 'gatsby-plugin-image';
import stadeTribune from '../../../images/stade-tribune.webp'
import stadePelouse from '../../../images/stade-pelouse.webp'
import stadePelouseZ4 from '../../../images/stade-pelouse-Z4.png'
import stadePelouseZ6 from '../../../images/stade-pelouse-Z6.png'
import { CheckBoxButton } from '../../checkbox-button';
import { SelectField, Label } from '../../select/select.styles';
import { CloseIcon } from '../../common/vd-modal/styles';
import { Link } from 'gatsby';


import Popup from 'reactjs-popup';

import {
    ImageWrapper,
} from '../../landing/styles';


import {navigate} from "@reach/router";
import { values } from 'lodash';

const cardTypes = [
    {value: 'TRIBUNE', label: 'TRIBUNE - 1550 Dh'},
    {value: 'PELOUSE', label: 'PELOUSE - 550 Dh'},
];

const tribuneZone = [
    {value: 'ZONE 2', label: 'zone 2'},
];

const pelouseZone = [
    {value: 'ZONE 4', label: 'zone 4'},
    {value: 'ZONE 6', label: 'zone 6'},
];

export const WacOrderForm = ({
                                nextStep,
                                isValid,
                                setTouched,
                                validate,
                                setValue,
                                values,
                                orderType,
                                accountType,
                                openNotification,
                            }) => {
    const handleNextClick = () => {
        setTouched({
            wydadTerms: true,
            accountType:true,
            widadCardZone:true,
        });
        validate().then((errors) => {
            console.log(errors)
            if (Object.keys(errors).length === 0) {
                nextStep();
                setValue('step', 1);
            } else {
                openNotification();
            }
        });
    };
    const [state, setState] = useState({
        zone:'',
        openTermsPopup: false,
    });

    const [stateZone, setStateZone] = useState({
        zones:[]
    });
    const [stateImg, setStateImg] = useState({
        imgUrl:stadeTribune
    });
    const handleSelectCardType = (e) => {
        console.log(e.target.value)
        accountType = e.target.value
        setValue("accountType",e.target.value)
        console.log(accountType)
        if(accountType ==="TRIBUNE"){
            setStateZone({
                ...state,
                zones: tribuneZone,
            });
            setStateImg({
                ...state,
                imgUrl: stadeTribune,
            });
        }else{
            setStateZone({
                ...state,
                zones: pelouseZone,
            });
            setStateImg({
                ...state,
                imgUrl: stadePelouse,
            });
        }
     }
    const handleSelectOption = (e) => {
        setState({
            ...state,
            zone: e.target.value,
        });
        setValue("widadCardZone",e.target.value)
        if(e.target.value === "ZONE 4"){
            setStateImg({
                ...state,
                imgUrl: stadePelouseZ4,
            });
        }else if(e.target.value === "ZONE 6"){
            setStateImg({
                ...state,
                imgUrl: stadePelouseZ6,
            });
        }
        else {
            setStateImg({
                ...state,
                imgUrl: stadeTribune,
            });
        }
     }
    return (
        <>
            <Row align="middle" className='field-left'  gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{padding: '0px 8px'}}
                >
                    <Title>Choisissez l'abonnement qui vous convient</Title>
                </Col>
            </Row>
            <div style={{display: 'flex', flexWrap: 'wrap'}} >
                <div style={{flex: '50%'}}>
                    <Row align="top"  className='field-left'>
                     <Col style={{width: '80%',padding: "12px 8px"}}>
                     <Label> Type de carte * </Label>
                            <SelectField
                                label="Type de carte"
                                placeholder=""
                                name="accountType"
                                maxLength={100}
                                onChange={handleSelectCardType}
                            >
                            <option value=""></option>
                                {cardTypes.map((option) => (
                                <option value={option.value}>{option.label}</option>
                                ))}
                            </SelectField>
                        </Col>
                    </Row>
                    <Row align="top"  className='field-left' >
                        <Col style={{width: '80%',padding: "12px 8px"}}>
                        <Label> Choix de la zone * </Label>

                            <SelectField
                                name="widadCardZone"
                                onChange={handleSelectOption}
                            >
                                <option value=""></option>
                                  {stateZone.zones.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                    ))}
                            </SelectField>

                        </Col>
                    </Row>
                </div>
                <div style={{flex: '50%'}}>
                    <ImageWrapper
                                    style={{
                                        maxWidth: '100%',
                                        padding: 'unset',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    <img
                                        className='animated-img'
                                        style={{
                                            width: '35rem',
                                            height: '100%'
                                        }}
                                        placeholder='blurred'
                                        src={stateImg.imgUrl}
                                        alt="La carte Naps pour les fans du Wydad Sports Club sera lancée"
                                        width='350'
                                    />
                    </ImageWrapper>
                </div> 
            </div>  
            <Row align="top"  className='field-left' >
                        <Col style={{width: '80%'}}>
                            <CheckBoxButton
                                value={values.wydadTerms}
                                linkText="Je confirme avoir lu et accepté les Conditions générale de vente"
                                onClick={() => {
                                    setState({
                                        ...state,
                                        openTermsPopup: true,
                                    });
                                }}
                                name="wydadTerms"
                            ></CheckBoxButton>
                            {/* <CheckBox
                                name="wydadTerms"
                                type="checkbox"
                                value={values.wydadTerms}
                             />
                            
                                <button type="button" onClick={() => {
                                            setState({
                                                ...state,
                                                openTermsPopup: true,
                                            });
                                        }} >
                               <a style={{color: '#0390fc'}} rel="noreferrer" target="_blank">Je confirme avoir lu et accepté les Conditions générale de vente</a>
                                </button>
                                <Error>{errorText}</Error> */}
                        </Col>
                    </Row>   
            <Row align="top" style={{marginBottom: '20px',float: 'right'}}>
                <Col xs={24} sm={24} md={22} lg={16} xl={16} xxl={14} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <ButtonWrapper>
                        <BackButton
                            type="button"
                            onClick={() =>
                                navigate(-1)
                            }
                        >
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                    <ButtonWrapper>

                        <Button type="button" onClick={handleNextClick}>
                            Continuer
                        </Button>
                    </ButtonWrapper>
                </Col>
                 <Popup
                    overlayStyle={{
                        background: '#00000090',
                    }}
                    modal
                    nested
                    open={state.openTermsPopup}
                    onClose={() =>
                        setState({
                            ...state,
                            openTermsPopup: false,
                        })
                    }
                >
                    <div
                        style={{
                            width: '800px',
                            height: '500px',
                            padding: '0 20px',
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                        className="modal"
                    >
                        <CloseIcon
                            className="close"
                            onClick={() =>
                                setState({
                                    ...state,
                                    openTermsPopup: false,
                                })
                            }
                        >
                            &times;
                        </CloseIcon>
                        <p
                            style={{
                                width: '100%',
                                padding:'10px 0',
                                fontFamily: 'Maven Pro',
                                fontWeight: '500',
                                overflowY:'scroll'
                            }}
                        >
               
               • La souscription d’un abonnement auprès du Wydad Athletic Club pour la saison 2022/2023 emporte l’adhésion aux conditions générales suivantes.
               <br/><br/>
• ARTICLE 1 – OBJET : La présente carte d’abonnement donne droit d’accès au stade pour
assister aux matchs à domicile du Wydad Athletic Club se déroulant au Complexe
Mohammed V dans le cadre de la Botola Pro Inwi, de la Coupe du Trône et des compétitions
interclubs de la CAF pour la saison 2022/2023.
<br/>
• ARTICLE 2 – UTILISATION DE LA CARTE : L’abonné reconnaît que pour des impératifs de
sécurité, il est nécessaire que son identité et son adresse soient connus. Les cartes perdues
ou volées ne seront pas remboursées. Aucun duplicata ne sera délivré en cas de perte ou de
vol. En cas de perte ou de vol de son abonnement, l’abonné doit immédiatement en informer
le club afin d’éviter qu’il ne reste responsable de son utilisation abusive.
Toute utilisation de la carte d'abonnement est subordonnée au respect du règlement
général par l'abonné qui, reconnaît en avoir pris entière connaissance et accepter ses
termes.
<br/>
• ARTICLE 3 – CONDITIONS D’ACCES : L’abonné doit présenter son abonnement au contrôle
pour entrer dans le stade. L’abonné devra se conformer à toutes les règles existantes ou
susceptibles d’être édictées par le Wydad Athletic Club, la FRMF, la LNFP ou la CAF. L’abonné
s’engage à respecter les consignes, notamment de sécurité prises par l’organisateur du
match et les autorités locales. Toute sortie du stade est considérée comme définitive.
La carte est strictement personnelle. Les mineurs non accompagnés de leur tuteur ne
peuvent accéder au stade.
<br/>
• ARTICLE 4 – INFRACTION AU REGLEMENT :
L'abonné est tenu de respecter les mesures de sécurité imposées par le club ainsi que par les
autorités locales. Tout manquement aux dispositions réglementaires dont les mesures de
sécurité et toute infraction constatée, fraude ou tentative de fraude entraînera de plein droit
la résiliation de l’abonnement sans remboursement et sans préjudice de poursuites pénales.
<br/>
• ARTICLE 5 – DEMANDE D'ABONNEMENT: La commande en ligne d'une carte d'abonnement
fait foi de demande d'abonnement. Le club se réserve le droit d'accepter ou de refuser toute
demande d'abonnement. Le Wydad Athletic Club n'est pas tenu de motiver toute décision
prise au sujet des demandes d'abonnement. En cas de refus de demande, la somme payée
par le demandeur lui sera restituée.
<br/>
• ARTICLE 6 – Le Wydad Athletic Club n’encourra aucune responsabilité du fait de la
survenance de tout événement constituant un cas de force majeure ou survenant du fait
d’un tiers et notamment, intempéries, grèves, changement de réglementation, suspension
de terrain, décisions de toute autorité compétente en matière de sécurité et de discipline,
notamment impossibilité d’utiliser le stade ou certains espaces de celui-ci.
Le Wydad Athletic Club se réserve le droit de prendre les décisions qui s'imposent en matière
d'utilisation de carte d'abonnement pour des évènements à caractère exceptionnel.
L’abonné renonce expressément à toute indemnité de quelque nature que ce soit, en cas de
survenance d’un des faits visés ci-dessus.
<br/>
Le Wydad Athletic Club se réserve le droit de modifier les conditions générales de ventes à condition d'en informer préalablement l'abonné par tout moyen de communication.
• ARTICLE 7 – Tout litige relatif à l’exécution ou à l’interprétation du présent règlement est régi par la loi marocaine. Les tribunaux de Casablanca sont les seuls compétents à ce sujet.
Je déclare avoir pris connaissance et accepter expressément les conditions générales énoncées ci-dessus.
           
                        </p>

                        <ButtonWrapper>
                                    <Button type="button" onClick={() =>
                                            setState({
                                                ...state,
                                                openTermsPopup: false,
                                            })
                                        }>
                                        Ok
                                    </Button>
                        </ButtonWrapper>
                    </div>
                </Popup>  
            </Row>
        </>
            // <div style={{display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // flexDirection: 'column'}}>
            //     <Title style={{color:'#e2651a', marginTop:'10rem'}}>
            //         La vente des cartes WAC sera reprise ultérieurement.
            //     </Title>
            //     <Row align="top" justify="center" style={{marginBottom: '20px'}}>
            //         <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12} style={{display: 'flex'}}>
            //             <Link className='back-btn' to="/">Retour</Link>
            //         </Col>
            //     </Row>
            // </div>

    );
};
