import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Error } from '../../contact/form/text-field/test-field.styles';
import Recaptcha from 'react-recaptcha';

const FormReCaptcha = ({ setValue, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const verifyReCaptchaCallback = (response) => {
        setValue(field.name, response);
    };

    const siteKey = process.env.RECAPTCHA_SITE_KEY
    console.log("site key : "+siteKey)
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
    return (
        <div style={{padding: '5px'}}>
            <Recaptcha
                sitekey="6LeUp2QhAAAAAC27fMR8JNr664iIMX3HqPyQ3xAx"
                verifyCallback={verifyReCaptchaCallback}
            />
            <Error>{errorText}</Error>
        </div>
    );
};
export default FormReCaptcha;
