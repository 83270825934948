import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import {
    InfoWrapper,
    Title,
    ImageWrapper,
    AnimatedImage,
    SubTitle,
    Button,
    ButtonWrapper,
    ButtonIcon,
} from '../../../common/SimpleRow/styles';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql, Link } from 'gatsby';
import VdModal from '../../../common/vd-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { EcommerceRow } from './styles';
import { detect } from 'detect-browser';

const Landing = ({ data }) => {
    const [state, setState] = useState({
        showModal: false,
    });

    const { theme } = useContext(ThemeContext);

    const br = detect();

    return (
        <>
            <EcommerceRow br={br?.name} theme={theme} justify="center">
                <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
                    <div className="justify-center" lg={9} md={24} sm={24} xs={24}>
                        <InfoWrapper>
                            <Fade left>
                                <Title theme={theme}>
                                    {data.contentfulLanding.title}
                                </Title>
                            </Fade>
                            <Fade top>
                                <SubTitle theme={theme}>
                                    {data.contentfulLanding.subTitle}
                                </SubTitle>
                            </Fade>
                        </InfoWrapper>
                    </div>
                    <div className="justify-center" lg={9} md={24} sm={24} xs={24}>
                        <ImageWrapper>
                            <Fade right>
                                <AnimatedImage
                                    style={
                                        br?.name === 'ie' ? { width: '24rem' } : {}
                                    }
                                    src={data.contentfulLanding.image.file.url}
                                />
                            </Fade>
                        </ImageWrapper>
                    </div>
                </div>
            </EcommerceRow>
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://drive.google.com/file/d/18bHMBf6Xguj8NuxWsMajJT50_2t65SR7/view?usp=sharing"
            />
        </>
    );
};

export default Landing;
