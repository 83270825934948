import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const CheckBox = styled.input`
    margin-right: 10px;
    width: 50px;
    
`;
