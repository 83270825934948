import React, { useEffect,useState,useContext } from 'react';

import {Row, Col,Modal} from 'antd';
import axios from 'axios';
import {TextField} from '../../text-field';
import whiteHourglass from '../../../images/hourglass-white.svg'
import { CheckBoxField } from '../../checkbox-field';
import { http } from '../../http';
import RadioField from '../../common/radio-field';
import ticketIcon from '../../../images/icons-ticket.png'

import {
    onlyCharacters,
    emailKeyPress,
    onlyNumbers,
    adresseKeyPress,
} from '../../helpers/only-characters';
import {Title,  
        Wrapper,
        HeaderCard,
        BodyCard,
        ZoneCard,
        EventName,
        Offer,
        Button,
        EventDate,SubTitle, ButtonWrapper, BackButton} from '../styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../../layout/theme/ThemeContext';

const marchantId= process.env.NAPS_GATEWAY_MARCHANT;
const apiKey= process.env.API_KEY;
const ticketingUrl= process.env.TICKETING_URL;


export const PaymenReceipt = ({
                                values,
                                back,
                                setValue,
                            }) => {

const { theme } = useContext(ThemeContext);

const handleBackClick = () => {
    setValue('step', 0);
    back();
}; 
const downloadRecipt = () => {
    http.post('/card-order/download', values.receipt,{ responseType: 'blob'}).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', 'billet.pdf'); 
         document.body.appendChild(link);
         link.click();
      });
}
    useEffect(() => {
        // http.post('/card-order/paymentticketssucceeded/',{ data: values.data, codecmr: values.codecmr }).then(
        //     (res) => {
        //         setValue('receipt',res.data)
        //         setValue('fullName',res.data.fullName)
        //         setValue('clientId',res.data.clientId)
        //     }
        // );
    }, []);

    return (
        <>
                <Row align="middle" gutter={[16, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={14}
                    xl={14}
                    xxl={12}
                    style={{padding: '0px 8px'}}
                >
                    <Title>Télécharger Votre reçu </Title>
                </Col>
            </Row>

            <Wrapper style={{margin:'2rem auto',height:'250px',maxWidth:'400px', borderRadius:'1rem'}} theme={theme}>
                    <Offer style={{margin:'auto'}}>
                    <img
                        src={ticketIcon}
                        style={{
                            color: "#205993",
                            width: '5rem',
                            margin:'0.5rem auto'
                        }}
                    />
                    <p>Nom : {values.receipt.fullName}</p>
                    <p>ID Client : {values.receipt.clientId}</p>
                    <Button style={{margin:'1rem auto' ,height: '35px',padding: '0 20px',fontWeight: '500'}} 
                         onClick={downloadRecipt}  type="button" >
                            Télécharger Votre reçu
                    </Button>
                    </Offer>
            </Wrapper>
            <Row align="top" justify="center" style={{marginBottom: '20px'}}>
                <Col xs={24} sm={24} md={20} lg={14} xl={14} xxl={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <ButtonWrapper>
                        <BackButton
                            type="button"
                            onClick={handleBackClick}
                        >
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
            </Row>
        </>
    );
};
