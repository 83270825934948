import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ImageWrapper = styled.div`
    width: 110px;
    z-index: 1;
    transition: 0.2s ease-in-out;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
`;
export const Wrapper = styled.div`
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s ease-in-out;
`;
export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-size: 15px;
    letter-spacing: -0.17px;
    color: #ffa055;
    padding: 5px;
    font-weight: 100;
    background-color: #f3f3f3;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
`;

export const Image = styled.img`
    max-width: 100%;
`;
export const Content = styled.p`
    font-family: Rajdhani, sans-serif;
    font-size: 13px;
    color: #000000;
    padding: 10px;
`;
export const IconWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Icon = styled(FontAwesomeIcon)`
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    font-size: 25px;
    color: #e66d30;
    width: 35px;
    height: 25px;
`;
