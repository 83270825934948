import styled from "styled-components";

export const Text = styled.p`
    padding: 0;
    color: #383838;
    font-size: 24px;
    font-family: Rajdhani, sans-serif;
    font-weight: 500;
    max-width: 767px;
    text-align: center;
`;