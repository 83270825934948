import React from 'react';
import { Label, Error } from '../text-field/test-field.styles';
import { Select } from 'antd';
import { useField } from 'formik';
import { MultiSelect } from './style';
import './styles.css';
const MultipleSelectField = ({
    label,
    placeholder,
    data,
    setValue,
    ...props
}) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const options =
        data &&
        data.map((option, i) => (
            <Select.Option key={i} value={option.value} label={option.label}>
                {' '}
                {option.label}{' '}
            </Select.Option>
        ));
    return (
        <div>
            <Label> {label} </Label>
            <MultiSelect
                showSearch ={false}
                mode="multiple"
                placeholder={placeholder}
                {...field}
                helperText={errorText}
                error={!!errorText}
                onChange={(value) => setValue(field.name, value)}
            >
                {options}
            </MultiSelect>
            <Error>{errorText}</Error>
        </div>
    );
};

export default MultipleSelectField;
