import React from 'react';
import { useField } from 'formik';
import { Radio } from 'antd';
import { Error } from '../../select/select.styles';
const RadioField = ({ disabled, values, onChange,flexDirection, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <div>
            <Radio.Group style={{display: 'flex',flexDirection: flexDirection === 'column' ? 'column' : 'row'}}
                {...field}
                disabled={disabled}
                error={!!errorText}
                onChange={(e) => {
                    if (onChange) onChange(e);
                    field.onChange(e);
                }}
            >
                {values.map((item, index) => {
                        return (
                            <Radio key={index} value={item.value}>
                                {item.label}
                            </Radio>
                        );
                    
                })}
            </Radio.Group>
            <br />
            <Error>{errorText}</Error>
        </div>
    );
};

export default RadioField;
