import React, { useContext } from 'react';
import { Wrapper, Content, Title } from './styles';
import ThemeContext from '../../layout/theme/ThemeContext';
const FamilyCard = ({ content, title }) => {
    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper theme={theme}>
            <Title theme={theme} >{title}</Title>
            <Content>{content}</Content>
        </Wrapper>
    );
};

export default FamilyCard;
