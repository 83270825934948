import React, { useEffect } from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import ArticlesVideo from '../../../components/news/videos';

const ArticlesVideos = () => {
    return (
        <Layout >
            <SEO title="Videos" url={'/news/videos'}/>
            <div style={{ padding: '150px 0' }}>
                <ArticlesVideo/>
            </div>
        </Layout>
    );
};
export default ArticlesVideos;
