import React, { useState } from 'react';
import {
    Title,
    Button,
    ArticleImage,
    ArticleWrapper,
    SubTitle,
} from './styles';
import { Col } from 'antd';

export const ArticleCard = ({
    title,
    content,
    image,
    button,
    link,
    onMouseEnter
}) => {
    const [state, setState] = useState({
        displayContent: '',
        showAll: false,
    });
    return (
        <ArticleWrapper className="wrapper-article" onMouseEnter={onMouseEnter}>
            <Col className="justify-center">
                <div className="style-article">
                    <Title >
                        {title}
                    </Title>

                    <SubTitle >
                        {state.showAll ? content : content.substring(0, 200) + '...'}
                    </SubTitle>
                </div>
                <ArticleImage
                    src={image}
                />
            </Col>
            <a href={link} rel="noreferrer" target="_blank" >
                <Button>
                    Lire Article
                </Button>
            </a>
        </ArticleWrapper>
    );
};

export default ArticleCard;
