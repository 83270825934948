import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import ProductLanding from '../../../components/products/landing';
import Tpe from '../../../components/products/tpe';
import { useStaticQuery, graphql } from 'gatsby';

const TpePage = () => {
    const { contentfulLanding } = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "primary-tpe-landing" }) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Produits" url={'/products/tpe'} />
            <ProductLanding pack="tpe" {...contentfulLanding} />
            <Tpe />
        </Layout>
    );
};

export default TpePage;
