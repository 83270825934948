import React, { useState } from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';
import { SecondTitle, Wrapper } from '../../individual/order/styles';
import { SubTitle} from '../../form/styles';
import {Row, Col } from 'antd';
import ThemeContext from '../../layout/theme/ThemeContext';
import { useContext } from 'react';
import './styles.css';

const CasaweGeolocation = () => {
    const data = [
        {
            "name": "Gauthier",
            "address": "20,rue Moussa bnou Nousseir , Quartier Gauthier",
            "geolocationUrl": "https://www.google.com/maps?q=33.5905767,-7.6282445&z=17&hl=fr",
        },
        {
            "name": "Dar Lamane",
            "address": "129 , Bd Ali Yaata RD Hay smara",
            "geolocationUrl": "https://goo.gl/maps/ws8D8Uhn49EXNDPu9",
        },
        {
            "name": "Moulay Rachid",
            "address": "Lotissement beld el khier lot n 15 bd med bouziane,quartier moulay rachid ",
            "geolocationUrl": "https://goo.gl/maps/afrMhgDbcX3yWMtu6",
        },
        {
            "name": "Sidi Moumen",
            "address": "3 LOT AL WALAA S1 T6 MAG ",
            "geolocationUrl": "https://goo.gl/maps/Kt8X4VR2n3U1S7UX6",
        },
        {
            "name": "Koutoubia",
            "address": "N° 56, Rue 1 Allée Jbel Saghro , Hay Moulay Abdellah, Ain Chok ",
            "geolocationUrl": "https://goo.gl/maps/2cLDUP797RPDyZDM8",
        },

    ]
    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper
            id="order"
            style={{ display: 'flex', justifyContent: 'center' }}
            className="container mx-auto"
        >
            <SecondTitle theme={theme}>Achetez votre carte CasaWe en agence</SecondTitle>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col
                    style={{ textAlign: 'left' }}
                >
                    <table class="table">
                        <thead class="thead">
                            <tr style={{ width: '100%' }}>
                                <th style={{padding:'0 30px 0 30px'}}>
                                    <SubTitle  
                                        style={{
                                            color: 'white',
                                            margin: '0',
                                            padding: '0',
                                            fontSize: '18px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Nom agence
                                    </SubTitle>
                                </th>
                                <th style={{padding:'0 30px 0 30px'}}>
                                    <SubTitle
                                        style={{
                                            color: 'white',
                                            margin: '0',
                                            padding: '0',
                                            fontSize: '18px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Adresse
                                    </SubTitle>
                                </th>
                                <th style={{padding:'0 30px 0 30px'}}>
                                    <SubTitle
                                        style={{
                                            color: 'white',
                                            margin: '0',
                                            padding: '0',
                                            fontSize: '18px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Géolocalisation
                                    </SubTitle>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">
                            {data?.map((event, key) => {
                                return (
                                    <>
                                        <tr style={{ width: '100%' }}>
                                            <td>
                                                <SubTitle
                                                    style={{
                                                        color: '#000',
                                                        margin: '0',
                                                        padding: '0',
                                                    }}
                                                >
                                                    {event.name}
                                                </SubTitle>
                                            </td>
                                            <td>
                                                <SubTitle
                                                    style={{
                                                        color: '#000',
                                                        margin: '0',
                                                        padding: '0',
                                                    }}
                                                >
                                                    {event.address}
                                                </SubTitle>
                                            </td>
                                            <td>
                                                <SubTitle
                                                    style={{
                                                        color: '#15c',
                                                        margin: '0',
                                                        padding: '0',
                                                    }}
                                                >
                                                    <a href={event.geolocationUrl} target='_blank' >Aller à l'agence {event.name}</a>
                                                </SubTitle>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Wrapper>
    );
};
export default CasaweGeolocation;
