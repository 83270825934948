import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Advantage from '../ecommerce/advantage';
import Payment from '../ecommerce/payment';
import Landing from '../ecommerce/landing';

const PayByEmail = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(
                templateKey: { eq: "product-landing-pay-by-email" }
            ) {
                id
                title
                subTitle
                offers {
                    offer_name
                }
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <>
            <Landing data={data} />
            <Advantage />
            <Payment />
        </>
    );
};

export default PayByEmail;