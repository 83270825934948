import React, { useState, useContext } from 'react';
import { Button, Row, Col } from 'antd';
import { Title,SecondTitle, Wrapper, ButtonsWrapper, Hint, Text, HintCol } from './styles';
import { useStaticQuery, graphql } from 'gatsby';

import './styles.css';
import OrderCard from '../../common/order-card';
import { useEffect } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
const IndividualOrder = ({ location,orderType }) => {
    const [state, setstate] = useState({
        orderType: 'individual',
        accountType: '',
    });
    const [referrer, setreferrer] = useState({
        referrer: '',
    });
    const handleOrderTypeChange = (type) => {
        setstate({
            ...state,
            orderType: type,
        });
    };
    const data = useStaticQuery(graphql`
        query {
            allContentfulOffer(sort: { fields: order, order: ASC }) {
                nodes {
                    accountType
                    price
                    priceString
                    offers {
                        name
                    }
                }
            }
        }
    `);
    useEffect(() => {
        if (location.state && location.state.pack) {
            setstate({
                ...state,
                orderType: location.state.pack,
            });
        }
    if (orderType) {
        setstate({
            ...state,
            orderType: orderType,
        }); 
    }
    setreferrer({
        ...referrer,
        referrer:document.referrer
    });
    }, [location.state]);
    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper
            id="order"
            style={{ display: 'flex', justifyContent: 'center' }}
            className="container mx-auto"
        >
            <SecondTitle theme={theme}>Commander maintenant</SecondTitle>
            <ButtonsWrapper>
            {orderType != 'casawe' && (
                <Button
                    onClick={() => handleOrderTypeChange('individual')}
                    className={`left-button ${
                        state.orderType === 'individual'
                            ? 'selected-botton'
                            : ''
                    }`}
                    shape="round"
                    size="large"
                >
                    Carte classique
                </Button>
            )}
                {/* <Button
                    onClick={() => handleOrderTypeChange('family')}
                    className={`right-button ${
                        state.orderType === 'family' ? 'selected-botton' : ''
                    }`}
                    shape="round"
                    size="large"
                >
                    Pack Family
                </Button> */}
                {/* <Button
                    onClick={() => handleOrderTypeChange('casawe')}
                    className={`right-button ${
                        state.orderType === 'casawe' ? 'selected-botton' : ''
                    }`}
                    shape="round"
                    size="large"
                >
                   Carte CasaWe
                </Button>
                <Button
                    onClick={() => handleOrderTypeChange('wac')}
                    className={`right-button ${
                        state.orderType === 'wac' ? 'selected-botton' : ''
                    }`}
                    shape="round"
                    size="large"
                >
                   Carte WAC
                </Button> */}
            </ButtonsWrapper>
            <Row justify="center">
                <HintCol
                    style={{justifyContent: 'unset'}}
                    className="justify-center"
                    xxl={15}
                    xl={115}
                    lg={15}
                    md={12}
                    sm={24}
                    xs={24}
                    theme={theme}
                >
                    <Hint theme={theme}>
                        <Text>Frais de souscription</Text>
                        <Text>Offert</Text>
                    </Hint>
                    <Hint>
                        <Text>Frais de gestion</Text>
                        <Text>Offert</Text>
                    </Hint>
                    <Hint>
                        {/* {state.orderType == 'family' ? (
                            <>
                                <Text>
                                    Prix du Pack (4 cartes)
                                    <br />
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        (Cartes valables 2 ans)
                                    </span>
                                </Text>
                                <Text style={{ textAlign: 'right' }}>
                                    250 DHS
                                    <br />
                                    <span style={{ fontSize: '12px' }}>
                                        au lieu de :{' '}
                                        <span
                                            style={{
                                                textDecoration: 'line-through',
                                                textDecorationColor: '#eb6807',
                                            }}
                                        >
                                            396 DHS
                                        </span>
                                    </span>
                                </Text>
                            </>
                        ) : (  */}
                        {state.orderType !== 'wac' && (
                            <>
                            {state.orderType == 'casawe' ? (
                                <>
                                    <Text>
                                        Prix de la carte
                                        <br />
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            (valables 3 ans)
                                        </span>
                                    </Text>
                                    <div style={{display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{textDecoration:'line-through', textDecorationColor: 'red'}}>120 DHS</Text>
                                        <Text>99 DHS</Text>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Text>
                                        Prix de la carte
                                        <br />
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            (Valable 2 ans)
                                        </span>
                                    </Text>
                                    <div style={{display: 'flex', flexDirection: 'column' }}>
                                        {/* <Text style={{textDecoration:'line-through', textDecorationColor: 'red'}}>99 DHS</Text>
                                        <Text>59 DHS</Text> */}
                                        <Text>99 DHS</Text>
                                    </div>
                                </>
                            )}
                          </>
                        )}
                    </Hint>
                </HintCol>
            </Row>
            <Row justify="center">
                {data.allContentfulOffer.nodes.map((offer, index) => {
                    return (
                        !(
                            // (state.orderType === 'family' &&
                            // (offer.accountType === 'Express' ||
                            //     offer.accountType === 'Confort')
                            // )||
                            (state.orderType === 'wac' &&
                            (offer.accountType === 'Express' ||
                                offer.accountType === 'Confort' || 
                                offer.accountType === 'Confort +')
                            )||
                            (state.orderType === 'individual' &&
                            (offer.accountType === 'TRIBUNE' ||
                                offer.accountType === 'PELOUSE')
                            )||
                            (
                                state.orderType === 'casawe' &&
                                (offer.accountType === 'Express' ||
                                offer.accountType === 'TRIBUNE' ||
                                offer.accountType === 'PELOUSE')
                            
                        )) && (
                            <Col
                                key={index}
                                className="justify-center"
                                xxl={6}
                                xl={8}
                                lg={10}
                                md={12}
                                sm={24}
                                xs={24}
                            >
                                <OrderCard
                                    orderType={state.orderType}
                                    accountType={offer.accountType}
                                    price={offer.priceString}
                                    offers={offer.offers}
                                    pack={state.orderType}
                                    urlSource={referrer.referrer}
                                />
                            </Col>
                        )
                    );
                })}
            </Row>
        </Wrapper>
    );
};

export default IndividualOrder;
