import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const Divider = styled.hr`
    height: 6px;
    color: #e5e5e5;
    width: 100px;
    border-radius: 20px;
    background-color: #ffa055;
    border: none;
    margin-bottom: 20px;
`;

export const Icon = styled(FontAwesomeIcon)`
    color: #ffa055;
    font-size: 40px;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 266px;
    min-height: 363px;
    background: ${({ theme }) => theme === 'light' ? '#fff' : '#212121'};
    box-shadow: -1px 7px 19px 0px ${({ theme }) => theme === 'light' ? '#e5e5e5' : '#000'}; ;
    border-radius: 15px;
    color: ${({ theme }) => theme === 'light' ? '#383838' : '#fff'};
    transition: 0.2s ease-in-out;
    cursor: pointer;
    align-items: center;
    &:hover {
        transform: scale(1.1);
        background: #ffa055;
        color: #fff;
        box-shadow: -1px 7px 19px 0px #ffa055;
    }
    &:hover ${Icon} {
        color: #fff;
    }
    &:hover ${Divider} {
        background-color: #fff;
    }
`;
export const IconWrapper = styled.div`
    padding: 20px 20px;
    width: 100px;
    height: 85px;
`;

export const Title = styled.p`
    padding: 0 20px 30px 20px;
    margin: 0;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    min-height: 76px;
    max-height: 76px;
    width: 100%;
`;

export const Content = styled.p`
    padding: 0 20px 0 20px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    max-width: 100%;
`;
