import styled from 'styled-components';

export const Input = styled.input`
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 50px;
    border: 1px solid #5f5f5f90;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    &:focus {
        border: 1px solid #e66d30;
    }
    &:hover {
        border: 1px solid #e66d30;
    }
`;
export const TextArea = styled.textarea`
    font-weight: 500;
    font-size: 16px;
    color: rgb(7, 20, 53);
    line-height: 42px;
    width: 100%;
    text-align: left;
    height: 100px;
    border: 1px solid #5f5f5f90;
    border-radius: 4px;
    padding: 8px 16px;
    outline: 0px;
    margin-top: 10px;
    &:focus {
        outline-color: #e66d30;
        outline-width: 1px;
    }
`;

export const Label = styled.label`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) =>
        theme === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.7)'};
    font-family: 'Maven Pro', sans-serif;
`;
export const Error = styled.span`
    font-size: 11px;
    color: #cf1500;
`;
