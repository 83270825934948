import React, { useState, useEffect } from 'react';
import './styles.css';

import { graphql, useStaticQuery } from 'gatsby';
import {
    ArticleRow,
    InfoWrapper,
    ImageWrapper,
    ImageCard
} from './styles';
import { Col } from 'antd';
import ArticleVideoCard from '../../common/article-video-card';
import ArticleListVideo from '../../common/article-list-videos';

const ArticlesVideo = () => {
    const [selecterVd, setSelecterVd] = useState(null)
    const { articlesVideo } = useStaticQuery(graphql`
            query {
              articlesVideo:  allContentfulArticlesVideo {
                                nodes {
                                  title
                                  videoId
                                    thumb {
                                        file {
                                          url
                                        }
                                      }
                                }
                              }
             }
        `);
    const i = articlesVideo?.nodes.length - 1
    useEffect(() => {
        if (!selecterVd) {
            setSelecterVd(articlesVideo.nodes[i])
        }
    })
    return (
        <div className="news-article">
            <ArticleRow justify="center">
                <Col className="justify-center border-left" lg={6} md={6} sm={26} xs={26} >
                    <InfoWrapper>
                        {articlesVideo?.nodes.map((item, index) => {
                            return (
                                <ImageCard key={index} background={item.thumb === null ?
                                    "https://i3.ytimg.com/vi/" + item.videoId + "/maxresdefault.jpg" : item.thumb.file.url}>
                                    <ArticleListVideo className="background-img"
                                        key={index}
                                        title={item.title}
                                        videoId={item.videoId}
                                        onHover={() => setSelecterVd(item)}>
                                    </ArticleListVideo>
                                </ImageCard>
                            );
                        })}
                    </InfoWrapper>
                </Col>
                <Col className="justify-center border-right" lg={14} md={12} sm={22} xs={22} style={{ paddingLeft: '0' }}>

                    <ImageWrapper background={selecterVd?.thumb === null ?
                        "https://i3.ytimg.com/vi/" + selecterVd.videoId + "/maxresdefault.jpg" : selecterVd?.thumb?.file?.url}
                    >
                        <ArticleVideoCard title={selecterVd?.title}
                            videoId={selecterVd?.videoId}>
                        </ArticleVideoCard>

                    </ImageWrapper>
                </Col>
            </ArticleRow>
        </div>
    );
};

export default ArticlesVideo;
