import styled from 'styled-components';

export const Title = styled.h1`
    font-family: 'Maven Pro';
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    margin: 20px 0 5px 0;
    color: #5f5f5f;
`;
export const SubTitle = styled.h2`
    font-family: 'Maven Pro';
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#a9a9a9')};
    margin: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 8px;
`;
export const Button = styled.button`
    height: 40px;
    padding: 0 30px;
    background-color: #e66d30;
    border: none;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
`;

export const BackButton = styled.button`
    background-color: transparent;
    opacity: 0.85;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    padding: 10px 0 10px;
    border: 0.5px solid #5f5f5f;
    height: 40px;
    padding: 0 30px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;


export const Wrapper = styled.div`
    display: flex;
    max-width: 700px;
    height: 550px;
    border-radius: 20px;
    border: #205993 solid 5px;
    background-color: ${({ theme }) =>
        theme === 'light' ? '#ffffff' : '#313131'};
    margin: 1rem 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: ${({ theme }) => (theme === 'light' ? '#383838' : '#ffffff')};
    @media (max-width:760px) {
        height: auto;
    }
`;

export const HeaderCard = styled.div`
    width: 100%;
    height: 16rem;
    border-radius: 12px 12px 0 0;
    background-size: cover;
    background-position: center;
    display:flex;
    flex-direction: column;

`;

export const BodyCard = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    background-size: cover;
    background-position: center;
    display:flex;
    @media (max-width:760px) {
        flex-direction: column;
    }

`;
export const ZoneCard = styled.div`
    margin-right: 2rem;
    margin-left: 0.5rem;
    border: 1px solid #b3b1b1;
    border-radius: 3px;
    padding: 0.5rem; 
    width: 70%;
    overflow: scroll;

`;

export const EventCounter = styled.p`
font-size: 0.6rem;
background-color: #205993;
color: #fff;
padding: 0 1rem;
margin: 0.5rem 0;
text-align: center;
border-radius: 0 5px 5px 0;
height: 1rem;
`;
export const EventDate = styled.p`
    font-size: 0.6rem;
    background-color: #205993;
    color: #fff;
    padding: 0 1rem;
    margin: 0.5rem 0;
    text-align: center;
    border-radius: 0 5px 5px 0;
    height: 1rem;
`;
export const EventName = styled.p`
    font-size: 2rem;
    font-weight: bold;
    padding: 0 1rem;
    text-align: center;
    margin: auto;
`;
export const Description = styled.p`
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    margin-bottom: 0px;
    font-weight: bold;
`;

export const Offer = styled.div`
    font-family: 'Maven Pro';
    font-size: 16px;
    height: 100%;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    position: relative;
`;

export const Label = styled.label`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) =>
        theme === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.7)'};
    font-family: 'Maven Pro', sans-serif;
`;

