import React, { useState } from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '10%', fontSize:'20px' }}>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The page you are looking for doesn't exist or an other error occurred.</p>
            
        </div>
    );
};

export default NotFound;
