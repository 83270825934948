import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import './styles.css';
import { AnimatedImage, Button } from '../../../common/SimpleRow/styles';
import { Title, OfferRow, ThemedImageWrapper } from './styles';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql } from 'gatsby';
import { ButtonIcon } from '../../../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../../../layout/theme/ThemeContext';
import { useContext } from 'react';
import { detect } from 'detect-browser';
const Offer = ({
    select,
    selected,
    showButton,
    title,
    leftPicture,
    rightPicture,
}) => {
    const [state, setState] = useState({
        br: '',
    });

    const data = useStaticQuery(graphql`
        query {
            contentfulEntrepriseOffer {
                title
                leftImage {
                    file {
                        url
                    }
                }
                rightImage {
                    file {
                        url
                    }
                }
            }
        }
    `);
    const selectedStyle = {
        background: '#EEA672',
        background: ` -webkit-linear-gradient(
        to right,
        #EB6807,
        #EB6807,
        #EEA672
    )`,
        background: `linear-gradient(
        to right,
        #EB6807,
        #EB6807,
        #EEA672
    )`,

        color: '#fff',
    };
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    return (
        <OfferRow br={state.br} theme={theme} style={{ marginBottom: '-35px' }}>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
                <div className="justify-center lg:col-span-2 md:col-span-0">
                    <Fade right>
                        <Title theme={theme} style={{ marginTop: '170px' }}>
                            {title}
                        </Title>
                    </Fade>
                </div>
            </div>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">

                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '10px 0 25px 0',
                        maxWidth: '83vw'
                    }}
                    className="justify-center"
                >
                    <ThemedImageWrapper
                        className="image-col"
                        theme={theme}
                    >
                        <Fade left>
                            <AnimatedImage
                                src={leftPicture}
                                alt={title}
                            />
                        </Fade>
                    </ThemedImageWrapper>
                    {showButton && (
                        <Button
                            style={selected === 'card' ? selectedStyle : {}}
                            onClick={() => select('card')}
                        >
                            Cartes collaborateurs
                        </Button>
                    )}
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        margin: '10px 0 25px 0',
                        alignItems: 'center',
                        maxWidth: '83vw'
                    }}
                    className="justify-center"
                >
                    <ThemedImageWrapper
                        className="image-col"
                        theme={theme}
                    >
                        <Fade right>
                            <AnimatedImage
                                src={rightPicture}
                                alt={title}
                            />
                        </Fade>
                    </ThemedImageWrapper>
                    {showButton && (
                        <Button
                            style={selected === 'portal' ? selectedStyle : {}}
                            onClick={() => select('portal')}
                        >
                            Plateforme de gestion
                        </Button>
                    )}
                </div>
            </div>
        </OfferRow>
    );
};

export default Offer;
