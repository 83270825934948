import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import {
    InfoWrapper,
    Title,
    SubTitle,
    ImageWrapper,
    AnimatedImage,
    ButtonWrapper,
    Button,
    ButtonIcon,
    PrimaryButton,
    SubTitleWrapper,
} from '../../common/SimpleRow/styles';
import { Zoom, Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';
import FamilyCard from '../../common/family-card';
import './styles.css';
import VdModal from '../../common/vd-modal';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Steps from '../../common/steps';
import { PrevIcon, NextIcon } from './styles';
import ThemeContext from '../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { useEffect } from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Family = () => {
    const [state, setState] = useState({
        showModal: false,
        showSteps: false,
        br: '',
    });

    const { theme } = useContext(ThemeContext);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="custom_dot"></div>,
        prevArrow: (
            <PrevIcon
                icon="caret-left"
                style={{
                    color: '#000',
                    fontSize: '10rem',
                    width: '10px',
                    left: '-5px !important',
                }}
            />
        ),
        nextArrow: (
            <NextIcon
                icon="caret-right"
                style={{
                    color: '#000',
                    fontSize: '10rem',
                    width: '10px',
                    right: '5px',
                }}
            />
        ),
    };
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "individual-family" }) {
                id
                title
                subTitle
                description {
                raw
                }
                image {
                    file {
                        url
                    }
                }
                darkImage {
                    file {
                        url
                    }
                }
                offers {
                    offer_name
                    name
                }
            }
        }
    `);

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);

    return (
        <div className='container mx-auto'>
            <div
                justify="center"
                className="row-padding grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1"
                id="family"
                style={{ paddingTop: '100px' }}
            >
                <div
                    className="justify-center"
                >
                    <InfoWrapper>
                        <Fade big left>
                            <Title theme={theme}>
                                {data.contentfulLanding.title}
                            </Title>
                        </Fade>
                        <Zoom right big cascade>
                            <SubTitle
                                theme={theme}
                                style={{ marginBottom: '0' }}
                            >
                                {data.contentfulLanding.subTitle}
                            </SubTitle>
                            <SubTitleWrapper theme={theme}>
                                {documentToReactComponents(JSON.parse(
                                    data.contentfulLanding.description.raw)
                                )}
                            </SubTitleWrapper>
                        </Zoom>
                    </InfoWrapper>
                </div>
                <div
                    style={{ flexDirection: 'column' }}
                    className="justify-center"
                >
                    <ImageWrapper>
                        {data.contentfulLanding.image && (
                            <Fade right>
                                <AnimatedImage
                                    loading="lazy"
                                    src={
                                        theme === 'dark'
                                            ? data.contentfulLanding.darkImage
                                                .file.url
                                            : data.contentfulLanding.image.file
                                                .url
                                    }
                                    alt={data.contentfulLanding.title}
                                    style={{
                                        width: `${state.br === 'ie' ? '30rem' : ''
                                            }`,
                                        maxHeight: '22rem',
                                    }}
                                />
                            </Fade>
                        )}
                    </ImageWrapper>
                    <ButtonWrapper>
                        <PrimaryButton
                            onClick={() =>
                                setState({ ...state, showModal: true })
                            }
                        >
                            Je découvre en Vidéo
                        </PrimaryButton>
                    </ButtonWrapper>
                </div>
            </div>

            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/COIQPN4neOw"
            />
        </div>
    );
};
export default Family;
