import styled from 'styled-components';
import { Row } from 'antd';


export const VideoWrapper = styled(Row)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: solid 2px rgb(156 160 169);
   
`;


export const Title = styled.h3`
    padding: 0;
    margin: 0 0 1.45rem;
    color: #fff;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 1.1rem;
    margin: 0;
    font-weight: bold;
    @media (max-width:801px) {
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }
`;
