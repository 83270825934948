import React from 'react';
import Layout from '../../components/layout';
import WydadiOrder from '../../components/wydadi/wydadi-oreder';
import ManualOrder from '../../components/individual/manual-order';
import '@fortawesome/fontawesome-free-solid'
import 'font-awesome/css/font-awesome.min.css';

const WacPage = ({ location }) => {
    return (
        
        <Layout>
            <WydadiOrder location={location}/>
            <ManualOrder />
        </Layout>
    );
};

export default WacPage;
