import React from 'react';
import Landing from './landing';
import Advantage from './advantage';
import Tpes from './tpes';
import Pack from './pack';
import Activities from './acitivities';
const Tpe = () => {
    return (
        <>
            <Landing />
            <Advantage />
            <Tpes />
            <Activities />
            {/*<Pack />*/}
        </>
    );
};
export default Tpe;
