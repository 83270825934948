import styled from 'styled-components';


export const Wrapper = styled.div`
    display: flex;
    max-width: 400px;
    border-radius: 10px;
    border: #205993 solid 3px;
    background-color: ${({ theme }) =>
        theme === 'light' ? '#ffffff' : '#313131'};
    margin: 1rem 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: ${({ theme }) => (theme === 'light' ? '#383838' : '#ffffff')};
    @media (max-width:760px) {
        height: auto;
    }
`;

export const BodyCard = styled.div`
    width: 100%;
    height: 12rem;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-position: center;
    display:flex;
    flex-direction: row;

`;

export const FooterCard = styled.div`
    width: 100%;
    height: 3.5rem;
    background-size: cover;
    background-position: center;
    display:flex;

`;

export const EventCounter = styled.p`
font-size: 0.6rem;
background-color: #205993;
color: #fff;
padding: 0 1rem;
margin: 0.5rem 0;
text-align: center;
border-radius: 0 5px 5px 0;
height: 1rem;
`;
export const EventDate = styled.p`
    font-size: 0.6rem;
    background-color: #205993;
    color: #fff;
    padding: 0 1rem;
    margin: 0.5rem 0;
    text-align: center;
    border-radius: 0 5px 5px 0;
    height: 1rem;
`;
export const EventName = styled.p`
    font-size: 0.8rem;
    width: 20%;
    font-weight: bold;
    padding: 0 1rem;
    text-align: center;
    margin-left: auto;
`;
export const Description = styled.p`
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    margin-bottom: 0px;
    font-weight: bold;
`;

export const Offer = styled.div`
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    position: relative;
`;

export const Button = styled.button`
    border-radius: 3px;
    margin-left: auto;
    border: none;
    padding: 5px 15px;
    color: #fff;
    font-family: 'Maven Pro';
    font-size: 12px;
    background: #eea672; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #eb6807,
        #eb6807,
        #eea672
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: #e66d30 0px 0px 19px -5px;
    }
`;
