import styled from 'styled-components';
import { Col } from 'antd';
export const Wrapper = styled.div`
    display: flex; 
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;

`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 2rem 0;
`;

export const Title = styled.h1`
    padding: 0;
    margin: 0 0 1.45rem;
    color: ${({ theme }) => theme === 'light' ? 'inherit' : '#fff'} ;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    text-align: center;

`;

export const SecondTitle = styled.h2`
    padding: 0;
    margin: 0 0 1.45rem;
    color: ${({ theme }) => theme === 'light' ? 'inherit' : '#fff'} ;
    font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    text-align: center;

`;

export const Hint = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 22px;
    margin: 0px 20px;
    padding: 10px 25px;
    border-bottom: 1px solid #fff;
`;
export const Text = styled.p`
    margin: 0;
    padding: 0;
`;

export const HintCol = styled(Col)`
    background-color: ${({ theme }) => theme === 'light' ? '#f3f3f3' : '#212121'} ;
    border-radius: 20px;
    height: 500px;
    margin-bottom: -250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    color: ${({ theme }) => theme === 'light' ? '' : '#fff'} ;
`;