import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Row, Col } from 'antd';
import AppCard from '../common/app-card';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.css';
import { useContext } from 'react';
import ThemeContext from '../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
export const ClientSpace = () => {
    const [state, setState] = useState({
        br: '',
    });

    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br?.name,
        });
    }, []);
    const { allContentfulApp } = useStaticQuery(graphql`
        query {
            allContentfulApp(sort: { fields: order, order: ASC }) {
                nodes {
                    title
                    content
                    url
                    row
                    image {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    const firstRow = allContentfulApp.nodes
        .filter((i) => i.row === '1')
        .map((item, index) => {
            return (
                <Col
                    key={index}
                    md={12}
                    sm={12}
                    xs={24}
                    className="justify-center"
                >
                    <AppCard
                        title={item.title}
                        content={item.content}
                        image={item.image && item.image.file.url}
                        url={item.url}
                    />
                </Col>
            );
        });
    const secondRow = allContentfulApp.nodes
        .filter((i) => i.row === '2')
        .map((item, index) => {
            return (
                <Col
                    key={index}
                    md={12}
                    sm={12}
                    xs={24}
                    className="justify-center"
                >
                    <AppCard
                        title={item.title}
                        content={item.content}
                        image={item.image && item.image.file.url}
                        url={item.url}
                        target="current"
                    />
                </Col>
            );
        });

    const { theme } = useContext(ThemeContext);
    return (
        <Wrapper br={state.br} theme={theme}>
            <Row>
                <Col xs={24}>
                    <Row
                        justify="center"
                        style={state.br === 'ie' && { width: '100%' }}
                        gutter={[24, 24]}
                    >
                        {firstRow}
                    </Row>
                    <Row
                        justify="center"
                        style={state.br === 'ie' && { width: '100%' }}
                        gutter={[24, 24]}
                    >
                        {secondRow}
                    </Row>
                </Col>
            </Row>
        </Wrapper>
    );
};
export default ClientSpace;
