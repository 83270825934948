import React, { useContext } from 'react';
import { Wrapper, Image, Name, SubName, Button, Filter } from './styles';
import { Fade } from 'react-awesome-reveal';
import { navigate } from 'gatsby';
import ThemeContext from '../../layout/theme/ThemeContext';
import { detect } from 'detect-browser';

const TpeCategoryCard = ({ isActive, name, image, from, seeDetailsClick, style }) => {
    const splitedName = name && name.split('(');
    const { theme } = useContext(ThemeContext);
    const br = detect();
    return (
        <Wrapper style={style} isActive={isActive}>
            {/* {!isActive && <Filter from={from} br={br?.name} />} */}
            <Image src={image} isActive={isActive} ></Image>
            <Name isActive={isActive}>{splitedName && splitedName[0]}</Name>
            {from !== 'partner' && (
                <SubName isActive={isActive}>{splitedName && ('(' + splitedName[1])}</SubName>
            )}

            {from !== 'partner' && (
                <Button
                    onClick={() => {
                        seeDetailsClick();
                        navigate('#category-details');
                    }}
                    isActive={isActive}
                    theme={theme}
                >
                    Découvrir
                </Button>
            )}
        </Wrapper>
    );
};

export default TpeCategoryCard;
