import styled, { css } from 'styled-components';
import { Row } from 'antd';
import Img from '../../../../images/entrepriseplatformback.svg';
import DarkImg from '../../../../images/entrepriseplatformbackdark.svg';
import IeImg from '../../../../images/ie-entrepriseplatformback.webp';
import IeDarkImg from '../../../../images/ie-entrepriseplatformbackdark.webp';
export const M3allemRow = styled.div`
    background-color: ${({ theme }) =>
        theme === 'dark' ? 'rgb(43,42,42)' : '#fff'};
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -35px;
    width: 100%;
`;
