import React from 'react';
import {
    Wrapper,
    Title,
    Content,
    Button,
    IconWrapper,
    Picture,
} from './styles';
import { Link } from 'gatsby';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
export const AppCard = ({
    title,
    content,
    image,
    url,
    from,
    state,
    button,
}) => {
    const { theme } = useContext(ThemeContext);
    const link =
        from === 'details' ? (
            <Link to={url} state={state}>
                <Button>{button ? button : 'En savoir plus'} </Button>
            </Link>
        ) : (
            <Link href={url} rel="noreferrer" target="_blank">
                <Button>Accéder</Button>
            </Link>
        );
    return (
        <Wrapper theme={theme}>
            {image && (
                <IconWrapper>
                    <Picture src={image}></Picture>
                </IconWrapper>
            )}

            <Title>{title}</Title>
            <Content theme={theme}>{content}</Content>
            {link}
        </Wrapper>
    );
};

export default AppCard;
