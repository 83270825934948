import styled from 'styled-components';

export const Wrapper = styled.div`
    min-width: 18rem;
    width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    border-radius: 20px;
    background-color: rgb(230, 109, 48);
    padding: 10px;
    transition: 0.2s ease-in-out;
`;

export const Details = styled.div`
    width: 100%;
    height: auto;
    min-height: 50px;
    background: #fff;
    border-radius: 20px;
    position: relative;
    box-shadow: -1px 7px 19px 0px #00000020;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    transition: 0.2s ease-in-out;
    margin-top: 8rem;
`;
export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 10px 0;
`;
export const PlusButton = styled.button`
    outline: none;
    border: none;
    min-width: 40px;
    min-height: 40px;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 5px;
    background-color: rgb(230, 109, 48);
    color: #fff;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        box-shadow: 0px 8px 15px rgb(230, 109, 48);
    }
`;
export const Image = styled.img`
    max-width: 9rem;
    /* margin-bottom: -8rem; */
    z-index: 2;
    position: relative;
    top: 100px;
    @media (max-width: 485px) {
        max-width: 100%;
        max-height: 16rem;
    }
`;
