import React from 'react';
import { useField } from 'formik';
import { Input, Label, Error, TextArea } from './test-field.styles';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
export const TextField = ({
    textarea,
    label,
    placeholder,
    disabled,
    maxLength,
    onChange,
    keyPress,
    ...props
}) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ padding: '5px', width: '100%' }}>
            <Label theme={theme}> {label} </Label>
            {textarea ? (
                <TextArea
                    placeholder={placeholder}
                    {...field}
                    helperText={errorText}
                    error={!!errorText}
                    type={props.type}
                    disabled={disabled}
                    theme={theme}
                />
            ) : (
                <Input
                    placeholder={placeholder}
                    {...field}
                    helperText={errorText}
                    error={!!errorText}
                    type={props.type}
                    disabled={disabled}
                    maxLength={maxLength}
                    onChange={onChange ? onChange : field.onChange}
                    onKeyPress={keyPress}
                    theme={theme}
                />
            )}

            <Error>{errorText}</Error>
        </div>
    );
};
