import React from 'react';
import { Row, Col } from 'antd';
import { Title, SubTitle } from '../landing/styles';
import { useContext } from 'react';
import ThemeContext from '../layout/theme/ThemeContext';
import { detect } from 'detect-browser';
import { useState } from 'react';
import { useEffect } from 'react';
import { ButtonIcon } from '../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Steps from '../common/steps';
import SEO from '../seo';

const Amb = () => {
    const [state, setState] = useState({
        br: '',
        showSteps: true,
    });
    useEffect(() => {
        const br = detect();
        setState({
            ...state,
            br: br.name,
        });
    }, []);
    const { theme } = useContext(ThemeContext);

    return (
        <>
            <SEO
                title="Ambassadeurs"
                description={
                    "Rejoignez le Programme Ambassadeurs NAPS et bénéficiez  d'une multitude d'avantages..."
                }
                keywords={
                    'naps, naps maroc, payment, paiement, carte, recharge, e-commecre, tpe, naps m3allem, m3allem'
                }
                url={'/pro/ambassadeurs'}
            />
            <Row
                justify="center"
                className="row-padding"
                id="ambassadeurs"
                style={{ paddingTop: '100px' }}
            >
                <Col
                    style={{ flexDirection: 'column', alignItems: 'center' }}
                    className="justify-center"
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Title
                        style={{
                            textAlign: 'center',
                            maxWidth: '700px',
                            textAlign: 'center',
                        }}
                        theme={theme}
                    >
                        Parrainez vos amis et proches !
                    </Title>
                </Col>
                <Col
                    style={{ flexDirection: 'column' }}
                    className="justify-center"
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <SubTitle theme={theme} style={{ textAlign: 'center' }}>
                        Rejoignez le Programme Ambassadeurs NAPS et bénéficiez
                        d'une multitude d'avantages...
                    </SubTitle>
                </Col>

                <Col
                    style={{ flexDirection: 'column', alignItems: 'center' }}
                    className="justify-center"
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    {state.showSteps && <Steps></Steps>}
                </Col>
            </Row>
        </>
    );
};

export default Amb;
