import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { LoadingOutlined } from '@ant-design/icons';
import SEO from '../../../../components/seo';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentFail = () => {
    const [orderId, setNum] = useQueryParam('id_commande', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });
    useEffect(() => {

        navigate('/b2c', {state: { step: 5,orderId: orderId,message:"Votre commande n'a pas abouti. Merci de réessayer.",orderType:"wac"},})

    }, []);
    return (
        <Layout className="container layout">
        <SEO title="Échec de paiement" url={'/wac/b2c/fail'} />     
        </Layout>
    );
};

export default PaymentFail;
