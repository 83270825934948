import React from 'react';
import SimpleRow from '../../common/SimpleRow';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const IndividualRecharge = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "individual-recharge" }) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
                description {
                    raw
                }
            }
        }
    `);
    return (
        <SimpleRow
            title={data.contentfulLanding.title}
            subTitle={documentToReactComponents(JSON.parse(
                data.contentfulLanding.description.raw)
            )}
            imageUrl={data.contentfulLanding.image.file.url}
            state="right"
            imgClassName="recharge-image-class"
        />
    );
};
export default IndividualRecharge;
