import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'antd';
import AdvantageCard from '../../../common/advantage-card';
import { AdvantageRow } from '../../styles';
import ThemeContext from '../../../layout/theme/ThemeContext';

const Advantage = ({ selected }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulParagraph(
                sort: { fields: number, order: ASC }
                filter: { key: { eq: "entreprise" } }
            ) {
                nodes {
                    number
                    title
                    type
                    text {
                      text
                    }
                    image {
                        file {
                            url
                        }
                    }
                  }
            }
        }
    `);
    const card = data.allContentfulParagraph.nodes
        .filter((item) => item.type === 'card')
        .map((item, index) => {
            return (
                <div
                    key={index}
                    className="justify-center"
                >
                    <AdvantageCard
                        number={item.number}
                        title={item.title}
                        content={item.text.text}
                        image={item.image ? item.image.file.url : ''}
                    />
                </div>
            );
        });
    const portal = data.allContentfulParagraph.nodes
        .filter((item) => item.type === 'portal')
        .map((item, index) => {
            return (
                <Col
                    key={index}
                    className="justify-center"
                >
                    <AdvantageCard
                        number={item.number}
                        title={item.title}
                        content={item.text.text}
                        image={item.image ? item.image.file.url : ''}
                    />
                </Col>
            );
        });
    const { theme } = useContext(ThemeContext);
    return (
        <AdvantageRow theme={theme} justify="center">
            <div className="container mx-auto dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
                {selected === 'card' ? card : portal}
            </div>
        </AdvantageRow>
    );
};

export default Advantage;
