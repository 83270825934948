import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 300px;
    height: 150px;
    background-color: ${({ theme }) =>
        theme === 'dark' ? '#212121' : '#fff'};
    box-shadow: #000 0px 0px 17px -14px;
    margin: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    flex-direction: column;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#383838')};
`;
export const Content = styled.p`
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', sans-serif;
    color: inherit;
    font-size: 12px;
    min-height: 100px;
    padding: 10px 0;
    max-width: 100%;
`;

export const Name = styled.p`
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', sans-serif;
    color: inherit;
    font-size: 13px;
`;
