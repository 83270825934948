import React from 'react';
import { Wrapper } from './styles';
import { useContext } from 'react';
import ThemeContext from '../layout/theme/ThemeContext';
const Banner = () => {
    const { theme } = useContext(ThemeContext);
    return <Wrapper theme={theme} ></Wrapper>;
};

export default Banner;
