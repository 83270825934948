import styled, { css } from 'styled-components';
import { Row } from 'antd';
import ProBack from '../../../images/proback.svg';
import ProBackDark from '../../../images/probackdark.svg';
import IeProBack from '../../../images/ie-proback.webp';
import IeProBackDark from '../../../images/ie-probackdark.webp';
export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;
export const ProRow = styled(Row)`
    align-items: center;
    height: auto;
    padding: 1rem 2rem;
    padding-top: 80px;
    /* background-image: ${({ theme }) =>
        theme === 'dark' ? `url(${ProBackDark})` : `url(${ProBack})`} ; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 2;
    min-height: 100vh;
    /* position: absolute; */
`;
export const ProBackground = styled.div`
    ${({ br }) =>
        br === 'ie'
            ? css`
                  background-image: ${({ theme }) =>
                      theme === 'dark'
                          ? `url(${IeProBackDark})`
                          : `url(${IeProBack})`};
              `
            : css`
                  background-image: ${({ theme }) =>
                      theme === 'dark'
                          ? `url(${ProBackDark})`
                          : `url(${ProBack})`};
              `};

    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
`;
