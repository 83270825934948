import React, { useState } from 'react';
import { Wrapper, IconWrapper, Icon, Title, Content, Divider } from './styles';
import { Zoom } from 'react-awesome-reveal';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
const ProductCard = ({ title, content, image, lightImage }) => {
    const [state, setState] = useState({
        currentImage: image,
    });

    const { theme } = useContext(ThemeContext);
    return (
        <Zoom>
            <Wrapper
                onMouseEnter={() =>
                    setState({ ...state, currentImage: lightImage })
                }
                onMouseLeave={() => setState({ ...state, currentImage: image })}
                theme={theme}
            >
                <IconWrapper>
                    <img
                        src={state.currentImage}
                        style={{ maxWidth: '100%' }}
                        alt={title}
                    ></img>
                </IconWrapper>
                <Title>{title}</Title>
                <Divider />
                <Content>{content}</Content>
            </Wrapper>
        </Zoom>
    );
};

export default ProductCard;
