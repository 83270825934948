import styled from 'styled-components';

export const NumbersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
export const Number = styled.div`
    width: 68px;
    height: 82px;
    border-radius: 24px;
    background-color: #f8f8f8;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 7px 19px 0px #e5e5e5;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover {
        box-shadow: -1px 7px 19px 0px rgb(230, 109, 48);
        color: #fff;
        background-color: rgb(230, 109, 48);
        transform: scale(1.1);
    }
`;
