import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { StringParam, useQueryParam } from 'use-query-params';
import { http } from '../../../components/http';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal } from 'antd';
import { Title } from '../../../components/b2c/index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
const antIcon = (
    <LoadingOutlined style={{ fontSize: 55, color: '#e66d30' }} spin />
);

const PaymentSucceeded = () => {
    const [data, setNum] = useQueryParam('data', StringParam);
    const [codecmr, setCodecmr] = useQueryParam('codecmr', StringParam);
    const [state, setState] = useState({
        isLoading: true,
        data: {},
    });
    useEffect(() => {
        http.post('/card-order/payment', { data: data, codecmr: codecmr }).then(
            (res) => {
                setState({
                    ...state,
                    isLoading: false,
                    data: res.data,
                });
            }
        );
    }, []);
    return (
        <Layout>
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {state.isLoading && <Spin indicator={antIcon} />}
                {!state.isLoading && <></>}
            </div>
            {!state.isLoading && state.data && (
                <Modal
                    visible={!state.isLoading && state.data}
                    centered
                    onCancel={() => {
                        navigate('/');
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            flexDirection: 'column',
                            textAlign: 'center',
                        }}
                    >
                        <FontAwesomeIcon
                            icon="check"
                            style={{
                                fontSize: '100px',
                                color: '#63e6be',
                                margin: '20px',
                                width: '100px',
                            }}
                        />
                        <Title style={{ fontSize: '17px', color: '#212121' }}>
                            Félicitations !
                            <br />
                            <br />
                            Votre commande est enregistrée avec succès !
                            <br />
                            <br />
                            <span
                                style={{ fontSize: '14px', color: '#c4c4c4' }}
                            >
                                Un email de confirmation a été envoyé à votre
                                adresse :
                                <br />
                                {state.data && state?.data?.email}
                                <br />
                                <br />À bientôt !
                            </span>
                            <br />
                            <br />
                        </Title>
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default PaymentSucceeded;
