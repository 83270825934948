import React from 'react';
import { Row, Col } from 'antd';
import ProductCard from '../../../common/product-card';
import { Title } from '../../../common/SimpleRow/styles';
import { Text } from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { useContext } from 'react';
import ThemeContext from '../../../layout/theme/ThemeContext';
const Advantage = () => {
    const { allContentfulProductAdvantage } = useStaticQuery(graphql`
        query {
            allContentfulProductAdvantage(
                filter: { key: { eq: "tpe" } }
                sort: { order: ASC, fields: order }
            ) {
                nodes {
                    title
                    content
                    orangeImage {
                        file {
                            url
                        }
                    }
                    lightImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <>
            <div className="container mx-auto dispal-grid grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1" justify="center">
                {allContentfulProductAdvantage.nodes.map((item, index) => {
                    return (
                        <div
                            className="justify-center"
                            style={{ padding: '20px' }}
                            xxl={4}
                            xl={5}
                            lg={8}
                            md={8}
                            sm={24}
                            xs={24}
                            key={index}
                        >
                            <ProductCard
                                title={item.title}
                                content={item.content}
                                image={item.orangeImage && item.orangeImage.file.url}
                                lightImage={item.lightImage && item.lightImage.file.url}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Advantage;