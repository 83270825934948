import React, { useState } from 'react';
import {
    Title,
    VideoWrapper,
} from './styles';
import { Col } from 'antd';
import Play from '../../../images/play.inline.svg';
import VdModal from '../vd-modal';

export const ArticleVideoCard = ({
                                     title,
                                     videoId,
                                 }) => {


        const [state, setState] = useState({
            showModal: false,
        });

        return (
            <>
                <VideoWrapper>
                    <Col className="justify-center wrapper-article">
                        <div className="style-article">
                            <Title>
                                {title}
                            </Title>

                        </div>
                    </Col>
                    {state.browser === 'ie' ? (
                        <span
                            class="material-icons"
                            style={{
                                position: 'absolute',
                                left: 'calc(50% - 30px)',
                                top: 'calc(50% - 35px)',
                                fontSize: '50px',
                                cursor: 'pointer',
                                width: '100px',
                                zIndex: 2,
                            }}
                        >
                                        play_arrow
                                    </span>
                    ) : (
                        <Play
                            onClick={() =>
                                setState({ ...state, showModal: true })
                            }
                            icon={['fa', 'play-circle']}
                            style={{
                                position: 'absolute',
                                left: 'calc(50% - 30px)',
                                top: 'calc(50% - 30px)',
                                fontSize: '50px',
                                cursor: 'pointer',
                                width: '60px',
                                zIndex: 1,
                            }}
                        />
                    )}
                </VideoWrapper>
                <VdModal
                    url={"https://www.youtube.com/embed/"+ videoId}
                    showModal={state.showModal}
                    hide={() => setState({ ...state, showModal: false })}
                ></VdModal>
            </>

        );
    }
;

export default ArticleVideoCard;
