import React, { useState } from 'react';
import SimpleRow from '../../../common/SimpleRow';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'antd';
import VdModal from '../../../common/vd-modal';
import {
    ProTitle,
    ProSubTitle,
    PrimaryButton,
    Button,
} from '../../entreprise/presentation/styles';
import { ButtonWrapper, ButtonIcon } from '../../../common/SimpleRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import B2cContactForm from '../../../b2c-contact-form';
import { detect } from 'detect-browser';
import SEO from '../../../seo';

const Presentation = () => {
    const [state, setState] = useState({
        showModal: false,
        showForm: false,
    });
    // const data = useStaticQuery(graphql`
    //     query {
    //         contentfulLanding(templateKey: { eq: "naps-m3alem" }) {
    //             title
    //             description {
    //                 content {
    //                     content {
    //                         value
    //                     }
    //                 }
    //             }
    //             image {
    //                 file {
    //                     url
    //                 }
    //             }
    //         }
    //     }
    // `);

    const br = detect();
    return (
        <div className="pro-entreprise">
            <SEO
                title="M3allem"
                // description={
                //     data.contentfulLanding.title +
                //     ',' +
                //     data.contentfulLanding.description.content[0].content[0]
                //         .value
                // }
                keywords={
                    'naps, naps maroc, payment, paiement, carte, recharge, e-commecre, tpe, naps m3allem, m3allem'
                }
                url={'/pro/m3allem'}
            />
            <Row justify="center">
                <Col className="justify-center" xxl={24} sm={24}>
                    {/* <ProTitle>{data.contentfulLanding.title}</ProTitle> */}
                </Col>
                <Col className="justify-center" md={12} sm={24}>
                    {/* <ProSubTitle>
                        {
                            data.contentfulLanding.description.content[0]
                                .content[0].value
                        }
                    </ProSubTitle> */}
                </Col>

                <Col className="justify-center" md={13} sm={24}>
                    <ButtonWrapper>
                        <Button
                            br={br?.name}
                            onClick={() =>
                                setState({
                                    ...state,
                                    showForm: !state.showForm,
                                })
                            }
                        >
                            Je prends RDV
                        </Button>
                        <PrimaryButton
                            onClick={() =>
                                setState({ ...state, showModal: true })
                            }
                            br={br?.name}
                        >
                            Je découvre en vidéo
                        </PrimaryButton>
                    </ButtonWrapper>
                </Col>
            </Row>
            <VdModal
                showModal={state.showModal}
                hide={() => setState({ ...state, showModal: false })}
                url="https://www.youtube.com/embed/VRUXQOhOfzg"
            />
            {state.showForm && <B2cContactForm pack="pro" />}
        </div>
    );
};
export default Presentation;
