import React, { useState } from 'react';
import {
    Title,
    Button,
    ButtonWrapper,
    SubTitle,
    BackButton,
} from '../../styles';
import { Row, Col } from 'antd';
import { Select } from '../../../select';
import { TextField } from '../../../text-field';
import sectors from '../sectors.json';
import volumes from '../volume.json';
import { FileField } from '../../../file-field';
import {
    adresseKeyPress,
    emailKeyPress,
} from '../../../helpers/only-characters';

const InformationForm = ({
    nextStep,
    isValid,
    setTouched,
    validate,
    setValue,
    back,
    type,
    values,
}) => {
    const handleNextClick = () => {
        setTouched({
            sector: true,
            function: true,
            organization: true,
            volume: true,
            url: true,
            cv: true,
        });
        validate().then((errors) => {
            if (Object.keys(errors).length === 0) {
                nextStep();
                setValue('step', 2);
            }
        });
    };
    const handleBackClick = () => {
        back();
        setValue('step', 0);
    };

    return (
        <>
            <Row align="middle" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Title>A propos de vous</Title>
                </Col>
            </Row>
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Select
                        data={sectors}
                        label="Secteur d'activité"
                        placeholder=""
                        name="sector"
                    ></Select>
                </Col>
            </Row>

            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <TextField
                        label="Fonction"
                        placeholder=""
                        name="function"
                        keyPress={adresseKeyPress}
                        maxLength={20}
                    ></TextField>
                </Col>
            </Row>
            <Row align="top" justify="center" gutter={[16, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <TextField
                        label="Organisme / Commerce"
                        placeholder=""
                        name="organization"
                        keyPress={adresseKeyPress}
                        maxLength={20}
                    ></TextField>
                </Col>
            </Row>
            {type === 'e-commerce' && (
                <Row align="top" justify="center" gutter={[16, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <TextField
                            label="Site web (url)"
                            placeholder=""
                            name="url"
                            maxLength="40"
                            keyPress={emailKeyPress}
                        ></TextField>
                    </Col>
                </Row>
            )}
            {(type === 'e-commerce' || type === 'tpe') && (
                <Row align="top" justify="center" gutter={[16, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Select
                            data={volumes.volumes}
                            label="Volume de transactions (Montant mensuel traité par votre commerce)"
                            placeholder=""
                            name="volume"
                        ></Select>
                    </Col>
                </Row>
            )}
            {type === 'partner' && (
                <Row align="top" justify="center" gutter={[16, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FileField
                            label="Votre CV"
                            placeholder="Votre CV"
                            name="cv"
                            setFieldValue={setValue}
                            title="Votre CV"
                        ></FileField>
                    </Col>
                </Row>
            )}
            <Row align="top" justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ButtonWrapper>
                        <Button type="button" onClick={handleNextClick}>
                            Continuer
                        </Button>
                        <BackButton type="button" onClick={handleBackClick}>
                            Retour
                        </BackButton>
                    </ButtonWrapper>
                </Col>
            </Row>
        </>
    );
};
export default InformationForm;
