import React from 'react';
import { Row, Col } from 'antd';
import { ImageWrapper, AnimatedImage, InfoWrapper, LandingRow } from '../../landing/styles';
import {
    Title,
    OfferList,
    Option,
    Button,
    SubTitle,
    OfferText,
    Image,
} from './styles';
import '../../landing/styles.css';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { LightSpeed, Zoom, Roll, Fade } from 'react-awesome-reveal';
import Goo from '../../common/animated';
import './styles.css';
import SEO from '../../seo';
import { detect } from 'detect-browser';
import { useContext } from 'react';
import ThemeContext from '../../layout/theme/ThemeContext';
const Landing = () => {

    const { theme } = useContext(ThemeContext);

    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "franchises" }) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
                offers {
                    offer_name
                    icon {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);



    return (
        <div
            className="container mx-auto auto-top-margin row-padding dispal-grid grid lg:grid-cols-2 md:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
            <SEO
                title="Franchises"
                description={
                    data.contentfulLanding.title +
                    ',' +
                    data.contentfulLanding.subTitle
                }
                url={'/franchises'}
            />
            <div>
                <ImageWrapper style={{ padding: 0 }}>
                    <Roll left>
                        <AnimatedImage
                            src={data.contentfulLanding.image.file.url}
                        />
                    </Roll>
                </ImageWrapper>
            </div>
            <div
                className="justify-center"
            >
                <InfoWrapper>
                    <Fade left>
                        <Title
                            theme={theme}
                        >
                            {data.contentfulLanding.title}
                        </Title>
                    </Fade>
                    <OfferList>
                        {data.contentfulLanding.offers.map((offer, index) => {
                            return (
                                <Option key={index} className="item"  >
                                    <Image
                                        src={
                                            offer.icon
                                                ? offer.icon.file.url
                                                : ''
                                        }
                                    ></Image>{' '}
                                    <OfferText theme={theme}>{offer.offer_name}</OfferText>
                                </Option>
                            );
                        })}
                    </OfferList>
                </InfoWrapper>
            </div>
            <div className="lg:col-span-2 md:col-span-2 justify-center">
                <Fade right>
                    <SubTitle
                        style={{ fontWeight: '400', marginBottom: '-50px', fontSize: '18px', maxWidth: '700px' }}
                    >
                        {data.contentfulLanding.subTitle}
                    </SubTitle>
                </Fade>
            </div>
            {/* <Col className="justify-center" lg={24}>
                <Button>Un large potentiel sur les marchés B2C & B2B</Button>
            </Col> */}
        </div>
    );
};
export default Landing;
