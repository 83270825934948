import React from 'react';
import SimpleRow from '../../../common/SimpleRow';
import { useStaticQuery, graphql } from 'gatsby';

const AcceptPayment = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulLanding(templateKey: { eq: "accept-payment" }) {
                id
                title
                subTitle
                image {
                    file {
                        url
                    }
                }
            }
        }
    `);
    return (
        <SimpleRow
            key="accept-payment"
            title={data.contentfulLanding.title}
            subTitle={data.contentfulLanding.subTitle}
            imageUrl={data.contentfulLanding.image.file.url}
            buttonText="En savoir plus"
            id="accepte"
            link={'/products/e-commerce'}
            buttonsToLeft={true}
        />
    );
};

export default AcceptPayment;
