import styled from 'styled-components';
import { Row } from 'antd';
export const Title = styled.h2`
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    padding: 10px 10px;
    min-
    background-color: transparent;
    border-radius: 40px;
    text-align: center;
    text-transform: capitalize;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : ''};
    @media (max-width: 485px) {
        min-width: 100%;
        width: 85vw;
    }
`;
export const UseCaseWrapper = styled(Row)`
    background: transparent;
    height: auto;
    width: 100%;
    margin-top: 20px;
    border-radius: 25px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
`;
export const NumbersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const Number = styled.div`
    width: 82px;
    height: 82px;
    border-radius: 24px;
    background-color: #f8f8f8;
    margin: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 7px 19px 0px #e5e5e5;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    cursor: pointer;
    flex-direction: column;
    transition: 0.2s ease-in-out;
    color: ${({ theme }) => theme === 'dark' ? '#fff' : '#000'};
    &:hover {
        transform: scale(1.1);
    }
`;
export const HintImage = styled.img`
    max-width: 50px;
    margin-bottom: 30px;
`;
export const CategoryTitle = styled.button`
    background-color: rgba(230, 109, 48, 0.9);
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    height: fit-content;
    padding: 10px;
    min-width: 33rem;
    border-radius: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        box-shadow: -1px 7px 19px 0px rgb(230, 109, 48);
    }
    @media (max-width: 485px) {
        min-width: 100%;
        width: 85vw;
    }
    @media (max-width: 617px) {
        min-width: 100%;
        width: 85vw;
    }
`;
