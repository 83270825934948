import React from 'react';
import { useField } from 'formik';
import { SelectField, Label, Error } from './select.styles';
export const Select = ({ label, placeholder, data,onChange, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const options =
        data &&
        data.map((option, i) => (
            <option key={i} value={option.value}>
                {' '}
                {option.label}{' '}
            </option>
        ));
    return (
        <div style={{padding: "12px 8px"}}>
            <Label> {label} * </Label>
            <SelectField
                onChange={onChange}
                placeholder={placeholder}
                {...field}
                helperText={errorText}
                error={!!errorText}
            >
                <option value=""></option>
                {options}
            </SelectField>
            <Error>{errorText}</Error>
        </div>
    );
};
