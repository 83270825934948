import React from 'react';
import B2b from '../../components/pro/b2b';
import SEO from '../../components/seo';

const B2bPage = () => {
    return (
        <>
            <B2b></B2b>
        </>
    );
};
export default B2bPage;
