import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-shadow: -1px 7px 19px 0px #e5e5e5;
    
    max-width: 210px;
    max-height: 325px;
    
    min-width: 210px;
    min-height: 325px;
    background: #fff;
    transition: .2s ease-in-out;
    margin: 10px 0;
    border-radius: 20px;
    &:hover {
        transform: scale(1.1);
    }
`;
export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    min-width: 180px;
    min-height: 180px;
    overflow: hidden;
    background: #fff;
    width: 100%;
    height: 100%;
    border: none;
    
`;

export const Text = styled.h2`
    color: #383838;
    font-size: 14px;
    letter-spacing: -0.4px;
    margin: 0 10px 0 10px;
    padding: 20px 0 10px 0;
    text-align: center;
    background: #fff;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 90px;
    margin-bottom: 0px;
`;
